import { createReducer, on } from '@ngrx/store';
import { getAuthToken, getAuthTokenFailed, getAuthTokenSuccess } from '../_actions/account.actions';

export const initialState = null;


const _authTokenReducer = createReducer(initialState,
    on(getAuthToken, (state, payload) => {
        return state;
    }),
    on(getAuthTokenSuccess, (state, payload) => {
        sessionStorage.setItem('authToken', payload)
        return payload;
    }),
    on(getAuthTokenFailed, (state, payload) => {
        return state;
    })
); 


export function authTokenReducer(state, action) {
    return _authTokenReducer(state, action);
}
