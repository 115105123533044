
import { createReducer, on } from '@ngrx/store';
import { s3UploadDocuments, s3UploadFailure, s3UploadSuccess } from '../_actions/s3-management.action';


export const initialState = null;


const _s3ManagementValidationReducer = createReducer(initialState,
  on(s3UploadDocuments, (state, payload) => {
    return state;

  }),
  on(s3UploadSuccess, (state, payload) => {
    return payload;

  }),
  on(s3UploadFailure, (state, payload) => {
    return {success: false};

  }),

);


export function s3ManagementValidationReducer(state, action) {
  return _s3ManagementValidationReducer(state, action);
}
