import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { saveSliceRateMapping, rehydrateSliceRateMapping } from '../_actions/slice-rate-mapping.actions';


const _sliceRateMappingReducer = createReducer(initialState,
    on(saveSliceRateMapping, (state, payload) => {
        sessionStorage.setItem('sliceRateMapping', JSON.stringify(payload))
        return payload;
    }),
    on(rehydrateSliceRateMapping, (state, payload) => {
        sessionStorage.setItem('sliceRateMapping', JSON.stringify(payload))
        return payload;
    }),
); 


export function sliceRateMappingReducer(state, action) {
    return _sliceRateMappingReducer(state, action);
}
