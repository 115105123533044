import { VinDecodeResponse, VinDecodeData, Vehicle } from "../_models/vehicle";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { LoggingService } from "./logging.service";
import { VINDecodeCarInfoRequest, VINDecodeRequest } from "../_models/vin-decode";
import { RatesResponse } from "../_models/rates";
import brandInfo  from "../../environments/brand.json";


@Injectable({
  providedIn: "root",
})
export class VehicleService {

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private httpClient: HttpClient, private loggingService: LoggingService) {}

  // HttpClient API get() method => vin decode and fetch vehicle information
  vin_decode(vinDecodeRequest): Observable<VinDecodeData> {
    return this.httpClient
      .get<VinDecodeResponse>(
        environment.apiUrl + "dtc-vehicle-srvc/vin/decode",
        {
          params: {
            vin: vinDecodeRequest.toUpperCase(),
          },
        }
      )
      .pipe(
        map((result) => result.data),
        retry(1),
        catchError(this.loggingService.handleError)
      );
  }

  getVinCarInfo(vin: string) {
    return this.httpClient
    .get(`${environment.apiUrl}/v1/api/verifyVin/${vin}`, {
    })
    .pipe(
      catchError(this.loggingService.handleError)
    );

  }

  getVehicleImage(vehicle: Vehicle) {
    return this.httpClient
      .get(`${environment.apiUrl}/v1/api/vehicle`, {
        params: {
          year: vehicle.year,
          make: vehicle.make,
          model: vehicle.model,
          trim: vehicle.trim,
          color: vehicle.exteriorColor,
        },
      })
      .pipe(
        catchError(this.loggingService.handleError)
      );
  }

  decodeVinGetRates(dataObject: VINDecodeRequest) {

    let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
    let page = JSON.parse(sessionStorage.getItem('pagesFlow'))
    let product = ''

    if (page && page.landingPage === 'added-security-plan' || page.landingPage === 'vehicle-service-protection') {
      if (_vehicle?.isElectric) {
        product = 'EVSC'
      } else {
        product = 'VSC'
      }
    } else if (page.landingPage === 'prepaid-maintenance' || page.landingPage === 'pre-paid-maintenance') {
      product = 'PPM'
    }

    return this.httpClient.post<RatesResponse>(`${environment.apiUrl}/v1/api/rates/getRates`, {
      vin: dataObject.vin.toUpperCase(),
      saledate: dataObject.saleDate,
      inServiceDate: dataObject.inServiceDate,
      odometer: dataObject.odometer,
      brandName: brandInfo.brand,
      customerState: dataObject.customerState,
      product: product
  })
  .pipe(
      map(response => {
          // Inject the 'product' property into the response
          return {
              ...response,
              product: product
          };
      }),
      catchError(this.loggingService.handleError)
  );
  }
}
