import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { verifyVinSuccess, verifyVin, verifyVinFailed, verifyVinReset, verifyVehCust, verifyVehCustSuccess, verifyVehCustFailed, verifyVehCustReset, verifyVehCustContactInformation, verifyVehCustSuccessContactInformation, verifyVehCustFailedContactInformation, verifyVehCustResetContactInformation } from '../_actions/vehicle.actions';


const _verifyVinReducer = createReducer(initialState,
    on(verifyVin, (state, payload) => {
        return payload;
    }),
    on(verifyVinSuccess, (state, payload) => {
        return payload;
    }),
    on(verifyVinFailed, (state, payload) => {
        return 'failed';
    }),
    on(verifyVinReset, (state, payload) => {
        return null;
    }),
    on(verifyVehCust, (state, payload) => {
        return payload
    }),
    on(verifyVehCustSuccess, (state, payload) => {
        console.log('VVC modal', payload)
        sessionStorage.setItem('vvc', JSON.stringify(payload))
        return payload
    }),
    on(verifyVehCustFailed, (state, payload) => {
        return 'failed'
    }),
    on(verifyVehCustReset, (state, payload) => {
        return null
    }),
    on(verifyVehCustContactInformation, (state, payload) => {
        return payload
    }),
    on(verifyVehCustSuccessContactInformation, (state, payload) => {
        console.log('VVC Contact Info', payload)
        sessionStorage.setItem('vvc', JSON.stringify(payload))
        return payload
    }),
    on(verifyVehCustFailedContactInformation, (state, payload) => {
        return 'failed'
    }),
    on(verifyVehCustResetContactInformation, (state, payload) => {
        return null
    })
);

export function verifyVinReducer(state, action) {
    return _verifyVinReducer(state, action);
}
