import { Component, Input, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.scss']
})
export class ModalInfoComponent implements OnInit {

  @Input() title: string;
  @Input() content: string;

  constructor(    public modalRef: MDBModalRef,
    ) { }

  ngOnInit(): void {
  }

}
