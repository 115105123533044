import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { clearCart } from 'src/app/_actions/cart.actions';
import { validateDealerSuccess } from 'src/app/_actions/dealer-actions';
import { oktaSignOut, resetOktaUser } from 'src/app/_actions/okta.actions';
import { OktaUser } from 'src/app/_models/okta';
import { Vehicle } from 'src/app/_models/vehicle';
import { HomeService } from 'src/app/_services/home.service';
import { PagesService } from 'src/app/_services/pages.service';
import brandInfo from "src/environments/brand.json";
@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss']
})
export class MobileNavComponent implements OnInit {
  @Input() logo: any;
  @Input() isLogin: boolean;
  @Input() navLinks: any;
  @Output("callbackSignOut") callbackSignOut: EventEmitter<any> = new EventEmitter();
  hasProductsMenu = false;
  oktaUserSub: Subscription;
  oktaId: any;
  access_token: any;
  showSDLogout = false;
  dealerAgent = "";
  dealer: any;
  dealerSub: Subscription;
  dealerName: string;

  constructor(
    private home: HomeService, 
    private pageService: PagesService,
    private store: Store<{
      oktaUser: any,
      dealer: any;
    }>,
  ) { }

  ngOnInit(): void {
    if (brandInfo.brand === 'NCESI') { // Nissan Canada has products dropdown menu
      this.hasProductsMenu = true;
    }

    this.oktaUserSub = this.store.select(store => store.oktaUser).subscribe(data => {
      if (data !== null && data !== false) {
        this.oktaId = data.id
        this.access_token = data.access_token
      } else if (data === false) {
        this.callbackSignOut.emit();
        this.store.dispatch(resetOktaUser())
      }
    })

    this.dealerSub = this.store.select(store => store.dealer).subscribe(data => {
      if (data && sessionStorage.getItem('appFlowState') && sessionStorage.getItem('appFlowState').includes('serviceDrive')) {
        if (data && data.data) {
          this.dealerName = data.data.name;
          if (brandInfo.brand === "Schomp") {
            this.dealerAgent = "Logout " + data.data.employeeId + "";
          } else {
            let name = " " + data.data.employeeLastName ? data.data.employeeFirstName + " " + data.data.employeeLastName.split('')[0] + "." : '';
            this.dealerAgent = "Logout " + name + "";
          }          
          this.showSDLogout = true;
        } else {
          this.dealerName = "";
          this.showSDLogout = false;
        }
      } else {
        this.dealerName = "";
        this.showSDLogout = false;
      }
    })
  }

  logoutDealerAgent() :void {
    this.closeNav();
    this.store.dispatch(validateDealerSuccess({}));
    this.store.dispatch(clearCart()); 
    this.pageService.redirectTo('/service-drive');
  }

  goToHelp(){
    if(brandInfo.brand == 'NCESI'){
      this.pageService.redirectTo('/help');
    } else {
      this.pageService.redirectTo('/help-center');
    }
  }

  redirectTo(uri){
    this.pageService.redirectTo(uri);
  }

  redirectToHome() {
    let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
    if (_vehicle.isElectric) {
      this.pageService.redirectTo(`/${brandInfo.homePageElectric}`);
    } else {
      this.pageService.redirectTo(`/${brandInfo.homePage}`);
    }
  }

  closeNav() {
    document.getElementById("mySidenav").style.marginRight = "-300px";
    setTimeout(() => {
      document.getElementById("mySidenav").style.display = "none";
    }, 400);
  }

  signOut(): void {
    let object: OktaUser = {
      id: this.oktaId,
      login: '',
      access_token: this.access_token
    }
    this.store.dispatch(resetOktaUser())
    this.store.dispatch(oktaSignOut(object))
    sessionStorage.clear()
  }

}
