import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { showPreferenceCenterInlineLoader, hidePreferenceCenterInlineLoader } from '../_actions/preference-center.actions';


const _inlineLoaderPreferenceCenterReducer = createReducer(initialState,
    on(showPreferenceCenterInlineLoader, (state) => {
      return true;
    }),
    on(hidePreferenceCenterInlineLoader, (state) => {
      return false;
    }),
); 


export function inlineLoaderPreferenceCenterReducer(state, action) {
    return _inlineLoaderPreferenceCenterReducer(state, action);
}
