import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { verifyCode, saveCode, clearCode, verifyCodeSuccess, verifyCodeFailed } from '../_actions/verify-account.actions';


const _verifyCodeReducer = createReducer(initialState,
    on(verifyCode, (state, payload) => {
        return state;
    }),
    on(saveCode, (state, payload) => {
        sessionStorage.setItem('code', JSON.stringify(payload))
        return payload;
    }),
    on(clearCode, (state, payload) => {
        sessionStorage.removeItem('code');
        return null;
    }),
    on(verifyCodeSuccess, (state, payload) => {
        return true;
    }),
    on(verifyCodeFailed, (state, payload) => {
        return payload;
    }),

);

export function verifyCodeReducer(state, action) {
    return _verifyCodeReducer(state, action);
}