import { trigger, state, style, animate, transition, query, group, animateChild } from '@angular/animations';

export const fadeOut = trigger('fadeOutAnimation', [
  transition(':leave', [
    style({ opacity: 1 }),
    animate('600ms 500ms', style({ opacity: 0 })) // Added 500ms to account for page rendering
  ])
]);

export const fadeIn = trigger('fadeInAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(600, style({ opacity: 1 }))
  ])
]);

export const fadeAnimation = trigger('fadeAnimation',
[
  transition('* => fade', [
    style({ opacity: 0 }),
    animate('.3s', style({ opacity: 1 }))
  ]),
  transition('fade => *', [
    style({ opacity: 1 }),
    animate('.3s', style({ opacity: 0 }))
  ])
]);

export const fadeInOut = trigger('fadeInOut',
[
  transition(':enter', [
    style({ opacity: 0 }),
    animate('.5s', style({ opacity: 1 }))
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('.1s', style({ opacity: 0 }))
  ])
]);


