import * as ActionConfigurationManager from './config.actions';


export const paypalAuthorizationApproved = ActionConfigurationManager.AUTHORIZED_PAYPAL_APPROVED;
export const paypalChangePaymentOptionClick = ActionConfigurationManager.CHANGE_PAYMENT_OPTION_CLICK;
export const cancelAuthorizedPayment = ActionConfigurationManager.CANCEL_AUTHORIZED_PAYMENT;

export const cancelAuthorizedPaymentSuccess = ActionConfigurationManager.CANCEL_AUTHORIZED_PAYMENT_SUCCESS;
export const cancelAuthorizedPaymentFailed = ActionConfigurationManager.CANCEL_AUTHORIZED_PAYMENT_FAILED;


export const rehydratePaypal = ActionConfigurationManager.REHYDRATE_PAYPAL;


export const paypalPickedAsOption = ActionConfigurationManager.PAYPAL_PICKED_AS_OPTION;
export const paypalOauth2Token = ActionConfigurationManager.PAYPAL_OAUTH2_TOKEN;
export const paypalOauth2TokenSuccess = ActionConfigurationManager.PAYPAL_OAUTH2_TOKEN_SUCCESS;
export const paypalOauth2TokenFailure = ActionConfigurationManager.PAYPAL_OAUTH2_TOKEN_FAILURE;


export const paypalSetupToken = ActionConfigurationManager.PAYPAL_SETUP_TOKEN;
export const paypalSetupTokenSuccess = ActionConfigurationManager.PAYPAL_SETUP_TOKEN_SUCCESS;
export const paypalSetupTokenFailure = ActionConfigurationManager.PAYPAL_SETUP_TOKEN_FAILURE;

export const createPaypalPaymentToken = ActionConfigurationManager.CREATE_PAYPAL_PAYMENT_TOKEN;
export const createPaypalPaymentTokenSuccess = ActionConfigurationManager.CREATE_PAYPAL_PAYMENT_TOKEN_SUCCESS;
export const createPaypalPaymentTokenFailure = ActionConfigurationManager.CREATE_PAYPAL_PAYMENT_TOKEN_FAILURE;
