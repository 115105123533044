import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { loadConfirmationNumber } from '../_actions/confirmation.actions';


const _confirmationNumberReducer = createReducer(initialState,
    on(loadConfirmationNumber, (state, payload) => {
        console.log('Confirmation Number', state, payload)
        sessionStorage.setItem('confirmationNumber', JSON.stringify(payload))
        return payload;
    })
);

export function confirmationNumberReducer(state, action) {
    return _confirmationNumberReducer(state, action);
}