import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Observable } from 'rxjs';
import { selectEmail, selectEmailError } from '../states/marketingEmail/marketingEmail.selectors';
import * as EmailActions from '../states/marketingEmail/marketingEmail.actions';



@Component({
  selector: 'app-login',
  templateUrl: './modal-marketing-email.component.html',
  styleUrls: ['./modal-marketing-email.component.scss']
})

export class ModalMarketingEmailComponent {
  emailForm: UntypedFormGroup;
  email: string = '';
  emailPattern = "[[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$";
  email$: Observable<string>;
  error$: Observable<any>;

  constructor(public modalRef: MDBModalRef,private store: Store) { }

  ngOnInit() {
    this.emailForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.emailPattern)])
    });

  }

  signup() {

    if (this.emailForm.invalid) {
      this.checkForm(this.emailForm);
      console.log('form invalid');
    } else {
      console.log('form values: ', this.emailForm.value);
      // Marketing logic here
      this.store.dispatch(EmailActions.submitEmail({ email: this.emailForm.value.email }));
      this.modalRef.hide();
    }
  }

  checkForm(form) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
}

