export class MakeResponse {
    status: string;
    data: Make[];
    errorMessage: string;
}

export class Make {
    makeId: number;
    code: string;
    description: string;
    name: string;
}

