
<section class="modal-content">
    <div class="modal-header mb-n1">
      <span aria-hidden="true"><mdb-icon class="modal-close-icon" tabindex="0" aria-label="Close" (click)="stateChangeModal.hide()" fas icon="times"></mdb-icon></span>
    </div>
    <div class="modal-body">
      <div class="row text-center">
        <div class="col-md-12 text-center">

            <h2 class="mb-4">{{title}}</h2>
            <p>{{data}}</p>
        </div>
      </div>
    </div>
</section>