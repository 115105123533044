import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { cookiesAccepted, cookiesDeclined } from '../_actions/cookie-acceptance.actions';

@Component({
  selector: 'app-cookie-acceptance',
  templateUrl: './cookie-acceptance.component.html',
  styleUrls: ['./cookie-acceptance.component.scss']
})
export class CookieAcceptanceComponent implements OnInit, OnDestroy, AfterViewInit {
  cookieAcceptanceSub: Subscription;
  showModal: boolean = false;

  constructor(
    public modalRef: MDBModalRef,
    public store: Store<{
      cookiesAccepted: boolean
    }>
  ) { }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.cookieAcceptanceSub = this.store.select(store => store.cookiesAccepted)
      .subscribe(boolean => {
        if (boolean !== null) {
          console.log('Modal should be hiding')
          this.showModal = false;
        } else {
          console.log('Modal should be showing')
          this.showModal = true;
        }
    })
  }

  ngOnDestroy() {
    if (this.cookieAcceptanceSub) {
      this.cookieAcceptanceSub.unsubscribe()
    }
  }

  acceptCookies() {
    this.store.dispatch(cookiesAccepted())
    this.modalRef.hide()
  }

  declineCookies() {
    this.store.dispatch(cookiesDeclined())
    this.modalRef.hide()
  }

}
