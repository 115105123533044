import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { saveBilling } from '../_actions/billing.actions';


const _billingReducer = createReducer(initialState,
    on(saveBilling, (state, payload) => {
        console.log('Billing Loaded', state, payload)
        return payload;
    })
);

export function billingReducer(state, action) {
    return _billingReducer(state, action);
}