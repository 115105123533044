import { createReducer, on } from "@ngrx/store";
import * as PrismicActions from './prismic2.actions'
import { prismicContent, prismicLandingPage } from "src/app/_models/prismic";


export interface PrismicState {
  prismicLandingPage?: prismicLandingPage;
  prismicContent?: prismicContent;
  error?: string;
}

export const initialPrismicState: PrismicState = {
  prismicLandingPage: null,
  prismicContent: null,
  error: null
};

export const PrismicReducers = createReducer(
  initialPrismicState,
  on(PrismicActions.loadPrismic2, (state, { prismicLandingPage }) => ({
    ...state,
    prismicLandingPage,
    error: null
  })),
  on(PrismicActions.loadPrismic2Successs, (state, { prismicContent }) => ({
    ...state,
    prismicContent,
    error: null
  })),
  on(PrismicActions.loadPrismic2Failure, (state, { errorMessage }) => ({
    ...state,
    error: errorMessage
  }))
);
