export let nissanvsc = [{
  productName: "NISSAN VEHICLE SERVICE PROTECTION",
  productNumber: "01t550000036ZT0AAM",
  productCode: "REVS",
  productFormNumber: null,
  productExternalId: "5d7d0281-d08a-455c-9586-2b1eaa23a823",
  productSfId: "01t550000036ZT0AAM",
  statusCode: 100,
  message: "Successfully retrieved product plans for this product",
  surcharge: null,
  productPlanList: [
    {
      planName: "PLATINUM COVERAGE",
      planNumber: "a0555000008RQ1gAAG",
      referenceNumber1: "N1",
      planCode: "VSCPLAT",
      planExternalId: "c015e066-d94e-463c-ade5-5ca9894a593c",
      deductible: "100.0",
      planTermSKUList: [
        {
          planTermSKUName: "6 YEARS, 75,000 KILOMETRES",
          planTermSKURate: "980.00",
          planTermSKUNumber: "a0655000004ZuA8AAK",
          productSkuSfId: "a0755000007jqEFAAY",
          productPlanSkuId: "178572",
          contractDealerCost: "490.0",
          productSkuTerm: 72.0,
          productSkuMileage: 75000.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
      ],
    },
    {
      planName: "PLATINUM ECO COVERAGE",
      planNumber: "a0555000008RQ1lAAG",
      referenceNumber1: "N2",
      planCode: "VSCPLAT",
      planExternalId: "809a1f19-4c2d-4f5d-9d7e-0a1ddc3082ef",
      deductible: "100.0",
      planTermSKUList: [
        {
          planTermSKUName: "7 YEARS, 100,000 KILOMETRES",
          planTermSKURate: "1316.00",
          planTermSKUNumber: "a0655000004ZuAaAAK",
          productSkuSfId: "a0755000007jqEhAAI",
          productPlanSkuId: "178575",
          contractDealerCost: "658.0",
          productSkuTerm: 84.0,
          productSkuMileage: 100000.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
      ],
    },
    {
      planName: "GOLD COVERAGE",
      planNumber: "a0555000008RQ1bAAG",
      referenceNumber1: "N3",
      planCode: "VSCGOLD",
      planExternalId: "4bdd1648-1fdc-4591-9993-8f71bef7476f",
      deductible: "100.0",
      planTermSKUList: [
        {
          planTermSKUName: "6 YEARS, 75,000 KILOMETRES",
          planTermSKURate: "874.00",
          planTermSKUNumber: "a0655000004Zu9nAAC",
          productSkuSfId: "a0755000007jqDuAAI",
          productPlanSkuId: "178571",
          contractDealerCost: "437.0",
          productSkuTerm: 72.0,
          productSkuMileage: 75000.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "7 YEARS, 100,000 KILOMETRES",
          planTermSKURate: "1120.00",
          planTermSKUNumber: "a0655000004Zu9vAAC",
          productSkuSfId: "a0755000007jqE2AAI",
          productPlanSkuId: "178574",
          contractDealerCost: "560.0",
          productSkuTerm: 84.0,
          productSkuMileage: 100000.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
      ],
    },
  ],
}]
