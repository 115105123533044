import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject, filter, takeUntil } from 'rxjs';
import brandInfo from "src/environments/brand.json";

@Component({
  selector: 'app-mobile-plan-footer',
  templateUrl: './mobile-plan-footer.component.html',
  styleUrls: ['./mobile-plan-footer.component.scss']
})
export class MobilePlanFooterComponent implements OnInit {

  @Input() plan: any;
  @Output() emitAddToCart = new EventEmitter<string>();
  destroy$ = new Subject<void>();
  currentPlan: any;
  brand: string;

  constructor(private translate: TranslateService,public store: Store<{selectedPlan: any}>) { }

  

  ngOnInit(): void {
    this.brand = brandInfo.brand;
    this.store.select((store) => store.selectedPlan).pipe(
      filter(val => val),
      takeUntil(this.destroy$),
      ).subscribe((val) => {
        if(val.currentSelectedPlan){
          console.log('footer val.currentSelectedPlan: ', val.currentSelectedPlan)
          this.currentPlan = val.currentSelectedPlan
        }
      })
  }

  addToCart(){
    console.log("addToCart")
    this.emitAddToCart.emit("addToCart");

  }

  convertTermLabel(term:string) {

    term = term.toUpperCase();
    var years1 = (term.includes(' YEARS') ? term.split(' YEARS') : term.includes(' YEAR(S)') ? term.split(' YEAR(S)') : term.split(' YEAR'));
    var years2 = (term.includes(' YEARS, ') ? term.split(' YEARS, ') : term.includes(' YEAR(S), ') ? term.split(' YEAR(S), ') : term.split(' YEAR, '));
    return (years1[0]) + ' ' + this.translate.instant("GENERIC.YEARS") + ' ' + this.translate.instant("RATES_PAGE.OR") + ' ' + years2[1].split(' ')[0] + ' ' + this.translate.instant("GENERIC.MILEAGE_PLURAL").toLowerCase()
  }

}
