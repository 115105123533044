import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { updateBillingStateOn, updateBillingStatOff } from '../_actions/account.actions';


const _updateBillingStateReducer = createReducer(initialState,
    on(updateBillingStateOn, (state) => {
      // To add a lead to state, use spread operator
      return true;
    }),
    on(updateBillingStatOff, (state) => {
      // To add a lead to state, use spread operator
      return false;
    })
);

export function updateBillingStateReducer(state, action) {
    return _updateBillingStateReducer(state, action);
}
