<div class="row" *ngIf="screen == 'LOGIN'">
    <div class="col-sm-12 col-md-6 offset-md-3 mt-4">
        <div><strong>{{component.primary.title[0].text}}</strong></div>
        <div>{{component.primary.subtitle[0].text}}</div>

        <div class="p-0" *ngIf="badDealerLogin">
            <div class="mt-3">
                <div class="alert alert-warning verify-alert w-100 pb-0 text-center" role="alert">{{ 'SERVICE_DRIVE.CANT_LOGIN' | translate }}</div>
            </div>
        </div>

        <form role="form" class="dealership_login" [formGroup]="loginForm">
            <div class="md-form mt-5">
                <label *ngIf="thisBrand == 'Lambo'" id="dealerlabel" for="dealershipId">{{ 'SERVICE_DRIVE.DEALER_CODE' | translate }}</label>
                <label *ngIf="thisBrand != 'Lambo'" id="dealerlabel" for="dealershipId">{{ 'SERVICE_DRIVE.DEALERSHIP_ID' | translate }}</label>
                <input aria-labelledby="dealerlabel" mdbInput mdbValidate type="text" id="dealershipId"
                    formControlName="dealershipId" class="form-control" maxlength="17" />
                <mdb-error role="alert"
                    *ngIf="loginForm.controls.dealershipId.invalid && (loginForm.controls.dealershipId.dirty || loginForm.controls.dealershipId.touched)">
                    {{ 'SERVICE_DRIVE.DEALER_CODE_REQ' | translate }}
                </mdb-error>
            </div>

            <div class="md-form mt-5"
                *ngIf="thisBrand == 'Lambo' ||thisBrand == 'Schomp' || thisBrand == 'BMW' || thisBrand == 'MINI'">
                <label *ngIf="thisBrand != 'Lambo'" id="employeelabel" for="employeeId">{{ 'SERVICE_DRIVE.EMPLOYEE_ID' | translate }}</label>
                <label *ngIf="thisBrand == 'Lambo'" id="employeelabel" for="employeeId">{{ 'SERVICE_DRIVE.EMPLOYEE_EMAIL' | translate }}</label>
                <input *ngIf="thisBrand != 'Lambo'" aria-labelledby="employeelabel" mdbInput mdbValidate type="text"
                    id="employeeId" formControlName="employeeId" class="form-control" minlength="5" maxlength="5" />
                <input *ngIf="thisBrand == 'Lambo'" aria-labelledby="employeelabel" mdbInput mdbValidate type="email"
                    id="employeeId" formControlName="employeeId" class="form-control" maxlength="40" />
                <mdb-error role="alert"
                    *ngIf="thisBrand != 'Lambo' && loginForm.controls.employeeId.invalid && (loginForm.controls.employeeId.dirty || loginForm.controls.employeeId.touched)">
                    {{ 'SERVICE_DRIVE.EMPLOYEE_ID_REQ' | translate }}
                </mdb-error>
                <mdb-error role="alert"
                    *ngIf="thisBrand == 'Lambo' && loginForm.controls.employeeId.invalid && (loginForm.controls.employeeId.dirty || loginForm.controls.employeeId.touched)">
                    {{ 'SERVICE_DRIVE.EMPLOYEE_EMAIL_REQ' | translate }}
                </mdb-error>
            </div>
            <div class="md-form mt-5" *ngIf="thisBrand == 'Hyundai' || thisBrand == 'HPP'">
                <label *ngIf="thisBrand == 'Hyundai' || thisBrand == 'HPP'" id="employeelabel" for="employeeId">HMA {{ 'SERVICE_DRIVE.EMPLOYEE_ID' | translate }}</label>
                <label *ngIf="thisBrand != 'Hyundai' && thisBrand != 'HPP'" id="employeelabel" for="employeeId">{{ 'SERVICE_DRIVE.EMPLOYEE_ID' | translate }}</label>
                <input aria-labelledby="employeelabel" mdbInput mdbValidate type="text" id="employeeId"
                    formControlName="employeeId" class="form-control" minlength="12" maxlength="12" />
                <mdb-error role="alert"
                    *ngIf="loginForm.controls.employeeId.invalid && (loginForm.controls.employeeId.dirty || loginForm.controls.employeeId.touched)">
                    *<span *ngIf="thisBrand == 'Hyundai' || thisBrand == 'HPP'">HMA </span>{{ 'SERVICE_DRIVE.EMPLOYEE_ID_REQ' | translate }}
                </mdb-error>
            </div>

            <div class="md-form mt-5"
                *ngIf="thisBrand == 'Lambo' || thisBrand == 'Hyundai' || thisBrand == 'HPP' || thisBrand == 'BMW' || thisBrand == 'MINI'">
                <label id="employeeFirstNameLabel" for="employeeFirstName">{{ 'SERVICE_DRIVE.EMPLOYEE_FIRST_NAME' | translate }}</label>
                <input aria-labelledby="employeeFirstNameLabel" mdbInput mdbValidate type="text" id="employeeFirstName"
                    formControlName="employeeFirstName" class="form-control" maxlength="17" />
                <mdb-error role="alert"
                    *ngIf="loginForm.controls.employeeFirstName.invalid && (loginForm.controls.employeeFirstName.dirty || loginForm.controls.employeeFirstName.touched)">
                    {{ 'SERVICE_DRIVE.EMPLOYEE_FIRST_NAME_REQ' | translate }}
                </mdb-error>
            </div>

            <div class="md-form mt-5"
                *ngIf="thisBrand == 'Lambo' || thisBrand == 'Hyundai' || thisBrand == 'HPP' || thisBrand == 'BMW' || thisBrand == 'MINI'">
                <label id="employeeLastNameLabel" for="employeeLastName">{{ 'SERVICE_DRIVE.EMPLOYEE_LAST_NAME' | translate }}</label>
                <input aria-labelledby="employeeLastNameLabel" mdbInput mdbValidate type="text" id="employeeLastName"
                    formControlName="employeeLastName" class="form-control" maxlength="17" />
                <mdb-error role="alert"
                    *ngIf="loginForm.controls.employeeLastName.invalid && (loginForm.controls.employeeLastName.dirty || loginForm.controls.employeeLastName.touched)">
                    {{ 'SERVICE_DRIVE.EMPLOYEE_LAST_NAME_REQ' | translate }}
                </mdb-error>
            </div>
            <div class="mt-5">
                <button mdbBtn class="modal-component-see-pricing-btn btn primary-btn flat waves-light" mdbWavesEffect
                    type="button" (click)="validateDealer('CAPTURE')">
                    {{ 'SERVICE_DRIVE.SCAN_VIN' | translate }}
                </button>
            </div>
            <div class="text-center w-100 mt-2"><a (click)="validateDealer('CUSTOMER'); readonly = false;"
                    class="blue-link uppercase">{{ 'SERVICE_DRIVE.MANUAL_VIN_INPUT' | translate }}</a>
            </div>
        </form>
    </div>
</div>

<div class="row" *ngIf="screen == 'CAPTURE'">
    <div class="col-12">
        <div class="text-center w-100 mt-2">
            <div class="preloader-wrapper active indicator mt-5 mb-5" *ngIf="showCamera == false">
                <div class="spinner-layer spinner-client-color">
                    <div class="circle-clipper left">
                        <div class="circle"></div>
                    </div>
                    <div class="gap-patch">
                        <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                        <div class="circle"></div>
                    </div>
                </div>
            </div>
            <div id="scandit-barcode-picker" [class]="{'show': showCamera == true}"></div>
            <div [hidden]="!barcodeValues || barcodeValues.length == 0" class="mt-2">
                <strong>{{ 'SERVICE_DRIVE.CLICK_TO_USE' | translate }}</strong><br>
                <div class="mt-2 mb-2" *ngFor="let barcode of barcodeValues"><button class="btn primary-btn"
                        (click)="useVIN(barcode)">{{ barcode }}</button></div>
            </div>
            <div class="text-center w-100 mt-2"><a (click)="validateDealer('CUSTOMER'); readonly = false;"
                    class="blue-link uppercase">{{ 'SERVICE_DRIVE.MANUAL_VIN_INPUT' | translate }}</a></div>
            <div class="mt-2 mb-2"><a (click)="loadView('LOGIN')" class="blue-link uppercase">{{ 'SERVICE_DRIVE.BACK_TO_LOGIN' | translate }}</a></div>

        </div>
    </div>
</div>

<div class="row" *ngIf="screen == 'CUSTOMER'">
    <div class="col-sm-12 col-md-4 offset-md-4 mt-4 mb-4">
        <div><strong>{{component.primary.vin_title[0].text}}</strong></div>
        <div>{{component.primary.vin_subtitle[0].text}}</div>
    </div>

    <div class="p-0 col-12" *ngIf="showError">
        <div class="mb-3">
            <div *ngIf="thisBrand !== 'Lambo'" class="alert alert-warning verify-alert w-100 pb-0 text-center"
                role="alert">{{ 'SERVICE_DRIVE.NOT_ELIGIBLE' | translate }}</div>
            <div *ngIf="thisBrand === 'Lambo' && errorMessage === 1" class="alert alert-warning verify-alert w-100 pb-0 text-center"
                role="alert">{{ 'SERVICE_DRIVE.NOT_ELIGIBLE_LAMBO' | translate }}.</div>
            <div *ngIf="thisBrand === 'Lambo' && errorMessage === 2" class="alert alert-warning verify-alert w-100 pb-0 text-center"
                role="alert">{{ 'ERRORS.NO_ELIGIBLE_PRODUCTS' | translate }} {{branding.cs_phone_number }}</div>
        </div>
    </div>

    <div class="manual_container col-12">
        <form role="form" class="vehicle_login row" [formGroup]="customerForm">
            <div class="vehicle pr-3 pl-3 mt-3">
                <div class="vehicle_login row">
                    <div class="col-12 pb-5 pr-1 pl-1">
                        <div class="md-form">
                            <label id="vinlabel" for="vin">{{ 'CART_CHECKOUT.VIN' | translate }}</label>
                            <input aria-labelledby="vinlabel" mdbInput mdbValidate type="text" id="vin"
                                oninput="this.value = this.value.toUpperCase()" [readonly]="readonly"
                                formControlName="vin" class="form-control" minlength="17" maxlength="17"
                                [class]="{'grey-input': readonly}" autocomplete="off"
                                (change)="lookupVin(customerForm.controls.vin.value)" />

                            <mdb-error role="alert"
                                *ngIf="customerForm.controls.vin.invalid && (customerForm.controls.vin.dirty || customerForm.controls.vin.touched)">
                                <span *ngIf="!customerForm.controls.vin.errors?.pattern">*{{ 'ASP_LANDING.VIN_REQUIRED'
                                    | translate }}</span>
                                <span
                                    *ngIf="customerForm.controls.vin.errors?.pattern && customerForm.controls.vin.errors?.pattern.requiredPattern.indexOf('[a-zA-Z0-9]{0,17}') > -1">*{{
                                    'ASP_LANDING.VALID_VIN' | translate }}</span>
                                <span
                                    *ngIf="customerForm.controls.vin.errors?.pattern && customerForm.controls.vin.errors?.pattern.requiredPattern.indexOf('[a-zA-Z0-9]{0,12}[0-9]{5}$') > -1">
                                    <span><strong class="uppercase">*{{ 'ASP_LANDING.INVALID_VIN' | translate }}
                                    </strong></span>
                                <span>{{ 'ASP_LANDING.INVALID_VIN_MSG_3' | translate }}</span></span>
                                <span
                                    *ngIf="customerForm.controls.vin.errors?.pattern && customerForm.controls.vin.errors?.pattern.requiredPattern.indexOf('[^IOQioq ]{0,17}') > -1">
                                    <span><strong class="uppercase">*{{ 'ASP_LANDING.INVALID_VIN' | translate }}
                                        </strong></span>
                                    <span>{{ 'ASP_LANDING.INVALID_VIN_MSG' | translate }}</span>
                                </span>
                            </mdb-error>
                            <mdb-error role="alert" *ngIf="customerForm.controls.vin.valid && invalidVin">
                                {{ 'ASP_LANDING.VALID_VIN' | translate }}
                            </mdb-error>
                        </div>
                    </div>
                    <div class="col-4 pr-1 pl-1 pb-5">
                        <div class="md-form">
                            <label id="yearlabel" for="year">{{ 'CART_CHECKOUT.YEAR' | translate }}</label>
                            <input aria-labelledby="yearlabel" mdbInput mdbValidate type="number" id="year" readonly
                                formControlName="year" class="form-control grey-input" max="9999" />
                            <mdb-error role="alert"
                                *ngIf="customerForm.controls.year.invalid && (customerForm.controls.year.dirty || customerForm.controls.year.touched)">
                                *Year required.
                            </mdb-error>
                        </div>
                    </div>
                    <div class="col-4 pr-1 pl-1 pb-5">
                        <div class="md-form">
                            <label id="makeLabel" for="make">{{ 'CART_CHECKOUT.MAKE' | translate }}</label>
                            <input aria-labelledby="makeLabel" mdbInput mdbValidate type="text" id="make" readonly
                                formControlName="make" class="form-control grey-input" maxlength="20" />
                            <mdb-error role="alert"
                                *ngIf="customerForm.controls.make.invalid && (customerForm.controls.make.dirty || customerForm.controls.make.touched)">
                                *Make required.
                            </mdb-error>
                        </div>
                    </div>
                    <div class="col-4 pr-1 pl-1 pb-5">
                        <div class="md-form">
                            <label id="modelLabel" for="model">{{ 'CART_CHECKOUT.MODEL' | translate }}</label>
                            <input aria-labelledby="modelLabel" mdbInput mdbValidate type="text" id="model" readonly
                                formControlName="model" class="form-control grey-input" maxlength="30" />
                            <mdb-error role="alert"
                                *ngIf="customerForm.controls.model.invalid && (customerForm.controls.model.dirty || customerForm.controls.model.touched)">
                                *Model required.
                            </mdb-error>
                        </div>
                    </div>
                    <div class="col-12 pr-1 pl-1 pb-1">
                        <div class="md-form">
                            <label id="odometerlabel" for="odometer">{{ 'CART_CHECKOUT.ODOMETER' | translate }}</label>
                            <input aria-labelledby="odometerlabel" mdbInput mdbValidate type="text" id="odometer"
                                formControlName="odometer" class="form-control" (keypress)="onlyNumberKey($event)"
                                (focus)="removeCommas()" (change)="removeCommas()" (blur)="addCommas()" max="100000" />
                            <mdb-error role="alert"
                                *ngIf="customerForm.controls.odometer.invalid && (customerForm.controls.odometer.dirty || customerForm.controls.odometer.touched)">
                                {{ 'SERVICE_DRIVE.ODOMETER_REQ' | translate }}
                            </mdb-error>
                        </div>

                        <span class="valid-photo"
                            *ngIf="thisBrand == 'Hyundai' || thisBrand == 'HPP' || thisBrand == 'BMW' || thisBrand == 'MINI'">{{ 'SERVICE_DRIVE.VALID_ODOMETER' | translate }}</span>
                    </div>
                    <div class="col-12 pr-1 pl-1 pb-5 mt-5" *ngIf="thisBrand != 'Hyundai' && thisBrand != 'HPP' && thisBrand != 'Lambo' && thisBrand != 'BMW' && thisBrand != 'MINI'">
                        <div class="md-form text-left no-shadow">
                            <label id="warrantylabel" for="hasWarranty">{{ 'SERVICE_DRIVE.NEW_CAR_WARRANTY' | translate }}</label>
                            <button (click)="setWarranty(true)"
                                [class]="{active: customerForm.controls.hasWarranty.value == true}"
                                class="btn uppercase warranty_btn">{{ 'RATES_PAGE.YES' | translate }}</button>&nbsp;
                            <button (click)="setWarranty(false)"
                                [class]="{active: customerForm.controls.hasWarranty.value == false}"
                                class="btn uppercase warranty_btn">{{ 'RATES_PAGE.NO' | translate }}</button>
                            <div class="w-100 error-message mt-2">{{ 'SERVICE_DRIVE.INSPECTION_DOCUMENT' | translate }}</div>
                        </div>
                    </div>
                    <div class="col-12 pr-1 pl-1 text-left" *ngIf="thisBrand == 'Lambo'">
                        <span class="inspection-statement">{{ 'SERVICE_DRIVE.PASS_CHECKLIST' | translate }}</span>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="col-12">
        <div class="mt-5">
            <button mdbBtn class="modal-component-see-pricing-btn btn primary-btn flat waves-light" (click)="getRates()"
                mdbWavesEffect type="submit">
                {{ 'SERVICE_DRIVE.VIEW_RATES' | translate }}
            </button>
        </div>
        <div class="text-center w-100 mt-2">
            <a (click)="loadView('CAPTURE')" class="blue-link uppercase">{{ 'SERVICE_DRIVE.CHANGE_VEHICLE' | translate }}</a>
        </div>
    </div>
</div>