import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { savePlan, rehydratePlan, commercialPlan, savePlanRatesPage } from '../_actions/plan.actions';

const _planReducer = createReducer(initialState,
    on(savePlan, (state, payload) => {
      console.log('Plan Saved',state, payload);
      window.sessionStorage.setItem('plan', JSON.stringify(payload));
      return payload;
    }),
    on(savePlanRatesPage, (state, payload) => {
      console.log('Plan Saved',state, payload);
      window.sessionStorage.setItem('planRatesPage', JSON.stringify(payload));
      return state;
    }),
    on(rehydratePlan, (state, payload) => {
      // console.log('Rehydrate Plan',state, payload);
      window.sessionStorage.setItem('plan', JSON.stringify(payload));
      return payload;
    }),
    on(commercialPlan, (state, payload) => {
      console.log('commercialPlan commercialPlan', state, payload)
      return payload;
  }),

);

export function planReducer(state, action) {
    return _planReducer(state, action);
}
