<!-- COMPARE PLANS -->
<div class="content-margin w-100" [ngStyle]="{ 'margin-top': slice.primary.margin_top+'px', 'margin-bottom': slice.primary.margin_bottom+'px' }">
<div class="compare" *ngFor="let field of slice.fields">
    <div class="container">
        <div class="row p-3">
            <div class="col-12 text-center">
                <div tabindex="0" role="heading" [innerHTML]="getHtml(slice.primary.products_title, vehicle)"></div>
                <div tabindex="0" [innerHTML]="getHtml(slice.primary.products_description, vehicle)"></div>
            </div>
        </div>
        <ngx-slick-carousel class="carousel" [config]="slide2Config" #slickModal="slick-carousel">
            <div ngxSlickItem *ngFor="let plan of field.product.plans" class="slide">
                <div class="w-100 pt-2 pl-2 pr-2 pb-4">
                    <mdb-card class="shadow">
                        <!-- <mdb-card-img *ngIf="slide.plan?.plan_image" width="100%" src="{{ slide.plan.plan_image }}"></mdb-card-img> -->
                        <mdb-card-body>
                            <mdb-card-title class="text-center">
                                <h3 role="heading" class="mt-3" [innerHTML]="getHtml(plan.plan.plan_title, vehicle)"></h3>
                                <p [innerHTML]="getHtml(plan.plan.short_description, vehicle)"></p>

                            </mdb-card-title>
                            <hr />
                            <mdb-card-text class="w-100 float-left">
                                <div class="w-100 float-left">
                                    <ul style="list-style: none; padding-left: 10px;">
                                        <!-- This is the loop for the included items -->
                                        <li *ngFor="let feature of plan.plan.components" class="{{ feature.class }} float-left col-6 pt-1 pb-1">
                                            <!-- <img width="24" [src]="feature.component_icon.url" alt="" /> -->
                                            <mdb-icon class="float-left mr-1 green-text" style="font-size:16px;" fas icon="check"></mdb-icon>
                                            <!-- <span class="float-left" [innerHTML]="getHtml(feature.component_title, vehicle)"></span> -->
                                            <span class="float-left" style="color:#333333;"> <h6>{{feature.component_title[0].text}}</h6></span>
                                        </li>
                                        <!-- This is the loop for the non-included items based on the non-included slice in each plan -->
                                       <div *ngFor="let planBodyItem of plan.plan.body">
                                         <div *ngIf="planBodyItem.type == 'not_included'">
                                            <li *ngFor="let notIncludedItem of planBodyItem.fields" class="float-left col-6 pt-1 pb-1" aria-hidden="true">
                                                <mdb-icon class="float-left mr-1" style="font-size:16px; color:#bbbbbb;" fas icon="times"></mdb-icon>
                                                <span class="float-left" style="color:#bbbbbb;"> <h6>{{notIncludedItem.component[0].text}}</h6></span>
                                            </li>
                                         </div>
                                       </div>
                                       
                                    </ul>
                                </div>
                                <div  *ngFor="let planBodyItem of plan.plan.body">
                                    <div class="w-100 float-left text-center">
                                        <div class="mt-4 p-3" *ngIf="planBodyItem.type == 'cta'">
                                            <button type="button" aria-label="{{planBodyItem.primary.cta_text}}" mdbBtn class="btn {{planBodyItem.primary.cta_background_color}}-btn mb-4" mdbWavesEffect (click)="openLink(planBodyItem.primary.ref_id, planBodyItem.primary.link_to)"><span tabindex="0" role="button">{{planBodyItem.primary.cta_text}}</span></button>
                                        </div>
                                    </div>
                                </div>
                            </mdb-card-text>
                        </mdb-card-body>
                    </mdb-card>
                </div>
            </div>
        </ngx-slick-carousel>
        <div class="row pl-4 pr-4">
            <div class="col-12">
                <div class="pl-3 pr-3" *ngFor="let productSection of field.product.body">
                    <mdb-card class="shadow" *ngIf="productSection.type == 'all_plans_include'" >
                        <mdb-card-body >
                            <mdb-card-title class="text-center">
                                <!-- <div [innerHTML]="getHtml(productSection.primary.title)"></div> -->
                                <h3>{{productSection.primary.title[0].text}}</h3>
                            </mdb-card-title>
                            <mdb-card-text class="text-center w-100 pt-4">
                                <div *ngFor="let allPlanItem of productSection.fields">
                                    <!-- <div [innerHTML]="getHtml(allPlanItem.component)"></div> -->
                                    <p><strong>{{allPlanItem.component[0].text}}</strong></p>
                                </div>
                                <!-- <p><strong>Towing</strong></p>
                                <p><strong>Rental Car Reimbursement</strong></p> -->
                            </mdb-card-text>
                        </mdb-card-body>
                    </mdb-card>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<!-- <pre>{{ slice | json }}</pre> -->
