<div class="row bg-white" *ngIf="disclaimers">
    <div class="col-md-12">
      <div class="container">
        <div class="p-4 pt-5 text-muted">
          <p>
            <strong class="footer-demo-text" [innerHTML]="disclaimers"></strong>
          </p>
        </div>
      </div>
    </div>
  </div>