<div class="modal-content">
  <div class="modal-header mb-n1">
    <span aria-hidden="true"><mdb-icon tabindex="0" class="modal-close-icon" aria-label="Close" (click)="modalRef.hide()" fas icon="times"></mdb-icon></span>
  </div>
  <div class="modal-body p-0">
    <div class="row pl-3 pr-3">
      <div class="col-12 text-center">
        <h2 class="mb-5">improve your experience</h2>

        <div class="row justify-content-md-center">
          <div class="col-md-10 text-center">
            <h4 class="mb-3">Please update your browser</h4>
            <p>You are using a web browser we don't support. Try one of these options to have a better experience.</p>
            <div class="mt-5 mb-5 d-flex flex-row justify-content-center">
              <img class="browser-img" src="assets/img/browsers/chrome.png" alt="" />
              <img class="browser-img" src="assets/img/browsers/edge.png" alt="" />
              <img class="browser-img" src="assets/img/browsers/firefox.png" alt="" />
              <img class="browser-img" src="assets/img/browsers/opera.png" alt="" />
              <img class="browser-img" src="assets/img/browsers/safari.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
