import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PrismicService } from 'src/app/_services/prismic.service';

@Component({
  selector: 'app-slice-hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: ['./hero-section.component.scss']
})
export class HeroSectionComponent implements OnInit {
  @Input() public slice: any;
  @Input('vehicleObject') public vehicle: any;
  @Output() emitOpenModal = new EventEmitter<string>();

  constructor(private prismicService: PrismicService) {}

  brand = 'Rev Motors';
  year = sessionStorage.getItem('vehicleYear');
  model = sessionStorage.getItem('vehicleModel');
  trim = sessionStorage.getItem('vehicleTrim');

  ngOnInit() {

    // console.log('hero_slice: ', this.slice.primary.hero.body);
    // console.log('vehicle: ', this.vehicle);

  }

  openModal(data: string) {
    this.emitOpenModal.emit(data);
  }

  getHtml(content, vehicleObject) {
    return this.prismicService.getHtml(content, vehicleObject);
  }

}
