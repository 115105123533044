<div class="parts-covered-background">
    <div class="row">
        <div class="col-12">
            <div class="container protection">
                <h2>{{plan?.componentCoverage.coverageHeader}}</h2>
                <span [innerHTML]="plan?.componentCoverage.coverageComponent ? getHtml(plan?.componentCoverage.coverageComponent) : '<h3>Component Coverage</h3>'"></span>
            </div>
            <div class="container parts">
                <span [innerHTML]="plan?.componentCoverage.partsInclude ? getHtml(plan?.componentCoverage.partsInclude) : '<h3>Covered parts include, but are not limited to:</h3>'"></span>
                <div class="col-sm-12 offset-md-1 col-md-10">
                    <div class="d-flex flex-column flex-wrap align-self-start justify-content-start covered-parts">
                        <div class="row covered-part" *ngFor="let coveredPart of plan?.componentCoverage.coveredParts;">
                            <div class="col-1">

                                <mdb-icon *ngIf="coveredPart.checked" aria-hidden="true" width="100%" fas icon="check"></mdb-icon>
                                <mdb-icon class="gray" *ngIf="!coveredPart.checked" aria-hidden="true" width="100%" fas icon="times"></mdb-icon>
                              </div>
                              <div class="col-10" style="text-align: left; padding-left:4px;">
                                <span aria-label="{{ coveredPart.name }}">{{ coveredPart.name }}</span>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
