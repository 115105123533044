import * as ActionConfigurationManager from './config.actions';

export const success = ActionConfigurationManager.SUCCESS
export const ratesError = ActionConfigurationManager.ERROR
export const clear = ActionConfigurationManager.CLEAR
export const invalidState = ActionConfigurationManager.INVALID_STATE
export const productExist = ActionConfigurationManager.PRODUCT_EXIST
export const showInlineLoaderModal = ActionConfigurationManager.SHOW_INLINE_LOADER_MODAL
export const hideInlineLoaderModal = ActionConfigurationManager.HIDE_INLINE_LOADER_MODAL
export const lamboNeverEligible = ActionConfigurationManager.LAMBO_NEVER_ELIGIBLE
export const notWithinSixtyDays = ActionConfigurationManager.NOT_WITHIN_SIXTY_DAYS