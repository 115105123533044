<div class="row justify-content-md-center">
    <div class="alert alert-warning col-md-8 col-12 text-center" role="alert" *ngIf="(session$ | async) === 'invalid'">
        <div *ngIf="showIcon"><i class="fas fa-times-circle"></i></div>
        {{ 'MY_ACCOUNT_LOGIN.LOGGED_OUT' | translate }}
    </div>
    <div class="alert alert-warning col-md-8 col-12 text-center" role="alert" *ngIf="(account$ | async) === false">
        <div *ngIf="showIcon"><i class="fas fa-times-circle"></i></div>
        {{ 'ERRORS.LOGIN_ERROR' | translate: { phone: branding.cs_phone_number } }}
    </div>
    <div class="alert alert-warning col-md-8 col-12 text-center" role="alert" *ngIf="(verifyAccount$ | async) === 'invalid'" >
        <div *ngIf="showIcon"><i class="fas fa-times-circle 3x"></i></div>
        {{ 'MY_ACCOUNT_LOGIN.ERROR_INFO' | translate }}
    </div>
</div>