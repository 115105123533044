import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { saveAbandonedCart, storeCustomerData } from '../_actions/abandon-cart.actions';


const _customerReducer = createReducer(initialState,
    on(storeCustomerData, (state, payload) => {
      sessionStorage.setItem('customerData', JSON.stringify(payload));
      return payload;
    })
);


export function customerReducer(state, action) {
    return _customerReducer(state, action);
}
