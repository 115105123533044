import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as EmailActions from './marketingEmail.actions';
import { environment } from "../../../environments/environment";

@Injectable()
export class EmailEffects {
  submitEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailActions.submitEmail),
      mergeMap((action) =>
        this.http.post(`${environment.apiUrl}/v1/api/opportunities/captureMarketingEmail`, { email: action.email }).pipe(
          map(() => EmailActions.submitEmailSuccess()),
          catchError((error) => of(EmailActions.submitEmailFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private http: HttpClient) {}
}