import { Injectable } from "@angular/core";
import brandInfo from "../../environments/brand.json";
import { PrismicService } from "./prismic.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class GoogleMapsService {

  constructor(private prismicService: PrismicService) {}

  loadScriptTag(): void {

    let prismicEnv = this.prismicService.getEnvironment("prod");

    let language = sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language

    const googleMaps = document.createElement('script');
    // googleMaps.setAttribute('async', 'true');
    googleMaps.setAttribute('type','text/javascript');
    googleMaps.setAttribute('src','https://maps.googleapis.com/maps/api/js?key=' + prismicEnv.googleMapsKey + '&libraries=places&language=' + language.split('-')[0])
    document.documentElement.firstChild.appendChild(googleMaps);
    
  }
}
