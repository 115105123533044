import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { showInlineLoaderModal, hideInlineLoaderModal } from '../_actions/modal-component.actions';


const _inlineLoaderModalReducer = createReducer(initialState,
    on(showInlineLoaderModal, (state) => {
        console.log('Modal Inline Loader - true')
      return true;
    }),
    on(hideInlineLoaderModal, (state) => {
        console.log('Modal Inline Loader - false')
      return false;
    }),
); 


export function inlineLoaderModalReducer(state, action) {
    return _inlineLoaderModalReducer(state, action);
}
