import * as ActionConfigurationManager from './config.actions';

export const verifyAccount = ActionConfigurationManager.VERIFY_ACCOUNT;
export const accountVerified = ActionConfigurationManager.ACCOUNT_VERIFIED;
export const verifyCode = ActionConfigurationManager.VERIFY_CODE;
export const verifyCodeSuccess = ActionConfigurationManager.VERIFY_CODE_SUCCESS;
export const verifyCodeFailed = ActionConfigurationManager.VERIFY_CODE_FAILED
export const saveCode = ActionConfigurationManager.SAVE_VERIFICATION_CODE
export const clearCode = ActionConfigurationManager.REMOVE_VERIFICATION_CODE;
export const rehydrateVerifiedAccount = ActionConfigurationManager.REHYDRATE_VERIFIED_ACCOUNT;
export const resetVerifiedAccount = ActionConfigurationManager.RESET_VERIFIED_ACCOUNT
export const invalidAccount = ActionConfigurationManager.INVALID_ACCOUNT
export const verifyAccountOktaFlow = ActionConfigurationManager.VERIFY_ACCOUNT_OKTA_FLOW
export const verifyAccountOktaFlowSuccess = ActionConfigurationManager.VERIFY_ACCOUNT_OKTA_FLOW_SUCCESS
export const verifyAccountOktaFlowFailed = ActionConfigurationManager.VERIFY_ACCOUNT_OKTA_FLOW_FAILED