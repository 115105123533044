<div class="modal-content">
    <div class="modal-header mb-n1 text-right">
        <span aria-hidden="true"><mdb-icon tabindex="0" class="modal-close-icon" aria-label="Close"
                (click)="modalRef.hide()" fas icon="times"></mdb-icon></span>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 text-center">
                <h2>MINI Big Things Coming Your Way!</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center mb-4">
                <p>Please enter your email address below to receive information on when MINI Motoring Protection will be
                    available for purchase.</p>
                <form class="dealership_login" [formGroup]="emailForm">
                    <div class="md-form mt-5">
                        <label>Email</label>
                        <input matInput formControlName="email" name="firstName" class="form-control" maxlength="100"
                            required>
                        <mdb-error role="alert"
                            *ngIf="emailForm.controls.email.invalid && (emailForm.controls.email.dirty || emailForm.controls.email.touched)">
                            *Email required.
                        </mdb-error>
                    </div>
                    <div class="mt-4 mb-4 disclaimer">
                        <p>We will only send you details pertaining to MINI Vehicle Motoring Protection plans.</p>
                    </div>
                    <div class="mt-4 mb-3">
                        <button mdbBtn type="button" (click)="signup()"
                            class="modal-email-button btn primary-btn flat waves-light" mdbWavesEffect type="button">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>