import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { compareTaxes, submitVertexTax, taxesCompared, vertexTaxSubmitted } from '../_actions/vertex-tax.actions';


const _vertexTaxReducer = createReducer(initialState,
    on(submitVertexTax, (state, payload) => {
        return state;
    }),
    on(vertexTaxSubmitted, (state, payload) => {
        window.sessionStorage.setItem('tax', JSON.stringify(payload))
        return payload;
    }),
    on(compareTaxes, (state, payload) => {
        return state;
    }),
    on(taxesCompared, (state, payload) => {
        return payload;
    })
);

export function vertexTaxReducer(state, action) {
    return _vertexTaxReducer(state, action);
}
