<div class="modal-content">
  <div class="modal-header mb-n1">
    <span aria-hidden="true"><mdb-icon tabindex="0" class="modal-close-icon" aria-label="Close" (click)="modalRef.hide()" fas icon="times"></mdb-icon></span>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center">
        <h3>{{ title }}</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center">
        <div class="pb-4">{{ subheader }}</div>
        <img [src]="imageURL" style="width:100%" />
      </div>
      <div class="col-6 text-right bold mb-3"><strong>{{ 'ASP_LANDING.DASH' | translate }}</strong></div>
      <div class="col-4 text-right bold mb-3"><strong>{{ 'ASP_LANDING.DOOR' | translate }}</strong></div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <a class="btn primary-btn waves-light uppercase" mdbBtn mdbWavesEffect (click)="modalRef.hide()"> {{ 'GENERIC.CLOSE' | translate }}</a>
      </div>
    </div>
  </div>
</div>
