import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/appconfig/appconfig.service';
import { resetMyAccount } from 'src/app/_actions/account.actions';
import { showInlineLoader } from 'src/app/_actions/inline-loader.actions';
import { verifyCode } from 'src/app/_actions/verify-account.actions';
import { resetInvalidCodeState } from 'src/app/_actions/verify-code-state.actions';
import { VerifyCodeRequest } from 'src/app/_models/verify-account';

@Component({
  selector: 'app-my-account-verify-code',
  templateUrl: './my-account-verify-code.component.html',
  styleUrls: ['./my-account-verify-code.component.scss']
})
export class MyAccountVerifyCodeComponent implements OnInit {
  @Output() viewEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() resetEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() verifyType: string
  @Input() verifyCodeRequest: VerifyCodeRequest

  _verifyCodeRequest: VerifyCodeRequest = {
    contactType: '',
    contact: '',
    lastName: '',
    code: ''
  }
  _verifyType: string
  codeValidationForm: UntypedFormGroup;
  verifyCodeState$: Observable<any>;
  inlineLoader$: Observable<any>;
  branding: any;

  constructor(
    public store: Store<{
      verifyCodeState: any,
      inlineLoader: any
    }>,
    private appConfig: AppConfigService
  ) { }

  ngOnInit(): void {
    this.verifyCodeState$ = this.store.select(store => store.verifyCodeState)
    this.inlineLoader$ = this.store.select(store => store.inlineLoader)
    
    this.codeValidationForm = new UntypedFormGroup({
      code: new UntypedFormControl(null, [Validators.required, Validators.pattern("^[0-9]*$")]),
    });

    this.appConfig.getSettings().subscribe((result) => {
      this.branding = result;
    });
  }

  ngOnChanges() {
    this._verifyType = this.verifyType
    this._verifyCodeRequest = this.verifyCodeRequest

    console.log('_verifyType', this._verifyType)
  }

  clearForms() {
    this.resetEmitter.emit(true)
    this.codeValidationForm.reset()
    this.store.dispatch(resetInvalidCodeState())
    this.store.dispatch(resetMyAccount())
    this.viewEmitter.emit('1')
  }

  validateCode() {
    this.store.dispatch(resetInvalidCodeState())
    this._verifyCodeRequest.code = this.codeValidationForm.controls["code"].value
    this.store.dispatch(showInlineLoader())
    this.store.dispatch(verifyCode(this._verifyCodeRequest))
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
}
