import * as ActionConfigurationManager from './config.actions';
import { Action } from '@ngrx/store';

export const decodeVinPH = ActionConfigurationManager.DECODE_VIN_PRODUCT_HOME;
export const vinDecodedSuccess = ActionConfigurationManager.LOAD_DECODED_VIN;
export const decodeVinVV = ActionConfigurationManager.DECODE_VIN_VERIFY_VIN;
export const rehydrateVin = ActionConfigurationManager.REHYDRATE_VIN
export const vinDecodeStateTrue = ActionConfigurationManager.VIN_DECODE_SUCCESS;
export const vinDecodeStateFalse = ActionConfigurationManager.VIN_DECODE_FAILED;
export const decodeVinGetRates = ActionConfigurationManager.DECODE_VIN_GET_RATES;
export const decodeVinGetCarInfo = ActionConfigurationManager.DECODE_VIN_GET_CAR_INFO;
export const decodeVinGetCarInfoSuccess = ActionConfigurationManager.DECODE_VIN_GET_CAR_INFO_SUCCESS;
export const decodeVinGetCarInfoFail = ActionConfigurationManager.DECODE_VIN_GET_CAR_INFO_FAIL;