import { createReducer, on } from '@ngrx/store';
import { verifyVehicleVinInformation, verifyVehicleVinInformationFailed, verifyVehicleVinInformationSuccess,  } from '../_actions/vehicle.actions';

export const initialState = null;

const _verifyVehicleVinInfoReducer = createReducer(initialState,

    on(verifyVehicleVinInformation, (state, payload) => {
        return payload;
    }),
    on(verifyVehicleVinInformationSuccess, (state, payload) => {
        console.log('payload payload ', payload)

        return payload;
    }),

    on(verifyVehicleVinInformationFailed, (state, payload) => {
        return payload;
    }),



);


export function verifyVehicleVinInfoReducer(state, action) {
    return _verifyVehicleVinInfoReducer(state, action);
}