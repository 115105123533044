import * as ActionConfigurationManager from './config.actions';

export const abandonCartTrigger = ActionConfigurationManager.ABANDON_CART_TRIGGER
export const abandonCartTriggerSuccess = ActionConfigurationManager.ABANDON_CART_TRIGGER_SUCCES
export const saveAbandonedCart = ActionConfigurationManager.SAVE_ABANDONED_CART
export const saveAbandonedCartSuccess = ActionConfigurationManager.SAVE_ABANDONED_CART_SUCCESS
export const saveAbandonedCartFailed = ActionConfigurationManager.SAVE_ABANDONED_CART_FAILED
export const getAbandonedCart = ActionConfigurationManager.GET_ABANDONED_CART
export const getAbandonedCartSuccess = ActionConfigurationManager.GET_ABANDONED_CART_SUCCESS
export const getAbandonedCartFailed = ActionConfigurationManager.GET_ABANDONED_CART_FAILED
export const storeCustomerData = ActionConfigurationManager.STORE_CUSTOMER_DATA
export const resetAbandonCart = ActionConfigurationManager.RESET_ABANDONED_CART
