import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { loadLead, leadLoaded, rehydrateLead, loadLeadOpportunity, leadLoadedOpportunity } from '../_actions/lead.actions';


const _leadReducer = createReducer(initialState,
    on(loadLead, (state, payload) => {
      console.log('Load Lead', state, payload)
      return payload;
    }),
    on(leadLoaded, (state, payload) => {
      console.log('Lead Loaded Success',state, payload);
      window.sessionStorage.setItem('lead', JSON.stringify(payload));
      return payload;
    }),
    on(rehydrateLead, (state, payload) => {
      // console.log('Rehydrate Lead', state, payload);
      sessionStorage.setItem('lead', JSON.stringify(payload));
      return payload;
    }),
    on(loadLeadOpportunity, (state, payload) => {
      console.log('Load Lead Opp', state, payload)
      return payload;
    }),
    on(leadLoadedOpportunity, (state, payload) => {
      console.log('Lead Loaded Success Opp',state, payload);
      window.sessionStorage.setItem('lead', JSON.stringify(payload));
      return payload;
    }),
);


export function leadReducer(state, action) {
    return _leadReducer(state, action);
}
