import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { loadEmail, updateEmail, rehydrateEmail } from '../_actions/email.actions';


const _emailReducer = createReducer(initialState,
    on(loadEmail, (state, payload) => {
        console.log('Email Loaded', state, payload);
        sessionStorage.setItem('email', JSON.stringify(payload))
        return payload;
    }),
    on(updateEmail, (state, payload) => {
        console.log('Email Updated', state, payload);
        sessionStorage.setItem('email', JSON.stringify(payload))
        return payload;
    }),
    on(rehydrateEmail, (state, payload) => {
        console.log('Rehydrated Email', state, payload)
        sessionStorage.setItem('email', JSON.stringify(payload))
        return payload;
    })
);

export function emailReducer(state, action) {
    return _emailReducer(state, action);
}