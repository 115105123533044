import { Component, OnInit, Input } from '@angular/core';
import { PrismicService } from 'src/app/_services/prismic.service';

@Component({
  selector: 'app-component-info-carousel',
  templateUrl: './info-carousel.component.html',
  styleUrls: ['./info-carousel.component.scss']
})
export class InfoCarouselComponent implements OnInit {

  vehicle: any;

  @Input() public component: any;
  @Input() public text_color: any;
  @Input() public vehicleObject: any;
  @Input() public text_align: any;



  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    arrows: true,
    infinite: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  addSlide() {
    // this.slides.push();
  }
  removeSlide() {
    // this.slides.length = this.slides.length - 1;
  }
  slickInit(e) {
    // console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }
  afterChange(e) {
    console.log('afterChange');
    setTimeout(() => {
      let slides = document.querySelectorAll('.slick-slide');
      slides.forEach((slide, index) => {
        slide.setAttribute('aria-hidden', 'false');
        if (slide.getAttribute('id') === '') {
          slide.removeAttribute('id');
        }
      })
    })
  }
  beforeChange(e) {
    console.log('beforeChange');
  }

  constructor(private prismicService: PrismicService) { }

  ngOnInit() {
    setTimeout(() => {
      let slides = document.querySelectorAll('.slick-slide');
      slides.forEach((slide, index) => {
        slide.setAttribute('aria-hidden', 'false');
        if (slide.getAttribute('id') === '') {
          slide.removeAttribute('id');
        }
      })
    })
  }


  getHtml(content, vehicleObject) {
    return this.prismicService.getHtml(content, vehicleObject);
  }

}
