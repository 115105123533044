import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Order } from '../_models/order'
import { AccountResponse } from '../_models/account';
import { environment } from '../../environments/environment'
import brandInfo from '../../environments/brand.json';

import { GatewayService } from './gateway.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  /**
   * Order Service Methods
   * Add Order
   * Get Order
   * Update Order
   */

  constructor(private httpClient: HttpClient, private gatewayService: GatewayService) { }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  get_order(orderId): Observable<AccountResponse> {
    console.log('GETORDERORDERID', orderId)
    console.log(orderId);
    return this.httpClient.get<AccountResponse>(this.gatewayService.baseUrl + 'dtc-order-srvc/orders/account/' + orderId.payload + '?brandName=' + brandInfo.brand)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  check_for_active_order(object): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl}/v1/api/order/activeOrder`, {
      vin: object.vin,
      email: object.email,
      productCode: object.productCode,
      brandName: brandInfo.brand
    })
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getDiscount(object): Observable<any> {
    return this.httpClient.post<any>(`${this.gatewayService.baseUrl}dtc-payment-srvc/addDiscount`, {
      discountName: object.discountName,
      state: object.state,
      brand: brandInfo.brand,
      type: object.paymentType,
      price: object.price
    })
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
