<div class="select-your-plan">
  <div class="row">
    <div class="col-12 text-center p-2 w-100 pt-5">
      <div *ngIf="title" [innerHTML]="getHtml(title, null)"></div>
      <div *ngIf="!title">
        <h2>Select Your Plan</h2>
      </div>
      <div [innerHTML]="getHtml(subTitle, null)"></div>
      <div *ngIf="allowDiscounts">
        <p>Get 5% off full payment with code <b>FULLPAY5</b></p>
      </div>
    </div>
  </div>
  <div class="plans-container w-100">
    <ngx-slick-carousel #slickModal="slick-carousel" class="carousel w-100" [config]="slickConfig"
      (beforeChange)="beforeChange($event)">
      <div ngxSlickItem *ngFor="let plan of plansToDisplay; let i = index" class="slide"
        style="position:relative; text-align: center;">
        <div class="plan" [ngClass]="{'disabled': !plan.term}">
          <div class="plan-header">{{plan?.header ?? ''}}</div>
          <div *ngIf="!isWAState && !fullPayOnly && brand !== 'Lambo'" class="plan-price"><strong><span
                *ngIf="plan?.term?.payment; else na1">${{
                plan?.term?.payment | number:
                ".2" }}*</span><ng-template #na1>N/A</ng-template></strong></div>
          <div *ngIf="!isWAState && !fullPayOnly && brand !== 'Lambo'" class="plan-cost"><span *ngIf="plan?.term?.totalPrice; else na2">Total
              cost is
              ${{plan?.term?.totalPrice
              | number: ".2" }}</span><ng-template #na2>Unavailable Option</ng-template></div>

          <div *ngIf="isWAState || fullPayOnly || brand ==='Lambo'" class="plan-price"><strong><span
                *ngIf="plan?.term?.totalPrice; else na3">${{
                plan?.term?.totalPrice | number:
                ".2" }}*</span><ng-template #na3>N/A</ng-template></strong></div>

          <div class="plan-detail">{{plan?.term?.payment ? plan.subHeader : '&nbsp;'}}</div>
          <div *ngIf="!multiDeductible && brand !== 'Lambo'" class="plan-deductible">
            <hr />
            <div class="mt-1">
              {{ 'CART_CHECKOUT.DEDUCTIBLE_CLAIM' | translate }}<br /><strong>{{plan.plan.deductible | currency: "USD" :
                "symbol-narrow" : "0.0": this.preferredLanguage}}</strong></div>
          </div>
          <button class="plan-button btn secondary-btn mt-3" style="font-size:80% !important"
            [ngClass]="{'highlighted': i === currentSelectedIndex}" (click)="selectPlan(i)">{{plan.name}}</button>
            <div style="display: none;">PLAN REFERENCE NUMBER: {{plan.plan.referenceNumber}}</div>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
  <div class="row">
    <div class="col-12 text-center p-2 w-100">
      <div class="plans-footer">
        <div class="plans-footer-text" *ngIf="!isWAState && !fullPayOnly && brand !=='Lambo' else WAS">
          {{ 'RATES_PAGE.LOWEST_MONTHLY_1' | translate }}
          <strong> {{ 'RATES_PAGE.SPLIT_PAYMENT' | translate: { numberOfPayments: paymentPlanCount,
            noFee: noFee } }}</strong>
          <span *ngIf="brand !== 'NCESI'">{{ 'RATES_PAGE.SPLIT_MONTHLY_3' | translate: { deductible: deductible |
            currency: "USD" :
            "symbol-narrow" : "0.0": this.preferredLanguage } }}</span>
          <div class="plans-footer-text">
            {{ 'RATES_PAGE.PRICES_EXCLUDE_DUE' | translate }}
          </div>
        </div>
        <ng-template #WAS>
          <div class="plans-footer-text">
            *{{ 'RATES_PAGE.PRICES_EXCLUDE' | translate }}
          </div>
        </ng-template>

      </div>
    </div>
  </div>
  <div class="row syp-add-to-cart">
    <div class="col-12 text-center p-2 w-100 desktop">
      <div class="sub-button-container">
        <div class="sub-button">
          <button (click)="addToCart()" class="btn primary-btn">{{ 'RATES_PAGE.ADD_TO_CART' | translate }}</button>
        </div>
      </div>
    </div>
    <div class="col-12 text-center p-2 w-100" *ngIf="paypal">
      <div class="paypal-checkout-option">
        <div class="paypal-select-pay-logo">
          <img src="../../../assets//paypal/paypal-select-your-paln.png">
        </div>
        <div class="text"> {{ 'RATES_PAGE.PAYPAL_PAYMENT_AVAILABILITY' | translate }}</div>
      </div>
    </div>
  </div>
</div>