<div class="select-container" *ngIf="brand !== 'HPP' && brand !== 'Hyundai'">
  <div class="row">
    <div class="col-12">
      <div class="container row">
        <div class="col-12 offset-md-1 col-md-10 d-flex justify-content-center plan-select-array">
          <div class="plan-select" tabindex="0" aria-label="{{ plan?.name }}" *ngFor="let plan of plans; let i = index"
            (click)="setPlan(i)" [ngClass]="{ active: plan.active === true }" id="chose-plan-{{ i }}">
            <!-- <div class="plan-header">{{ plan?.header }} &nbsp;</div> -->
            <div id="chose-plan-name-{{ i }}">{{ plan?.name }}</div>
          </div>
        </div>
        <div class="col-12 offset-md-1 col-md-10 d-flex justify-content-center">
          <div class="plan-select-border" *ngFor="let plan of plans; let i = index"></div>
        </div>

        <div class="col-12 offset-md-1 col-md-10 d-flex justify-content-center desktop" *ngIf="paymentOptions">
          <div class="price-plan" role="tab" id="plan-tab-id-{{i}}" tabindex="0" aria-controls="tab-plan-{{i}}"
            *ngFor="let plan of plans; let i = index" [style.display]="plan.active === true ? 'block' : 'none'"
            [ngClass]="{ active: plan.active === true }">
            <div class="price-priceHeader">
              <div class="price-cost">{{ plan.term.payment | currency: "USD" : "symbol-narrow" : "0.2-2":
                this.preferredLanguage }}<span class="price-cost-asterisk">*</span>
              </div>
              <span class="excludes-tax" [style.display]="plan.active === true ? 'block' : 'none'"
                *ngIf="!frontLoadTaxes">
                {{ 'RATES_PAGE.LOWEST_MONTHLY_1' | translate }}
                <strong> {{ 'RATES_PAGE.LOWEST_PAYMENT' | translate: { numberOfPayments: plan.term.numberOfPayments,
                  noFee: noFee } }}</strong>
                {{ 'RATES_PAGE.LOWEST_MONTHLY_2' | translate: { price:plan.term.price | currency: "USD" :
                "symbol-narrow" : "0.2-2": this.preferredLanguage, totalPrice: plan.term.totalPrice | currency: "USD" :
                "symbol-narrow" : "0.2-2": this.preferredLanguage } }}
              </span>
              <span class="excludes-tax" [style.display]="plan.active === true ? 'block' : 'none'"
                *ngIf="frontLoadTaxes">
                {{ 'RATES_PAGE.SPLIT_MONTHLY_1' | translate}}
                <strong> {{ 'RATES_PAGE.SPLIT_PAYMENT' | translate: { numberOfPayments: plan.term.numberOfPayments,
                  noFee: noFee } }}</strong>
                {{ 'RATES_PAGE.SPLIT_MONTHLY_2' | translate: { deductible: plan.plan.deductible | currency: "USD" :
                "symbol-narrow" : "1.0-0": this.preferredLanguage, totalPrice: plan.term.totalPrice | currency: "USD" :
                "symbol-narrow" : "0.2-2": this.preferredLanguage } }}
              </span>
            </div>
            <div class="col-12 mt-4 mb-4"
              *ngIf="(plan.plan.deductible !== 0 && plan.plan.deductible !== '0' && plan.plan.deductible !== '0.0') && !multipleDeductibles">
              <span class="pt-2 pl-2 pr-2 deductible-line">
                <span class="deductible-label">{{ 'CART_CHECKOUT.DEDUCTIBLE_OCCURENCE' | translate }}:&nbsp;</span>
                <span class="deductible">{{plan.plan.deductible | currency: "USD" : "symbol-narrow" : "1.0-0":
                  this.preferredLanguage}}</span>
              </span>
            </div>
            <div class="col-12 mt-4 mb-4" *ngIf="multipleDeductibles">
              <span class="pt-2 deductible-line">
                <span class="deductible-label">{{ 'RATES_PAGE.DEDUCTIBLE_OPTIONS' | translate }}:&nbsp;</span>
                <div>
                  <button *ngFor="let deductible of deductibles; let j = index" class="btn primary-btn deductible-btn"
                    id="deductible-{{i}}-{{j}}" (click)="chooseDeductible(deductible)"
                    [ngClass]="{ active: deductible == currentDeductible }">${{deductible}}</button>
                </div>
              </span>
              <div *ngIf="brand === 'Schomp'" [ngClass]="{'hidden': currentDeductible != 100}">
                <div mdbCollapse #currentDeductiblePanel>
                  <div class="switch success-switch pl-2 pt-2 pr-2 text-center">
                    <div class="">
                      <label tabindex="0" id="disappearingDeductible-{{i}}">
                        {{ 'RATES_PAGE.PAY_LESS' | translate }}
                      </label>
                    </div>
                    <div class="">
                      <label>
                        {{ 'RATES_PAGE.NO' | translate }}
                        <input aria-labelledby="disappearingDeductible" id="disappearingDeductibleValue-{{i}}"
                          [checked]="disappearingDeductibleValue" type="checkbox" (change)="toggleDisappearing()">
                        <span class="lever"></span> {{ 'RATES_PAGE.YES' | translate }}
                      </label>
                    </div>
                    <a class="click-text" role="link" tabindex="0" (click)="toggleDisclaimer()">{{
                      'RATES_PAGE.WHATS_THIS' | translate }}</a>
                    <div class="">
                      <div mdbCollapse #disappearingDeductible>
                        <div class="disappearing_def">
                          {{ 'RATES_PAGE.DISAPPEARING' | translate }}
                        </div>
                        <a class="click-text" role="link" tabindex="0" (click)="toggleDisclaimer()">{{
                          'RATES_PAGE.CLOSE' | translate }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="price-details">
                For {{ plan.term.numberOfPayments }} months with ${{ plan.term.price | number: ".2" }} due today
                <span class="excludes-tax">(${{ plan.term.totalPrice | number: ".2" }} total cost) *Price excludes taxes</span>
            </div> -->
          </div>
        </div>

        <div class="col-12 offset-md-1 col-md-10 d-flex justify-content-center desktop" *ngIf="!paymentOptions">
          <div class="price-plan-one-time" role="tab" id="plan-tab-id-{{i}}" tabindex="0" aria-controls="tab-plan-{{i}}"
            *ngFor="let plan of plans; let i = index" [style.display]="plan.active === true ? 'block' : 'none'"
            [ngClass]="{ active: plan.active === true }">
            <div class="price-priceHeader">
              <span class="price-cost">${{ plan.term.totalPrice | number: ".2" }}<span
                  class="price-cost-asterisk">*</span></span> <br />
              <span class="excludes-tax"><sup>*</sup>{{ 'RATES_PAGE.PRICE_EXCLUSED_TAX' | translate }}</span>
              <div class="col-12 mt-4 mb-3"
                *ngIf="(plan.plan.deductible !== 0 && plan.plan.deductible !== '0' && plan.plan.deductible !== '0.0') && !multipleDeductibles">
                <span class="pt-2 pl-2 pr-2 deductible-line">
                  <span class="deductible-label">{{ 'CART_CHECKOUT.DEDUCTIBLE_OCCURENCE' | translate }}:&nbsp;</span>
                  <span class="deductible normal-font-weight">{{plan.plan.deductible | currency: "USD" : "symbol-narrow"
                    : "1.0-0": this.preferredLanguage}}</span>
                </span>
              </div>
              <div class="col-12 mt-4 mb-3" *ngIf="multipleDeductibles">
                <span class="pt-2 pl-2 pr-2 deductible-line">
                  <span class="deductible-label">{{ 'RATES_PAGE.DEDUCTIBLE_OPTIONS' | translate }}:&nbsp;</span>
                  <div>
                    <button *ngFor="let deductible of deductibles; let j = index" class="btn primary-btn deductible-btn"
                      id="deductible-{{i}}-{{j}}" (click)="chooseDeductible(deductible)"
                      [ngClass]="{ active: deductible == currentDeductible }">${{deductible}}</button>
                  </div>
                </span>
              </div>

            </div>
            <!-- <div class="price-details">
                <span class="excludes-tax">with a {{plan.term.}} year coverage term</span>
            </div> -->
          </div>
        </div>

        <div class="col-12 mobile pb-3" *ngIf="paymentOptions">
          <div class="d-flex justify-content-center">
            <div tabindex="0" class="mobile-price-plan" *ngFor="let plan of plans; let i = index"
              [style.display]="plan.active === true ? 'block' : 'none'" [ngClass]="{ active: plan.active === true }">
              <div *ngIf="plans.length > 0">
                <div class="row">
                  <div class="col-12 price pl-3 pr-3">
                    <div>
                      <!-- {{ plan.term.priceHeader }}<br /> -->
                      <div class="price-cost">${{ plan.term.payment | number: ".2" }}<span
                          class="price-cost-asterisk">*</span></div>
                      <span class="excludes-tax" [style.display]="plan.active === true ? 'block' : 'none'"
                        *ngIf="!frontLoadTaxes">
                        {{ 'RATES_PAGE.LOWEST_MONTHLY_1' | translate }}
                        <strong> {{ 'RATES_PAGE.LOWEST_PAYMENT' | translate: { numberOfPayments:
                          plan.term.numberOfPayments, noFee: noFee } }}</strong>
                        {{ 'RATES_PAGE.LOWEST_MONTHLY_2' | translate: { price:plan.term.price | currency: "USD" :
                        "symbol-narrow" : "0.2-2": this.preferredLanguage, totalPrice: plan.term.totalPrice | currency:
                        "USD" : "symbol-narrow" : "0.2-2": this.preferredLanguage } }}
                      </span>
                      <span class="excludes-tax" [style.display]="plan.active === true ? 'block' : 'none'"
                        *ngIf="frontLoadTaxes">
                        {{ 'RATES_PAGE.SPLIT_MONTHLY_1' | translate}}
                        <strong> {{ 'RATES_PAGE.SPLIT_PAYMENT' | translate: { numberOfPayments:
                          plan.term.numberOfPayments, noFee: noFee } }}</strong>
                        {{ 'RATES_PAGE.SPLIT_MONTHLY_2' | translate: { deductible: plan.plan.deductible | currency:
                        "USD" : "symbol-narrow" : "1.0-0": this.preferredLanguage, totalPrice: plan.term.totalPrice |
                        currency: "USD" : "symbol-narrow" : "0.2-2": this.preferredLanguage } }}
                      </span>
                    </div>
                  </div>
                  <div class="col-12 mt-2 mb-3"
                    *ngIf="(plan.plan.deductible !== 0 && plan.plan.deductible !== '0' && plan.plan.deductible !== '0.0') && !multipleDeductibles">
                    <span class="pt-2 pl-2 pr-2 deductible-line">
                      <span class="deductible-label">{{ 'CART_CHECKOUT.DEDUCTIBLE_OCCURENCE' | translate
                        }}:&nbsp;</span>
                      <span class="deductible">{{plan.plan.deductible | currency: "USD" : "symbol-narrow" : "1.0-0":
                        this.preferredLanguage}}</span>
                    </span>
                  </div>
                  <div class="col-12 mt-2 mb-3" *ngIf="multipleDeductibles">
                    <span class="pt-2  deductible-line">
                      <span class="deductible-label">{{ 'RATES_PAGE.DEDUCTIBLE_OPTIONS' | translate }}:&nbsp;</span>
                      <div>
                        <button *ngFor="let deductible of deductibles; let j = index"
                          class="btn primary-btn deductible-btn" id="deductible-{{i}}-{{j}}"
                          (click)="chooseDeductible(deductible)"
                          [ngClass]="{ active: deductible == currentDeductible }">${{deductible}}</button>
                      </div>
                    </span>
                    <div *ngIf="brand === 'Schomp'" [ngClass]="{'hidden': currentDeductible != 100}">
                      <div mdbCollapse #currentDeductibleMobilePanel>
                        <div class="switch success-switch pl-2 pt-2 pr-2 text-center">
                          <div class="">
                            <label tabindex="0" id="disappearingDeductibleMobile-{{i}}">
                              {{ 'RATES_PAGE.PAY_LESS' | translate }}
                            </label>
                          </div>
                          <div class="">
                            <label>
                              {{ 'RATES_PAGE.NO' | translate }}
                              <input aria-labelledby="disappearingDeductible"
                                id="disappearingDeductibleMobileValue-{{i}}" [checked]="disappearingDeductibleValue"
                                type="checkbox" (change)="toggleDisappearing()">
                              <span class="lever"></span> {{ 'RATES_PAGE.YES' | translate }}
                            </label>
                          </div>
                          <a class="click-text" role="link" tabindex="0" (click)="toggleDisclaimer()">{{
                            'RATES_PAGE.WHATS_THIS' | translate }}</a>
                          <div class="">
                            <div mdbCollapse #disappearingDeductibleMobile>
                              <div class="disappearing_def">
                                {{ 'RATES_PAGE.DISAPPEARING' | translate }}
                              </div>
                              <a class="click-text" role="link" tabindex="0" (click)="toggleDisclaimer()">Close</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="col-12" [style.display]="plan.active === true ? 'block' : 'none'">
                    <div>For {{ plan.term.numberOfPayments }} months with ${{ plan.term.price | number: ".2" }} due today<br /></div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mobile" *ngIf="!paymentOptions">
          <div class="d-flex justify-content-center ">
            <div tabindex="0" class="mobile-price-plan" *ngFor="let plan of plans; let i = index"
              [style.display]="plan.active === true ? 'block' : 'none'" [ngClass]="{ active: plan.active === true }">
              <div *ngIf="plans.length > 0">
                <div class="row">
                  <div class="col-12 price">
                    <div class="price-cost">${{ plan.term.totalPrice | number: ".2" }}<span
                        class="price-cost-asterisk">*</span></div>
                    <span class="excludes-tax"><sup>*</sup>{{ 'RATES_PAGE.PRICE_EXCLUSED_TAX' | translate }}</span>
                    <div class="col-12 mt-2 mb-3"
                      *ngIf="(plan.plan.deductible !== 0 && plan.plan.deductible !== '0' && plan.plan.deductible !== '0.0') && !multipleDeductibles">
                      <span class="pt-2 pl-2 pr-2 deductible-line">
                        <span class="deductible-label">{{ 'CART_CHECKOUT.DEDUCTIBLE_OCCURENCE' | translate
                          }}:&nbsp;</span>
                        <span class="deductible normal-font-weight">{{plan.plan.deductible | currency: "USD" :
                          "symbol-narrow" : "1.0-0": this.preferredLanguage}}</span>
                      </span>
                    </div>
                    <div class="col-12 mt-2 mb-3" *ngIf="multipleDeductibles">
                      <span class="pt-2 pl-2 pr-2 deductible-line">
                        <span class="deductible-label">{{ 'RATES_PAGE.DEDUCTIBLE_OPTIONS' | translate }}:&nbsp;</span>
                        <div>
                          <button *ngFor="let deductible of deductibles; let j = index"
                            class="btn primary-btn deductible-btn" id="deductible-{{i}}-{{j}}"
                            (click)="chooseDeductible(deductible)"
                            [ngClass]="{ active: deductible == currentDeductible }">${{deductible}}</button>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="(brand === 'HPP' || brand === 'Hyundai') && readyToShow" class="select-container subscription switch success-switch">
  <div class="row">
    <div *ngIf="normalPlans !== 0 && severePlans !== 0" class="col-12 pb-3">
      <label>
        Normal
        <input aria-labelledby="" type="checkbox" (ngModelChange)="onTypeChange($event)" [ngModel]="planType">
        <span class="lever"></span> Severe
      </label>
    </div>
    <div class="col-12">
      <div class="container plans-group">
        <div *ngFor="let plan of plans; let i = index">
          <div class="plan-container" *ngIf="plan.plan_type && showSevere" [ngClass]="{ active: plan.active === true }">
            <div (click)="setPlan(i)" class="inner-container" id="chose-plan-{{ i }}">
              <div class="plan-header">{{plan.header}}</div>
              <div class="price-cost">${{ plan.term.totalPrice | number: ".2" }}<span
                  class="price-cost-asterisk">*</span>
              </div>
              <span class="excludes-tax"><sup>*</sup>{{ 'RATES_PAGE.PRICE_EXCLUSED_TAX' | translate }}</span>
              <div class="plan-name">{{plan.name}}</div>
              <div class="text-left deductible-line mt-2 pt-2 mr-3 ml-3 deductible-bullets">
                <span [innerHTML]="plan.cardContent | safeHtml"></span>
              </div>
            </div>
            <div class="sub-button-container">
              <div class="sub-button">
                <button class="btn"
                  [ngClass]="{ 'primary-btn': plan.active === true, 'secondary-btn': plan.active !== true }"
                  (click)="addToCart(i)">{{ 'RATES_PAGE.ADD_TO_CART' | translate }}</button>
              </div>
            </div>
          </div>
          <div class="plan-container" *ngIf="(!plan.plan_type && !showSevere) || (normalPlans === 0 || severePlans === 0)" [ngClass]="{ active: plan.active === true }">
            <div (click)="setPlan(i)" class="inner-container" id="chose-plan-{{ i }}">
              <div class="plan-header">{{plan.header}}</div>
              <div class="price-cost">${{ plan.term.totalPrice | number: ".2" }}<span
                  class="price-cost-asterisk">*</span>
              </div>
              <span class="excludes-tax"><sup>*</sup>{{ 'RATES_PAGE.PRICE_EXCLUSED_TAX' | translate }}</span>
              <div class="plan-name">{{plan.name}}</div>
              <div class="text-left deductible-line mt-2 pt-2 mr-3 ml-3 deductible-bullets">
                <span [innerHTML]="plan.cardContent | safeHtml"></span>
              </div>
            </div>
            <div class="sub-button-container">
              <div class="sub-button">
                <button class="btn"
                  [ngClass]="{ 'primary-btn': plan.active === true, 'secondary-btn': plan.active !== true }"
                  (click)="addToCart(i)">{{ 'RATES_PAGE.ADD_TO_CART' | translate }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>