
<div class="coverage-overview">
    <div class="row">
        <div class="col-12">
            <div class="container overview">
                <h2 class="text-center mb-2">{{ 'MY_ACCOUNT_LOGIN.COVERAGE_OVERVIEW' | translate }}</h2>
                <div class="text-center" [innerHTML]="plan?.coverageOverview.coverageText"></div>
                <div class="col-sm-12">
                    <div class="row">
                        <div class="col-sm-12 col-md-6" *ngFor="let coverage of plan?.coverageOverview.coverageBoxes">
                            <mdb-card class="card">
                                <mdb-card-body>
                                    <mdb-card-title>
                                        <div *ngIf="coverage.banner" class="cr cr-top cr-right cr-sticky cr-red"
                                            [innerHTML]="coverage.banner"></div>
                                        <h3><img _ngcontent-fri-c113="" class="check-mark" alt="null"
                                                src="https://prismic-io.s3.amazonaws.com/sgidigital/a1845ec5-d719-426a-8ed8-fc2c885aa772_Vroom-check-invert.svg">{{coverage.title}}
                                        </h3>
                                    </mdb-card-title>
                                    <mdb-card-text>
                                        <span [innerHTML]="getHtml(coverage.body) | safeHtml"></span>
                                    </mdb-card-text>
                                </mdb-card-body>
                            </mdb-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
