import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, throwError } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import * as ActionConfigurationManager from '.././_actions/config.actions';
import { getPDFSuccess, getPDFFailed, downloadPDFSuccess, downloadPDFFailed, postServiceAgreementSuccess, postServiceAgreementFailed, postPaymentPlanAgreementSuccess, postPaymentPlanAgreementFailed, getPaymentPlanAgreementSuccess, getPaymentPlanAgreementFailed, setDateAndTimeFailed, downloadPaymentPlanAgreementSuccess, downloadPaymentPlanAgreementFailed } from '../_actions/pdf.actions';
import { DataLookupService } from '../_services/data-lookup.service';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Action, Store } from '@ngrx/store';
import { s3ManagementService } from '../_services/s3-management.service';
import { s3UploadFailure, s3UploadSuccess } from '../_actions/s3-management.action';


@Injectable()

export class S3ManagementEffects {

public s3Upload$: Observable<Action> = createEffect(() =>
this.actions$.pipe(
    ofType(ActionConfigurationManager.S3_UPLOAD_DOCUMENTS),
    switchMap((action: any) => this.s3ManagementService.s3Upload(action.brandName, action.fileToUpload, action.vin)
        .pipe(
            map(file => {
                console.log('file', file)
                if (file) {
                    return s3UploadSuccess(file);
                } else {
                    return s3UploadFailure(file)
                }

            }),
            catchError((error) => {
                return throwError(error)
            })
        )
    )
)
)

constructor(
  private actions$: Actions,
  private s3ManagementService: s3ManagementService
) {}
}
