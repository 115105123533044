import { createReducer, on } from "@ngrx/store";
import { SelectedPlan } from "src/app/_models/selectedPlan";
import * as SelectedPlanActions from './selectedPlan.actions'

export interface SelectedPlanState {
  selectedPlan: any;
  defaultPlan: any;
  selectedPlanTerms: any;
  allPlans: any;
  allPlansPlanBasedTerm: any;
  currentSelectedPlan: any
}

export const initialSelectedPlanState: SelectedPlanState = {
  defaultPlan: null,
  selectedPlanTerms: null,
  allPlans: null,
  allPlansPlanBasedTerm: null,
  currentSelectedPlan: null,
  selectedPlan: null
};

export const SelectedPlanReducers = createReducer(
  initialSelectedPlanState,
  on(SelectedPlanActions.loadSelectedPlan, (state, { defaultPlan }) => ({
    ...state,
    defaultPlan,
  }),
  ),
  on(SelectedPlanActions.loadloadSelectedCoverageTerms, (state, { selectedPlanTerms }) => ({
    ...state,
    selectedPlanTerms,
  })),
  on(SelectedPlanActions.loadAllPlans, (state, { allPlans }) => ({
    ...state,
    allPlans,
  })),
  on(SelectedPlanActions.loadAllPlansBasedTerms, (state, { allPlansPlanBasedTerm }) => ({
    ...state,
    allPlansPlanBasedTerm,
  })),
  on(SelectedPlanActions.loadCurrentSelectedPlan, (state, { currentSelectedPlan }) => ({
    ...state,
    currentSelectedPlan,
  })),
);
