import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { submitOrderSuccess, submitOrderFailed, resetCheckoutState, paymentError, noGuestAllowed, notGuest, activeClaims } from '../_actions/order.actions';


const _orderStateReducer = createReducer(initialState,
    on(submitOrderSuccess, (state, payload) => {
        return true;
    }),
    on(submitOrderFailed, (state) => {
        return false;
    }),
    on(paymentError, (state) => {
        return 'payment';
    }),
    on(resetCheckoutState, (state) => {
        return null;
    }),
    on(noGuestAllowed, (state) => {
        return 'sessionEndedGuest';
    }),
    on(notGuest, (state) => {
        return 'notGuest';
    }),
    on(activeClaims, (state) => {
        return 'activeClaims';
    }),
);

export function orderStateReducer(state, action) {
    return _orderStateReducer(state, action);
}