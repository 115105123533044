export let previewPlan = {
  planName: "PLATINUM COVERAGE",
  referenceNumber: "V2",
  productName: "VROOM VSC PROTECTION",
  productCode: "VRVD",
  planIndex: 1,
  termIndex: 0,
  yearsMileage: "4 YEARS, 48,000 MILES",
  type: "[Product Plans Component] Save Plan",
};
