
<!-- COMPARE PLANS -->
<div class="compare content-margin">
  <div class="container mt-5">
    <div class="row p-4">
      <div class="col-12 text-center">
        <div [innerHTML]="getHtml(slice.primary.section_title, vehicle)"></div>
        <div [innerHTML]="getHtml(slice.primary.section_description, vehicle)"></div>
      </div>
    </div>
    <ngx-slick-carousel
      class="carousel"
      [config]="slide2Config"
      #slickModal="slick-carousel" >
        <div ngxSlickItem *ngFor="let slide of slice.fields" class="slide">
              <div class="w-100 pt-2 pl-2 pr-2 pb-4">
                <mdb-card class="shadow">
                  <mdb-card-img *ngIf="slide.plan?.plan_image" width="100%" src="{{slide.plan.plan_image}}"></mdb-card-img>
                  <mdb-card-body>
                    <mdb-card-title class="text-center">
                      <h3 [innerHTML]="getHtml(slide.plan.plan_title, vehicle)"></h3>
                      <p [innerHTML]="getHtml(slide.plan.short_description, vehicle)"></p>
                    </mdb-card-title>
                    <hr>
                    <mdb-card-text class="w-100 float-left">

                      <div class="w-100 float-left">
                        <ul style="list-style: none; padding-left: 10px;">
                          <!-- This is the loop for the included items -->
                          <li *ngFor="let feature of slide.plan.components" class="{{feature.class}} float-left col-md-6 col-12">
                            <!-- <img width="24" [src]="feature.component_icon.url" alt="" /> -->
                            <mdb-icon class="float-left mr-1 green-text" style="font-size:16px; margin-top:8px;" fas icon="check"></mdb-icon> <span class="float-left" [innerHTML]="getHtml(feature.component_title, vehicle)"></span>
                          </li>
                          <!-- This is the loop for the non-included items based on the non-included slice in each plan -->
                          <li class="float-left col-md-6 col-12">
                            <mdb-icon class="float-left mr-1 red-text" style="font-size:16px; margin-top:8px;" fas icon="times"></mdb-icon> <span class="float-left"> <h6>Not included</h6></span>
                          </li>
                          <li class="float-left col-md-6 col-12">
                            <mdb-icon class="float-left mr-1 red-text" style="font-size:16px; margin-top:8px;" fas icon="times"></mdb-icon> <span class="float-left"> <h6>Not included</h6></span>
                          </li>
                          <li class="float-left col-md-6 col-12">
                            <mdb-icon class="float-left mr-1 red-text" style="font-size:16px; margin-top:8px;" fas icon="times"></mdb-icon> <span class="float-left"> <h6>Not included</h6></span>
                          </li>
                          <li class="float-left col-md-6 col-12">
                            <mdb-icon class="float-left mr-1 red-text" style="font-size:16px; margin-top:8px;" fas icon="times"></mdb-icon> <span class="float-left"> <h6>Not included</h6></span>
                          </li>
                        </ul>
                      </div>
                      <!-- <div class=" w-100 float-left cost-benefit text-center">
                        <hr>
                        <div class="col-12 bg-gray pb-3">
                          <p>Protected Up To <br/>
                            <strong>{{slide.maxTerm}}</strong>
                          </p>
                        </div>
                      </div> -->
                      <div class="w-100 float-left mt-3 pt-4 pb-4 border-top text-center">
                        <p><strong>Protected up to<br/>10 Years or 100,000 Miles</strong></p>
                      </div>
                      <div class="pt-3 text-center">
                          <a mdbBtn class="primary-btn mb-4" mdbWavesEffect (click)="openModal('plans')">GET PRICING</a>
                      </div>
                    </mdb-card-text>

                  </mdb-card-body>
                </mdb-card>
            </div>
        </div>
    </ngx-slick-carousel>
    <div class="row pl-4 pr-4">
      <div class="col-12">
        <div class="pl-3 pr-3">
          <mdb-card class="shadow">
            <mdb-card-body>
              <mdb-card-title class="text-center">
                <h3>All Plans Include</h3>
              </mdb-card-title>
              <mdb-card-text class="text-center w-100 pt-4">
                <p><strong>Roadside Assistance</strong></p>
                <p><strong>Towing</strong></p>
                <p><strong>Rental Car Reimbursement</strong></p>
              </mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <pre>{{slice | json}}</pre> -->
