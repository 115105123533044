import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { saveReferenceID, resetReferenceID } from '../_actions/reference-id.actions';


const _refIDReducer = createReducer(initialState,
    on(saveReferenceID, (state, payload) => {
        sessionStorage.setItem('refID', JSON.stringify(payload))
        return payload;
    }),
    on(resetReferenceID, (state, payload) => {
        sessionStorage.removeItem('refID')
        return null;
    })
); 


export function refIDReducer(state, action) {
    return _refIDReducer(state, action);
}
