export class VINDecodeRequest {
    vin: string;
    saleDate: string;
    inServiceDate: string;
    odometer: number;
    brandName: string;
    customerState: string;
    getRatesPage: string
}

export class VINDecodeCarInfoRequest {
    vin: string;
}

export class VINDecodeCarInfoResponse {
    vin: string;
    make: string;
    model: string;
    year: number;
}