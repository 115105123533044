import { Make } from './make';
import { Model } from './model';
import { Trim } from './trim';
import { Vehicle } from './vehicle';

export class LeadResponse {
    data: Lead;
    status: string;
    errorMessage: string;
}

export class Lead {
    brandExternalId: string;
    brandSfId: string;
    customer: Customer;
    purchaseRecordSfId: string;
    opportunityId: string;
    vehicle: Vehicle;
    shoppingCartExternalId: string;
    dealerName?: string;
}

export class Customer {
    accountExternalId: string;
    accountSfId: string;
    address1: string;
    address2: string;
    city: string;
    contactExternalId: string;
    contactSfId: string;
    country: string;
    emailId: string;
    firstName: string;
    lastName: string;
    phoneNumber1: string;
    phoneNumber2: string;
    state: string;
    zipCode: string;
}

export class UpdateLead {
    accountContactSfId: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    emailId: string;
    phoneNumber1: string;
    phoneNumber2: string;
    firstName: string;
    lastName: string;
}
