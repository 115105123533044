import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PrismicService } from 'src/app/_services/prismic.service';
import { Router } from '@angular/router';
import { ReferenceID } from 'src/app/_models/reference-id';
import { saveReferenceID } from 'src/app/_actions/reference-id.actions';
import { Store } from '@ngrx/store';
import { AppConfigService } from 'src/app/appconfig/appconfig.service';

@Component({
  selector: 'app-slice-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @Input() public slice: any;
  @Input('vehicleObject') public vehicle: any;

  @Output() emitOpenModal = new EventEmitter<string>();

  branding: any;

  slide2Config = {
    slidesToShow: 3,
    slidesToScroll: 3,
    adaptiveHeight: true,
    centerPadding: '30px',
    speed: 300,
    dots: false,
    arrows: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  openModal(data: string) {
    this.emitOpenModal.emit(data);
  }

  constructor(private prismicService: PrismicService, private appConfig: AppConfigService, private router: Router, private store: Store<{}>) { }

  ngOnInit() {
    // console.log('slice: ', this.slice)
  }

  getHtml(content, vehicleObject = null) {
    return this.prismicService.getHtml(content, vehicleObject);
  }
  openLink(object, link) {
    console.log(link);
    console.log(object)

    let request: ReferenceID = {
      ref_id: object
    }

    this.store.dispatch(saveReferenceID(request))
    console.log(this.store)

    switch (link) {
      case 'How it Works':
        this.router.navigate(['/how-vsp-works']);
        break;
      case 'Product Home':
        this.router.navigate(['/vehicle-service-protection']);
        break;
      case 'PPM':
        this.router.navigate(['/prepaid-maintenance']);
        break;
      case "Call":
        this.call();
        break;
      case 'Plans':
          this.emitOpenModal.emit("cta component");
      break;
      default:
          this.emitOpenModal.emit("cta component");
        // this.router.navigate([link]);
        break;
    }

    return false;
  }

  call() {
    window.location.href = "tel://" + this.branding.cs_phone_number;
  }

}
