import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, throwError } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import * as ActionConfigurationManager from '.././_actions/config.actions';
import { Action, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { DataLookupService } from '../_services/data-lookup.service';
import { verifyVinSuccess, verifyVinFailed, updateVehicleByVin, verifyVehCustSuccess, verifyVehCustFailed, verifyVehCustSuccessContactInformation, verifyVehCustFailedContactInformation, verifyVinRates, verifyVinRatesEdit, verifyVehicleVinInformationFailed, verifyVehicleVinInformationSuccess } from '../_actions/vehicle.actions';
import { clear, ratesError } from '../_actions/modal-component.actions';
import { loadRates, loadRatesHappy } from '../_actions/rates.actions';
import { RatesRequest } from '../_models/rates';
import { hideInlineLoader } from '../_actions/inline-loader.actions';
import { Vehicle } from '../_models/vehicle';
import { URLParameters } from '../_models/urlParams';
import { loadLead } from '../_actions/lead.actions';
import { saveUrlParams } from '../_actions/urlParams.actions';
import { account, guest } from '../_actions/app-flow-state.actions';
import { loadOpportunity } from '../_actions/opportunity.actions';
import { Opportunity } from '../_models/account';
import { noGuestAllowed, notGuest } from '../_actions/order.actions';

@Injectable()

export class VerifyVinEffects {

    public verifyVin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.VERIFY_VIN),
            switchMap((action: any) => this.vin.check_vin(action.vin)
                .pipe(
                    map(vin => {
                        if (vin.data.vinExist) {
                            if (vin.data.factoryExpired && vin.data.ninetyDayExpired) {
                                this.store.dispatch(clear())
                                return ratesError()
                            } else {
                                let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem('vehicle'))
                                if (_vehicle) {
                                    _vehicle.isElectric = vin.data.isElectric
                                    this.store.dispatch(updateVehicleByVin(_vehicle))
                                } else {
                                    let vehicle: Vehicle = {
                                        vin: action.vin,
                                        registeredState: "",
                                        trim: "",
                                        make: "",
                                        makeExternalId: "",
                                        mileage: 0,
                                        makeSfId: "",
                                        model: "",
                                        modelExternalId: "",
                                        modelNumber: "",
                                        modelSfId: "",
                                        referenceNumber1: "",
                                        referenceNumber2: "",
                                        referenceNumber3: "",
                                        referenceNumber4: "",
                                        isElectric: vin.data.isElectric,
                                        trimExternalId: "",
                                        trimSfId: "",
                                        vehicleExternalId: "",
                                        vehicleCondition: "",
                                        vehicleSfId: "",
                                        vehicleType: "",
                                        purchaseDate: "",
                                        exteriorColor: "",
                                        year: ""
                                    }
                                    this.store.dispatch(updateVehicleByVin(vehicle))
                                }


                                return verifyVinSuccess()
                            }
                        } else {
                            this.store.dispatch(clear())
                            return verifyVinFailed()
                        }
                    }),
                    catchError((error) => {
                        this.store.dispatch(clear())
                        this.store.dispatch(verifyVinFailed())
                        return throwError(error)
                    }),
                )
            )
        )
    )

    public verifyVinRates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(verifyVinRates),
            switchMap((action: any) => this.vin.check_vin(action.vin)
                .pipe(
                    map(vin => {
                        if (vin.data.vinExist) {
                            if (vin.data.factoryExpired && vin.data.ninetyDayExpired) {
                                this.store.dispatch(clear())
                                this.store.dispatch(ratesError())
                                return hideInlineLoader()
                            } else {
                                let request: RatesRequest = {
                                    brandName: action.brandName,
                                    vin: action.vin,
                                    saleDate: action.saleDate,
                                    odometer: action.odometer,
                                    inServiceDate: action.inServiceDate,
                                    financeType: action.financeType,
                                    isAfterSale: action.isAfterSale,
                                    vehiclePurchaseDate: action.vehiclePurchaseDate,
                                    vehicleCondition: action.vehicleCondition,
                                    customerState: action.customerState,
                                    financeAmount: action.financeAmount,
                                    vehicleCost: action.vehicleCost,
                                    vehicleMSRP: action.vehicleMSRP,
                                    lienholderName: action.lienholderName,
                                    make: action.make,
                                    model: action.model,
                                    year: action.year,
                                    vehicleSfId: action.vehicleSfId,
                                    getRatesPage: action.getRatesPage,
                                    product: action.product

                                }

                                console.log("verifyVinRates$: ", request)

                                this.store.dispatch(verifyVinSuccess())
                                return loadRatesHappy(request)
                            }
                        } else {
                            this.store.dispatch(clear())
                            return verifyVinFailed()
                        }
                    }),
                    catchError((error) => {
                        this.store.dispatch(clear())
                        this.store.dispatch(verifyVinFailed())
                        this.store.dispatch(hideInlineLoader())
                        return throwError(error)
                    }),
                )
            )
        )
    )

    public verifyVinRatesEdit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(verifyVinRatesEdit),
            switchMap((action: any) => this.vin.check_vin(action.vin)
                .pipe(
                    map(vin => {
                        if (vin.data.vinExist) {
                            if (vin.data.factoryExpired && vin.data.ninetyDayExpired) {
                                this.store.dispatch(clear())
                                this.store.dispatch(ratesError())
                                return hideInlineLoader()
                            } else {
                                let request: RatesRequest = {
                                    brandName: action.brandName,
                                    vin: action.vin,
                                    saleDate: action.saleDate,
                                    odometer: action.odometer,
                                    inServiceDate: action.inServiceDate,
                                    financeType: action.financeType,
                                    isAfterSale: action.isAfterSale,
                                    vehiclePurchaseDate: action.vehiclePurchaseDate,
                                    vehicleCondition: action.vehicleCondition,
                                    customerState: action.customerState,
                                    financeAmount: action.financeAmount,
                                    vehicleCost: action.vehicleCost,
                                    vehicleMSRP: action.vehicleMSRP,
                                    lienholderName: action.lienholderName,
                                    make: action.make,
                                    model: action.model,
                                    year: action.year,
                                    vehicleSfId: action.vehicleSfId,
                                    getRatesPage: action.getRatesPage,
                                    product: action.product

                                }
                                this.store.dispatch(verifyVinSuccess())
                                let _appFlowState: string = sessionStorage.getItem('appFlowState')
                                if (_appFlowState === 'account') {
                                    return loadRatesHappy(request)
                                } else {
                                    return loadRates(request)
                                }

                            }
                        } else {
                            this.store.dispatch(clear())
                            return verifyVinFailed()
                        }
                    }),
                    catchError((error) => {
                        this.store.dispatch(clear())
                        this.store.dispatch(verifyVinFailed())
                        this.store.dispatch(hideInlineLoader())
                        return throwError(error)
                    }),
                )
            )
        )
    )

    public verifyVehicleCustomer = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.VERIFY_VEHICLE_AND_CUSTOMER),
            switchMap((action: any) => this.vin.check_vin_customer(action)
                .pipe(
                    map(vin => {
                        if (vin.data.vinExist) {
                            // let opp: Opportunity = {
                            //     opportunityId: vin.data.opportunityId
                            // }
                            // this.store.dispatch(loadOpportunity(opp))
                            if (vin.data.factoryExpired && vin.data.ninetyDayExpired) {
                                this.store.dispatch(clear())
                                this.store.dispatch(ratesError())
                                return hideInlineLoader()
                            } else {
                                if (vin.data.customerBrandMapping && vin.data.customerVehicleMapping) {
                                    let urlParamsObject: URLParameters = {
                                        prid: "",
                                        pageType: "",
                                        pageId: "",
                                        pageurl: "",
                                        product: "",
                                        oid: ""
                                    }
                                    urlParamsObject = JSON.parse(sessionStorage.getItem('urlParams'))
                                    urlParamsObject.prid = vin.data.purchaseRecordId
                                    urlParamsObject.oid = vin.data.opportunityId
                                    if (urlParamsObject.oid) {
                                        this.store.dispatch(account())
                                        this.store.dispatch({
                                            type: "[Lead Component] Load Lead Opportunity",
                                            payload: urlParamsObject,
                                        });
                                        this.store.dispatch(saveUrlParams(urlParamsObject));
                                    } else if (urlParamsObject.prid) {
                                        this.store.dispatch(account())
                                        this.store.dispatch({
                                            type: "[Lead Component] Load Lead",
                                            payload: urlParamsObject,
                                        });
                                        this.store.dispatch(saveUrlParams(urlParamsObject));
                                    }
                                } else {
                                    let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem('vehicle'))
                                    if (_vehicle) {
                                        _vehicle.isElectric = vin.data.electric
                                        this.store.dispatch(updateVehicleByVin(_vehicle))
                                    } else {
                                        let vehicle: Vehicle = {
                                            vin: action.vin,
                                            registeredState: "",
                                            trim: "",
                                            make: "",
                                            makeExternalId: "",
                                            mileage: 0,
                                            makeSfId: "",
                                            model: "",
                                            modelExternalId: "",
                                            modelNumber: "",
                                            modelSfId: "",
                                            referenceNumber1: "",
                                            referenceNumber2: "",
                                            referenceNumber3: "",
                                            referenceNumber4: "",
                                            isElectric: vin.data.electric,
                                            trimExternalId: "",
                                            trimSfId: "",
                                            vehicleExternalId: "",
                                            vehicleCondition: "",
                                            vehicleSfId: "",
                                            vehicleType: "",
                                            purchaseDate: "",
                                            year: ""
                                        }
                                        this.store.dispatch(updateVehicleByVin(vehicle))
                                    }
                                }
                                return verifyVehCustSuccess(vin)
                            }
                        } else {
                            this.store.dispatch(clear())
                            return verifyVehCustFailed()
                        }
                    }),
                    catchError((error) => {
                        this.store.dispatch(clear())
                        this.store.dispatch(verifyVehCustFailed())
                        this.store.dispatch(hideInlineLoader())
                        return throwError(error)
                    }),
                )
            )
        )
    )

    public verifyVehicleCustomerContactInformation = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.VERIFY_VEHICLE_AND_CUSTOMER_CONTACT_INFORMATION),
            switchMap((action: any) => this.vin.check_vin_customer(action)
                .pipe(
                    map(vin => {
                        let opp: Opportunity = {
                            opportunityId: vin.data.opportunityId
                        }
                        this.store.dispatch(loadOpportunity(opp))

                        if (vin.data.customerBrandMapping && vin.data.customerVehicleMapping) {
                            this.store.dispatch(notGuest())
                            let urlParamsObject: URLParameters = {
                                prid: "",
                                pageType: "",
                                pageId: "",
                                pageurl: "",
                                product: "",
                                oid: ""
                            }
                            urlParamsObject.prid = vin.data.purchaseRecordId
                            urlParamsObject.oid = vin.data.opportunityId
                            if (urlParamsObject.oid) {
                                this.store.dispatch(account())
                                this.store.dispatch({
                                    type: "[Checkout Component] Load Lead Opportunity Contact Information",
                                    payload: urlParamsObject,
                                });
                                this.store.dispatch(saveUrlParams(urlParamsObject));
                            } else if (urlParamsObject.prid) {
                                this.store.dispatch(account())
                                this.store.dispatch({
                                    type: "[Checkout Component] Load Lead Contact Information",
                                    payload: urlParamsObject,
                                });
                                this.store.dispatch(saveUrlParams(urlParamsObject));
                            }
                            return verifyVehCustSuccessContactInformation(vin)
                        } else {
                            let lead = JSON.parse(sessionStorage.getItem('lead'))
                            if (!lead) {
                                this.store.dispatch(noGuestAllowed())
                            } else {
                                this.store.dispatch(notGuest())
                            }
                            return verifyVehCustSuccessContactInformation(vin)
                        }
                    }),
                    catchError((error) => {
                        this.store.dispatch(verifyVehCustFailedContactInformation())
                        return throwError(error)
                    }),
                )
            )
        )
    )




    public verifyVehicleVinInformation$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.VERIFY_VEHICLE_VIN_INFORMATION),
            tap( x => console.log("EFFFECCCT", x.vin) ),
            switchMap((action: any) =>
            this.vin.verifyAutoCheckVinNumber(action.vin, action.mileage)
            .pipe(
                map(response => {
                    console.log('RESPONSE FROM verifyVehicleVinInformation ', response)
                    if (response) {
                        return verifyVehicleVinInformationSuccess(response)
                    } else {
                        this.store.dispatch(verifyVehicleVinInformationFailed())

                    }
                }),
                catchError((error) => {
                    this.store.dispatch(verifyVehicleVinInformationFailed())
                    return throwError(error)
                })
            )
            )
        )
    )





    constructor(
        private actions$: Actions,
        private vin: DataLookupService,
        private store: Store<{}>
    ) { }
}

