import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class GoogleAnalyticsService {

  constructor() {}

  loadScriptTag(trackingID: string): void {

    const gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${ trackingID }`);

    const gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${ trackingID }\');`;

    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);
    // console.log("%cGoogle Analytics Loaded", "color:blue");
  }

}
