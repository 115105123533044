import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-toggle-button-group',
  templateUrl: './toggle-button-group.component.html',
  styleUrls: ['./toggle-button-group.component.scss']
})
export class ToggleButtonGroupComponent {

  @Input() options2: Array<{ value: string, label: string }> = [];
  @Input() groupName: string = 'toggleGroup';
  @Input() ariaLabel: string = 'Button Toggle Group';
  @Input() label: string = '';


  matToggleOptions = [
    { value: '0', label: '$0' },
    { value: '100', label: '$100' },
    { value: '250', label: '$250' }
  ];
  displayLabel = 'RATES_PAGE.CHOOSE_TERM'


  constructor() { }

  ngOnInit(): void {
    this.label = this.displayLabel;
    this.options2 = this.matToggleOptions;

  }

}
