<div class="modal-content">
  <div class="modal-header mb-n1">
    <span aria-hidden="true"><mdb-icon tabindex="0" class="modal-close-icon" aria-label="Close"
        (click)="modalRef.hide()" fas icon="times"></mdb-icon></span>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center">
        <div class="mb-0"><strong>Delete Document</strong></div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-center mb-4">
        Are you sure you want to delete this record?<br>
        This process cannot be undone.
      </div>
    </div>

    <!-- <div class="desktop">
      <div class="row">
        <div class="col-6 text-center mt-2 mb-3">
          <a class="btn secondary-btn waves-light uppercase" mdbBtn mdbWavesEffect (click)="modalRef.hide()">Cancel</a>
        </div>
        <div class="col-6 text-center mt-2 mb-3">
          <button class="btn primary-btn waves-light uppercase" mdbBtn mdbWavesEffect (click)="modalRef.hide(); delete();">Delete</button>
        </div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-12 text-center mt-2 mb-3">
        <button class="btn primary-btn waves-light uppercase" mdbBtn mdbWavesEffect (click)="modalRef.hide(); delete();">Delete</button>
      </div>
      <div class="col-12 text-center mt-2 mb-3">
        <a class="uppercase click-text" (click)="modalRef.hide()">Cancel</a>
      </div>
    </div>
  </div>
</div>
