import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-slice-image',
  templateUrl: './image-slice.component.html',
  styleUrls: ['./image-slice.component.scss'],
})
export class ImageSliceComponent implements OnInit {
  @Input() public slice: any;

  constructor() {}

  ngOnInit() {
  }

  getStyles(slice){
    return { 'background-image': 'url('+slice.primary.image.url+')', 'margin-top':slice.primary.margin_top ? slice.primary.margin_top+'px': '0', 'margin-bottom':slice.primary.margin_bottom ? slice.primary.margin_bottom+'px': '0'}
  }
}
