<!-- FEATURES SECTION -->
<!-- FULL WIDTH - 2 COLUMNS - TEXT RIGHT -->
<!-- FULL WIDTH - 2 COLUMNS - TEXT RIGHT -->

<div class="content-block-wrap {{ slice.primary?.class_name }}" [ngStyle]="getContainerBlockStyles(slice)" [attr.aria-label]="getAria(slice)">
  <!-- <section class="view"> -->
  <section>
    <div class="row d-flex align-items-stretch" [ngStyle]="getSectionBlockStyles(slice)">
      <div *ngIf="slice.primary?.background_image" class="mask flex-center" [ngClass]="{ 'rgba-black-strong': slice.primary?.overlay_tint == 'dark', 'rgba-white-light': slice.primary?.overlay_tint == 'light' }"></div>

      <div *ngFor="let feature of slice.fields" class="col-md col-12 content-feature {{feature.content_block?.class_name}}" [ngClass]="{ 'content-feature-top-padding': feature.content_block?.mobile_height, 'countdown-timer': (feature.content_block?.body && feature.content_block?.body[0]?.type) == 'countdown_timer'}" [ngStyle]="getContentBlockStyles(feature)">
        <!-- Background Color -->

        <div *ngIf="feature.content_block?.background_image" class="mask desktop" [ngClass]="{ 'rgba-black-strong': feature.content_block?.overlay_tint == 'dark', 'rgba-white-light': feature.content_block?.overlay_tint == 'light' }"></div>

        <div *ngFor="let component of feature.content_block?.body" [ngClass]="{  }">
          <div [ngSwitch]="component.type" [ngClass]="{'cb-text': component.type !== 'image', 'mobile-margin': (component.type !== 'countdown_timer' && component.type !== 'image_carousel' && component.type !== 'vehicle' && component.type !== 'disclaimer') }">
            <!-- <app-slice-content-block *ngSwitchCase="'content_block'" [slice]="component" [vehicleObject]="vehicleObject"></app-slice-content-block> -->
            <app-countdown-timer *ngSwitchCase="'countdown_timer'" [component]="component" [vehicleObject]="vehicle"></app-countdown-timer>
            <app-service-drive *ngSwitchCase="'service_drive'" [component]="component" [vehicleObject]="vehicle"></app-service-drive>
            <app-image-carousel *ngSwitchCase="'image_carousel'" [component]="component" [vehicleObject]="vehicle"></app-image-carousel>
            <app-component-title *ngSwitchCase="'title'" [component]="component" [vehicleObject]="vehicle" [text_color]="feature.content_block.text_color" [text_align]="feature.content_block.text_alignment"></app-component-title>
            <app-component-icon-group *ngSwitchCase="'icon_group'" [component]="component" [vehicleObject]="vehicle" [text_color]="feature.content_block.text_color" [text_align]="feature.content_block.text_alignment"></app-component-icon-group>
            <app-component-icon *ngSwitchCase="'icon'" [component]="component" [vehicleObject]="vehicle" [text_color]="feature.content_block.text_color" [text_align]="feature.content_block.text_alignment"></app-component-icon>
            <app-component-text *ngSwitchCase="'text'" [component]="component" [vehicleObject]="vehicle" [text_color]="feature.content_block.text_color" [text_align]="feature.content_block.text_alignment"></app-component-text>
            <app-component-table *ngSwitchCase="'grid'" [component]="component" [vehicleObject]="vehicle" [max_width]="feature.content_block.width_percent" [position]="feature.content_block.position"></app-component-table>
            <app-component-info-carousel *ngSwitchCase="'info_carousel'" [component]="component" [vehicleObject]="vehicle" [text_color]="feature.content_block.text_color" [text_align]="feature.content_block.text_alignment"></app-component-info-carousel>
            <app-component-cta *ngSwitchCase="'cta'" [component]="component" [vehicleObject]="vehicle" [text_color]="feature.content_block.text_color" [text_align]="feature.content_block.text_alignment" (emitOpenModal)="openModal($event)"></app-component-cta>
            <app-component-image *ngSwitchCase="'image'" [component]="component" [vehicleObject]="vehicle"></app-component-image>
            <app-component-icon-with-cta *ngSwitchCase="'icon_with_cta'" [component]="component" [vehicleObject]="vehicle"></app-component-icon-with-cta>
            <app-component-video *ngSwitchCase="'video'" [component]="component" [vehicleObject]="vehicle" [text_color]="feature.content_block.text_color" [text_align]="feature.content_block.text_alignment"></app-component-video>
            <div *ngIf="!showMobile">
              <app-component-vehicle *ngSwitchCase="'vehicle'" class="desktop app-component-vehicle" [component]="component" [vehicleObject]="vehicle" [text_color]="feature.content_block.text_color" [text_align]="feature.content_block.text_alignment"></app-component-vehicle>
            </div>
            <div *ngIf="showMobile">
              <div *ngSwitchCase="'vehicle'">
                <app-component-vehicle *ngIf="!component.primary?.stick_to_bottom_for_mobile" class="mobile app-component-vehicle" [component]="component" [vehicleObject]="vehicle" [text_color]="feature.content_block.text_color" [text_align]="feature.content_block.text_alignment"></app-component-vehicle>
              </div>
            </div>
            <app-disclaimer *ngSwitchCase="'disclaimer'" [component]="component" [vehicleObject]="vehicle" [text_color]="feature.content_block.text_color" [text_align]="feature.content_block.text_alignment"></app-disclaimer>
          </div>
        </div>
      </div>

      <div *ngFor="let feature of slice.fields" class="mobile full-width" [ngClass]="'col-md col-12'">
        <!-- Background Color -->

        <div *ngFor="let component of feature.content_block?.body">
          <div [ngSwitch]="component.type">
            <div *ngSwitchCase="'vehicle'">
              <app-component-vehicle *ngIf="component.primary?.stick_to_bottom_for_mobile && showMobile" [component]="component" [vehicleObject]="vehicle" [text_color]="feature.content_block.text_color" [text_align]="feature.content_block.text_alignment"></app-component-vehicle>
            </div>
            <!-- <app-slice-content-block *ngSwitchCase="'content_block'" [slice]="component" [vehicleObject]="vehicleObject"></app-slice-content-block> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- <pre>{{slice.primary | json}}</pre> -->
