import * as ActionConfigurationManager from './config.actions';

export const saveCart = ActionConfigurationManager.SAVE_CART;
export const cartSaved = ActionConfigurationManager.CART_DB;
export const cartSavedSuccess = ActionConfigurationManager.CART_DB_SUCCESS;
export const updateCart = ActionConfigurationManager.UPDATE_CART;
export const updateCartSuccess = ActionConfigurationManager.UPDATE_CART_SUCCESS;
export const clearCart = ActionConfigurationManager.CLEAR_CART;
export const rehydrateCart = ActionConfigurationManager.REHYDRATE_CART;
export const updateNISCart = ActionConfigurationManager.UPDATE_CART_NIS
export const getCart = ActionConfigurationManager.GET_CART
export const getCartSuccess = ActionConfigurationManager.GET_CART_SUCCESS
export const prepCartForOrder = ActionConfigurationManager.PREP_CART_FOR_ORDER
export const saveCartDeleteItemSkuID = ActionConfigurationManager.SAVE_CART_DELETE_ITEM_SKU_ID
export const removeCartDeleteItemSkuID = ActionConfigurationManager.REMOVE_CART_DELETE_ITEM_SKU_ID 