import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { showOrderStateInlineLoader, hideOrderStateInlineLoader } from '../_actions/order.actions';


const _orderStateInlineLoaderReducer = createReducer(initialState,
    on(showOrderStateInlineLoader, (state, payload) => {
        return true;
    }),
    on(hideOrderStateInlineLoader, (state) => {
        return false;
    })
);

export function orderStateInlineLoaderReducer(state, action) {
    return _orderStateInlineLoaderReducer(state, action);
}