import { createReducer, on } from "@ngrx/store";
import { createCaseDirectMarketing, storeUserDirectMarketing, createCaseDirectMarketingSuccess, createCaseDirectMarketingFailed, storeUserDirectMarketingSuccess, storeUserDirectMarketingFailed } from "../_actions/prospect.actions";
export const initialState = null;

const _prospectReducer = createReducer(
  initialState,
  on(createCaseDirectMarketing, (state, payload) =>  {
    sessionStorage.setItem('productDisclaimers', JSON.stringify(payload));
    return payload;
  }),
  on(storeUserDirectMarketing, (state, payload) => {
    sessionStorage.setItem('productDisclaimers', JSON.stringify(payload));
    return payload;
  }),
  on(createCaseDirectMarketingSuccess, (state) => {
    return state;
  }),
  on(createCaseDirectMarketingFailed, (state) => {
    return state;
  }),
  on(storeUserDirectMarketingSuccess, (state) => {
    return state;
  }),
  on(storeUserDirectMarketingFailed, (state) => {
    return state;
  })
);


export function prospectReducer(state, action) {
  return _prospectReducer(state, action);
}
