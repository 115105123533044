import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CookieYesService {

  constructor() {}

  loadScriptTag(scriptTag: string): void {

    if(scriptTag && scriptTag !== '') {
      const cookieYesScript = document.createElement('script');
      cookieYesScript.setAttribute('async', 'true');
      cookieYesScript.setAttribute('src', `${ scriptTag }`);
      cookieYesScript.setAttribute('id','cookieyes');
      cookieYesScript.setAttribute('type','text/javascript');
      document.documentElement.firstChild.appendChild(cookieYesScript);
    }
  }
}
