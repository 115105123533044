import { Component, OnInit, Input } from '@angular/core';
import { PrismicService } from 'src/app/_services/prismic.service';

@Component({
  selector: 'app-slice-title',
  templateUrl: './title-slice.component.html',
  styleUrls: ['./title-slice.component.scss']
})
export class TitleSliceComponent implements OnInit {
  @Input() slice: any;
  @Input() vehicle: any;

  constructor(private prismicService: PrismicService) { }

  ngOnInit() {
  }

  getHtml(content, vehicleObject) {
    return this.prismicService.getHtml(content, vehicleObject);
  }

  getStyles(slice){

    if(slice.primary.margin_top || slice.primary.margin_bottom){

      return {
        "color": slice.primary.text_color,
        'margin-top': slice.primary.margin_top
          ? slice.primary.margin_top + 'px'
          : '-8em',
        'text-align': slice.primary.text_align,
        'margin-bottom': slice.primary.margin_bottom
          ? slice.primary.margin_bottom + 'px'
          : '-8em'
      } 

    } else {
      return{
        color: slice.primary.text_color ,
        'text-align': slice.primary.text_align,
      }
    }

  }

}
