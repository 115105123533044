import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PrismicService } from 'src/app/_services/prismic.service';

@Component({
  selector: 'app-covered-components',
  templateUrl: './covered-components.component.html',
  styleUrls: ['./covered-components.component.scss']
})
export class CoveredComponentsComponent implements OnInit {

  @Input() plan: any;

  constructor(
    private prismicService: PrismicService
  ) { }

  ngOnInit(): void {
  }

  getHtml(content) {
    return this.prismicService.getHtml(content);
  }

}
