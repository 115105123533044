import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment'
import brandInfo from '../../environments/brand.json'

declare var gtag;

@Injectable({
  providedIn: 'root'
})
export class GatewayService {

  public baseUrl;
  constructor(private httpClient : HttpClient) {

    this.baseUrl = this.getBaseUrl();

  }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  public getBaseUrl(){
    let baseUrl = environment.baseUrl;

    if(brandInfo.useFakeAPI == true){
      baseUrl = environment.fakeApiUrl;
    }

    return baseUrl

  }



  verify_code(): Observable<any> {
      return this.httpClient.get<any>(this.baseUrl + 'session')
        .pipe(
            retry(1),
            catchError(this.handleError)
        )
  }

   // Error handling
   handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
    }
}
