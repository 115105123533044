export let nissanppm = [{
  productName: "NISSAN PRE-PAID MAINTENANCE WITH TIRE ROTATIONS2",
  productNumber: "01t550000036ZT5AAM",
  productCode: "REPM",
  productFormNumber: null,
  productExternalId: "17ef1208-4d0c-48bc-b725-3fb631a96ee8",
  productSfId: "01t550000036ZT5AAM",
  statusCode: 100,
  message: "Successfully retrieved product plans for this product",
  surcharge: null,
  productPlanList: [
    {
      planName: "PMTR2T3",
      planNumber: "a0555000008RQ1uAAG",
      referenceNumber1: "NP1",
      planCode: "PMTR2N7",
      planExternalId: "4913abee-7a42-4a4a-8b8d-b9eabf3e92a4",
      deductible: "100.0",
      planTermSKUList: [
        {
          planTermSKURate: "242.00",
          planTermSKUName: "1 YEARS, 50,000 MILES",
          planTermSKUNumber: "a0655000004ZuB5AAK",
          productSkuSfId: "a0755000007jqFCAAY",
          productPlanSkuId: "178544",
          contractDealerCost: "121.0",
          productSkuTerm: 12.0,
          productSkuMileage: 0.0,
          paymentTerms: ["6 months", "1 payment"],
        },
        {
          planTermSKUName: "2 YEARS, 30,000 MILES",
          planTermSKURate: "370.00",
          planTermSKUNumber: "a0655000004ZuB6AAK",
          productSkuSfId: "a0755000007jqFDAAY",
          productPlanSkuId: "178565",
          contractDealerCost: "185.0",
          productSkuTerm: 24.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "2 YEARS, 30,000 MILES",
          planTermSKURate: "566.00",
          planTermSKUNumber: "a0655000004ZuB7AAK",
          productSkuSfId: "a0755000007jqFEAAY",
          productPlanSkuId: "178533",
          contractDealerCost: "283.0",
          productSkuTerm: 36.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        }
      ],
    },
    {
      planName: "PMTR2T6",
      planNumber: "a0555000008RQ1vAAG",
      referenceNumber1: 'NP2',
      planCode: "PMTR2N3",
      planExternalId: "15588a53-b57d-4722-84ba-389310975a58",
      deductible: "100.0",
      planTermSKUList: [
        {
          planTermSKUName: "1 YEARS, 100,000 MILES",
          planTermSKURate: "1316.00",
          planTermSKUNumber: "a0655000004ZuAaAAK",
          productSkuSfId: "a0755000007jqEhAAI",
          productPlanSkuId: "178575",
          contractDealerCost: "658.0",
          productSkuTerm: 84.0,
          productSkuMileage: 100000.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        }
      ],
    },
    {
      planName: "PMTR2T7",
      planNumber: "a0555000008RQ1vAAG",
      referenceNumber1: 'NP3',
      planCode: "PMTR2N3",
      planExternalId: "15588a53-b57d-4722-84ba-389310975a58",
      deductible: "100.0",
      planTermSKUList: [
        {
          planTermSKUName: "1 YEARS, 100,000 MILES",
          planTermSKURate: "1316.00",
          planTermSKUNumber: "a0655000004ZuAaAAK",
          productSkuSfId: "a0755000007jqEhAAI",
          productPlanSkuId: "178575",
          contractDealerCost: "658.0",
          productSkuTerm: 84.0,
          productSkuMileage: 100000.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        }
      ],
    },
    {
      planName: "PMTR2N3",
      planNumber: "a0555000008RQ1qAAG",
      referenceNumber1: 'RP3',
      planCode: "PMTR2T3",
      planExternalId: "4a4d7e65-7b26-430c-9f23-59879df7d4f2",
      deductible: "0.0",
      planTermSKUList: [
        {
          planTermSKUName: "1 YEAR",
          planTermSKURate: "270.00",
          planTermSKUNumber: "a0655000004ZuAfAAK",
          productSkuSfId: "a0755000007jqEmAAI",
          productPlanSkuId: "178557",
          contractDealerCost: "135.0",
          productSkuTerm: 12.0,
          productSkuMileage: 0.0,
          paymentTerms: ["6 months", "1 payment"],
        },
        {
          planTermSKUName: "2 YEARS",
          planTermSKURate: "268.00",
          planTermSKUNumber: "a0655000004ZuAgAAK",
          productSkuSfId: "a0755000007jqEnAAI",
          productPlanSkuId: "178538",
          contractDealerCost: "134.0",
          productSkuTerm: 24.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "3 YEARS",
          planTermSKURate: "654.00",
          planTermSKUNumber: "a0655000004ZuAhAAK",
          productSkuSfId: "a0755000007jqEoAAI",
          productPlanSkuId: "178546",
          contractDealerCost: "327.0",
          productSkuTerm: 36.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "4 YEARS",
          planTermSKURate: "728.00",
          planTermSKUNumber: "a0655000004ZuAiAAK",
          productSkuSfId: "a0755000007jqEpAAI",
          productPlanSkuId: "178534",
          contractDealerCost: "364.0",
          productSkuTerm: 48.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "5 YEARS",
          planTermSKURate: "730.00",
          planTermSKUNumber: "a0655000004ZuAjAAK",
          productSkuSfId: "a0755000007jqEqAAI",
          productPlanSkuId: "178568",
          contractDealerCost: "365.0",
          productSkuTerm: 60.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "6 YEARS",
          planTermSKURate: "1348.00",
          planTermSKUNumber: "a0655000004ZuAkAAK",
          productSkuSfId: "a0755000007jqErAAI",
          productPlanSkuId: "178562",
          contractDealerCost: "674.0",
          productSkuTerm: 72.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "7 YEARS",
          planTermSKURate: "654.00",
          planTermSKUNumber: "a0655000004ZuAlAAK",
          productSkuSfId: "a0755000007jqEsAAI",
          productPlanSkuId: "178543",
          contractDealerCost: "327.0",
          productSkuTerm: 84.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
      ],
    },
    {
      planName: "PMTR2N7",
      planNumber: "a0555000008RQ1rAAG",
      referenceNumber1: 'RP4',
      planCode: "PMTR2S6",
      planExternalId: "17593c29-1613-46a1-bfaf-0320f5f4386f",
      deductible: "0.0",
      planTermSKUList: [
        {
          planTermSKUName: "2 YEARS",
          planTermSKURate: "536.00",
          planTermSKUNumber: "a0655000004ZuAmAAK",
          productSkuSfId: "a0755000007jqEtAAI",
          productPlanSkuId: "178545",
          contractDealerCost: "268.0",
          productSkuTerm: 24.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "3 YEARS",
          planTermSKURate: "318.00",
          planTermSKUNumber: "a0655000004ZuAnAAK",
          productSkuSfId: "a0755000007jqEuAAI",
          productPlanSkuId: "178539",
          contractDealerCost: "159.0",
          productSkuTerm: 36.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "4 YEARS",
          planTermSKURate: "684.00",
          planTermSKUNumber: "a0655000004ZuAoAAK",
          productSkuSfId: "a0755000007jqEvAAI",
          productPlanSkuId: "178553",
          contractDealerCost: "342.0",
          productSkuTerm: 48.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "5 YEARS",
          planTermSKURate: "1202.00",
          planTermSKUNumber: "a0655000004ZuApAAK",
          productSkuSfId: "a0755000007jqEwAAI",
          productPlanSkuId: "178548",
          contractDealerCost: "601.0",
          productSkuTerm: 60.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "6 YEARS",
          planTermSKURate: "1054.00",
          planTermSKUNumber: "a0655000004ZuAqAAK",
          productSkuSfId: "a0755000007jqExAAI",
          productPlanSkuId: "178536",
          contractDealerCost: "527.0",
          productSkuTerm: 72.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "7 YEARS",
          planTermSKURate: "1072.00",
          planTermSKUNumber: "a0655000004ZuArAAK",
          productSkuSfId: "a0755000007jqEyAAI",
          productPlanSkuId: "178556",
          contractDealerCost: "536.0",
          productSkuTerm: 84.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
      ],
    },
    {
      planName: "PMTR2S6",
      planNumber: "a0555000008RQ1sAAG",
      referenceNumber1: 'RP5',
      planCode: "PMTR2T6",
      planExternalId: "3d8e7f4c-813c-4edf-8ed4-9172ce1adf17",
      deductible: "0.0",
      planTermSKUList: [
        {
          planTermSKUName: "1 YEAR",
          planTermSKURate: "146.00",
          planTermSKUNumber: "a0655000004ZuAsAAK",
          productSkuSfId: "a0755000007jqEzAAI",
          productPlanSkuId: "178564",
          contractDealerCost: "73.0",
          productSkuTerm: 12.0,
          productSkuMileage: 0.0,
          paymentTerms: ["6 months", "1 payment"],
        },
        {
          planTermSKUName: "2 YEARS",
          planTermSKURate: "424.00",
          planTermSKUNumber: "a0655000004ZuAtAAK",
          productSkuSfId: "a0755000007jqF0AAI",
          productPlanSkuId: "178532",
          contractDealerCost: "212.0",
          productSkuTerm: 24.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "3 YEARS",
          planTermSKURate: "496.00",
          planTermSKUNumber: "a0655000004ZuAuAAK",
          productSkuSfId: "a0755000007jqF1AAI",
          productPlanSkuId: "178552",
          contractDealerCost: "248.0",
          productSkuTerm: 36.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "4 YEARS",
          planTermSKURate: "428.00",
          planTermSKUNumber: "a0655000004ZuAvAAK",
          productSkuSfId: "a0755000007jqF2AAI",
          productPlanSkuId: "178540",
          contractDealerCost: "214.0",
          productSkuTerm: 48.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "5 YEARS",
          planTermSKURate: "808.00",
          planTermSKUNumber: "a0655000004ZuAwAAK",
          productSkuSfId: "a0755000007jqF3AAI",
          productPlanSkuId: "178554",
          contractDealerCost: "404.0",
          productSkuTerm: 60.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "6 YEARS",
          planTermSKURate: "964.00",
          planTermSKUNumber: "a0655000004ZuAxAAK",
          productSkuSfId: "a0755000007jqF4AAI",
          productPlanSkuId: "178555",
          contractDealerCost: "482.0",
          productSkuTerm: 72.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "7 YEARS",
          planTermSKURate: "1190.00",
          planTermSKUNumber: "a0655000004ZuAyAAK",
          productSkuSfId: "a0755000007jqF5AAI",
          productPlanSkuId: "178537",
          contractDealerCost: "595.0",
          productSkuTerm: 84.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
      ],
    },
    {
      planName: "PMTR2S7",
      planNumber: "a0555000008RQ1tAAG",
      referenceNumber1: 'RP6',
      planCode: "PMTR2T3",
      planExternalId: "4708792b-6747-46d6-b7b3-1998273a325a",
      deductible: "0.0",
      planTermSKUList: [
        {
          planTermSKUName: "2 YEARS",
          planTermSKURate: "534.00",
          planTermSKUNumber: "a0655000004ZuAzAAK",
          productSkuSfId: "a0755000007jqF6AAI",
          productPlanSkuId: "178558",
          contractDealerCost: "267.0",
          productSkuTerm: 24.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "3 YEARS",
          planTermSKURate: "486.00",
          planTermSKUNumber: "a0655000004ZuB0AAK",
          productSkuSfId: "a0755000007jqF7AAI",
          productPlanSkuId: "178566",
          contractDealerCost: "243.0",
          productSkuTerm: 36.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "4 YEARS",
          planTermSKURate: "918.00",
          planTermSKUNumber: "a0655000004ZuB1AAK",
          productSkuSfId: "a0755000007jqF8AAI",
          productPlanSkuId: "178560",
          contractDealerCost: "459.0",
          productSkuTerm: 48.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "5 YEARS",
          planTermSKURate: "920.00",
          planTermSKUNumber: "a0655000004ZuB2AAK",
          productSkuSfId: "a0755000007jqF9AAI",
          productPlanSkuId: "178535",
          contractDealerCost: "460.0",
          productSkuTerm: 60.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "6 YEARS",
          planTermSKURate: "828.00",
          planTermSKUNumber: "a0655000004ZuB3AAK",
          productSkuSfId: "a0755000007jqFAAAY",
          productPlanSkuId: "178569",
          contractDealerCost: "414.0",
          productSkuTerm: 72.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
        {
          planTermSKUName: "7 YEARS",
          planTermSKURate: "978.00",
          planTermSKUNumber: "a0655000004ZuB4AAK",
          productSkuSfId: "a0755000007jqFBAAY",
          productPlanSkuId: "178570",
          contractDealerCost: "489.0",
          productSkuTerm: 84.0,
          productSkuMileage: 0.0,
          paymentTerms: ["12 months", "6 months", "1 payment"],
        },
      ],
    },
  ],
}]
