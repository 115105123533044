import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { checkActiveOrderSuccess, checkActiveOrderFailed, checkActiveOrder } from '../_actions/check-active-order.actions';


const _activeOrderReducer = createReducer(initialState,
    on(checkActiveOrderSuccess, (state, payload) => {
        console.log('Confirmation Number', state, payload)
        sessionStorage.setItem('confirmationNumber', JSON.stringify(payload))
        return false;
    }),
    on(checkActiveOrderFailed, (state, payload) => {
        return true
    }),
    on(checkActiveOrder, (state, payload) => {
        return state
    })
);

export function activeOrderReducer(state, action) {
    return _activeOrderReducer(state, action);
}