import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { showInlineLoaderPayment, hideInlineLoaderPayment } from '../_actions/inline-loader.actions';


const _inlineLoaderMyAccountPaymentReducer = createReducer(initialState,
    on(showInlineLoaderPayment, (state) => {
      return true;
    }),
    on(hideInlineLoaderPayment, (state) => {
      return false;
    }),
); 


export function inlineLoaderMyAccountPaymentReducer(state, action) {
    return _inlineLoaderMyAccountPaymentReducer(state, action);
}
