<div class="modal-content cookie-acceptance-modal" *ngIf="showModal">
    <div class="modal-header mb-n1">
        <span aria-hidden="true">
            <mdb-icon class="modal-close-icon" tabindex="0" aria-label="Close" (click)="declineCookies()" fas icon="times"></mdb-icon>
        </span>
    </div>
    <div class="modal-body">
        <div class="row text-center">
            <div class="col-md-6 offset-md-3">
                <div class="text-center d-flex align-items-baseline">
                    <p class="cookie-acceptance-modal-text mr-3">This website uses cookies in order to offer you the most relevant information. Please accept cookies for optimal performance.</p>
                    <button mdbBtn class="btn primary-btn waves-light cookie-acceptance-modal-button" mdbWavesEffect type="submit"
                        (click)="acceptCookies()">ACCEPT</button>
                </div>
            </div>
        </div>
    </div>
</div>