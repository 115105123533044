import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, throwError } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { DealerService } from '../_services/dealer.service';
import { HttpClient } from '@angular/common/http';
import * as ActionConfigurationManager from '.././_actions/config.actions';
import { validateDealerError, validateDealerSuccess} from '../_actions/dealer-actions'
import { Injectable } from '@angular/core';


@Injectable()

export class DealerEffects {

    public validateDealer$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
        ofType(ActionConfigurationManager.VALIDATE_DEALER),
        switchMap((action: any) => this.dealerService.validateDealerID(action.dealerId, action.customerId)
            .pipe(
                map((res: any) => {
                  if (res) {
                    console.log('response from dealer', res)
                     if (action.employeeFirstName && action.employeeLastName) {
                      res.data.employeeFirstName = action.employeeFirstName
                      res.data.employeeLastName = action.employeeLastName
                     }
                     res.data.date = Date.now();
                     return validateDealerSuccess(res)
                  }
                  else {
                    return validateDealerError()
                  }
                }),
                catchError((error) => {
                    this.store.dispatch(validateDealerError())
                    return throwError(error)
                })
            )
        )
    )
  )

  constructor(
    private actions$: Actions, 
    private http: HttpClient, 
    private dealerService: DealerService,
    private store: Store<{}>
  ) {}
}





