<div class="modal-content">
  <div class="modal-header mb-n1">
    <span aria-hidden="true"><mdb-icon tabindex="0" class="modal-close-icon" aria-label="Close" (click)="modalRef.hide()" fas icon="times"></mdb-icon></span>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center pt-4">
        <h2>Oops, There was a problem.</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <div class="pb-4 pt-4">Sorry, we're having trouble pulling up your account. Don't worry, we can fix this.</div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12 col-6 text-center">
        <div><a class="btn primary-btn waves-light uppercase" mdbBtn mdbWavesEffect href="tel:{{ branding.cs_phone_number }}">Call Support</a></div>
      </div>
      <div class="col-md-12 col-sm-12 col-6 pt-3 pb-3"><a class="uppercase" (click)="modalRef.hide()">Cancel</a></div>
    </div>
  </div>
</div>
