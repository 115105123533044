import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { submitTaxMP, rehydrateTaxMP, taxUpdatedMP, updateTaxMP, taxSubmittedMP } from '../_actions/tax.actions';


const _taxRemainingReducer = createReducer(initialState,
    on(taxSubmittedMP, (state, payload) => {
        console.log('Tax Submitted', state, payload)
        window.sessionStorage.setItem('taxMP', JSON.stringify(payload));
        return payload;
    }),
    on(updateTaxMP, (state, payload) => {
        console.log('Tax Updated', state, payload)
        return state;
    }),
    on(taxUpdatedMP, (state, payload) => {
        console.log('Tax Updated', state, payload)
        window.sessionStorage.setItem('taxMP', JSON.stringify(payload));
        return payload;
    }),
    on(rehydrateTaxMP, (state, payload) => {
        console.log('Rehydrate Tax', state, payload)
        sessionStorage.setItem('taxMP', JSON.stringify(payload));
        return payload;
    }),
    on(submitTaxMP, (state, payload) => {
        console.log('Submit Tax Monthly Payments', state, payload)
        return state;
    })
);

export function taxRemainingReducer(state, action) {
    return _taxRemainingReducer(state, action);
}
