/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Subject } from "rxjs";
import { SelectionModel } from "@angular/cdk/collections";
export interface customColumnStyle {
  color?: string;
}
export interface TableColumn {
  key: string;
  display: string;
  config?: {
    clickableCell?: boolean;
    hasImage?: boolean;
    image?: string;
    isDate?: boolean;
    format?: string;
    isBoolean?: boolean;
    values?: { [key: string]: string };
    isAction?: boolean;
    actions?: string[];
    isToggle?: boolean;
    toggleValue?: boolean;
  };
  style?: customColumnStyle;
}
@Component({
  selector: "app-generic-table",
  templateUrl: "./generic-table.component.html",
  styleUrls: ["./generic-table.component.scss"],
})

export class GenericTableComponent<T>
  implements AfterViewInit, OnInit, OnDestroy
{
  private destroyer$ = new Subject();
  @Output() emitter = new EventEmitter();
  @Input() dataSource: MatTableDataSource<T> = new MatTableDataSource<T>([]);
  @Input() tableCols: TableColumn[] = [];
  @Input() selectable: boolean = true;
  @Input() paginationValues: number[] = [10, 25, 100];
  @Input() preview: boolean = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  searchTerm: string;
  selection = new SelectionModel<T>(true, []);
  displayableRows: TableColumn[] = [];
  constructor() {

  }
  ngOnInit(): void {
    if (this.selectable) {
      this.displayableRows = [
        { key: "select", display: "Select" },
        ...this.tableCols,
      ];
    } else {
      this.displayableRows = this.tableCols;
    }
  }
  get keys() {
    const rows = this.displayableRows.map(({ key }) => key);
    return rows;
  }
  showBooleanValue(elt: any, column: any) {
    return column.config.values[`${elt[column.key]}`];
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }
  checkboxLabel(row?: T): string {
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row }`;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  handleClickAction(emission?: string[] | undefined) {
    this.emitter.emit(emission);
  }
  ngOnDestroy(): void {
    this.destroyer$.next(false);
    this.destroyer$.complete();
  }
}
