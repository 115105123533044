import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { setPaymentOptionMax } from '../_actions/payment-options.actions';


const _paymentOptionsMaxReducer = createReducer(initialState,
    on(setPaymentOptionMax, (state, payload) => {
        sessionStorage.setItem('paymentOptionsMax', JSON.stringify(payload))
        return payload;
    })
);

export function paymentOptionsMaxReducer(state, action) {
    return _paymentOptionsMaxReducer(state, action);
}