import { Component, Input, OnInit } from '@angular/core';
import { PrismicService } from 'src/app/_services/prismic.service';

interface PanelData {
  title: string;
  content: string;
}

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent implements OnInit {
  @Input() panelData: PanelData;
  @Input() title: string;

  @Input() slice: any;
  @Input() vehicle: any;


  constructor(private prismicService: PrismicService) {}

  ngOnInit(): void {

  }

  getStyles(slice) {
    return {
      "background-color": slice.primary.background_color ? slice.primary.background_color : "inherit",
      "margin-top": slice.primary.margin_top  ? slice.primary.margin_top + "px": "initial",
      "margin-bottom": slice.primary.margin_bottom ? slice.primary.margin_bottom + "px": "initial",
      "padding-top": slice.primary.padding_top  ? slice.primary.padding_top + "px": "initial",
      "padding-bottom": slice.primary.padding_bottom ? slice.primary.padding_bottom + "px": "initial",
    };
  }

  // getHtml(content) {
  //   if (!content || !content.length) return '';

  //   let html = content[0].text;
  //   content[0].spans.slice().reverse().forEach(span => {
  //     if (span.type === 'hyperlink') {
  //       const link = `<a href="${span.data.url}" target="${span.data.target}">${html.substring(span.start, span.end)}</a>`;
  //       html = html.substring(0, span.start) + link + html.substring(span.end);
  //     } else if (span.type === 'strong') {
  //       const strong = `<strong>${html.substring(span.start, span.end)}</strong>`;
  //       html = html.substring(0, span.start) + strong + html.substring(span.end);
  //     }
  //   });

  //   return html;
  // }


  getHtml(content, vehicleObject) {
    return this.prismicService.getHtml(content, vehicleObject);
  }
}
