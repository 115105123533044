import { Actions, createEffect, ofType, Effect } from '@ngrx/effects';
import { of, EMPTY, throwError } from 'rxjs';
import { map, mergeMap, switchMap, mapTo, catchError } from 'rxjs/operators';
import { OrderService } from '../_services/order.service';
import * as ActionConfigurationManager from '.././_actions/config.actions';
import { Store } from '@ngrx/store';
import { accountVerified, invalidAccount, saveCode, verifyAccountOktaFlowFailed, verifyAccountOktaFlowSuccess } from '../_actions/verify-account.actions';
import { Injectable } from '@angular/core';
import { validCode, invalidCode } from '../_actions/verify-code-state.actions';
import { hideInlineLoader } from '../_actions/inline-loader.actions';
import { VerifyAccountOktaFlowResponse } from '../_models/verify-account';
import { OktaService } from '../_services/okta.service';
import { ConfirmationNumber } from '../_models/confirmation';
import { checkActiveOrderFailed, checkActiveOrderSuccess } from '../_actions/check-active-order.actions';
import { loadConfirmationNumber } from '../_actions/confirmation.actions';
import { Router } from '@angular/router';

@Injectable()

export class ActiveOrderEffects {

    public verifyAccountOktaFlow$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.CHECK_FOR_ACTIVE_ORDER),
            switchMap((action) => this.orderService.check_for_active_order(action)
                .pipe(
                    map(response => {
                        let object: ConfirmationNumber = {
                            confirmationNumber: response.data.orderId
                        }
                        this.store.dispatch(loadConfirmationNumber(object));
                        return checkActiveOrderSuccess(object);
                    }),
                    catchError((error) => {
                        this.store.dispatch(checkActiveOrderFailed())
                        return throwError(error);
                    }),
                )
            )
        )
    )



    constructor(
        private actions$: Actions,
        private store: Store<{}>,
        private orderService: OrderService,
        private router: Router
    ) { }
}
