<div id="mySidenav" class="sidenav shadow sideNavLeft" style="display: none" role="dialog" aria-live="assertive">
  <app-close-button (callbackFunction)="closeNav()"></app-close-button>
  <div class="brand" routerLink="/" routerLinkActive="active" (click)="closeNav()" (click)="redirectToHome()">
    <img [src]="logo?.url ? logo.url : ''" [alt]="logo?.alt" />
  </div>
  <div class="w-100 float-left">

    <a *ngIf="useNissanMenu" class="asp sidenav-item" tabindex="0" routerLink="/added-security-plan"
      (click)="redirectTo('/added-security-plan')" routerLinkActive="active" (click)="closeNav()">{{ 'NAV.PRODUCT1' |
      translate }}</a>
    <a *ngIf="useNissanMenu" class="ppm sidenav-item" tabindex="0" routerLink="/prepaid-maintenance"
      (click)="redirectTo('/prepaid-maintenance')" routerLinkActive="active" (click)="closeNav()">{{ 'NAV.PRODUCT2' |
      translate }}</a>

    <span *ngFor="let link of navLinks" class="nav-item" [ngClass]="{'active': currentURL == link.url}">
      <a *ngIf="link.url !== '/service-drive'" [href]="link.url" class="sidenav-item">{{link.text}}</a>
    </span>

    <a class="sidenav-item" tabindex="0" href="javascript:void(0)" routerLink="/help" routerLinkActive="active"
      aria-label="Visit our help center" (click)="goToHelp()" (click)="closeNav()">{{ 'NAV.HELP' | translate }}</a>

    <a *ngIf="!showSDLogout && customer == ''" href="javascript:void(0)" (click)="closeNav()" class="sidenav-item dropdown-toggle">{{ 'NAV.LOGIN' | translate }}</a>

    <a *ngIf="!showSDLogout && customer == ''" href="javascript:void(0)" class="sidenav-item sub-item" tabindex="0" href="/verify-account" (click)="closeNav()"
      routerLinkActive="active">{{
      'NAV.MY_ACCOUNT' | translate }}</a>

    <span class="sidenav-item" *ngIf="showSDLogout" style="white-space: nowrap;">
      <a style="display: inline-block;" href="javascript:void(0)" role="link" tabindex="0" (click)="closeNav()" (click)="logoutDealerAgent()"
        class="ignore"><mdb-icon aria-hidden="true" width="100%" fas icon="sign-out-alt"
          style="font-size:13px; display: inline-block;"></mdb-icon>&nbsp;{{dealerAgent}}</a>
    </span>

    <span *ngFor="let link of navLinks">
      <a role="link" *ngIf="link.url === '/service-drive' && !showSDLogout && customer === ''"  href="javascript:void(0)" (click)="navigateWithRefresh('service-drive')" (click)="closeNav()"
        class="sidenav-item sub-item ignore">{{ 'NAV.DEALER' | translate }}</a>
    </span>

    <span class="sidenav-item" *ngIf="showSDLogout" style="white-space: nowrap;">
      <a style="display: inline-block;" href="javascript:void(0)" role="link" href="/service-drive" tabindex="0" (click)="closeNav()"
        class="ignore">{{ 'NAV.SCAN_VIN' | translate }}</a>
    </span>

    <span class="sidenav-item" *ngIf="customer !== ''" style="white-space: nowrap;">
      <a style="display: inline-block;" role="link"
        tabindex="0" (click)="closeNav()" href="javascript:void(0)" (click)="callbackSignOut.emit()" class=""><mdb-icon aria-hidden="true" width="100%" fas icon="sign-out-alt"
        style="font-size:13px; display: inline-block;"></mdb-icon>&nbsp;{{
        'NAV.LOGOUT' |
        translate }} {{customer}}</a>
    </span>

  </div>
</div>

<mdb-navbar id="navbar" SideClass="navbar navbar-toggleable-md scrolling-navbar double-nav fixed-top"
  [containerInside]="false" mdbStickyHeader>
  <div class="row w-100 mb-1"
    style="align-items: center; display: flex; flex-direction: row; justify-content: space-between; flex-wrap: nowrap;">

    <div class="" style="display: flex;">
      <a class="navbar-brand" style="display: flex; flex-direction: column; justify-content: space-around;"
        href="javascript:void(0)" (click)="redirectToHome()">
        <img class="logo" [src]="logo?.url ? logo.url : ''" [alt]="logo?.alt" />
      </a>
      <div class="nav-dealer" *ngIf="dealerName">{{dealerName}}</div>
    </div>

    <div *ngIf="!isCheckoutPage && !showMobile" id="navContainer" #navbarDiv style="">

      <span *ngFor="let link of navLinks" class="nav-item" [ngClass]="{'active': currentURL == link.url}">
        <a *ngIf="link.url !== '/service-drive'" [href]="link.url"
          class="nav-link waves-light d-sm-inline-block nav-buttons">{{link.text}}</a>
      </span>

      <span *ngIf="useNissanMenu" class="nav-item desktop d-sm-inline-block nav-buttons myaccount" mdbDropdown
        [ngClass]="{'active': currentProductLink !== compareProduct}">
        <a mdbDropdownToggle class="dropdown-toggle nav-products" tabindex="0" role="link">{{currentProductLink}}</a>
        <div class="dropdown-menu">
          <a role="link" tabindex="0" routerLink="/added-security-plan" (click)="redirectTo('/added-security-plan')"
            routerLinkActive="active" class="waves-light nav-buttons" mdbWavesEffect style="white-space: nowrap;">{{
            'NAV.PRODUCT1' | translate }}</a><br />
          <a role="link" tabindex="0" routerLink="/prepaid-maintenance" (click)="redirectTo('/prepaid-maintenance')"
            routerLinkActive="active" class="waves-light nav-buttons" mdbWavesEffect style="white-space: nowrap;">{{
            'NAV.PRODUCT2' | translate }}</a>
        </div>
      </span>

      <span (click)="goToHelp()" class="nav-item" [ngClass]="{'active': isHelpPage}">
        <a role="link" href="javascript:void(0)" tabindex="0" class="nav-link waves-light d-sm-inline-block nav-buttons"
          mdbWavesEffect>{{ 'NAV.HELP' | translate }}</a>
      </span>

      <span class="nav-item nav-buttons float-right" *ngIf="showSDLogout" style="white-space: nowrap;">
        <mdb-icon aria-hidden="true" width="100%" fas icon="sign-out-alt"
          style="font-size:13px; display: inline-block;"></mdb-icon>&nbsp;<a style="display: inline-block;" role="link"
          tabindex="0" (click)="logoutDealerAgent()" class="nav-link waves-light nav-buttons">{{dealerAgent}}</a>
      </span>

      <span class="nav-item nav-buttons float-right" *ngIf="showSDLogout && brandInfo.brand !== 'VCIVW'" style="white-space: nowrap;">
        <a style="display: inline-block;" role="link" href="/service-drive" tabindex="0"
          class="nav-link waves-light nav-buttons">{{ 'NAV.SCAN_VIN' | translate }}</a>
      </span>

      <span class="nav-item nav-buttons float-right" *ngIf="customer !== ''" style="white-space: nowrap;">
        <mdb-icon aria-hidden="true" width="100%" fas icon="sign-out-alt"
          style="font-size:13px; display: inline-block;"></mdb-icon>&nbsp;<a style="display: inline-block;" role="link"
          tabindex="0" (click)="callbackSignOut.emit()" class="nav-link waves-light nav-buttons">{{ 'NAV.LOGOUT' |
          translate }} {{customer}}</a>
      </span>

      <div *ngIf="!showSDLogout && customer == ''" class="nav-item float-right d-sm-inline-block nav-buttons myaccount"
        mdbDropdown>
        <a mdbDropdownToggle class="dropdown-toggle" tabindex="0" role="link">{{ 'NAV.LOGIN' | translate }}</a>
        <div class="dropdown-menu">
          <a role="link" class="waves-light d-sm-inline-block nav-buttons" href="/my-account"
            routerLinkActive="active" mdbWavesEffect>{{ 'NAV.MY_ACCOUNT' | translate }}</a><br />
          <span *ngFor="let link of navLinks">
            <a role="link" *ngIf="link.url === '/service-drive'" href="/service-drive"
              class="waves-light d-sm-inline-block nav-buttons" mdbWavesEffect>{{ 'NAV.DEALER' | translate }}</a>
          </span>
        </div>
      </div>

      <span class="call nav-item">
        <a role="link" href="tel:{{ branding.cs_phone_number }}" tabindex="0"
          class="phone_number nav-link waves-light d-sm-inline-block nav-buttons" mdbWavesEffect
          [attr.aria-label]="setCallUs(branding.cs_phone_number)">
          <mdb-icon fas icon="phone"></mdb-icon><span>{{ branding.cs_phone_number }}</span>
        </a>
      </span>

      <span class="nav-item shopping-cart-icon" [ngClass]="{'active': isCartPage}">
        <a tabindex="0" role="button" alt="" aria-hidden="true" class="nav-link waves-light nav-buttons"
          (click)="cartModalShow()" data-toggle="modal">
          <mdb-icon fas icon="shopping-cart"></mdb-icon>
          <span style="display: none;">Shopping Cart</span>
          <span *ngIf="cartCount > 0" class="badge badge-primary">{{cartCount}}</span>
        </a>
      </span>
    </div>
    <div class="checkout nav-item float-right" *ngIf="isCheckoutPage">
      <h3>{{ 'CART_CHECKOUT.CHECKOUT' | translate }}</h3>
    </div>
    <div *ngIf="!isCheckoutPage && showMobile" style="position:fixed; right:15px; display: flex; align-items: center;">

      <span class="call nav-item float-right box">
        <a role="link" href="tel:{{ branding.cs_phone_number }}" tabindex="0"
          class="phone_number nav-link waves-light d-sm-inline-block nav-buttons" mdbWavesEffect
          [attr.aria-label]="'NAV.CALL_US' | translate">
          <mdb-icon fas icon="phone"></mdb-icon><span> {{ 'NAV.CALL_US' | translate }}</span>
        </a>
      </span>

      <span class="nav-item shopping-cart-icon" [ngClass]="{'active': isCartPage}">
        <a tabindex="0" role="button" alt="" aria-hidden="true" class="nav-link waves-light nav-buttons"
          (click)="cartModalShow()" data-toggle="modal">
          <mdb-icon fas icon="shopping-cart"></mdb-icon>
          <span style="display: none;">Shopping Cart</span>
          <span *ngIf="cartCount > 0" class="badge badge-primary">{{cartCount}}</span>
        </a>
      </span>

      <span class="nav-item">
        <a tabindex="0" role="button" class="nav-link waves-light nav-buttons" mdbWavesEffect (click)="openNav()"
          aria-label="Menu"> <span class=""> </span> <i class="fas fa-bars"></i></a>
      </span>
    </div>
  </div>

</mdb-navbar>