import {createAction, props} from "@ngrx/store"
import { prismicContent, prismicLandingPage } from "src/app/_models/prismic";


  export const loadPrismic2 = createAction(
    '[Prismic Component] Load Prismic2',
    props<{ prismicLandingPage: prismicLandingPage }>()
  );

export const loadPrismic2Successs = createAction(
  '[Prismic Component] Load Prismic Success2',
  props<{ prismicContent: prismicContent }>()

  );

export const loadPrismic2Failure = createAction(
  '[Prismic Component] Load Prismic Failed',
    props<{ errorMessage: string }>()
  );
