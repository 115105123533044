import { Actions, createEffect, ofType, Effect } from '@ngrx/effects';
import { throwError } from 'rxjs';
import { map, switchMap, mapTo, catchError } from 'rxjs/operators';
import { AccountService } from '../_services/account.service';
import { accountLoaded, accountUpdated, updateContactStateOn, updateBillingStateOn, updatePaymentStateOn, loadPaylink, noAccountFound, updateBillingInformation, getAccountSFIDSuccess, getAccountSFIDFailed, loadSPPHistory, getAuthTokenSuccess, getAuthTokenFailed, getAccountSFID } from '../_actions/account.actions'
import * as ActionConfigurationManager from '.././_actions/config.actions';
import { hideInlineLoader, hideInlineLoaderContact, hideInlineLoaderPayment, hideInlineLoaderBilling, hideInlineLoaderBillingInformation } from '../_actions/inline-loader.actions'
import { Store } from '@ngrx/store'
import { Injectable } from '@angular/core';


@Injectable()

export class AuthTokenEffects {

    public getAccountByEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.GET_AUTH_TOKEN),
            switchMap((action: any) => this.accountService.get_auth_token(action)
                .pipe(
                    map(response => {
                        sessionStorage.setItem('tokenId', response.data.access_token)
                        this.store.dispatch(getAuthTokenSuccess(response))
                        return getAccountSFID(action);

                    }),
                    catchError((error) => {
                        this.store.dispatch(getAuthTokenFailed())
                        return throwError(error)
                    }),
                )
            )
        )
    )

    constructor(
        private actions$: Actions,
        private accountService: AccountService,
        private store: Store<{}>,
    ) { }
}
