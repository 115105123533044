import { loadProductDisclaimers, loadProductDisclaimersSuccess, loadProductDisclaimersFailed } from "../_actions/product-disclaimers.actions";
import { createReducer, on } from "@ngrx/store";
export const initialState = null;

const _productDisclaimerReducer = createReducer(
  initialState,
  on(loadProductDisclaimers, (state, payload) =>  {
    // console.log("Load ProductDisclaimers", state)
    sessionStorage.setItem('productDisclaimers', JSON.stringify(payload));
    return payload;
  }),
  on(loadProductDisclaimersSuccess, (state, payload) => {
    console.log("Load ProductDisclaimers Success", state, payload);
    sessionStorage.setItem('productDisclaimers', JSON.stringify(payload));
    return payload;
  }),
  on(loadProductDisclaimersFailed, (state) => {
    console.log("Load ProductDisclaimers Failed", state);
    sessionStorage.removeItem('productDisclaimers');
    return false;
  })
);


export function productDisclaimerReducer(state, action) {
  return _productDisclaimerReducer(state, action);
}
