import { Make } from "./make";
import { Model } from "./model";
import { Trim } from "./trim";

export class Vehicle {
  isElectric: Boolean;
  make: string;
  makeExternalId?: string;
  makeSfId?: string;
  mileage?: number;
  model: string;
  modelExternalId?: string;
  modelSfId?: string;
  modelNumber?: string;
  purchaseDate?: string;
  inserviceDate?: string;
  deliveryDate?: string;
  exteriorColor?: string;
  mfrWarrantyDate?: string;
  referenceNumber1?: string;
  referenceNumber2?: string;
  referenceNumber3?: string;
  referenceNumber4?: string;
  trim?: string;
  trimExternalId?: string;
  trimSfId?: string;
  vehicleCondition?: string;
  vehicleExternalId?: string;
  vehicleSfId?: string;
  vehicleType?: string;
  vin: string;
  registeredState: string;
  year: string;
  image?: string;
}

export class VehicleImage {
  image: string;
}

export class VinDecodeResponse {
  data: VinDecodeData;
  status: string;
  errorMessage: string;
}

export class VinDecodeData {
  vin: string;
  vehicleYear: string;
  series: string;
  trimLevel: string;
  vehicleType: string;
  vehicleSubtype: string;
  bodyType: string;
  vehicleClass: string;
  driveLine: string;
  engineCode: string;
  engineType: string;
  fuelType: string;
  manufacturingPlant: string;
  manufacturingCountry: string;
  model: string;
  make: string;
}

export class Vin {
  vin: string;
}

export class MyVehicle {
  makeSfId: string;
  makeExternalId: string;
  purchaseDate: string;
  referenceNumber1: string;
  referenceNumber2: string;
  referenceNumber3: string;
  referenceNumber4: string;
  year: string;
  trimSfId: string;
  trimExternalId: string;
  modelSfId: string;
  modelExternalId: string;
  vin: string;
  modelNumber: string;
  vehicleaType: string;
  mileage: number;
  vehicleCondition: string;
}

export class VerifyCustVehReq {
  vin: string;
  email: string;
}

export class VerifyVehCustResponse {
  status: string;
  data: VerifyVehCustResponseData;
  errorMessage: string;

}

export class VerifyVehCustResponseData {
  factoryExpired: boolean;
  vinExist: boolean;
  ninetyDayExpired: boolean;
  vehicleSfid: string;
  accountId: string;
  contactSfId: string;
  purchaseRecordId: string;
  opportunityId: string;
  customerBrandMapping: boolean;
  customerVehicleMapping: boolean;
  brandSfid: string;
  exteriorColor: string;
  customerExist: boolean;
  electric: boolean;
}