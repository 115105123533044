<!-- HERO SECTION -->
<div class="hero override" [ngStyle]="{ 'margin-top': slice.primary.margin_top+'px', 'margin-bottom': slice.primary.margin_bottom+'px' }">
  <div *ngFor="let component of slice.primary.hero?.body">
    <div [ngSwitch]="component.type">
      <app-component-hero-image *ngSwitchCase="'image'" [component]="component" [vehicleObject]="vehicle"></app-component-hero-image>
    </div>
  </div>

  <div class="main-hero-section pr-4 pl-4 container">
    <mdb-card role="region" aria-live="polite"> 
      <mdb-card-body [ngStyle]="{ 'padding-top': slice.primary.hero?.padding_top+'px', 'padding-bottom': slice.primary.hero?.padding_bottom+'px', 'padding-left': slice.primary.hero?.padding_left+'px', 'padding-right': slice.primary.hero?.padding_right+'px' }">
        <mdb-card-title class="text-center">
          <div *ngFor="let component of slice.primary.hero?.body">
            <div [ngSwitch]="component.type">
              <app-component-icon-group *ngSwitchCase="'icon_group'" [component]="component" [vehicleObject]="vehicle" ></app-component-icon-group>
              <app-component-icon *ngSwitchCase="'icon'" [component]="component" [vehicleObject]="vehicle"></app-component-icon>
              <app-component-title *ngSwitchCase="'title'" [component]="component" [vehicleObject]="vehicle"></app-component-title>
              <app-component-subtitle *ngSwitchCase="'subtitle'" [component]="component" [vehicleObject]="vehicle"></app-component-subtitle>
              <app-component-hero-body *ngSwitchCase="'body'" [component]="component" [vehicleObject]="vehicle"></app-component-hero-body>
              <app-component-icon-with-cta *ngSwitchCase="'icon_with_cta'" [component]="component" [vehicleObject]="vehicle"></app-component-icon-with-cta>

              <!-- <app-component-cta *ngSwitchCase="'cta'" [component]="component" [vehicleObject]="vehicle"></app-component-cta> -->
            </div>
          </div>
        </mdb-card-title>
        <mdb-card-text class="text-center">
          <div *ngFor="let component of slice.primary.hero?.body">
            <div [ngSwitch]="component.type">
              <app-component-cta *ngSwitchCase="'cta'" [component]="component" [vehicleObject]="vehicle" (emitOpenModal)="openModal($event)"></app-component-cta>
            </div>
          </div>
        </mdb-card-text>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
