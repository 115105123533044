<div [ngStyle]="{ color: text_color }">
  <div class="container">

    <div class="row d-flex justify-content-center desktop mt-3" [ngClass]="{'desktop': component.primary.mobile_carousel_mode == 'enabled'}">
      <div *ngFor="let item of component.fields" class="text-center icon-group col-12" [ngClass]="{'col-lg-3 col-md-4': component.fields.length > 5, 'col-md': component.fields.length < 6}">

          <img *ngIf="item.icon" class="hiw-img pl-3 pr-3 pb-2" style="max-width:90px" [alt]="item.icon?.alt" [src]="item.icon?.url" />
          <p tabindex="0" role="text" class="cap-head"><strong [innerHTML]="getHtml(item.caption, vehicle)"></strong></p>
          <div *ngIf="item.disclaimer_subscript" class="disclaimer_subscript" (click)="showNotification(item.disclaimer_text)">
            {{item.disclaimer_subscript[0].text}}
          </div>

      </div>
    </div>

    <div class="mt-3" [ngClass]="{'d-none': component.primary.mobile_carousel_mode !== 'enabled', 'mobile': component.primary.mobile_carousel_mode == 'enabled'}">
      <ngx-slick-carousel class="carousel" [config]="slideConfig" #slickModal="slick-carousel">
        <div ngxSlickItem *ngFor="let item of component.fields" class="slide">
          <div class="w-100 p-2">
              <img class="hiw-img pl-3 pr-3 pb-2" style="max-width:90px" [alt]="item.icon?.alt" [src]="item.icon?.url" />
              <p tabindex="0" class="cap-head"><strong [innerHTML]="getHtml(item.caption, vehicle)"></strong></p>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>

  </div>
</div>
