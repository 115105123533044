import { createReducer, on } from '@ngrx/store';
import { removeCartDeleteItemSkuID, saveCartDeleteItemSkuID } from '../_actions/cart.actions';

export const initialState = null;


const _cartDeleteItemReducer = createReducer(initialState,
    on(saveCartDeleteItemSkuID, (state, payload) => {
        sessionStorage.setItem('cartDeleteItem', JSON.stringify(payload))
        return payload;
    }),
    on(removeCartDeleteItemSkuID, (state, payload) => {
        sessionStorage.removeItem('cartDeleteItem')
        return payload
    })
);

export function cartDeleteItemReducer(state, action) {
    return _cartDeleteItemReducer(state, action);
}