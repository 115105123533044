import { PrismicService } from './../_services/prismic.service';
import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-modal-disclaimer',
  templateUrl: './modal-disclaimer.component.html',
  styleUrls: ['./modal-disclaimer.component.scss']
})
export class ModalDisclaimerComponent implements OnInit {

  disclaimer_text: any;

  constructor(public modalRef: MDBModalRef, private prismicService: PrismicService) { }

  ngOnInit(): void {
  }


  getHtml(content, vehicleObject = null) {
    return this.prismicService.getHtml(content, vehicleObject);
  }

}
