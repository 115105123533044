<div class="mobile-plan-footer mobile">
    <div class="container">
        <div class="row">
            <div class="col-12 plan">
                <div class="text-center" *ngIf="brand !== 'Lambo'">
                    <div class="plan-name">{{currentPlan?.name}}</div>
                    <span class="plan-terms">{{convertTermLabel(plan?.planTerms ? plan.planTerms[plan.term.termIndex]?.planTermSKUName : "")}}</span>
                    <span class="deductible" *ngIf="currentPlan?.term.deductible">, ${{currentPlan?.term.deductible}} {{ 'CART_CHECKOUT.DEDUCTIBLE_OCCURENCE' | translate }}</span>
                    <span *ngIf="currentPlan?.term.commercialUseSelected">, Commerical Coverage</span>
                </div>
                <div class="text-center" *ngIf="brand === 'Lambo'">
                    <div class="plan-name">{{currentPlan?.name}}</div>
                    <span class="plan-terms">{{ 'CART_CHECKOUT.UNLIMITED_MILEAGE' | translate }}</span>
                </div>
            </div>
        </div>
        <div class="col-12 text-center add-button">
            <button mdbBtn class="btn primary-btn waves-light add-to-cart-button-mobile" id="add-to-cart-button-mobile" mdbWavesEffect type="submit" (click)="addToCart()">
                <span>
                    {{ 'RATES_PAGE.ADD_TO_CART' | translate }}
                </span>
            </button>
        </div>
    </div>
</div>
