import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { InlineLoaderComponent } from '../inline-loader/inline-loader.component';
import { CloseButtonComponent } from '../close-button/close-button.component';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { AppTooltipComponent } from '../tooltip/tooltip.component';
import { VerifyVehicleComponent } from './verify-vehicle/verify-vehicle.component';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { AddOnsComponent } from './surcharge-commercial/add-ons.component';

import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { CustomButtonComponent } from './material-component/custom-button/custom-button.component';
import { GenericTableComponent } from './material-component/generic-table/generic-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { SelectDropdownComponent } from './material-component/select-dropdown/select-dropdown.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SelectPlanDescriptionComponent } from './material-component/select-plan-description/select-plan-description.component';
import { ToggleButtonGroupComponent } from './material-component/toggle-button-group/toggle-button-group.component';
import { MatButtonModule } from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { ExpansionPanelComponent } from './material-component/expansion-panel/expansion-panel.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatTooltipModule} from '@angular/material/tooltip';

@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@NgModule({
  declarations: [
    InlineLoaderComponent,
    CloseButtonComponent,
    AppTooltipComponent,
    VerifyVehicleComponent,
    // Pipes
    SafeHtmlPipe,
    ErrorMessageComponent,
    AddOnsComponent,
    ConfirmationDialogComponent,
    CustomButtonComponent,
    GenericTableComponent,
    SelectDropdownComponent,
    SelectPlanDescriptionComponent,
    ToggleButtonGroupComponent,
    ExpansionPanelComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    MDBBootstrapModulesPro,
    TranslateModule.forChild(),
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule
   ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    InlineLoaderComponent,
    CloseButtonComponent,
    AppTooltipComponent,
    VerifyVehicleComponent,
    // Pipes
    SafeHtmlPipe,
    TranslateModule,
    ErrorMessageComponent,
    AddOnsComponent,
    CustomButtonComponent,
    GenericTableComponent,
    SelectDropdownComponent,
    SelectPlanDescriptionComponent,
    ToggleButtonGroupComponent,
    ExpansionPanelComponent,
  ]
})
export class SharedModule { }
