import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-account-verify-no-orders',
  templateUrl: './my-account-verify-no-orders.component.html',
  styleUrls: ['./my-account-verify-no-orders.component.scss']
})
export class MyAccountVerifyNoOrdersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
