<section class="purchase-plan">
  <div>
    <h4>{{ item.title }}</h4>
    <p>${{item.amount}}</p>
    <p>Total cost is ${{ item.amount.toFixed(2) }}</p>
    <small [style.color]="item.textColor">{{ item.coloredText }}</small>
    <div>
      <button  mat-stroked-button>POWERTRAIN</button>
    </div>

  </div>
</section>
