<div class="row vehicle-info-container">
  <div *ngIf="(vehicle$ | async) as vehicle">
    <div tabindex="0" role="heading" aria-live="assertive" [ngStyle]="getVehicleStyle(component)" class="vehicle-component"
      *ngIf="!component.primary?.verify_form">
      <div class="vehicle-information"><span *ngIf="component.primary?.year"> {{vehicle?.year}}</span><span *ngIf="component.primary?.make">
          {{vehicle?.make}}</span> <span *ngIf="component.primary?.model"> {{vehicle?.model}}</span>
      </div>
      <div *ngIf="component.primary?.vin"><strong> {{ 'ASP_LANDING.VIN' | translate }} {{ maskVin(vehicle?.vin)}}</strong></div>
    </div>
  </div>

  <app-verify-vehicle class="verify_form" *ngIf="component.primary?.verify_form" [ngStyle]="getStyles(component)"
    [verifyVehicleData]="component.primary"></app-verify-vehicle>
</div>