<div mdbModal #phoneModal="mdbModal" class="modal fade right modal-scrolling chat" id="phoneModal" tabindex="-1" role="dialog" aria-hidden="true" [config]="{ backdrop: false, ignoreBackdropClick: false }">
    <div class="modal-dialog modal-side modal-bottom-right modal-notify modal-info phone-modal" role="document">
      <!--Content-->
      <div class="modal-content">
        <!--Header-->
        <div class="modal-header">
          <p class="heading" style="margin-right: 25px;">{{ 'NAV.CALL_US' | translate }}: <a [attr.aria-label]="'NAV.CALL_US' | translate" href="tel:{{ phoneNumber }}">{{ phoneNumber }}</a></p>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="phoneModal.hide()">
            <mdb-icon fas icon="times"></mdb-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  