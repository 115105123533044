import { nissanvsc } from "./products/nissanvsc";
import { nissanppm } from "./products/nissanppm";
import { buickvsc } from "./products/buickvsc";
import { vroomvsc } from "./products/vroomvsc";
import { chevroletvsc } from "./products/chevroletvsc";
import { cadillacvsc } from "./products/cadillacvsc";
import { gmcvsc } from "./products/gmcvsc";
import { revvsc } from "./products/revvsc";

export let previewRates = {
  status: "OK",
  data: {
    productDetailsList: [
      ...nissanvsc,
      ...nissanppm,
      ...buickvsc,
      ...chevroletvsc,
      ...vroomvsc,
      ...cadillacvsc,
      ...gmcvsc,
      ...revvsc,
    ],
    vehicleYear: "2020",
    vehicleMake: "AUDI",
    vehicleModel: "A4",
    vehicleTrim: "2.0T PREMIUM",
    paymentOptions: true,
  },
  errorMessage: null,
  type: "[Rehydrate] Rates",
};
