import { Component, OnInit, Input } from '@angular/core';
import { PrismicService } from 'src/app/_services/prismic.service';

@Component({
  selector: 'app-component-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {

  @Input() public component: any;
  @Input() public max_width: any;
  @Input() public vehicleObject: any;
  @Input() public position: any;

  constructor(private prismicService: PrismicService) { }

  ngOnInit() {
    setTimeout(() => {
      let grid  = window.document.querySelector('.prismic-grid');
      console.log('GRID', grid);
      let columns = grid.querySelectorAll('.grid-column');
      console.log('COLUMNS', columns);
      console.log('COLUMNS LENGTH', columns.length);

      let gridBodyCount = columns[0].querySelectorAll('.grid-body').length;
      console.log('gridBodyCount', gridBodyCount)
      
      let table = document.createElement('table');
      let trHeader = document.createElement('tr');
      columns.forEach(column => {
        let th = document.createElement('th');
        let thText = document.createTextNode(column.querySelector('.grid-header').innerHTML);
        th.appendChild(thText);
        trHeader.appendChild(th)
      });
      table.appendChild(trHeader);
      for (let x = 2; x <= gridBodyCount + 1; x++) {
        let trBody = document.createElement('tr');
        columns.forEach(column => {
          let td = document.createElement('td');
          let tdText = document.createTextNode(column.querySelector('.grid-body:nth-child(' + x +')') ? column.querySelector('.grid-body:nth-child(' + x +')').innerHTML : '');
          td.appendChild(tdText);
          trBody.appendChild(td)
        });
        table.appendChild(trBody);
      }
      table.setAttribute('id', 'gridTable')
      if (this.max_width) {
        table.style.maxWidth = this.max_width + "%";
        table.style.width = this.max_width + "%";
      }
      if (this.position) {
        table.style.float = this.position;
      }

      if (!document.querySelector('#gridTable')) {
        document.querySelector('.gridHolder').appendChild(table);
      }
      console.log('TABLE', table);
    }, 10);
  }

  getWidthStyle(width) {
    return {
      "max-width": width ? width + "%": "auto" 
    }
  }
}
