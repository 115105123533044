import { Component, OnInit, Input } from '@angular/core';
import { PrismicService } from 'src/app/_services/prismic.service';

@Component({
  selector: 'app-component-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  @Input() public component: any;
  @Input() public text_color: any;
  @Input() public vehicleObject: any;
  @Input() public text_align: any;


  constructor(private prismicService: PrismicService) { }

  ngOnInit() {
  }


  getHtml(content, vehicleObject) {
    return this.prismicService.getHtml(content, vehicleObject);
  }


}
