import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Token } from '../_models/token'
import { addToken, removeToken } from '../_actions/token.actions';
import { Injectable } from "@angular/core";


@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private store: Store<{}>) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token: Token = JSON.parse(sessionStorage.getItem("token"));
    let correlationId = sessionStorage.getItem("correlationId");

    // Used to log into portal
    let tokenId = sessionStorage.getItem("tokenId");

    let headers = {};
    if (!request.url.includes('s3Upload/upload')) {
      headers['Content-Type'] = 'application/json';
    }


    if (!correlationId) {
      console.log('Found correlationId id from sessionStorage.');
      correlationId = `${Date.now()}-${Math.floor(Math.random() * 1000000000) + 1}`
      sessionStorage.setItem('correlationId', correlationId);
    }

    headers['correlationId'] = `${correlationId}`;

    if (token && token.token) {
      headers['Authorization'] = token.token;
    }

    if(tokenId){
      headers['tokenId'] = `Bearer ${tokenId}` ;
    }

    request = request.clone({
      headers: new HttpHeaders(headers)
    });


    // if(request.url.includes('/v1/api/content')){
    //   console.log(`Interception ${request.url}`, request.headers.getAll("prismic-baseurl"),  request.headers.getAll("prismic-ref"));
    // }

    return next.handle(request).pipe(
      map(resp => {
        if (resp instanceof HttpResponse) {
          // console.log(resp)
          // console.log(resp.headers.get("Authorization"))

          if (resp.headers.get('Authorization')) {
            let authorization: Token = {
              token: resp.headers.get("Authorization")
            }

            if (authorization.token !== null) {
              this.store.dispatch(addToken(authorization))
            } else {
              this.store.dispatch(removeToken())
            }
          }
          return resp;
        }
      })
    );
  }
}
