import { Component, Input, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-modal-interstitial',
  templateUrl: './modal-interstitial-popup.component.html',
  styleUrls: ['./modal-interstitial-popup.component.scss']
})
export class ModalInterstitialComponent implements OnInit {

  @Input() title: string;
  @Input() content: string;
  @Input() link: string;
  @Input() timeout: number;
  modalTimer: any;

  constructor(    public modalRef: MDBModalRef,
    ) { }

  ngOnInit(): void {
    this.modalTimer = setInterval(() => {
      this.modalRef.hide();
      window.open(this.link);
      clearInterval(this.modalTimer);
    }, this.timeout? this.timeout : 15000); // default to 15 seconds if timeout is not set
  }

  followLink() {
    this.modalRef.hide();
    clearInterval(this.modalTimer);
    window.open(this.link);
  }

  closeModal() {
    this.modalRef.hide();
    clearInterval(this.modalTimer);
  }
}
