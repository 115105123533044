import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { downloadPaymentPlanAgreement, downloadPaymentPlanAgreementFailed, downloadPaymentPlanAgreementSuccess, getPaymentPlanAgreement, getPaymentPlanAgreementFailed, getPaymentPlanAgreementSuccess } from '../_actions/pdf.actions';

const _paymentPlanAgreementReducer = createReducer(initialState,

    on(getPaymentPlanAgreement, (state, payload) => {
        return state;
    }),
    on(getPaymentPlanAgreementSuccess, (state, payload) => {
        console.log('payload payload ', payload)

        return payload;
    }),

    on(getPaymentPlanAgreementFailed, (state, payload) => {
        return payload;
    }),
    on(downloadPaymentPlanAgreement, (state, payload) => {
        return state;
    }),
    on(downloadPaymentPlanAgreementSuccess, (state, payload) => {
        console.log('payload payload ', payload)

        return payload;
    }),

    on(downloadPaymentPlanAgreementFailed, (state, payload) => {
        return payload;
    }),

);


export function paymentPlanAgreementReducer(state, action) {
    return _paymentPlanAgreementReducer(state, action);
}