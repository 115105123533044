import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import brandInfo from "../../environments/brand.json";
import { AppConfigService } from '../appconfig/appconfig.service';

@Component({
  selector: 'app-modal-okta-error',
  templateUrl: './modal-okta-error.component.html',
  styleUrls: ['./modal-okta-error.component.scss']
})
export class ModalOktaErrorComponent implements OnInit {
  branding: any;

  constructor(public modalRef: MDBModalRef, private appConfig: AppConfigService) { }

  ngOnInit(): void {
    this.appConfig.getSettings().subscribe(
      (result) => {
        this.branding = result;
      });
  }
}
