import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  isCheckoutPage = false;
  isWidget = false;

  constructor(private router: Router, private location: Location) {}

  ngOnInit() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (event.url === "/checkout") {
        this.isCheckoutPage = true;
      } else {
        this.isCheckoutPage = false;
      }
    });

    // If widget, hide nav
    if (this.location.path().includes("widget")) {
      this.isWidget = true;
    }
  }
}
