export let previewVehicle = {
  isElectric: false,
  vehicleExternalId: null,
  vehicleSfId: "0015500001ILzgQAAT",
  make: "Audi",
  makeExternalId: "AUDI",
  makeSfId: "a0055000006OUWqAAO",
  inserviceDate: "03-08-2021",
  deliveryDate: "03-08-2021",
  exteriorColor: null,
  purchaseDate: "03-08-2021",
  mfrWarrantyDate: "02-02-2026",
  mileage: 500,
  model: "A4",
  modelNumber: null,
  modelExternalId: null,
  modelSfId: "a015500000FzbePAAR",
  referenceNumber1: null,
  referenceNumber2: null,
  referenceNumber3: null,
  referenceNumber4: null,
  trim: null,
  trimExternalId: null,
  trimSfId: null,
  vehicleCondition: null,
  vehicleType: null,
  vin: "WAUGMAF43LN020516",
  year: "2019",
  type: "[Vehicle Component] Save Vehicle",
  image: "https://dbhdyzvm8lm25.cloudfront.net/color_0640_032_png/MY2019/13355/13355_cc0640_032_0E0E.png",
};
