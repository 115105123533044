<!-- MEET MY VEHICLE MODAL -->
<!-- <div class="modal-content dark disclaimer desktop">
  <div class="row">
    <div class="modal-body">
      <div class="col-sm-1 offset-md-1 col-md-10 d-flex">
        <button type="button" class="close-big pull-left light f-26" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">x</span>
        </button>
        <div [innerHtml]="getHtml(disclaimer_text)"></div>
      </div>
    </div>
  </div>
</div> -->

<div class="modal-content disclaimer">
  <div class="row">
    <div class="modal-body">
      <div class="col-sm-1 offset-md-1 col-md-10 d-flex">
        <button type="button" class="close-big pull-left light f-26" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">x</span>
        </button>
        <div [innerHtml]="getHtml(disclaimer_text)"></div>
      </div>
    </div>
  </div>
</div>
