import { Component, OnInit, Input } from '@angular/core';
import { PrismicService } from 'src/app/_services/prismic.service';

@Component({
  selector: 'app-component-hero-image',
  templateUrl: './hero-image.component.html',
  styleUrls: ['./hero-image.component.scss']
})
export class HeroImageComponent implements OnInit {
  @Input() public component: any;
  @Input() public text_color: any;
  @Input() public vehicleObject: any;

  constructor(private prismicService: PrismicService) { }

  ngOnInit() {
  }


  getHtml(content, vehicleObject) {
    return this.prismicService.getHtml(content, vehicleObject);
  }



}
