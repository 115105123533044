import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { invalidCode, resetInvalidCodeState, validCode } from '../_actions/verify-code-state.actions';


const _verifyCodeStateReducer = createReducer(initialState,
    on(invalidCode, (state, payload) => {
        return payload;
    }),
    on(resetInvalidCodeState, (state, payload) => {
        return null;
    }),
    on(validCode, (state, payload) => {
        return 'valid';
    })

);

export function verifyCodeStateReducer(state, action) {
    return _verifyCodeStateReducer(state, action);
}