import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { oktaSignOut, oktaSignIn, resetOktaUser, setOktaUser } from '../_actions/okta.actions';


const _oktaUserReducer = createReducer(initialState,
    on(oktaSignIn, (state, payload) => {
        sessionStorage.setItem('oktaUser', JSON.stringify(payload))
        return payload;
    }),
    on(oktaSignOut, (state) => {
        sessionStorage.removeItem('oktaUser')
        return false;
    }),
    on(resetOktaUser, (state) => {
        sessionStorage.removeItem('oktaUser')
        return null
    }),
    on(setOktaUser, (state, payload) => {
        sessionStorage.setItem('oktaUser', JSON.stringify(payload))
        return payload
    })

);

export function oktaUserReducer(state, action) {
    return _oktaUserReducer(state, action);
}