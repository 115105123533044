export let previewProductDisclaimers = {
  productName: "Vroom Protect Vehicle Service Protection",
  disclaimers: [
    {
      type: "paragraph",
      text: "NEVADA CUSTOMERS ONLY: By initialing this box, You acknowledge that this Agreement contains an Arbitration provision, that You have read and understand the Arbitration Procedure section and affirmatively agree to the terms contained therein.",
      state: "NV",
      spans: [{ start: 0, end: 22, type: "strong" }],
    },
  ],
  toolTips: [],
  type: "[Prismic Content] Load Product Disclaimers",
};
