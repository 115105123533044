import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { LoggingService } from "./logging.service";
import { AbandonCartTrigger } from "../_models/abandon-cart";
import { Opportunity } from "../_models/account";
import brandInfo from '../../environments/brand.json'

@Injectable({
  providedIn: "root",
})
export class AbandonCartService {

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private httpClient: HttpClient, private loggingService: LoggingService) {}


  abandon_cart_trigger(object): Observable<AbandonCartTrigger> {
      return this.httpClient.post<any>(environment.apiUrl + "/v1/api/abandon-cart/abandoncarttrigger", {
        trigger: object.trigger,
        opportunityID: object.opportunityID
      })
      .pipe(
          catchError(this.loggingService.handleError)
        )
  }

  get_abandoned_cart(object): Observable<Opportunity> {
    console.log('object @ service', object)
    return this.httpClient.get<any>(`${environment.apiUrl}/v1/api/abandon-cart/abandoncart/${object.opportunityId}`)
  }

  save_abandoned_cart(): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl}/v1/api/abandon-cart/abandoncart`, {
      productDisclaimers: JSON.parse(sessionStorage.getItem('productDisclaimers')),
      vehicle: JSON.parse(sessionStorage.getItem('vehicle')),
      rates: JSON.parse(sessionStorage.getItem('rates')),
      prismicPDF: JSON.parse(sessionStorage.getItem('prismicPDF')),
      plan: JSON.parse(sessionStorage.getItem('plan')),
      cart: JSON.parse(sessionStorage.getItem('cart')),
      paymentOptionsMax: JSON.parse(sessionStorage.getItem('paymentOptionsMax')),
      numOfMonthlyPayments: JSON.parse(sessionStorage.getItem('numOfMonthlyPayments')),
      opportunityId: sessionStorage.getItem('opp') ? JSON.parse(sessionStorage.getItem('opp')).opportunityId : null,
      customer: JSON.parse(sessionStorage.getItem('customerData')),
      brandName: brandInfo.brand,
      appFlowState: sessionStorage.getItem('appFlowState'),
      dealer: JSON.parse(sessionStorage.getItem('dealer')),
    })
  }
}
