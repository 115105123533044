import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { saveCart } from "../_actions/cart.actions";
import { Addon, Cart, LineItems, PlanTerms, ShoppingCart } from "../_models/cart";

@Injectable({
  providedIn: "root",
})
export class CartService {
  customerCart: Cart = {
    shoppingCartId: null,
    isDisappearing: false,
    estimatedTax: 0,
    commercialUse: false,
    liftKit: false,
    snowPlow: false,
    greyMarket: false,
    subtotal: 0,
    grandTotal: 0,
    downPayment: 0,
    deductible: 0,
    downPaymentWithTax: 0,
    numberOfMonthlyPayments: 0,
    monthlyPayment: 0,
    plans: [
      {
        referenceNumber: '',
        productSkuId: '',
        productCode: '',
        productName: '',
        ratesProductName: '',
        planName: "",
        planIndex: 0,
        termIndex: 0,
        skuId: "",
        planPrice: 0,
        planTerms: {
          yearsMileage: "",
          deductible: null,
        },
        addons: [
          {
            addonName: "",
            skuId: "",
            addonPrice: 0,
          },
          {
            addonName: "",
            skuId: "",
            addonPrice: 0,
          },
        ],
        planCode: '',
        contractDealerCost: '',
        productFormNumber: ''
      },
    ],
    shoppingCartData: {
      accountExternalId: "",
      accountSfId: "",
      brandExternalId: "",
      brandSfId: "",
      contactExternalId: "",
      contactSfId: "",
      lineItems: [],
      status: "NEW",
      shoppingCartReferenceNumber1: "",
      shoppingCartReferenceNumber2: "",
      shoppingCartReferenceNumber3: "",
      shoppingCartReferenceNumber4: "",
      vehicleExternalId: "",
      vehicleSfId: "",
    },
  };

  lineItem: LineItems = {
    currency: "USD",
    itemDescription: "",
    itemInCartFrom: null,
    itemName: "",
    lineItemCostExcludingTax: 0,
    lineItemCostIncludingTax: 0,
    productSkuExternalId: "",
    productSkuSfId: "",
    product2ExternalId: "",
    product2Sfid: "",
    salesTax: 0,
    shoppingCartLineItemId: null,
    shoppingCartLineItemReferenceNumber1: "",
    shoppingCartLineItemReferenceNumber2: "",
    shoppingCartLineItemReferenceNumber3: "",
    shoppingCartLineItemReferenceNumber4: "",
    status: "NEW",
  };

  constructor(
    private store: Store<{
      prismic: any;
    }>
  ) { }

  mapPlanToCustomerCart(plan) {

    // shoppingCartId
    let _cart: Cart = JSON.parse(sessionStorage.getItem('cart'))
    if (_cart) {
      this.customerCart.shoppingCartId = _cart.shoppingCartId
    }

    // this.customerCart.estimatedTax = ,
    // this.customerCart.commercialUse = ,
    // this.customerCart.subtotal = ,
    // this.customerCart.grandTotal: 0,
    // this.customerCart.downPayment: 0,
    // this.customerCart.deductible: 0,
    // this.customerCart.downPaymentWithTax: 0,
    // this.customerCart.numberOfMonthlyPayments: 0,
    // this.customerCart.monthlyPayment: 0,



  }

  saveCart(plan) {
    if (plan.pricing.numberOfPayments !== '1') {
      this.customerCart.downPayment = plan.pricing.payment
    }


    this.store.dispatch(saveCart(this.customerCart));
    console.log(this.store)
  }

}
