import { Injectable } from '@angular/core';
import moment from 'moment';
import * as moment_tz from 'moment-timezone';
import { RatesResponse } from '../_models/rates';

@Injectable({
    providedIn: 'root'
})
export class ContractService {
    constructor() { }

    getCoverageStartDate() {
        const _rates: RatesResponse = JSON.parse(sessionStorage.getItem('rates'))

        let startDate: any;
        let endDate: any;

        //If Out of Warranty, Coverage Start Date is today
        if (_rates.data.productDetailsList[0].productPlanList[0] && _rates.data.productDetailsList[0].productPlanList[0].planCode === 'LAVDOOW') {
            startDate = moment().format('YYYY-MM-DD')
        } else if (_rates.data.productDetailsList[0].productPlanList[0] && _rates.data.productDetailsList[0].productPlanList[0].planCode === 'LAVDIN') {
            // If In Warranty, Coverage Start Date is In Service Date + 3 years
            startDate = moment(_rates.data.inServiceDate).add(3, 'years').format('YYYY-MM-DD')
        } else if (_rates.data.productDetailsList[0].productPlanList[0] && _rates.data.productDetailsList[0].productPlanList[0].planCode === 'LAVDCPO') {
            // If CPO, Coverage Start Date is Agreement Start Date (agreementEndDate of current CPO Warranty)
            startDate = moment(_rates.data.agreementStartDate).format('YYYY-MM-DD')
        } else {
            // If not CPO, In Warranty, or Out of Warranty, Coverage Start Date is agreementEndDate of some contract (EXT)
            startDate = moment(_rates.data.agreementEndDate).format('YYYY-MM-DD')
        }

        endDate = moment(startDate).add(1, 'years').format('YYYY-MM-DD')

        return {
            coverageStartDate: startDate,
            coverageEndDate: endDate
        }
    }
}
