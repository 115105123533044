import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { clearCart } from 'src/app/_actions/cart.actions';

@Component({
  selector: 'app-product-home-modal',
  templateUrl: './product-home-modal.component.html',
  styleUrls: ['./product-home-modal.component.scss']
})
export class ProductHomeModalComponent implements OnInit {

  constructor(
    private store: Store<{}>,
    public modalRefCart: MDBModalRef,
  ) { }

  ngOnInit(): void {
    console.log('ModelRefCart: ', this.modalRefCart.content)
  }

  startOver() {
    this.store.dispatch(clearCart())
  }

}
