import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-phone-modal',
  templateUrl: './phone-modal.component.html',
  styleUrls: ['./phone-modal.component.scss'],
  exportAs: 'phoneModal'
})
export class PhoneModalComponent implements OnInit {
  @Input() phoneNumber: string;
  @ViewChild('phoneModal') phoneModal;
  modalShown = false;

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    let body = document.querySelector('body') as HTMLElement;
    this.renderer.listen(body, 'click', () => {
      setTimeout(() => {
        if (!this.modalShown) {
          this.phoneModal.hide();
        }
      })
    })
  }
  show() {
    this.modalShown = true;
    this.phoneModal.show();
    setTimeout(() => {
      this.modalShown = false;
    }, 100);
  }
}
