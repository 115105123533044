import { Actions, createEffect, ofType, Effect } from "@ngrx/effects";
import { of, EMPTY } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";
import { Vehicle, VehicleImage } from "../_models/vehicle";
import {
  loadVehicleImage,
  loadVehicleImageSuccess,
} from "../_actions/vehicle.actions";
import { Store } from "@ngrx/store";
import { Injectable } from "@angular/core";
import { VehicleService } from "../_services/vehicle.service";

@Injectable()
export class VehicleEffects {
  public saveVehicleImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadVehicleImage),
      switchMap((vehicle: any) => {
        console.log('loadVehicleImage effect: ', vehicle);

        return this.vehicleService.getVehicleImage(vehicle).pipe(
          map((vehicleImage: VehicleImage) => {
            console.log('Found Vehicles from Image API: ', vehicleImage);
            return loadVehicleImageSuccess(vehicleImage);
          }),
          catchError((error: Error) => {
            console.error("Error loading vehicle image info: ", vehicle);
            return EMPTY;
          })
        );

      })
    )
  );

  constructor(
    private actions$: Actions,
    private vehicleService: VehicleService,
    public store: Store<{}>
  ) {}
}
