<section>
  <div *ngIf="label && label !=''" id="termlength" class="active">
    <mat-label aria-label="{{ displayLabel | translate }}">{{ displayLabel | translate }}**</mat-label>
  </div>
  <mat-button-toggle-group [name]="groupName">
    <mat-button-toggle *ngFor="let option of options2" [value]="option.value">
      {{ option.label }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</section>
