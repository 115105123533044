import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-switch-screen',
  templateUrl: './modal-switch-screen.component.html',
  styleUrls: ['./modal-switch-screen.component.scss']
})
export class ModalSwitchScreenComponent implements OnInit, OnDestroy {

  @Input() continue: string;
  action: Subject<any> = new Subject();
  sub: Subscription
  constructor(public modalRef: MDBModalRef, private router: Router) { }

  ngOnInit(): void {
    console.log(this.continue)
    this.sub = this.router.events.subscribe((event) => {
      this.modalRef.hide()
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    if (this.continue) {
      window.scrollTo(0, 0);
    }
  }

  cancel() {
    this.action.next('cancel');
  }

  proceed() {
    this.action.next('proceed');
    if (this.continue) {
      this.router.navigate(["/checkout"]);
    }
  }
}
