import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-inline-loader',
  templateUrl: './inline-loader.component.html',
  styleUrls: ['./inline-loader.component.scss']
})
export class InlineLoaderComponent implements OnInit {
  inlineLoader$: Observable<any>;

  constructor(private store: Store<{
    inlineLoader: any,
  }>) { }

  ngOnInit() {

    this.inlineLoader$ = this.store.select(store => store.inlineLoader)
  }

}
