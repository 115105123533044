import { Component, Input } from '@angular/core';

interface DisplayItem {
  title: string;
  amount: number;
  coloredText: string;
  textColor: string;
}

@Component({
  selector: 'app-select-plan-description',
  templateUrl: './select-plan-description.component.html',
  styleUrls: ['./select-plan-description.component.scss']
})
export class SelectPlanDescriptionComponent {

  @Input() item: any;
  @Input() title: string = '';
  @Input() amount: number = 0;
  @Input() coloredText: string = '';
  @Input() textColor: string = 'black';


  constructor() { }

  ngOnInit(): void {
  }

}
