import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { verifyAccount } from 'src/app/_actions/verify-account.actions';
import { resetInvalidCodeState } from 'src/app/_actions/verify-code-state.actions';
import { VerifyAccountRequest, VerifyCodeRequest } from 'src/app/_models/verify-account';
import brandInfo from '../../../../environments/brand.json'

@Component({
  selector: 'app-my-account-verify-email',
  templateUrl: './my-account-verify-email.component.html',
  styleUrls: ['./my-account-verify-email.component.scss']
})
export class MyAccountVerifyEmailComponent implements OnInit, OnChanges, OnDestroy {
  @Output() verifyCodeRequest: EventEmitter<VerifyCodeRequest> = new EventEmitter<VerifyCodeRequest>();
  @Output() viewEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() verifyType: EventEmitter<string> = new EventEmitter<string>();
  @Input() emailReset: boolean
  verifyAccount$: Observable<any>;
  verifyAccountSubscription: Subscription;

  accountEmailValidationForm: UntypedFormGroup;
  emailPattern = "[[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$";

  constructor(
    public store: Store<{ verifyAccount: any}>
  ) { }

  ngOnInit(): void {
    this.accountEmailValidationForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required, Validators.email, Validators.pattern(this.emailPattern)]),
      lastName: new UntypedFormControl(null, Validators.required),
    });
    this.verifyAccount$ = this.store.select(store => store.verifyAccount)
  }

  ngOnChanges() {
    this.resetForm(this.emailReset)
  }

  ngOnDestroy() {
    if(this.verifyAccountSubscription) {
      this.verifyAccountSubscription.unsubscribe()
    }
  }

  verifyAccountEmail() {
    this.store.dispatch(resetInvalidCodeState())

    let verifyAccountRequest: VerifyAccountRequest = {
      brandName: brandInfo.brand,
      emailId: this.accountEmailValidationForm.controls["email"].value,
      phoneNumber: null,
      contactType: 'email',
      lastName: this.accountEmailValidationForm.controls["lastName"].value
    }

    let verifyCodeRequest = {
      contact: this.accountEmailValidationForm.controls["email"].value,
      contactType: 'email',
      lastName: this.accountEmailValidationForm.controls["lastName"].value,
      code: ''
    }

    //this.store.dispatch(resetSession())
    this.store.dispatch(verifyAccount(verifyAccountRequest))

    this.verifyCodeRequest.emit(verifyCodeRequest)

    this.verifyAccountSubscription = this.verifyAccount$.subscribe(data => {
      if (data)
      {
        if (data?.errorMessage === '' || data?.errorMessage === null || data?.errorMessage === 'null') {
          this.viewEmitter.emit('2')
        }
      }
    })

    this.verifyType.emit('email')
  }

  resetForm(data) {
    if (data === true) {
      this.accountEmailValidationForm.reset()
    }
  }
}
