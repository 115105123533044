import { Component, OnDestroy, OnInit } from '@angular/core';
import { PdfViewer } from '../core/pdfViewer.service';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import brandInfo from "src/environments/brand.json";
import { ViewContractRequest } from '../_models/pdf';
import { getContractByUuid } from '../_actions/pdf.actions';
import { AppConfigService } from '../appconfig/appconfig.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-view-contract',
  templateUrl: './view-contract.component.html',
  styleUrls: ['./view-contract.component.scss']
})
export class ViewContractComponent implements OnInit, OnDestroy {
  pdf$: Observable<any>;
  pdfSub: Subscription;
  src: any;
  pdf: any;
  branding: any;

  constructor(
    private pdfViewer: PdfViewer,
    private store: Store<{
      pdf: any
    }>,
    private route: ActivatedRoute,
    private appConfig: AppConfigService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    console.log('snapshots', this.route.snapshot.params)
    this.route.queryParams.subscribe((params) => {
      const uuid = this.route.snapshot.params['uuid'];
      const request: any = {
        brandName: brandInfo.brand,
        uuid: uuid,
        viewType: params['type'] ? params['type'] : null
      }

      this.store.dispatch(getContractByUuid(request))
    })

    this.appConfig.getSettings().subscribe((result) => {
      this.branding = result;
    });
    
    this.pdf$ = this.store.select((store) => store.pdf);
    this.pdfSub = this.pdf$.subscribe((pdf) => {
      console.log('pdf', pdf)
      if (pdf) {
        if (pdf.status && pdf.status === 'OK') {
          this.pdf = pdf.data.pdfRaw;
          console.log('pdf is not null')
          this.src = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/pdf;base64," + pdf.data.pdfRaw);
          console.log('src', this.src)
        } else {
          this.pdf = null
        }
      }
    })
  }

  ngOnDestroy(): void {
    if (this.pdfSub) {
      this.pdfSub.unsubscribe();
    }
  }

  downloadPDF() {
    const downloadLink = document.createElement("a");
    downloadLink.href = 'data:application/pdf;base64,' + this.pdf;
    downloadLink.download = "Coverage Agreement.pdf";
    downloadLink.click();
  }

}
