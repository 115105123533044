import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import * as ActionConfigurationManager from '.././_actions/config.actions';
import { PaymentService } from '../_services/payment-and-tax-service.service';
import { Action, Store } from '@ngrx/store';
import { Cart, LineItems } from '../_models/cart';
import { hideInlineLoader } from '../_actions/inline-loader.actions'
import { cartSaved, updateCart, updateCartSuccess, updateNISCart } from '../_actions/cart.actions';
import { Injectable } from '@angular/core';
import { AVSResponse } from '../_models/avs';
import { TaxRequest, TaxResponse, TaxRequestItems, ComparisonRequest } from '../_models/tax';
import { Registration } from '../_models/registration';
import { RatesResponse } from '../_models/rates';
import { Lead, LeadResponse } from '../_models/lead';
import { Vehicle } from '../_models/vehicle';
import brandInfo from '../../environments/brand.json'
import { Plan } from '../_models/plan';
import { cartTaxTokenRefresh } from '../_actions/token-refresh.actions';
import { removeToken } from '../_actions/token.actions';
import { Opportunity } from '../_models/account';
import { compareTaxes, taxesCompared, vertexTaxSubmitted } from '../_actions/vertex-tax.actions';

@Injectable()

export class VertexTaxEffects {

    public saveVertexTax$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.SUBMIT_VERTEX_TAX),
            switchMap((action: any) => this.taxService.vertexTax(action)
                .pipe(
                    map(tax => {
                        const _vehicle: Vehicle = JSON.parse(sessionStorage.getItem('vehicle'))
                        let cart: Cart = JSON.parse(sessionStorage.getItem('cart'))

                        for(let i = 0; i < cart.plans.length; i++) {
                            for (let j = 0; j < tax.data.contracts.length; j++) {
                                if (cart.plans[i].ratesProductName === tax.data.contracts[j].contractNumber) {
                                  cart.shoppingCartData.lineItems[i].lineItemCostExcludingTax = Number(tax.data.contracts[j].salesTaxDetails[0].taxableAmount)
                                    cart.shoppingCartData.lineItems[i].lineItemCostIncludingTax = Number(tax.data.contracts[j].salesTaxDetails[0].taxableAmount) + Number(tax.data.contracts[j].salesTaxDetails[0].totalTaxAmount)
                                    cart.shoppingCartData.lineItems[i].salesTax = tax.data.contracts[j].salesTaxDetails[0].totalTaxAmount
                                }
                            }
                        }

                        if (cart.numberOfMonthlyPayments === 1) {
                            cart.estimatedTax = Number(tax.data.totalTaxAmount)
                            cart.grandTotal = Number(tax.data.grandTotalAmount)
                            cart.monthlyPayment = tax.data.monthlyPayment
                            // cart.subtotal = tax.data.totalTaxableAmount

                            this.store.dispatch(vertexTaxSubmitted(tax))
                            this.store.dispatch(updateCartSuccess(cart))

                            console.log('Cart After Vertex tax Call:', cart)
                        } else {
                            cart.estimatedTax = tax.data.totalTaxAmount
                            cart.grandTotal = tax.data.grandTotalAmount
                            cart.monthlyPayment = tax.data.monthlyPayment
                            cart.downPaymentWithTax = tax.data.totalToday
                            // cart.subtotal = tax.data.totalTaxableAmount

                            if (brandInfo.frontLoadTaxes) {
                                cart.downPaymentWithTax = Number(cart.monthlyPayment) + Number(cart.estimatedTax)
                            }


                            this.store.dispatch(vertexTaxSubmitted(tax))
                            this.store.dispatch(updateCartSuccess(cart))
                            console.log('Cart After Vertex tax Call:', cart)

                        }
                        return hideInlineLoader();
                    }),
                    catchError((error: string) => {
                        const cart: Cart = JSON.parse(sessionStorage.getItem('cart'))

                        if (error.includes('401 Unauthorized')) {
                            let opp: Opportunity = JSON.parse(sessionStorage.getItem('opp'))
                            this.store.dispatch(removeToken())
                            this.store.dispatch(cartTaxTokenRefresh(opp))
                        }
                        if (error.includes('400')) {
                            if (brandInfo.isoCode === 'USD') {
                                if (brandInfo.frontLoadTaxes) {
                                    if (cart.numberOfMonthlyPayments === 1) {
                                        cart.estimatedTax = cart.subtotal * .10
                                        cart.grandTotal = Number(cart.subtotal) + Number(cart.estimatedTax)
                                        cart.monthlyPayment = 0
                                        // cart.subtotal = cart.subtotal

                                        this.store.dispatch(updateCartSuccess(cart))
                                        this.store.dispatch(hideInlineLoader())
                                    } else {
                                        cart.estimatedTax = cart.subtotal * .10

                                        cart.grandTotal = Number(cart.subtotal) + Number(cart.estimatedTax)
                                        cart.monthlyPayment = Number(((cart.subtotal) / cart.numberOfMonthlyPayments).toFixed(2))
                                        cart.downPaymentWithTax = cart.estimatedTax + cart.monthlyPayment
                                        // cart.subtotal = tax.data.totalTaxableAmount


                                        this.store.dispatch(updateCartSuccess(cart))
                                        this.store.dispatch(hideInlineLoader())
                                    }
                                } else {
                                    if (cart.numberOfMonthlyPayments === 1) {
                                        cart.estimatedTax = cart.subtotal * .10
                                        cart.grandTotal = Number(cart.subtotal) + Number(cart.estimatedTax)
                                        cart.monthlyPayment = 0
                                        // cart.subtotal = cart.subtotal

                                        this.store.dispatch(updateCartSuccess(cart))
                                        this.store.dispatch(hideInlineLoader())
                                    } else {
                                        cart.estimatedTax = cart.subtotal * .10
                                        cart.grandTotal = Number(cart.subtotal) + Number(cart.estimatedTax)
                                        cart.monthlyPayment = Number(((cart.grandTotal - cart.downPayment) / cart.numberOfMonthlyPayments).toFixed(2))
                                        cart.downPaymentWithTax = cart.downPayment
                                        // cart.subtotal = tax.data.totalTaxableAmount


                                        this.store.dispatch(updateCartSuccess(cart))
                                        this.store.dispatch(hideInlineLoader())
                                    }
                                }
                            } else {
                                if (cart.numberOfMonthlyPayments === 1) {
                                    cart.estimatedTax = cart.subtotal * .15
                                    cart.grandTotal = Number(cart.subtotal) + Number(cart.estimatedTax)
                                    cart.monthlyPayment = 0
                                    // cart.subtotal = cart.subtotal

                                    this.store.dispatch(updateCartSuccess(cart))
                                    this.store.dispatch(hideInlineLoader())
                                } else {
                                    cart.estimatedTax = cart.subtotal * .15
                                    cart.grandTotal = Number(cart.subtotal) + Number(cart.estimatedTax)
                                    cart.monthlyPayment = Number(((cart.grandTotal - cart.downPayment) / cart.numberOfMonthlyPayments).toFixed(2))
                                    cart.downPaymentWithTax = cart.downPayment
                                    // this.customerCart.subtotal = tax.data.totalTaxableAmount


                                    this.store.dispatch(updateCartSuccess(cart))
                                    this.store.dispatch(hideInlineLoader())
                                }
                            }
                        }
                        return EMPTY;
                    }),
                )
            )
        )
    )

    public storeComparison$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.COMPARE_TAXES),
            switchMap((action: any) => this.taxService.storeComparison(action)
                .pipe(
                    map(tax => {
                        return taxesCompared()
                    }),
                    catchError((error: string) => {
                        return EMPTY;
                    }),
                )
            )
        )
    )

    constructor(
        private actions$: Actions,
        private taxService: PaymentService,
        private store: Store<{
            tax: TaxResponse
        }>
    ) { }
}

