import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { sessionEnded, resetSession } from '../_actions/session.actions';


const _sessionReducer = createReducer(initialState,
    on(sessionEnded, (state, payload) => {
        sessionStorage.setItem('sessionEnded', 'true')
        return true;
    }),
    on(resetSession, (state, payload) => {
        sessionStorage.removeItem('sessionEnded')
        return null;
    })
);

export function sessionReducer(state, action) {
    return _sessionReducer(state, action);
}