import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: "root",
})
export class GoogleTagManagerService {

  constructor() {}

  loadScriptTag(gtmidCode: any): void {

    // console.log("environment.production: ",environment.production)

    const gtmid = environment.production ? gtmidCode.prod : gtmidCode.dev;

    const gtmScript = document.createElement('script');
    gtmScript.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${ gtmid }');`

    if (gtmid !== '') {
      document.documentElement.firstChild.appendChild(gtmScript);
    }
  }
}
