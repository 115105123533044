import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { updatePaymentStateOn } from '../_actions/account.actions';


const _updatePaymentStateReducer = createReducer(initialState,
    on(updatePaymentStateOn, (state) => {
      // To add a lead to state, use spread operator
      return true;
    })
);

export function updatePaymentStateReducer(state, action) {
    return _updatePaymentStateReducer(state, action);
}
