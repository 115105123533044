import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { PrismicService } from "./prismic.service";


@Injectable({
  providedIn: "root",
})
export class PaypalSettingsService {

  constructor(    private prismicService: PrismicService,) {}

  loadScriptTag(): void {
    const env = environment.production ? "prod" : "dev";
    let prismicEnv = this.prismicService.getEnvironment(env);

    console.log("prismicEnv: ", prismicEnv);
    const paypal = document.createElement('script');
    paypal.setAttribute('type','text/javascript');
    paypal.setAttribute('src','https://www.paypal.com/sdk/js?client-id=' + prismicEnv.payPalConfig.client_id + '&merchant-id=' + prismicEnv.payPalConfig.merchant_id + '&intent=authorize&currency=USD&disable-funding=credit,card&commit=false')
    document.documentElement.firstChild.appendChild(paypal);

  }
}
