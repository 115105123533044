import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
  ElementRef,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { LocationService } from "src/app/_services/location.service";
import * as ScanditSDK from "scandit-sdk";
import brandInfo from "../../../environments/brand.json";
import { Store } from "@ngrx/store";
import { VINDecodeCarInfoRequest } from "src/app/_models/vin-decode";
import { decodeVinGetCarInfo } from "src/app/_actions/vinDecode.actions";
import { Observable, Subscription } from "rxjs";
import {
  validateDealer,
  validateDealerSuccess,
} from "src/app/_actions/dealer-actions";
import { WrapperService } from "src/app/_services/wrapper-service.service";
import { RatesRequest } from "src/app/_models/rates";
import { loadRatesHappy } from "src/app/_actions/rates.actions";
import { serviceDrive } from "src/app/_actions/app-flow-state.actions";
import { clear } from "src/app/_actions/modal-component.actions";
import { Router } from "@angular/router";
import { PagesService } from "src/app/_services/pages.service";
import { Dealer } from "src/app/_models/dealer";
import { showInlineLoader } from "src/app/_actions/inline-loader.actions";
import { environment } from "../../../environments/environment";
import { Vehicle } from "src/app/_models/vehicle";
import { clearCart } from "src/app/_actions/cart.actions";
import { AppConfigService } from "src/app/appconfig/appconfig.service";
import { DatePipe, DecimalPipe } from "@angular/common";
import { specialCharactersValidator } from "src/assets/customValidators/specialCharacters.validator";

@Component({
  selector: "app-service-drive",
  templateUrl: "./service-drive.component.html",
  styleUrls: ["./service-drive.component.scss"],
  providers: [DecimalPipe, DatePipe],
})
export class ServiceDriveComponent implements OnInit, OnDestroy {
  @Input() public component: any;
  screen: string;
  nextScreen: string;
  loginForm: UntypedFormGroup;
  customerForm: UntypedFormGroup;
  states: any;
  barcodeValues = [];
  loginInvalid = false;
  showCamera = false;
  bcScanner: any;
  vinDecoder$: Observable<any>;
  vinSubscription: Subscription;
  readonly = true;
  invalidVin = false;
  redirect_url: string;
  redirect_ev_url: string;
  isElectric: Boolean;

  scanditKey = environment.production
    ? brandInfo.scanditKey.prod
    : brandInfo.scanditKey.dev;
  dealerData: Dealer;
  dealerSub: Subscription;
  ratesStateSubscription: Subscription;
  getRatesPage: any;
  badDealerLogin = false;
  dealerSub$: Subscription;
  showError = false;
  thisBrand: string;
  errorMessage: number;
  branding: any;

  constructor(
    public locationService: LocationService,
    private pageService: PagesService,
    private router: Router,
    private ratesService: WrapperService,
    private el: ElementRef,
    private store: Store<{
      vinDecode: any;
      dealer: any;
      modalComponentState: any;
    }>,
    private appConfig: AppConfigService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe
  ) {}

  checkForDealer() {
    let _dealer: any = JSON.parse(sessionStorage.getItem("dealer"));
    if (_dealer && _dealer.data && _dealer.data.date) {
      let expireTime = new Date(_dealer.data.date);
      expireTime.setMinutes(expireTime.getMinutes() + 15); // 15 minutes
      let currentTime = new Date();
      console.log("Expire time: ", expireTime);
      console.log("Current time: ", currentTime);
      if (expireTime >= currentTime) {
        this.readonly = false;
        _dealer.data.date = currentTime;
        this.showCamera = false;
        if (this.bcScanner) {
          this.bcScanner.destroy();
        }
        this.store.dispatch(validateDealerSuccess(_dealer));
        this.nextScreen = "CUSTOMER";
        this.loginForm.get("dealershipId").patchValue(_dealer.data.id);
        this.loginForm.get("employeeId").patchValue(_dealer.data.employeeId);
        if (
          brandInfo.brand === "Lambo" ||
          brandInfo.brand === "Hyundai" ||
          brandInfo.brand === "HPP" ||
          brandInfo.brand === "BMW" ||
          brandInfo.brand === "MINI"
        ) {
          this.loginForm
            .get("employeeFirstName")
            .patchValue(_dealer.data.employeeFirstName);
          this.loginForm
            .get("employeeLastName")
            .patchValue(_dealer.data.employeeLastName);
        }
        return "CUSTOMER";
      } else {
        this.store.dispatch(validateDealerSuccess({}));
        this.store.dispatch(clearCart());
        return "LOGIN";
      }
    }
    this.store.dispatch(validateDealerSuccess({}));
    this.store.dispatch(clearCart());
    return "LOGIN";
  }

  ngOnInit() {
    this.thisBrand = brandInfo.brand;
    this.redirect_url = this.component.primary.redirect_link;
    this.redirect_ev_url = this.component.primary.redirect_ev_link;
    this.nextScreen = "";
    sessionStorage.removeItem("inspectionDocs"); // Reset inspection documents
    this.store.dispatch(serviceDrive());
    this.getRatesPage = this.pageService.getRatingPage();
    this.states = this.locationService.getStateData();
    this.detectModalComponentState();

    this.appConfig.getSettings().subscribe((result) => {
      this.branding = result;
    });

    this.loginForm =
      brandInfo.brand === "Lambo" ||
      brandInfo.brand === "Porsche" ||
      brandInfo.brand === "Hyundai" ||
      brandInfo.brand === "HPP" ||
      brandInfo.brand === "BMW" ||
      brandInfo.brand === "MINI"
        ? new UntypedFormGroup({
            dealershipId: new UntypedFormControl(null, [Validators.required]),
            employeeId:
              (brandInfo.brand === "Lambo" || brandInfo.brand === "Porsche")
                ? new UntypedFormControl(null, [
                    Validators.required,
                    Validators.email,
                  ])
                : new UntypedFormControl(null, [Validators.required]),
            employeeFirstName: new UntypedFormControl(null, [
              Validators.required,
              Validators.pattern("^[a-zA-Z. ]{2,30}$"),
            ]),
            employeeLastName: new UntypedFormControl(null, [
              Validators.required,
              Validators.pattern("^[a-zA-Z. ]{2,30}$"),
            ]),
          })
        : new UntypedFormGroup({
            dealershipId: new UntypedFormControl(null, [Validators.required]),
            employeeId: new UntypedFormControl(null, [Validators.required]),
          });
    this.customerForm = new UntypedFormGroup({
      vin: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9]{0,17}$"),
        Validators.pattern("^[a-zA-Z0-9]{0,12}[0-9]{5}$"),
        Validators.pattern("^[^IOQioq ]{0,17}$"),
        specialCharactersValidator()
      ]),
      odometer: new UntypedFormControl(null, [Validators.required]),
      year: new UntypedFormControl(null, [Validators.required]),
      make: new UntypedFormControl(null, [Validators.required]),
      model: new UntypedFormControl(null, [Validators.required]),
      hasWarranty: new UntypedFormControl(null),
    });
    this.screen = this.checkForDealer(); // LOGIN | CAPTURE | CUSTOMER
    //this.loadView(this.screen)
    this.vinDecoder$ = this.store.select((store) => store.vinDecode);
    this.vinSubscription = this.vinDecoder$.subscribe((vehicle) => {
      console.log("vehicle:", vehicle);
      if (vehicle && vehicle.make && vehicle.model) {
        console.log("vehicle:", vehicle);
        this.customerForm.get("make").patchValue(vehicle.make);
        this.customerForm.get("model").patchValue(vehicle.model);
        this.customerForm.get("year").patchValue(vehicle.year);
        let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
        this.isElectric = _vehicle.isElectric;

        if (
          (brandInfo.brand.toLowerCase() === "hyundai" ||
            brandInfo.brand.toLowerCase() === "hpp") &&
          vehicle.make.toLowerCase() !== "hyundai"
        ) {
          this.invalidVin = true;
        } else {
          this.invalidVin = false;
        }
      } else {
        console.log("bad vehicle VIN ");
        this.invalidVin = true;
        this.customerForm.get("make").patchValue("");
        this.customerForm.get("model").patchValue("");
        this.customerForm.get("year").patchValue("");
      }
    });

    this.dealerSub$ = this.store
      .select((store) => store.dealer)
      .subscribe((dealer) => {
        this.dealerData = dealer;
        console.log("this.dealerData ", this.dealerData);
        if (this.dealerData && this.dealerData.success === false) {
          this.badDealerLogin = true;
        } else {
          this.loadView(this.nextScreen);
          this.badDealerLogin = false;
        }
      });
  }

  ngOnDestroy(): void {
    if (this.dealerSub$) {
      this.dealerSub$.unsubscribe();
    }
    if (this.vinSubscription) {
      this.vinSubscription.unsubscribe();
    }
    if (this.bcScanner) {
      this.bcScanner.destroy();
    }
  }

  handleBillingAddressChange(event) {
    console.log(event);
    let addressFromEvent = event.formatted_address;
    let address = addressFromEvent.split(",", 4);
    let city = address[1].trim();

    let state = address[2].trim().split(" ", 1);
    state = state[0];

    let zip = address[2].trim().split(" ");
    let length = address[2].trim().split(" ").length;
    if (length === 1) {
      zip = null;
    } else if (length === 2) {
      zip = zip[1];
    } else if (length === 3) {
      zip = zip[1] + " " + zip[2];
    }

    address = address[0].trim();

    let fullAddress = address + ", " + city + ", " + state;
    // this.userBillingAddress = fullAddress;
    this.customerForm.controls["address"].setValue(address);
    // this.customerForm.controls['address2'].setValue(address);
    this.customerForm.controls["zip"].setValue(zip);
    this.customerForm.controls["city"].setValue(city.trim());

    for (let i = 0; i < this.states.length; i++) {
      if (this.states[i].value === state) {
        return this.customerForm.controls["state"].setValue(state);
      } else {
        this.customerForm.controls["state"].reset();
        this.customerForm.controls["state"].markAsUntouched();
        this.customerForm.controls["state"].updateValueAndValidity();
      }
    }
  }

  loadView(view) {
    // LOGIN | CAPTURE | CUSTOMER
    if (view === "LOGIN") {
      // DEALER LOGIN VIEW
      this.showError = false;
      this.readonly = true;
      this.showCamera = false;
      this.badDealerLogin = false;
      if (this.bcScanner) {
        this.bcScanner.destroy();
      }
      this.store.dispatch(validateDealerSuccess({}));
      this.store.dispatch(clearCart());

      this.loginForm.reset();
      this.screen = view;
    }

    if (view === "CAPTURE") {
      // CAPTURE VIN BARCODE VIEW
      this.showError = false;
      if (this.bcScanner) {
        this.bcScanner.destroy();
      }
      this.barcodeValues = [];
      this.customerForm.reset();
      this.screen = view;
      setTimeout(() => {
        let _scandit = document.getElementById("scandit-barcode-picker");
        if (_scandit) {
          _scandit.innerHTML = "";
          ScanditSDK.configure(this.scanditKey, {
            engineLocation: "/assets/",
            loadTextRecognition: true,
          })
            .then(() => {
              return ScanditSDK.BarcodePicker.create(
                document.getElementById("scandit-barcode-picker"),
                {
                  playSoundOnScan: true,
                  vibrateOnScan: true,
                }
              );
            })
            .then((barcodePicker) => {
              this.bcScanner = barcodePicker;
              const scanSettings = new ScanditSDK.ScanSettings({
                enabledSymbologies: [
                  ScanditSDK.Barcode.Symbology.CODE128,
                  ScanditSDK.Barcode.Symbology.QR,
                ],
                codeDuplicateFilter: 2000, // Minimum delay between duplicate results
                // recognitionMode: ScanditSDK.ScanSettings.RecognitionMode.TEXT, // Need a special license for OCR
                recognitionMode: ScanditSDK.ScanSettings.RecognitionMode.CODE,
              });
              this.bcScanner.applyScanSettings(scanSettings);
              this.bcScanner.on("ready", () => {
                this.showCamera = true;
              });
              // barcodePicker is ready here, show a message every time a barcode is scanned
              this.bcScanner.on("scan", (scanResult) => {
                let unique = true;
                this.barcodeValues.forEach((code) => {
                  if (code === scanResult.barcodes[0].data) {
                    unique = false;
                  }
                });
                if (unique) {
                  this.barcodeValues.push(scanResult.barcodes[0].data);
                  // console.log("TEXT: ",scanResult.texts[0].value) // OCR text -- Need a special license for OCR
                }
              });
            });
        }
      }, 1000);
    }

    if (view === "CUSTOMER") {
      // CUSTOMER LOGIN VIEW
      this.barcodeValues = [];
      this.customerForm.reset();
      this.customerForm.get("hasWarranty").patchValue(false);
      if (this.loginForm.valid) {
        this.screen = view;
      }

      // else {
      //   this.checkForm(this.loginForm)
      // }
    }
  }

  validateDealer(view) {
    this.showError = false;
    if (this.loginForm.valid) {
      this.nextScreen = view;
      const data = this.loginForm.value;
      brandInfo.brand === "Schomp"
        ? this.store.dispatch(
            validateDealer({
              dealerId: data.dealershipId,
              customerId: data.employeeId,
            })
          )
        : this.store.dispatch(
            validateDealer({
              dealerId: data.dealershipId,
              customerId: data.employeeId,
              employeeFirstName: data.employeeFirstName,
              employeeLastName: data.employeeLastName,
            })
          );
    } else {
      this.checkForm(this.loginForm);
    }
  }

  setWarranty(hasWarranty) {
    this.customerForm.get("hasWarranty").patchValue(hasWarranty);
  }

  checkForm(form) {
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  lookupVin(vin) {
    if(this.customerForm.controls['vin'].invalid){
      return
    }
    const vehicle: VINDecodeCarInfoRequest = {
      vin: vin,
    };
    this.store.dispatch(decodeVinGetCarInfo(vehicle));
  }

  useVIN(vin) {
    this.screen = "CUSTOMER";
    this.showCamera = false;
    this.readonly = true;
    this.bcScanner.destroy();
    this.customerForm.get("vin").patchValue(vin);
    this.customerForm.get("hasWarranty").patchValue(false);
    const vehicle: VINDecodeCarInfoRequest = {
      vin: vin,
    };
    this.store.dispatch(decodeVinGetCarInfo(vehicle));
  }

  onStarted(started) {
    console.log("started ", started);
  }

  onValueChanges(result) {
    this.barcodeValues.push(result.codeResult.code);
  }

  getRates() {
    let rateData: any;

    this.checkForm(this.customerForm);
    console.log("this.customerForm", this.customerForm.value);
    if (this.customerForm.valid && !this.invalidVin) {
      const currentCompatibleDate = this.datePipe.transform(
        new Date(),
        "yyyy-MM-dd"
      );

      console.log("this.dealerData ", this.dealerData);

      const warrantyBoolean = this.customerForm.get("hasWarranty").value;

      let request: RatesRequest = {
        brandName: brandInfo.brand,
        vin: this.customerForm.get("vin").value,
        saleDate: currentCompatibleDate,
        odometer: this.customerForm.get("odometer").value,
        inServiceDate: warrantyBoolean === true ? "" : "2008-04-01",
        financeType: "finance",
        isAfterSale: "false",
        vehiclePurchaseDate: currentCompatibleDate,
        vehicleCondition: "",
        customerState: this.dealerData.data.address.state,
        financeAmount: "",
        vehicleCost: "",
        vehicleMSRP: "",
        lienholderName: "",
        make: this.customerForm.get("make").value,
        model: this.customerForm.get("model").value,
        year: this.customerForm.get("year").value,
        vehicleSfId: "",
        getRatesPage: "",
        product: "VSC",
        dealer: this.dealerData.data.id,
      };

      console.log("loadNotEditedVehicle Rates:", request);

      this.store.dispatch(loadRatesHappy(request));

      this.store.dispatch(showInlineLoader());

      if (
        brandInfo.brand !== "Hyundai" &&
        brandInfo.brand !== "BMW" &&
        brandInfo.brand !== "MINI"
      ) {
        window.sessionStorage.setItem(
          "hasFactoryWarranty",
          this.customerForm.get("hasWarranty").value
        );
      }
    }
  }

  detectModalComponentState() {
    console.log("Called get rates");
    // Go to the getRatesPage Page
    this.ratesStateSubscription = this.store
      .select((store) => store.modalComponentState)
      .subscribe((ratesState) => {
        console.log("ratesState, ", ratesState);
        if (ratesState === "success") {
          this.showError = false;
          this.errorMessage = 0;
          console.log("electric", this.isElectric);
          this.store.dispatch(clear());
          if (this.redirect_ev_url && this.isElectric) {
            this.redirectTo(this.redirect_ev_url);
          } else if (this.redirect_url) {
            this.redirectTo(this.redirect_url);
          } else {
            this.redirectTo(this.getRatesPage);
          }
        } else if (ratesState === "error") {
          this.showError = true;
          this.errorMessage = 2;
        } else if (ratesState === "invalidState") {
          this.showError = true;
        } else if (ratesState === "lamboNeverEligible") {
          this.showError = true;
          this.errorMessage = 2;
        } else if (ratesState === "notWithinSixtyDays") {
          this.showError = true;
          this.errorMessage = 1;
        }
      });
  }

  redirectTo(uri: string, queryParams = {}) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate([uri], queryParams);
  }

  getStyles(slice) {
    return {
      "background-image": "url(" + slice.primary.image.url + ")",
      "margin-top": slice.primary.margin_top
        ? slice.primary.margin_top + "px"
        : "0",
      "margin-bottom": slice.primary.margin_bottom
        ? slice.primary.margin_bottom + "px"
        : "0",
    };
  }

  onlyNumberKey(event) {
    return event.charCode == 8 || event.charCode == 0
      ? null
      : event.charCode >= 48 && event.charCode <= 57;
  }

  removeCommas() {
    const odometer = this.customerForm.controls["odometer"];
    if (odometer.value) {
      odometer.setValue(odometer.value.replace(/,/g, ""));
    }
  }

  addCommas() {
    const odometer = this.customerForm.controls["odometer"];
    if (odometer.value) {
      if (odometer.value.length > 6) {
        odometer.setValue("999,999");
      } else {
        odometer.setValue(this.decimalPipe.transform(odometer.value));
      }
    }
  }
}
