import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { loadPaymentHistory, rehydratePaymentHistory } from '../_actions/account.actions';


const _paymentHistoryReducer = createReducer(initialState,
    on(loadPaymentHistory, (state, payload) => {
        console.log('Load Payment History', state, payload)
        sessionStorage.setItem('paymentHistory', JSON.stringify(payload))
        return payload;
    }),
    on(loadPaymentHistory, (state, payload) => {
        console.log('Load Payment History', state, payload)
        sessionStorage.setItem('paymentHistory', JSON.stringify(payload))
        return payload;
    }),
    on(rehydratePaymentHistory, (state, payload) => {
        console.log('Rehydrate Payment History', state, payload)
        sessionStorage.setItem('paymentHistory', JSON.stringify(payload))
        return payload;
    })
);

export function paymentHistoryReducer(state, action) {
    return _paymentHistoryReducer(state, action);
}