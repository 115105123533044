import * as ActionConfigurationManager from './config.actions';

export const loadLead = ActionConfigurationManager.LOAD_LEAD;
export const leadLoaded = ActionConfigurationManager.LEAD_LOADED;
export const rehydrateLead = ActionConfigurationManager.REHYDRATE_LEAD;
export const updateLead = ActionConfigurationManager.UPDATE_LEAD;
export const leadUpdated = ActionConfigurationManager.LEAD_UPDATED
export const loadLeadOpportunity = ActionConfigurationManager.LOAD_LEAD_OPPORTUNITY
export const leadLoadedOpportunity = ActionConfigurationManager.LEAD_LOADED_OPPORTUNITY
export const loadLeadOpportunityContactInformation = ActionConfigurationManager.LOAD_LEAD_OPPORTUNITY_CONTACT_INFORMATION
export const leadLoadedOpportunityContactInformation = ActionConfigurationManager.LEAD_LOADED_OPPORTUNITY_CONTACT_INFORMATION

