export class ModelResponse {
    status: string;
    data: Model[];
    errorMessage: string;
}

export class Model {
    makeId: string;
    code: string;
    description: string;
    name: string;
    modelId: string;
}
