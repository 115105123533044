import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { loadPaylink, paylinkLoaded, rehydratePaylink } from '../_actions/account.actions';


const _paylinkReducer = createReducer(initialState,
    on(loadPaylink, (state, payload) => {
        console.log('Load Paylink', state, payload)
        return state;
    }),
    on(paylinkLoaded, (state, payload) => {
        console.log('Paylink Loaded', state, payload)
        window.sessionStorage.setItem('paylink', JSON.stringify(payload));
        return payload;
    }),
    on(rehydratePaylink, (state, payload) => {
        console.log('Rehydrate Paylink', state, payload)
        window.sessionStorage.setItem('paylink', JSON.stringify(payload));
        return payload;
    })
);

export function paylinkReducer(state, action) {
    return _paylinkReducer(state, action);
}