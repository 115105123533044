<section class="main-expansion-panel">
  <div role="heading" tabindex="0" class="faq-title text-center"
    [innerHTML]="getHtml(slice.primary.section_title, vehicle)"></div>
  <mat-accordion multi="true" [ngStyle]="getStyles(slice)">
    <div *ngFor="let field of slice?.fields">
      <mat-expansion-panel class="expansion-panel" *ngFor="let qAndA of field.faq_group.body[0].fields">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span [innerHTML]="getHtml(qAndA.question)"></span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="answer" [innerHTML]="getHtml(qAndA.answer)"></p>
      </mat-expansion-panel>
    </div>
  </mat-accordion>
</section>