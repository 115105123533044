import { createReducer, on } from '@ngrx/store';
import { loadSPPHistory, loadSPPHistoryFailure, loadSPPHistorySuccess } from '../_actions/account.actions';

export const initialState = null;


const _sppPaymentHistory = createReducer(initialState,
    on(loadSPPHistory, (state, payload) => {
        console.log('Call to SPP Payment History', state, payload)
        sessionStorage.setItem('SPPPaymentHistory', JSON.stringify(payload))
        return payload;
    }),
    on(loadSPPHistorySuccess, (state, payload) => {
        console.log('Load SPP Payment History', state, payload)
        sessionStorage.setItem('SPPPaymentHistory', JSON.stringify(payload))
        return payload;
    }),
    on(loadSPPHistoryFailure, (state, payload) => {
        console.log('Failure to load SPP Payment History', state, payload)
        return payload;
    })
);

export function sppPaymentHistoryReducer(state, action) {
    return _sppPaymentHistory(state, action);
}
