import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/appconfig/appconfig.service';
import { HomeService } from 'src/app/_services/home.service';
import { PrismicService } from 'src/app/_services/prismic.service';
import brandInfo from "src/environments/brand.json";

@Component({
  selector: 'app-footer-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutFooterComponent implements OnInit {

  footer: any;
  branding: any;
  isFooterVisible = true;
  currentBrand: string;
  constructor(
    private home: HomeService,
    private appConfig: AppConfigService,
    private prismicService: PrismicService
    ) { }

  ngOnInit() {

    this.currentBrand = brandInfo.brand;

    this.home.getFooter(brandInfo.brand, sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language).subscribe({
      next: (result) => {
        this.footer = result;
      },
      error: (error) => {
        this.isFooterVisible = false;
      }
    });

    this.appConfig.getSettings().subscribe({
      next: (result) => {
        this.branding = result;
      }, error: (err) => {
        this.isFooterVisible = false;
      }
    });
  }

  getHtml(content, vehicleObject = null) {
    return this.prismicService.getHtml(content, vehicleObject);
  }
}
