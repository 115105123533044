<footer *ngIf="!isWidget">

  <app-footer-disclaimer *ngIf="!isCheckoutPage"></app-footer-disclaimer>
<!-- END DISCLAIMER TEXT -->

<!-- START QUESTIONS -->
<app-footer-questions *ngIf="!isCheckoutPage"></app-footer-questions>
<!-- END QUESTIONS -->

<!-- START CONTACT US -->
<app-footer-contact-us *ngIf="!isCheckoutPage"></app-footer-contact-us>
<!-- END CONTACT US -->

<!-- START CHECKOUT FOOTER -->
<app-footer-checkout *ngIf="isCheckoutPage"></app-footer-checkout>
<!-- END CHECKOUT FOOTER -->

</footer>
