<div class="modal-content">
  <div class="modal-header mb-n1">
    <span aria-hidden="true"><mdb-icon tabindex="0" class="modal-close-icon" aria-label="Close" (click)="modalRef.hide()" fas icon="times"></mdb-icon></span>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center">
        <h3 class="mb-4">{{ title }}</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-center mb-4">
        {{ content }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <a class="btn primary-btn waves-light uppercase" mdbBtn mdbWavesEffect (click)="modalRef.hide()"> Close</a>
      </div>
    </div>
  </div>
</div>
