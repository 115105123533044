import { Component, Input, OnInit } from '@angular/core';
import { PrismicService } from 'src/app/_services/prismic.service';

@Component({
  selector: 'app-coverage-overview',
  templateUrl: './coverage-overview.component.html',
  styleUrls: ['./coverage-overview.component.scss']
})
export class CoverageOverviewComponent implements OnInit {

  @Input() plan: any;

  constructor(private prismicService: PrismicService) { }

  ngOnInit(): void {
  }

  getHtml(content) {
    return this.prismicService.getHtml(content);
  }

}
