import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ReplaySubject, Subscription } from 'rxjs';
import { Vehicle } from '../_models/vehicle';
import imageCompression from 'browser-image-compression';
import brandInfo from "src/environments/brand.json";


@Component({
  selector: 'app-modal-vehicle-inspection',
  templateUrl: './modal-vehicle-inspection.component.html',
  styleUrls: ['./modal-vehicle-inspection.component.scss']
})
export class ModalVehicleInspectionComponent implements OnInit, OnDestroy {

  @Input() title: string;
  @Input() content: string;
  vin: string;
  sub: Subscription
  brandInfo: any;

  public files: NgxFileDropEntry[] = [];
  documents = [];

  constructor(public modalRef: MDBModalRef, private router: Router) { }

  ngOnInit(): void {
    this.brandInfo = brandInfo.brand;
    let vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
    this.vin = vehicle.vin;
    console.log('brandInfoINFOOOO', brandInfo.brand)
    this.sub = this.router.events.subscribe((event) => {
      this.modalRef.hide()
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    window.scrollTo(0, 0);
  }

  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }

  public dropped(files: NgxFileDropEntry[]) {
    files.forEach(file => {
      if (file.fileEntry.isFile &&
        (file.fileEntry.name.toLowerCase().indexOf(".png") > -1 ||
          file.fileEntry.name.toLowerCase().indexOf(".pdf") > -1 ||
          file.fileEntry.name.toLowerCase().indexOf(".jpg") > -1 ||
          file.fileEntry.name.toLowerCase().indexOf(".jfif") > -1 ||
          file.fileEntry.name.toLowerCase().indexOf(".jpeg") > -1)) {
        this.files.push(file)
      }
    })

    //  this.files = files;
    this.documents = [];
    for (const droppedFile of this.files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          console.log('FILEEEEEEEEE', file)

          const reader = new FileReader();
          reader.onloadend = (e) => {
            const ranNum = Math.floor(Math.random() * 10000)
            this.documents.push({ data: reader.result, date: file.lastModified, name: this.vin + '-' + ranNum + '-' + file.name, type: file.type });
            console.log('documentssssssssss: ', this.documents)
            console.log('reader: ', reader)
          }
          console.log('document stuff', file.name, file.type)
          if (file.type.toLowerCase().indexOf("pdf") > -1) {
            // Upload PDFs raw
            reader.readAsDataURL(file);
          } else if (file.type.toLowerCase().indexOf("png") > -1 ||
            file.type.toLowerCase().indexOf("jpg") > -1 ||
            file.type.toLowerCase().indexOf("jpeg") > -1 ||
            file.type.toLowerCase().indexOf("jfif") > -1) {

            // Compress images for better storage management
            const compressedFile = this.compressImage(file)

            compressedFile.then(file => {
              reader.readAsDataURL(file)
            })
          } else {
            alert('Some file(s) did not upload.')
          }
        });
      }
    }
  }

  async compressImage(file) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    const image = await imageCompression(file, options)
    return image;
  }

  _handleReaderLoaded(e) {
    console.log("_handleReaderLoaded")
    var reader = e.target;
    const imageSrc = reader.result;
  }

  uploadFiles(modal) {
    modal.hide();
    let _documents = JSON.parse(sessionStorage.getItem('inspectionDocs'))
    if (_documents) {
      this.documents.forEach(file => {
        let isDup = false
        for (let x = 0; x < _documents.length; x++) {
          if (_documents[x].name === file.name) {
            isDup = true
            _documents[x] = file
          }
        };
        if (!isDup) {
          _documents.push(file)
        }
      })
      try { // Session storage limit on some browsers
        window.sessionStorage.setItem('inspectionDocs', JSON.stringify(_documents))
        this.router.navigate(['/documents']);
      } catch (error) {
        alert('Document file size(s) exceeds limit.')
      }

    } else {
      try { // Session storage limit on some browsers
        window.sessionStorage.setItem('inspectionDocs', JSON.stringify(this.documents))
        this.router.navigate(['/documents']);
      } catch (error) {
        alert('Document file size(s) exceeds limit.')
      }

    }
  }
}
