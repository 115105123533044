<form [formGroup]="accountEmailValidationForm" role="form">
    <div class="row">
        <div class="col-12">
            <div>
                <div class="md-form mt-3 mb-3">
                    <label for="email">{{ 'MY_ACCOUNT_LOGIN.EMAIL_ADDRESS' | translate }}</label>
                    <input type="text" role="textbox" id="email" class="form-control" mdbInput required
                        formControlName="email">
                    <mdb-error
                        *ngIf="accountEmailValidationForm.controls.email.invalid && (accountEmailValidationForm.controls.email.dirty || accountEmailValidationForm.controls.email.touched)">
                        <span role="alert" *ngIf="accountEmailValidationForm.controls.email.errors.required">{{ 'MY_ACCOUNT_LOGIN.ERROR_EMAIL_REQ' | translate }}</span>
                        <span role="alert" *ngIf="accountEmailValidationForm.controls.email.errors.email">{{ 'MY_ACCOUNT_LOGIN.ERROR_EMAIL_INVAILD' | translate }}</span>
                    </mdb-error>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div>
                <div class="md-form mt-3 mb-3">
                    <label for="lnameemail">{{ 'MY_ACCOUNT_LOGIN.LAST_NAME' | translate }}</label>
                    <input type="text" role="textbox" id="lnameemail" class="form-control" mdbInput required
                        formControlName="lastName">
                    <mdb-error role="alert"
                        *ngIf="accountEmailValidationForm.controls.lastName.invalid && (accountEmailValidationForm.controls.lastName.dirty || accountEmailValidationForm.controls.lastName.touched)">
                        {{ 'MY_ACCOUNT_LOGIN.ERROR_NAME_REQ' | translate }}
                    </mdb-error>
                </div>
            </div>
        </div>
        <div class="pb-4 pt-4 w-100 text-center">
            <button mdbBtn class="btn primary-btn waves-light" mdbWavesEffect type="button"
                [disabled]="!accountEmailValidationForm.valid"
                (click)="verifyAccountEmail()">{{ 'MY_ACCOUNT_LOGIN.CONTINUE' | translate }}</button>
        </div>
    </div>
</form>