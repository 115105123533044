import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, throwError } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import * as ActionConfigurationManager from '.././_actions/config.actions';
import { getPDFSuccess, getPDFFailed, downloadPDFSuccess, downloadPDFFailed, postServiceAgreementSuccess, postServiceAgreementFailed, postPaymentPlanAgreementSuccess, postPaymentPlanAgreementFailed, getPaymentPlanAgreementSuccess, getPaymentPlanAgreementFailed, setDateAndTimeFailed, downloadPaymentPlanAgreementSuccess, downloadPaymentPlanAgreementFailed, getContractByUuidSuccess, getContractByUuidFailed } from '../_actions/pdf.actions';
import { DataLookupService } from '../_services/data-lookup.service';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Action, Store } from '@ngrx/store';
import { get } from 'jquery';
import { ViewContractRequest, ViewContractResponse } from '../_models/pdf';


@Injectable()

export class PDFEffects {

    public downloadPDF$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.DOWNLOAD_PDF),
            switchMap((action: any) => this.pdfService.generatePDF(action)
                .pipe(
                    map(pdf => {
                        console.log(pdf);
                        if (pdf) {
                            const linkSource = 'data:application/pdf;base64,' + pdf.data
                            const downloadLink = document.createElement("a");
                            const fileName = "Coverage Agreement.pdf";

                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            return downloadPDFSuccess(pdf);
                        } else {
                            return downloadPDFFailed(pdf)
                        }

                    }),
                    catchError((error) => {
                        return throwError(error)
                    })
                )
            )
        )
    )

    public getPDF$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.GET_PDF),
            switchMap((action: any) => this.pdfService.generatePDF(action)
                .pipe(
                    map(pdf => {
                        if (pdf) {
                            console.log(pdf);
                            let pdfWindow = window.open("")
                            pdfWindow.document.title = "Vehicle Service Contract"
                            pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdf.data) + "' ></iframe>")
                            return getPDFSuccess(pdf);
                        } else {
                            // return failed pdf
                            return getPDFFailed(pdf)
                        }

                    }),
                    catchError((error) => {
                        return throwError(error)
                    })
                )
            )
        )
    )


    public postPaymentPlanAgreementPdf$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.POST_PAYMENY_PLAN_AGREEMENT_PDF),
            switchMap((action: any) => this.pdfService.postPaymentPlanAgreementPdf(action)
                .pipe(
                    map(pdf => {
                        if (pdf.success) {
                            return postPaymentPlanAgreementSuccess({
                                ...pdf,
                                pdfRaw: encodeURI(pdf.data),
                                title: "Vehicle Service Contract",
                                frame: "<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdf.data) + "' ></iframe>",
                                data: this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/pdf;base64, ${pdf.data}`)
                            })
                        } else {
                            return postPaymentPlanAgreementFailed(pdf)
                        }

                    }),
                    catchError((error) => {
                        return throwError(error)
                    })
                ))
        )
    )

    //call to the service agreement go get a pdf back
    public postServiceAgreement$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.POST_SERVICE_AGREEMENT_PDF),
            switchMap((action: any) => this.pdfService.postServiceAgreementPdf(action)
                .pipe(
                    map(pdf => {
                        if (pdf.success) {
                            return postServiceAgreementSuccess({
                                ...pdf,
                                pdfRaw: encodeURI(pdf.data),
                                title: "Service Agreement",
                                frame: "<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdf.data) + "' ></iframe>",
                                data: this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/pdf;base64, ${pdf.data}`)
                            })
                        } else {
                            return postServiceAgreementFailed(pdf)
                        }

                    }),
                    catchError((error) => {
                        return throwError(error)
                    })
                ))
        )
    )

    public getPaymentPlanAgreement$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.GET_PAYMENT_PLAN_AGREEMENT_PDF),
            switchMap((action: any) => this.pdfService.getPaymentPlanAgreementPdf(action)
                .pipe(
                    map(pdf => {
                        if (pdf.success) {
                            let pdfWindow = window.open("")
                            pdfWindow.document.title = 'Payment Plan Agreement.pdf'
                            pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdf.data) + "' ></iframe>")
                            return getPaymentPlanAgreementSuccess({
                                ...pdf,
                                title: "Payment Plan Agreement",
                                frame: "<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdf.data) + "' ></iframe>",
                                data: this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/pdf;base64, ${pdf.data}`)
                            })
                        } else {
                            return getPaymentPlanAgreementFailed(pdf)
                        }
                    })
                )
            )
        )
    )

    public downloadPPA$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.DOWNLOAD_PAYMENT_PLAN_AGREEMENT_PDF),
            switchMap((action: any) => this.pdfService.getPaymentPlanAgreementPdf(action)
                .pipe(
                    map(pdf => {
                        console.log(pdf);
                        if (pdf) {
                            const linkSource = 'data:application/pdf;base64,' + pdf.data
                            const downloadLink = document.createElement("a");
                            const fileName = "Payment Plan Agreement.pdf";

                            downloadLink.href = linkSource;
                            downloadLink.download = fileName;
                            downloadLink.click();
                            return downloadPaymentPlanAgreementSuccess(pdf);
                        } else {
                            return downloadPaymentPlanAgreementFailed(pdf)
                        }

                    }),
                    catchError((error) => {
                        return throwError(error)
                    })
                )
            )
        )
    )




    public setDateAndTime$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.SET_DATE_AND_TIME),
            tap(x => console.log('xxxxxxxxxxxxx', x)),
            switchMap((action: any) => this.pdfService.setDateAndTime(action)
                .pipe(
                    catchError((error) => {
                        return throwError(error)
                    })
                ))
        )
    )

    public getContractByUuid$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.GET_CONTRACT_BY_UUID),
            switchMap((action: any) => this.pdfService.getContractByUuid(action)
                .pipe(
                    map(pdf => {
                        console.log('pdf respinse', pdf)
                        if (pdf.status === 'OK') {
                            return getContractByUuidSuccess(pdf)
                        } else {
                            return getContractByUuidFailed(pdf)
                        }
                    }),
                    catchError((error) => {
                        const obj: ViewContractResponse = {
                            status: 'ERROR',
                            data: null,
                            errorMessage: null
                        }
                        this.store.dispatch(getContractByUuidFailed(obj))
                        return throwError(error)
                    })
                ))
        )
    )


    constructor(
        private actions$: Actions,
        private pdfService: DataLookupService,
        private sanitizer: DomSanitizer,
        private store: Store<{}>
    ) { }
}
