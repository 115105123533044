<div id="mySidenav" class="sidenav shadow sideNavLeft" style="display: none" role="dialog" aria-live="assertive">
  <app-close-button (callbackFunction)="closeNav()"></app-close-button>
  <div class="brand" routerLink="/" routerLinkActive="active" (click)="closeNav()" (click)="redirectToHome()">
    <img [src]="logo?.url ? logo.url : ''" [alt]="logo?.alt" />
  </div>
  <div class="w-100 float-left">
    <a role="link" href="javascript:void(0)" *ngIf="showSDLogout" tabindex="0" (click)="logoutDealerAgent()"><mdb-icon aria-hidden="true" width="100%" fas icon="sign-out-alt" style="font-size:13px"></mdb-icon>&nbsp;{{dealerAgent}}</a>
    <a [href]="link.url" (click)="closeNav()" *ngFor="let link of navLinks">{{link.text}}</a>
    <a *ngIf="hasProductsMenu" class="asp" tabindex="0" routerLink="/added-security-plan"
      (click)="redirectTo('/added-security-plan')" routerLinkActive="active" (click)="closeNav()">{{ 'NAV.PRODUCT1' |
      translate }}</a>
    <a *ngIf="hasProductsMenu" class="ppm" tabindex="0" routerLink="/prepaid-maintenance"
      (click)="redirectTo('/prepaid-maintenance')" routerLinkActive="active" (click)="closeNav()">{{ 'NAV.PRODUCT2' |
      translate }}</a>
    <a tabindex="0" href="javascript:void(0)" routerLink="/help" routerLinkActive="active"
      aria-label="Visit our help center" (click)="goToHelp()" (click)="closeNav()">{{ 'NAV.HELP' | translate }}</a>
    <mdb-accordion class="myaccount" *ngIf="isLogin" [multiple]="false">
      <mdb-accordion-item [collapsed]="false">
        <mdb-accordion-item-head>{{ 'NAV.MY_ACCOUNT' | translate }}</mdb-accordion-item-head>
        <mdb-accordion-item-body>
          <a href="javascript:void(0)" tabindex="0" role="link"
            class="nav-link waves-light d-sm-inline-block nav-buttons" (click)="closeNav()" routerLink="/my-account"
            routerLinkActive="active" mdbWavesEffect>{{ 'NAV.MY_ORDER' | translate }}</a>
          <a href="javascript:void(0)" tabindex="0" role="link" class="nav-link waves-light d -sm-inline-block"
            (click)="closeNav()" mdbWavesEffect (click)="signOut()">{{ 'NAV.SIGN_OUT' | translate }}</a>
        </mdb-accordion-item-body>
      </mdb-accordion-item>
    </mdb-accordion>
    <a tabindex="0" *ngIf="!isLogin" routerLink="/verify-account" (click)="closeNav()" routerLinkActive="active">{{
      'NAV.MY_ACCOUNT' | translate }}</a>
  </div>
</div>