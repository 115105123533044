import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { showInlineLoaderContact, hideInlineLoaderContact } from '../_actions/inline-loader.actions';


const _inlineLoaderMyAccountContactReducer = createReducer(initialState,
    on(showInlineLoaderContact, (state) => {
      return true;
    }),
    on(hideInlineLoaderContact, (state) => {
      return false;
    }),
); 


export function inlineLoaderMyAccountContactReducer(state, action) {
    return _inlineLoaderMyAccountContactReducer(state, action);
}
