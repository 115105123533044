import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-delete-document',
  templateUrl: './modal-delete-document.component.html',
  styleUrls: ['./modal-delete-document.component.scss']
})
export class ModalDeleteDocumentComponent implements OnInit, OnDestroy {

  @Input() index: string;
  documents: any;
  action: Subject<any> = new Subject();
  sub: Subscription;

  constructor( public modalRef: MDBModalRef, private router: Router ) { }

  ngOnInit(): void {
    this.documents = JSON.parse(sessionStorage.getItem('inspectionDocs'))
    this.sub = this.router.events.subscribe((event) => {
      this.modalRef.hide()
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    window.scrollTo(0, 0);
  }

  delete() {
    this.documents.splice(this.index, 1);
    window.sessionStorage.setItem('inspectionDocs',  JSON.stringify(this.documents));
    if (this.documents.length === 0) {
      sessionStorage.removeItem('inspectionDocs');
    }
    this.action.next("delete");
  }

}
