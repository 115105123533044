import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { avsSuccess, avsFailed, avsReset } from '../_actions/avs-state.actions';


const _avsStateReducer = createReducer(initialState,
    on(avsSuccess, (state, payload) => {
        return 'success';
    }),
    on(avsFailed, (state, payload) => {
        return 'failed';
    }),
    on(avsReset, (state, payload) => {
        return null;
    })
); 


export function avsStateReducer(state, action) {
    return _avsStateReducer(state, action);
}
