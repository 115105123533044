import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-plan-pdf',
  templateUrl: './plan-pdf.component.html',
  styleUrls: ['./plan-pdf.component.scss']
})
export class PlanPdfComponent implements OnInit {

  prismicPDF$: Observable<any>;

  constructor(
      public store: Store<{prismicPDF: any}>
  ) { }

  ngOnInit(): void {
    this.prismicPDF$ = this.store.select(store => store.prismicPDF)
  }

  showPDF(pdf) {
    window.open(pdf, '_blank')
  }

}
