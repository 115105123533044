<!-- Guest flow timer-->
<div [ngClass]="{hide:hideImage || !show_for_guest_flow}"> 
    <div *ngIf="component.primary.guest_flow_expire_date"
        style="display: flex; justify-content: center; margin: auto;"
        [ngStyle]="{ 'background-color': div_background }">
        <img class="desktop guest_flow" [src]="imageLink" (load)="checkSize($event)">
        <img class="mobile guest_flow" [src]="imageLink" style="width:100%">
    </div>
</div>

<!-- OID flow timer-->
<div [ngClass]="{hide:hideImage || !show_for_oid_flow}"> 
    <div *ngIf="(leadData$ | async) as leadData;">
        <div *ngIf="leadData.vehicle"
            style="display: flex; justify-content: center; margin: auto;"
            [ngStyle]="{ 'background-color': div_background }">
            <img class="desktop oid_flow" [src]="imageLink" (load)="checkSize($event)">
            <img class="mobile oid_flow" [src]="imageLink" style="width:100%">
        </div>
    </div>
</div>
