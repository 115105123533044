import * as ActionConfigurationManager from './config.actions';

export const loadVehicle = ActionConfigurationManager.SAVE_VEHICLE;
export const loadVehicleImage = ActionConfigurationManager.SAVE_VEHICLE_IMAGE;
export const loadVehicleImageSuccess = ActionConfigurationManager.SAVE_VEHICLE_IMAGE_SUCCESS;
export const updateOdometer = ActionConfigurationManager.UPDATE_ODOMETER;
export const updateVehicleByVin = ActionConfigurationManager.UPDATE_VEHICLE_VIN;
export const updateVehicleByYmmt = ActionConfigurationManager.UPDATE_VEHICLE_YMMT;
export const updateVIN = ActionConfigurationManager.UPDATE_VIN
export const rehydrateVehicle = ActionConfigurationManager.REHYDRATE_VEHICLE;
export const verifyVin = ActionConfigurationManager.VERIFY_VIN
export const verifyVinRates = ActionConfigurationManager.VERIFY_VIN_RATES
export const verifyVinRatesEdit = ActionConfigurationManager.VERIFY_VIN_RATES_EDIT
export const verifyVinSuccess = ActionConfigurationManager.VERIFY_VIN_SUCCESS
export const verifyVinFailed = ActionConfigurationManager.VERIFY_VIN_FAILED
export const verifyVinReset = ActionConfigurationManager.VERIFY_VIN_RESET
export const verifyVehCust = ActionConfigurationManager.VERIFY_VEHICLE_AND_CUSTOMER
export const verifyVehCustSuccess = ActionConfigurationManager.VERIFY_VEHICLE_AND_CUSTOMER_SUCCESS
export const verifyVehCustFailed = ActionConfigurationManager.VERIFY_VEHICLE_AND_CUSTOMER_FAILED
export const verifyVehCustReset = ActionConfigurationManager.VERIFY_VEHICLE_AND_CUSTOMER_RESET
export const verifyVehCustContactInformation = ActionConfigurationManager.VERIFY_VEHICLE_AND_CUSTOMER_CONTACT_INFORMATION
export const verifyVehCustSuccessContactInformation = ActionConfigurationManager.VERIFY_VEHICLE_AND_CUSTOMER_SUCCESS_CONTACT_INFORMATION
export const verifyVehCustFailedContactInformation = ActionConfigurationManager.VERIFY_VEHICLE_AND_CUSTOMER_FAILED_CONTACT_INFORMATION
export const verifyVehCustResetContactInformation = ActionConfigurationManager.VERIFY_VEHICLE_AND_CUSTOMER_RESET_CONTACT_INFORMATION

export const verifyVehicleVinInformation = ActionConfigurationManager.VERIFY_VEHICLE_VIN_INFORMATION
export const verifyVehicleVinInformationSuccess = ActionConfigurationManager.VERIFY_VEHICLE_VIN_INFORMATION_SUCCESS
export const verifyVehicleVinInformationFailed = ActionConfigurationManager.VERIFY_VEHICLE_VIN_INFORMATION_FAILED
