import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { submitTax, taxSubmitted, updateTax, taxUpdated, rehydrateTax } from '../_actions/tax.actions';


const _taxReducer = createReducer(initialState,
    on(submitTax, (state, payload) => {
        console.log('Submit Tax', state, payload)
        return state;
    }),
    on(taxSubmitted, (state, payload) => {
        console.log('Tax Submitted', state, payload)
        window.sessionStorage.setItem('tax', JSON.stringify(payload));
        return payload;
    }),
    on(updateTax, (state, payload) => {
        console.log('Tax Updated', state, payload)
        return state;
    }),
    on(taxUpdated, (state, payload) => {
        console.log('Tax Updated', state, payload)
        window.sessionStorage.setItem('tax', JSON.stringify(payload));
        return payload;
    }),
    on(rehydrateTax, (state, payload) => {
        console.log('Rehydrate Tax', state, payload)
        sessionStorage.setItem('tax', JSON.stringify(payload));
        return payload;
    })
);

export function taxReducer(state, action) {
    return _taxReducer(state, action);
}
