<div class="subfooter" *ngIf="isFooterVisible && footer?.hasquestions">
  <div class="row pt-5 pb-5 justify-content-md-center text-center">
    <div class="col-md-12 mx-auto">
      <h2 aria-label="Any Questions.">still have questions?</h2>
      <h3 class="mt-2" aria-label="Let us help.">Let us help.</h3>
      <div class="row">
        <div class="col-md-4 mx-auto">
          <p class="mt-2" aria-label="Any Questions.">Our trusted experts are here to help you choose the best VSP plan for your vehicle.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-md-center pb-5 text-center">
    <div class="col-md-1"></div>
    <div class="col-12 col-md-10 row">
      <div class="col-12 col-md-4">
        <a href="/help-center" target="_blank" aria-label="Visit our help center (opens in a new tab)">
          <div class="subfooter-item">
            <img src="/assets/vroom/icons/question-red.svg" alt="visit help center" />
            <h3>Visit our help center</h3>
          </div>
        </a>
      </div>
      <div class="col-12 col-md-4">
        <a href="mailto:{{ branding.cs_email }}" aria-label="Send message">
          <div class="subfooter-item">
            <img width="32px" src="/assets/vroom/icons/message-red.png" alt="send a message" />
            <h3>Send a message</h3>
          </div>
        </a>
      </div>
      <div class="col-12 col-md-4">
        <a href="tel:{{ branding.cs_phone_number }}" aria-label="Phone">
          <div class="subfooter-item">
            <img src="/assets/vroom/icons/phone-red.svg" alt="phone number" />
            <h3>{{ branding.cs_phone_number }}</h3>
          </div>
        </a>
      </div>
    </div>
    <div class="col-md-1"></div>
  </div>
</div>
