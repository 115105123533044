import { Actions, createEffect, ofType, Effect } from '@ngrx/effects';
import { of, EMPTY, throwError } from 'rxjs';
import { map, mergeMap, switchMap, mapTo, catchError } from 'rxjs/operators';
import { AccountService } from '../_services/account.service';
import * as ActionConfigurationManager from '.././_actions/config.actions';
import { Store } from '@ngrx/store';
import { accountVerified, invalidAccount, saveCode, verifyAccountOktaFlowFailed, verifyAccountOktaFlowSuccess } from '../_actions/verify-account.actions';
import { Injectable } from '@angular/core';
import { validCode, invalidCode } from '../_actions/verify-code-state.actions';
import { hideInlineLoader } from '../_actions/inline-loader.actions';
import { VerifyAccountOktaFlowResponse } from '../_models/verify-account';
import { OktaService } from '../_services/okta.service';

@Injectable()

export class VerifyAccountEffects {

  public verifyAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.VERIFY_ACCOUNT),
            switchMap((action) => this.accountService.verify_account(action)
                .pipe(
                    map(account => {
                      console.log(action);
                    //   if (action.contactType === 'email') {
                    //     if (account.status === 'OK') {
                    //         this.store.dispatch({type: '[Verify Component] Verify Code', payload: {contact: action.emailId, contactType: 'email'}})
                    //     }
                    //   } else if (action.contactType === 'phone') {
                    //         if (account.status === 'OK') {
                    //             this.store.dispatch({type: '[Verify Component] Verify Code', payload: {contact: action.phoneNumber, contactType: 'phone'}})
                    //         }
                    //   }
                      this.store.dispatch(hideInlineLoader())
                      return accountVerified(account);
                    }),
                    catchError((error) => {
                        this.store.dispatch(hideInlineLoader())
                        this.store.dispatch(invalidAccount())
                        return throwError(error);
                    }),
                )
            )
        )
    )

    public verifyAccountOktaFlow$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.VERIFY_ACCOUNT_OKTA_FLOW),
            switchMap((action) => this.oktaService.verify_account_okta_flow(action)
                .pipe(
                    map(response => {
                      let object: VerifyAccountOktaFlowResponse = {
                          id: response.data.oktaUserId
                      }
                      return verifyAccountOktaFlowSuccess(object);
                    }),
                    catchError((error) => {
                        this.store.dispatch(verifyAccountOktaFlowFailed())
                        return throwError(error);
                    }),
                )
            )
        )
    )

    public verifyCode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.VERIFY_CODE),
            switchMap((action: any) => this.accountService.verify_code(action)
                .pipe(
                    map(code => {
                      console.log('code: ', code);
                      if (code.errorMessage === null) {
                          this.store.dispatch({ type: '[My Account Component] Load Account', payload: code.data.sfid})
                          sessionStorage.setItem('tokenId', code.data.access_token);
                          return validCode()
                      } else {
                          return invalidCode(code)
                      }
                    }),
                    catchError((error) => {
                        this.store.dispatch(hideInlineLoader())
                        this.store.dispatch(invalidCode(error))
                        return throwError(error)
                    }),
                )
            )
        )
    )

    constructor(
        private actions$: Actions,
        private accountService: AccountService,
        private store: Store<{}>,
        private oktaService: OktaService
    ) {}
}
