import {createAction, props} from "@ngrx/store"


export const loadSelectedPlan = createAction(
  '[Selected Plan Component] Selected Plan',
  props<{ defaultPlan: any }>()
);

export const loadloadSelectedCoverageTerms = createAction(
  '[Selected Coverage Terms] Load Selected Plan ',
  props<{ selectedPlanTerms: any }>()
);

export const loadAllPlans = createAction(
  '[Selected All Plans] Load All Plans ',
  props<{ allPlans: any }>()
);

export const loadAllPlansBasedTerms = createAction(
  '[Updated Plans based on Terms] Update All Plans Based on Terms ',
  props<{ allPlansPlanBasedTerm: any }>()
);

export const loadCurrentSelectedPlan = createAction(
  '[Current Selected Plan] Current plan',
  props<{ currentSelectedPlan: any }>()
);


