import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { submitAVS, avsSubmitted, rehydrateAVS } from '../_actions/avs.actions';
import { loadOpportunity } from '../_actions/opportunity.actions';


const _opportunityReducer = createReducer(initialState,
    on(loadOpportunity, (state, payload) => {
        // console.log('Load Opp ID', state, payload)
        sessionStorage.setItem('opp', JSON.stringify(payload))
        return payload;
    })
);

export function opportunityReducer(state, action) {
    return _opportunityReducer(state, action);
}
