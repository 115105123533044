import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { setOktaPassword, oktaPasswordSet, oktaPasswordSetFailed, oktaPasswordChanged, oktaPasswordChangedFailed, changeOktaPassword, oktaPasswordReset, oktaSignOut } from '../_actions/okta.actions';


const _oktaPasswordReducer = createReducer(initialState,
    on(setOktaPassword, (state, payload) => {
        return state;
    }),
    on(oktaPasswordSet, (state) => {
        return true;
    }),
    on(oktaPasswordSetFailed, (state) => {
        return false;
    }),
    on(oktaPasswordChanged, (state, payload) => {
        return true;
    }),
    on(changeOktaPassword, (state) => {
        return state;
    }),
    on(oktaPasswordChangedFailed, (state) => {
        return false;
    }),
    on(oktaPasswordReset, (state) => {
        return null;
    })
);

export function oktaPasswordReducer(state, action) {
    return _oktaPasswordReducer(state, action);
}