import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modal-price',
  templateUrl: './modal-price.component.html',
  styleUrls: ['./modal-price.component.scss'],
})
export class ModalPriceComponent implements OnInit {
  view: any;
  numOfPayments: any;
  currentMonth: any;
  currentYear: any;
  monthlyPayments: any;
  @Input() plans;
  @Input() components;
  @Input() termTotalPrice;
  @Input() termYears;
  @Input() termMileage;
  @Input() termNum;

  eliteLowestPrice: any;
  premiumLowestPrice: any;
  essentialLowestPrice: any;
  numOfYearsChosen: any;
  planId = 3;
  planPayments = 12;
  roadsideActive = false;
  ppmActive = false;
  selectedPlan: any;
  isActive = false;

  getEliteLowestPrice() {
    const totalPrice = this.plans[0].planDetails.terms[0].totalPrice;
    this.components[0].monthlyCost = (totalPrice / 12).toFixed(2);
  }

  getPremiumLowestPrice() {
    const totalPrice = this.plans[1].planDetails.terms[0].totalPrice;
    this.components[1].monthlyCost = (totalPrice / 12).toFixed(2);
  }

  getEssentialLowestPrice() {
    const totalPrice = this.plans[2].planDetails.terms[0].totalPrice;
    this.components[2].monthlyCost = (totalPrice / 12).toFixed(2);
  }

  getTermDataYears(event, id) {
    this.planId = id;
    const d = new Date();
    if (d.getMonth() === 0) {
      this.currentMonth = 'January';
    } else if (d.getMonth() === 1) {
      this.currentMonth = 'February';
    } else if (d.getMonth() === 2) {
      this.currentMonth = 'March';
    } else if (d.getMonth() === 3) {
      this.currentMonth = 'April';
    } else if (d.getMonth() === 4) {
      this.currentMonth = 'May';
    } else if (d.getMonth() === 5) {
      this.currentMonth = 'June';
    } else if (d.getMonth() === 6) {
      this.currentMonth = 'July';
    } else if (d.getMonth() === 7) {
      this.currentMonth = 'August';
    } else if (d.getMonth() === 8) {
      this.currentMonth = 'September';
    } else if (d.getMonth() === 9) {
      this.currentMonth = 'October';
    } else if (d.getMonth() === 10) {
      this.currentMonth = 'November';
    } else if (d.getMonth() === 11) {
      this.currentMonth = 'December';
    }

    this.currentYear = d.getFullYear() + parseInt(event.target.id, 10);

    if (event.target.id === 3) {
      this.termNum = 0;
    } else if (event.target.id === 5) {
      this.termNum = 1;
    } else { this.termNum = 2; }

    for (let i = 0; i < this.plans.length; i++) {
      if (this.selectedPlan.title === this.plans[i].planName) {
        this.termTotalPrice = this.plans[i].planDetails.terms[this.termNum].totalPrice;
        this.termYears = this.plans[i].planDetails.terms[this.termNum].months / 12;
        this.termMileage = this.plans[i].planDetails.terms[this.termNum].miles;
      }
    }

    this.monthlyPayments = (this.termTotalPrice / this.numOfPayments).toFixed(2);
    sessionStorage.setItem('termTotalPrice', this.termTotalPrice);
    sessionStorage.setItem('termYears', this.termYears);
    sessionStorage.setItem('termMileage', this.termMileage);
    sessionStorage.setItem('subtotal', this.termTotalPrice);
  }

  getTermDataNumOfPayments(event, id) {
    this.planPayments = id;
    this.numOfPayments = event.target.id;
    sessionStorage.setItem('numOfMonthlyPayments', id);
    this.monthlyPayments = (this.termTotalPrice / this.numOfPayments).toFixed(2);
    sessionStorage.setItem('monthlyPayments', this.monthlyPayments);
  }

  toggleAddon(addon) {
    this.isActive = !this.isActive;
    const roadSideTotalPrice = 24.0;
    const ppmTotalPrice = 240.0;
    const roadsidePrice = roadSideTotalPrice / parseInt(sessionStorage.getItem('numOfMonthlyPayments'), 10);
    const ppmPrice = ppmTotalPrice / parseInt(sessionStorage.getItem('numOfMonthlyPayments'), 10);

    if (addon.title === 'Roadside Assistance' && this.roadsideActive === false) {
      this.roadsideActive = true;
      this.monthlyPayments = (parseFloat(this.monthlyPayments) + roadsidePrice).toFixed(2);
      this.termTotalPrice = (parseFloat(this.termTotalPrice) + roadSideTotalPrice).toFixed(2);
    } else if (addon.title === 'Roadside Assistance' && this.roadsideActive === true) {
      this.roadsideActive = false;
      this.monthlyPayments = (parseFloat(this.monthlyPayments) - roadsidePrice).toFixed(2);
      this.termTotalPrice = (parseFloat(this.termTotalPrice) - roadSideTotalPrice).toFixed(2);
    } else if (addon.title === 'Prepaid Maintenance' && this.ppmActive === false) {
      this.ppmActive = true;
      this.monthlyPayments = (parseFloat(this.monthlyPayments) + ppmPrice).toFixed(2);
      this.termTotalPrice = (parseFloat(this.termTotalPrice) + ppmTotalPrice).toFixed(2);
    } else if (addon.title === 'Prepaid Maintenance' && this.ppmActive === true) {
      this.ppmActive = false;
      this.monthlyPayments = (parseFloat(this.monthlyPayments) - ppmPrice).toFixed(2);
      this.termTotalPrice = (parseFloat(this.termTotalPrice) - ppmTotalPrice).toFixed(2);
    }
    sessionStorage.setItem('monthlyPayments', this.monthlyPayments);
    sessionStorage.setItem('subtotal', this.termTotalPrice);
    if (this.ppmActive) {
      sessionStorage.setItem('ppmActive', 'true');
    } else {
      sessionStorage.setItem('ppmActive', 'false');
    }
    if (this.roadsideActive) {
      sessionStorage.setItem('roadsideActive', 'true');
    } else {
      sessionStorage.setItem('roadsideActive', 'false');
    }
  }

  constructor() {}

  ngOnInit() {
    this.getEliteLowestPrice();
    this.getPremiumLowestPrice();
    this.getEssentialLowestPrice();
    this.numOfPayments = 12;
    this.numOfYearsChosen = 3;

    sessionStorage.setItem('numOfMonthlyPayments', this.numOfPayments);

    const d = new Date();
    if (d.getMonth() === 0) {
      this.currentMonth = 'January';
    } else if (d.getMonth() === 1) {
      this.currentMonth = 'February';
    } else if (d.getMonth() === 2) {
      this.currentMonth = 'March';
    } else if (d.getMonth() === 3) {
      this.currentMonth = 'April';
    } else if (d.getMonth() === 4) {
      this.currentMonth = 'May';
    } else if (d.getMonth() === 5) {
      this.currentMonth = 'June';
    } else if (d.getMonth() === 6) {
      this.currentMonth = 'July';
    } else if (d.getMonth() === 7) {
      this.currentMonth = 'August';
    } else if (d.getMonth() === 8) {
      this.currentMonth = 'September';
    } else if (d.getMonth() === 9) {
      this.currentMonth = 'October';
    } else if (d.getMonth() === 10) {
      this.currentMonth = 'November';
    } else if (d.getMonth() === 11) {
      this.currentMonth = 'December';
    }

    this.currentYear = d.getFullYear() + 3;
  }
}
