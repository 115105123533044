export let previewLead = {
  purchaseRecordSfId: "a0g55000001a3CXAAY",
  brandSfId: "a0355000005HlLbAAK",
  brandExternalId: "c5b9f608-7e7b-4a7b-a421-89445a0be132",
  shoppingCartExternalId: "0065500000I6khFAAR",
  customer: {
    address1: "1435 Boggs Rd Northwest1007",
    address2: null,
    city: "Duluth",
    country: "US",
    emailId: "manmohan7260+26@gmail.com",
    firstName: "Manmohan",
    lastName: "Paragein",
    phoneNumber1: null,
    phoneNumber2: "1234567890",
    state: "GA",
    zipCode: "30096",
    accountExternalId: null,
    accountSfId: "0015500001ILzd7AAD",
    contactSfId: "0035500001BsoG9AAJ",
    contactExternalId: null,
  },
  vehicle: {
    makeSfId: "a0055000006OUWqAAO",
    makeExternalId: "AUDI",
    make: "Audi",
    purchaseDate: "03-08-2021",
    referenceNumber4: null,
    modelSfId: "a015500000FzbePAAR",
    modelExternalId: null,
    model: "A4",
    trimSfId: null,
    trimExternalId: null,
    trim: null,
    year: "2019",
    vin: "WAUGMAF43LN020516",
    modelNumber: null,
    referenceNumber3: null,
    referenceNumber2: null,
    vehicleType: null,
    referenceNumber1: null,
    mileage: null,
    vehicleCondition: null,
    vehicleExternalId: null,
    vehicleSfId: "0015500001ILzgQAAT",
    isElectric: false,
    mfrWarrantyDate: "02-02-2026",
    inserviceDate: "03-08-2021",
    deliveryDate: "03-08-2021",
    exteriorColor: null,
  },
  type: "[Lead API] Lead Loaded Success",
};
