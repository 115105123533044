import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { loadStates, statesLoaded } from '../_actions/states.actions';


const _stateReducer = createReducer(initialState,
    on(loadStates, (state) => console.log('Load States', state)),
    on(statesLoaded, (state, payload) => {
      console.log('States Loaded Success',state, payload);
      // To add a lead to state, use spread operator
      window.sessionStorage.setItem('states', JSON.stringify(payload));
      return payload;
    })
);

export function stateReducer(state, action) {
    return _stateReducer(state, action);
}
