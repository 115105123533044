import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef, ModalOptions } from 'ng-uikit-pro-standard';
import { Subject, Subscription } from 'rxjs';
import brandInfo from '../../environments/brand.json'
import { oktaSignOut } from '../_actions/okta.actions';
import { Store } from '@ngrx/store';
import { OktaUser } from '../_models/okta';

@Component({
  selector: 'app-modal-timeout',
  templateUrl: './modal-timeout.component.html',
  styleUrls: ['./modal-timeout.component.scss']
})
export class ModalTimeoutComponent implements OnInit, OnDestroy {
  public modalType: string;
  public action: Subject<any> = new Subject();
  oktaUserSub: Subscription;
  oktaId: any;
  login: any;
  access_token: any;

  constructor(public modalRef: MDBModalRef, private router: Router, 
    public options: ModalOptions,
    public store: Store<{ oktaUser: any }>,
    ) { }

  ngOnInit(): void {
    console.log(this.options.data); // here you will get the value

    this.oktaUserSub = this.store.select(store => store.oktaUser).subscribe(data => {
      if (data !== null && data !== false) {
        this.oktaId = data.id
        this.login = data.login
        this.access_token = data.access_token
      } 
    })
   }

   ngOnDestroy() {
    if (this.oktaUserSub) {
      this.oktaUserSub.unsubscribe();
    }

    if (brandInfo.okta) {
      const object: OktaUser = {
        id: this.oktaId,
        login: this.login,
        access_token: this.access_token
      }
      this.store.dispatch(oktaSignOut(object))
    }
   }

  killSession(){
    if (brandInfo.okta) {
      const object: OktaUser = {
        id: this.oktaId,
        login: this.login,
        access_token: this.access_token
      }
      this.store.dispatch(oktaSignOut(object))
    }

    this.router.navigate([`/${brandInfo.homePage}`]);
    this.action.next('killsession');
  }

  keepSession(){
    this.action.next('keepalive');
  }

  renew() {
    location.reload();
  }
}
