import { hideInlineLoader } from "./../_actions/inline-loader.actions";
import { prismicContent } from "./../_models/prismic";
import { loadPrismic, loadPrismicSuccess } from "./../_actions/prismic.actions";
import { HomeService } from "./../_services/home.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY, Observable, of } from "rxjs";
import { map, switchMap, mergeMap, catchError } from "rxjs/operators";
import { VehicleService } from "../_services/vehicle.service";
import { Injectable } from "@angular/core";
import * as ActionConfigurationManager from "../_actions/config.actions";
import { Vehicle } from "../_models/vehicle";
import { Action, Store } from "@ngrx/store";
import { ExpiryFormatDirective } from "angular-cc-library";

@Injectable()
export class PrismicEffects {
  constructor(
    private actions$: Actions,
    private homeService: HomeService,
    private store: Store<{}>
  ) {}

  public loadPrismic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadPrismic),
      switchMap((action) => {
        console.log("-------- loadPrismic$ action -------");
        console.log("1. loadPrismic$ action called:", action);
        return this.homeService.getPage(action).pipe(
          map((prismicResponse) => {
            let prismicContent = prismicResponse as prismicContent;

            console.log("3.loadPrismic$ Got Page Content: ", prismicContent);
            console.log("--------");

            //Remove loader
            this.store.dispatch(hideInlineLoader());

            // Load Prismic Data to Store
            return loadPrismicSuccess(prismicContent);
          }),
          catchError((error) => {
            console.error("2. Error loading prismic based on the following parameters: ", action, error);
            console.error("3. Going to the default home page...");
            console.log("--------");

            this.store.dispatch(hideInlineLoader());

            // const prismicLandingPage: prismicLandingPage = {
            //   brand: brand,
            //   pageType: "home",
            //   product: "",
            //   lang: "en-us",
            //   isElectric: "non-electric",
            //   pageId: "",
            // };

            // this.store.dispatch(loadPrismic(prismicLandingPage));

            return EMPTY;
          })
        );
      })
    );
  });
}
