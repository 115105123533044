import * as ActionConfigurationManager from './config.actions'

export const showInlineLoader = ActionConfigurationManager.SHOW_INLINE_LOADER;
export const hideInlineLoader = ActionConfigurationManager.HIDE_INLINE_LOADER;
export const showInlineLoaderContact = ActionConfigurationManager.SHOW_INLINE_LOADER_MY_ACCOUNT_CONTACT;
export const hideInlineLoaderContact = ActionConfigurationManager.HIDE_INLINE_LOADER_MY_ACCOUNT_CONTACT;
export const showInlineLoaderBilling = ActionConfigurationManager.SHOW_INLINE_LOADER_MY_ACCOUNT_BILLING;
export const hideInlineLoaderBilling = ActionConfigurationManager.HIDE_INLINE_LOADER_MY_ACCOUNT_BILLING;
export const showInlineLoaderPayment = ActionConfigurationManager.SHOW_INLINE_LOADER_MY_ACCOUNT_PAYMENT;
export const hideInlineLoaderPayment = ActionConfigurationManager.HIDE_INLINE_LOADER_MY_ACCOUNT_PAYMENT;
export const showInlineLoaderBillingInformation = ActionConfigurationManager.SHOW_INLINE_LOADER_MY_ACCOUNT_BILLING_INFORMATION
export const hideInlineLoaderBillingInformation = ActionConfigurationManager.HIDE_INLINE_LOADER_MY_ACCOUNT_BILLING_INFORMATION