import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { storePrismicPDF, rehydratePrismicPDF } from '../_actions/pdf.actions';


const _prismicPDFReducer = createReducer(initialState,
    on(storePrismicPDF, (state, payload) => {
        sessionStorage.setItem('prismicPDF', JSON.stringify(payload))
        return payload;
    }),
    on(rehydratePrismicPDF, (state, payload) => {
        sessionStorage.setItem('prismicPDF', JSON.stringify(payload))
        return payload;
    })
);

export function prismicPDFReducer(state, action) {
    return _prismicPDFReducer(state, action);
}