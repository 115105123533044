<div class="modal-content modal-timeout">
  <div class="modal-header mb-n1">
    <span aria-hidden="true" ><mdb-icon tabindex="0" class="modal-close-icon" aria-label="Close" fas icon="times" (click)="onClickClose()"></mdb-icon></span>
  </div>
  <div class="modal-body p-0">
    <div class="row pl-3 pr-3 mt-4">
      <div  class="col-12 text-center" tabindex="0" role="alert" aria-live="assertive" >
        <h2>{{ header }}</h2>
        <p class="mb-4">{{message}}</p>
        <!-- <p class="mb-5">Your session is about to expire</p> -->
        <div class="d-flex justify-content-center mb-5">
          <button role="button" mdbBtn class="btn cancel-btn" *ngIf="data.displayCancel" (click)="onCancelClick()">{{cancelButtonText}}</button>&nbsp;&nbsp;
          <button role="button" mdbBtn class="btn primary-btn" *ngIf="data.displayContinue" (click)="onConfirmClick()">{{confirmButtonText}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
