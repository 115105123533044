import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { retry, catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import {
  Account,
  Paylink,
  AccountResponse,
  MyAccount,
  AccountID,
} from "../_models/account";
import {
  VerifyAccountResponse,
  VerifyCodeResponse,
} from "../_models/verify-account";
import { environment } from "../../environments/environment";
import brandInfo from '../../environments/brand.json'
import { GatewayService } from "./gateway.service";
import { LoggingService } from "./logging.service";

declare var gtag;

@Injectable({
  providedIn: "root",
})
export class AccountService {

  constructor(private httpClient: HttpClient, private gatewayService: GatewayService, private loggingService: LoggingService) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  get_account(object): Observable<AccountResponse> {
    console.log(object.payload);
    return this.httpClient
      .get<AccountResponse>(
        this.gatewayService.baseUrl +
          "dtc-account-srvc/account/" +
          object.payload.accountID
      )
      .pipe(retry(1), catchError(this.loggingService.handleError));
  }

  get_account_id(object): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}/v1/api/account/getaccountsfid`, {
      params: {
        email: encodeURIComponent(object.email.toLowerCase())
      }
    })
    .pipe(
      retry(1)
    )
  }

  get_paylink(object: AccountID): Observable<any> {
    return this.httpClient.get<any>(
      `${this.gatewayService.baseUrl}dtc-payment-srvc/customerData/${object.d2cAccountId}`,
      {
        params: {
          accountNumber: object.paylinkAccountId,
          paymentService: object.paymentService,
        },
      }
    )
  }

  get_auth_token(object): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.apiUrl}/v1/api/account/getAuthToken`,
      {
        email: encodeURIComponent(object.email.toLowerCase()),
        access_token: object.access_token,
        brand: brandInfo.brand
      }
    );
  }

  /**
 * Retrieves the SPP (Service Payment Plan) payment history for a specific customer account.
 * @param {AccountID} object - The object containing the account ID for which the SPP payment history is to be fetched.
 * @returns {Observable<any>} An observable containing the response from the SPP payment history API call.
 */
//   get_SPP_Payment_History(object: AccountID): Observable<any> {

//     // const sppHistorydata =
//     // {
//     //     "statusCode": 200,
//     //     "data": {
//     //         "brand": "Chevrolet",
//     //         "accountNumber": "H05316",
//     //         "paymentMethod": "CreditCard",
//     //         "paymentMethod_last4": "********1111",
//     //         "numberOfPayments": 34,
//     //         "paymentsRemainaing": 33,
//     //         "nextPaymentDate": "9/8/2023",
//     //         "currentBalance": 2121.39,
//     //         "installment": [
//     //             {
//     //                 "scheduledDate": "2023-08-29T00:00:00",
//     //                 "actualDate": "2023-08-29T00:00:00",
//     //                 "scheduledAmount": 218.7,
//     //                 "actualAmount": 218.7,
//     //                 "paymentStatus": "Paid",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2023-09-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "Pending",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2023-10-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "Not Paid",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2023-11-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2023-12-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2024-01-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2024-02-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2024-03-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2024-04-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2024-05-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2024-06-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2024-07-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2024-08-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2024-09-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2024-10-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2024-11-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2024-12-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2025-01-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2025-02-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2025-03-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2025-04-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2025-05-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2025-06-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2025-07-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2025-08-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2025-09-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2025-10-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2025-11-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2025-12-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2026-01-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2026-02-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2026-03-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2026-04-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "Paid",
//     //                 "paymentFailedReason": "N/A"
//     //             },
//     //             {
//     //                 "scheduledDate": "2026-05-08T00:00:00",
//     //                 "scheduledAmount": 64.28,
//     //                 "paymentStatus": "None",
//     //                 "paymentFailedReason": "N/A"
//     //             }
//     //         ]
//     //     }
//     // }



//   //  return sppHistorydata

//   return this.httpClient.get<any>(
//     `${this.gatewayService.baseUrl}dtc-payment-srvc/customerData/${object.d2cAccountId}`,
//     {
//       params: {
//         accountNumber: object.paylinkAccountId,
//         paymentService: object.paymentService,
//       },
//     }
//   )
// }

  verify_account(object): Observable<VerifyAccountResponse> {
    console.log(object);
    let contact = "";
    if (object.emailId === null) {
      contact = object.phoneNumber;
    } else {
      contact = object.emailId;
    }
    return this.httpClient.get<VerifyAccountResponse>(
      this.gatewayService.baseUrl + "dtc-account-srvc/account/verify",
      {
        params: {
          contact: encodeURIComponent(contact),
          contactType: object.contactType,
          lastName: object.lastName,
          brandName: object.brandName
        },
      }
    );
  }

  verify_code(object): Observable<VerifyCodeResponse> {
    console.log(object);
    return this.httpClient.get<VerifyCodeResponse>(
      this.gatewayService.baseUrl + "dtc-account-srvc/account/verifycode",
      {
        params: {
          contact: encodeURIComponent(object.contact),
          contactType: object.contactType,
          code: object.code,
          lastName: object.lastName,
          brandName: brandInfo.brand
        },
      }
    );
  }

  logout() {
    // Clear Session Storage



    // Clear ngrx store
  }


}
