import { createReducer, on } from '@ngrx/store';
import { decodeVinPH, vinDecodedSuccess, decodeVinVV, rehydrateVin, decodeVinGetCarInfo, decodeVinGetCarInfoSuccess, decodeVinGetCarInfoFail } from '../_actions/vinDecode.actions'

export const initialState = null;


const _vinDecodeReducer = createReducer(initialState,
    on(decodeVinPH, (state, payload) => {
      console.log('Load Vin Decode',state, payload);
      window.sessionStorage.setItem('vinDecode', JSON.stringify(payload));
      return payload;
    }),
    on(vinDecodedSuccess, (state, payload ) => {
        console.log('Vin Decoded Success', state, payload);
        window.sessionStorage.setItem('vinDecode', JSON.stringify(payload));
        return payload;
    }),
    on(decodeVinVV, (state, payload) => {
        console.log('Load Vin Decode', state, payload);
        window.sessionStorage.setItem('vinDecode', JSON.stringify(payload));
        return payload;
    }),
    on(rehydrateVin, (state, payload) => {
        console.log('Rehydrate VIN', state, payload)
        sessionStorage.setItem('vinDecode', JSON.stringify(payload));
        return payload
    }),
    on(decodeVinGetCarInfo, (state, payload) => {
        // sessionStorage.setItem('vinDecodeCarInfo', JSON.stringify(payload));
        return state
    }),
    on(decodeVinGetCarInfoSuccess, (state, payload) => {
        sessionStorage.setItem('vinDecodeCarInfo', JSON.stringify(payload));
        return payload
    }),
    on(decodeVinGetCarInfoFail, (state, payload) => {
        // sessionStorage.setItem('vinDecodeCarInfo', JSON.stringify(payload));
        return payload
    })
);

export function vinDecodeReducer(state, action) {
    return _vinDecodeReducer(state, action);
}
