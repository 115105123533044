import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { multiProductPaymentTermsChanged } from 'src/app/_actions/multi-product-flow-payment-terms-changed.actions';

@Component({
  selector: 'app-product-tabset-payment-terms-change',
  templateUrl: './product-tabset-payment-terms-change.component.html',
  styleUrls: ['./product-tabset-payment-terms-change.component.scss']
})
export class ProductTabsetPaymentTermsChangeComponent implements OnInit {

  constructor(
    public modalRef: MDBModalRef,
    public store: Store<{}>
  ) { }

  ngOnInit(): void {
  }

  addNewItem() {
    this.store.dispatch(multiProductPaymentTermsChanged())
  }

}
