import { Component, OnInit } from '@angular/core';
import { MDBModalRef, ModalOptions } from 'ng-uikit-pro-standard';

@Component({
    selector: 'app-error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.scss']
  })

export class ErrorMessageComponent implements OnInit {
    title: string;
    data: string;

  constructor(public stateChangeModal: MDBModalRef,
    public options: ModalOptions
    ) { }


    ngOnInit(): void {
       this.title = this.options.data['title'];
       this.data = this.options.data['message']
    }

 }