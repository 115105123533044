import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { singleFlowProductChanged, resetSingleFlowProductChanged } from '../_actions/single-flow-product-changed.actions';


const _singleFlowProductChangedReducer = createReducer(initialState,
    on(singleFlowProductChanged, (state, payload) => {
        return true;
    }),
    on(resetSingleFlowProductChanged, (state, payload) => {
        return null;
    })
);

export function singleFlowProductChangedReducer(state, action) {
    return _singleFlowProductChangedReducer(state, action);
}