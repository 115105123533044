<section class="commercial-use-section mt-4" *ngIf="surcharges.length > 0">
  <div>
    <span class="label">Additional Use Coverage</span>
    <hr class="mt-2 mb-2">
  </div>
  <div *ngFor="let surcharge of surcharges; let i = index">
    <div class="switch success-switch">
      <div>
        <div class="addOnCost">
          <span class="addOnCost-inner" id="{{surcharge.type}}">{{surcharge.label.enLabel}}: <span
              *ngIf="!surcharge.active">${{surcharge.surchargeAmount}}</span></span>
          <span *ngIf="surcharge.active" class="font-weight-bold">{{surcharge.surchargeAmount | currency: "USD" :
            "symbol-narrow" : "0.2-2": this.preferredLanguage }}</span>
        </div>
        <a class="click-text" role="link" tabindex="0" (click)="toggleToolTip(i)">{{ 'RATES_PAGE.WHATS_THIS' | translate
          }}</a>
      </div>
      <div class="row">
        <label>
          {{ 'RATES_PAGE.NO' | translate }}
          <input aria-labelledby="{{surcharge.type}}" type="checkbox" [ngModel]="surcharge.active"
            (ngModelChange)="onModelChange(surcharge, $event)">
          <span class="lever"></span> {{ 'RATES_PAGE.YES' | translate }}
        </label>
      </div>
      <div class="row whats_this_panel">
        <div mdbCollapse>
          <div>
            {{surcharge.tooltip}}
          </div>
          <a class="click-text" (click)="toggleToolTip(i)">{{ 'RATES_PAGE.CLOSE' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</section>
