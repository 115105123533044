import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { paypalAuthorizationApproved, paypalOauth2TokenSuccess, paypalPickedAsOption, paypalSetupTokenSuccess, rehydratePaypal } from '../_actions/paypal.action';


const _paypalReducer = createReducer(initialState,
    on(paypalAuthorizationApproved, (state, payload) => {
        sessionStorage.setItem('paypal', JSON.stringify(payload))
        return payload;
    }),
    on(rehydratePaypal, (state, payload) => {
        sessionStorage.setItem('paypal', JSON.stringify(payload))
        return payload;
    }),
    on(paypalOauth2TokenSuccess, (state, payload) => {
      sessionStorage.setItem('paypalIdToken', JSON.stringify(payload))
      return payload;
    }),
    on(paypalSetupTokenSuccess, (state, payload) => {
      sessionStorage.setItem('paypalSetupToken', JSON.stringify(payload))
      return payload;
    }),
    on(paypalPickedAsOption, (state, payload) => {
      sessionStorage.setItem('paypalPickedAsOption', JSON.stringify(payload))
      return payload;
    }),

);

export function paypalReducer(state, action) {
    return _paypalReducer(state, action);
}
