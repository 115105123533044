import { createReducer, on } from '@ngrx/store';
import { abandonCartTrigger, abandonCartTriggerSuccess, getAbandonedCart, getAbandonedCartFailed, getAbandonedCartSuccess, saveAbandonedCart, saveAbandonedCartFailed, saveAbandonedCartSuccess, resetAbandonCart } from '../_actions/abandon-cart.actions';

export const initialState = null;


const _abandonCartTriggerReducer = createReducer(initialState,
    on(abandonCartTrigger, (state, payload) => {
        return null;
    }),
    on(abandonCartTriggerSuccess, (state, payload) => {
        return null;
    }),
    on(getAbandonedCart, (state, payload) => {
        return null;
    }),
    on(getAbandonedCartSuccess, (state, payload) => {
        return true;
    }),
    on(getAbandonedCartFailed, (state, payload) => {
        return false;
    }),
    on(saveAbandonedCart, (state, payload) => {
        return null;
    }),
    on(saveAbandonedCartSuccess, (state, payload) => {
        return 1;
    }),
    on(saveAbandonedCartFailed, (state, payload) => {
        return -1;
    }),
    on(resetAbandonCart, (state, payload) => {
        return null;
    })    
);

export function abandonCartTriggerReducer(state, action) {
    return _abandonCartTriggerReducer(state, action);
}
