import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PrismicService } from 'src/app/_services/prismic.service';

@Component({
  selector: 'app-slice-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {

  @Input() public slice: any;
  @Input('vehicleObject') public vehicle: any;

  @Output() emitOpenModal = new EventEmitter<string>();

  slide2Config = {
    slidesToShow: 3,
    slidesToScroll: 3,
    adaptiveHeight: true,
    centerMode: true,
    centerPadding: '30px',
    speed: 300,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  // slides2 = [
  //   {
  //     title: 'Essential Plan',
  //     subTitle: 'Most Affordable',
  //     monthlyCost: '75.00',
  //     averageSavings: '2,500.00',
  //     maxTerm: '5 Years or 60,000 Miles',
  //     image: '/assets/img/basic.png',
  //     planId: '',
  //     features: [
  //       {
  //         icon: 'engine',
  //         component: 'Engine',
  //         description:
  //           'Engine failure can be caused by numerous factors, the most common being overheating. In the case of an engine failure, your engine and all of its components will be covered. That includes crankshaft, pistons, timing belt and gears, intake and exhaust valves, and the oil pump. Please see the policy for a full description.',
  //         averageCostMin: '2,500',
  //         averageCostMax: '4,000',
  //         source: 'www.source.com',
  //         class: 'included'
  //       },
  //       {
  //         icon: 'transmission',
  //         component: 'Transmission',
  //         description:
  //           'If your vehicle is giving off strange vibrations or noises such as whining or grinding, gear slipping or lack of acceleration, transmission failure could be the cause. In the case of failure, your transmission protection includes all internal parts with the exception of cables and electrical components. Please see the policy for a full description.',
  //         averageCostMin: '1,300',
  //         averageCostMax: '3,400',
  //         source: 'www.source.com',
  //         class: 'included'
  //       },
  //       {
  //         icon: 'axle',
  //         component: 'Axles',
  //         description:
  //           'You may notice sudden clunking, sputtering while you accelerate, uncommon rumbling or vibrations as you steer, you likely have a worn or broken axle. In the case of axle failure, all the internal components such as the axle shafts and housing are covered. Please see the policy for a full description.',
  //         averageCostMin: '300',
  //         averageCostMax: '1,100',
  //         source: 'www.source.com',
  //         class: 'included'
  //       },
  //       {
  //         icon: 'ac',
  //         component: 'Air Conditioning',
  //         description: 'Typically a lack of power, contactor problems or even a faulty thermostat can cause A/C failure. In the case of failure, all A/C components including this, this and that are covered. Please see the policy for a full description.',
  //         averageCostMin: '200',
  //         averageCostMax: '800',
  //         source: 'www.source.com',
  //         class: 'included'
  //       }
  //     ]
  //   },
  //   {
  //     title: 'Premium Plan',
  //     subTitle: 'Most Popular',
  //     monthlyCost: '100.00',
  //     averageSavings: '5,000.00',
  //     maxTerm: '5 Years or 60,000 Miles',
  //     image: '/assets/img/premium.png',
  //     planId: '',
  //     features: [
  //       {
  //         icon: 'engine',
  //         component: 'Engine',
  //         description:
  //           'Engine failure can be caused by numerous factors, the most common being overheating. In the case of an engine failure, your engine and all of its components will be covered. That includes crankshaft, pistons, timing belt and gears, intake and exhaust valves, and the oil pump. Please see the policy for a full description.',
  //         averageCostMin: '2,500',
  //         averageCostMax: '4,000',
  //         source: 'www.source.com',
  //         class: 'included'
  //       },
  //       {
  //         icon: 'transmission',
  //         component: 'Transmission',
  //         description:
  //           'If your vehicle is giving off strange vibrations or noises such as whining or grinding, gear slipping or lack of acceleration, transmission failure could be the cause. In the case of failure, your transmission protection includes all internal parts with the exception of cables and electrical components. Please see the policy for a full description.',
  //         averageCostMin: '1,300',
  //         averageCostMax: '3,400',
  //         source: 'www.source.com',
  //         class: 'included'
  //       },
  //       {
  //         icon: 'axle',
  //         component: 'Axles',
  //         description:
  //           'You may notice sudden clunking, sputtering while you accelerate, uncommon rumbling or vibrations as you steer, you likely have a worn or broken axle. In the case of axle failure, all the internal components such as the axle shafts and housing are covered. Please see the policy for a full description.',
  //         averageCostMin: '300',
  //         averageCostMax: '1,100',
  //         source: 'www.source.com',
  //         class: 'included'
  //       },
  //       {
  //         icon: 'ac',
  //         component: 'Air Conditioning',
  //         description: 'Typically a lack of power, contactor problems or even a faulty thermostat can cause A/C failure. In the case of failure, all A/C components including this, this and that are covered. Please see the policy for a full description.',
  //         averageCostMin: '200',
  //         averageCostMax: '800',
  //         source: 'www.source.com',
  //         class: 'included'
  //       },
  //       {
  //         icon: 'brakes',
  //         component: 'Brake Pads',
  //         description: 'If you hear a squealing sound as you brake, it is likely that your brake pads are worn. If you experience grinding, your rotors could be damaged as well. In the case of worn brakes, Coverage includes all four brake pads. Please see the policy for a full description.',
  //         averageCostMin: '200',
  //         averageCostMax: '600',
  //         source: 'www.source.com',
  //         class: 'included'
  //       },
  //       {
  //         icon: 'cooling',
  //         component: 'Cooling System',
  //         description: 'Sometimes simple debris can find its way into your cooling system, causing blockage and failure. In the case of failure, coverage on your cooling system includes the radiator and all other internal components. Please see the policy for a full description.',
  //         averageCostMin: '300',
  //         averageCostMax: '1,200',
  //         source: 'www.source.com',
  //         class: 'included'
  //       },
  //       {
  //         icon: 'electrical',
  //         component: 'Electrical',
  //         description: 'Electrical failure is commonly the result of a bad starter or battery. If your electrical system fails, coverage includes all electrical system components. Please see the policy for a full description.',
  //         averageCostMin: '75',
  //         averageCostMax: '150',
  //         source: 'www.source.com',
  //         class: 'included'
  //       },
  //       {
  //         icon: 'suspension',
  //         component: 'Suspension',
  //         description:
  //           'If you feel unstable or unbalanced while driving, your suspension system may be worn or broken. This can be dangerous as you can lose control of your car. In case of suspension failure, coverage includes all components such as your shocks and struts. Please see the policy for a full description.',
  //         averageCostMin: '500',
  //         averageCostMax: '2,000',
  //         source: 'www.source.com',
  //         class: 'included'
  //       }
  //     ]
  //   },
  //   {
  //     title: 'Ultimate Plan',
  //     subTitle: 'Most Comprehensive',
  //     monthlyCost: '150.00',
  //     averageSavings: '7,500.00',
  //     maxTerm: '5 Years or 60,000 Miles',
  //     image: '/assets/img/ultimate.png',
  //     planId: '',
  //     features: [
  //       {
  //         icon: 'engine',
  //         component: 'Engine',
  //         description:
  //           'Engine failure can be caused by numerous factors, the most common being overheating. In the case of an engine failure, your engine and all of its components will be covered. That includes crankshaft, pistons, timing belt and gears, intake and exhaust valves, and the oil pump. Please see the policy for a full description.',
  //         averageCostMin: '2,500',
  //         averageCostMax: '4,000',
  //         source: 'www.source.com',
  //         class: 'included'
  //       },
  //       {
  //         icon: 'transmission',
  //         component: 'Transmission',
  //         description:
  //           'If your vehicle is giving off strange vibrations or noises such as whining or grinding, gear slipping or lack of acceleration, transmission failure could be the cause. In the case of failure, your transmission protection includes all internal parts with the exception of cables and electrical components. Please see the policy for a full description.',
  //         averageCostMin: '1,300',
  //         averageCostMax: '3,400',
  //         source: 'www.source.com',
  //         class: 'included'
  //       },
  //       {
  //         icon: 'axle',
  //         component: 'Axles',
  //         description:
  //           'You may notice sudden clunking, sputtering while you accelerate, uncommon rumbling or vibrations as you steer, you likely have a worn or broken axle. In the case of axle failure, all the internal components such as the axle shafts and housing are covered. Please see the policy for a full description.',
  //         averageCostMin: '300',
  //         averageCostMax: '1,100',
  //         source: 'www.source.com',
  //         class: 'included'
  //       },
  //       {
  //         icon: 'ac',
  //         component: 'Air Conditioning',
  //         description: 'Typically a lack of power, contactor problems or even a faulty thermostat can cause A/C failure. In the case of failure, all A/C components including this, this and that are covered. Please see the policy for a full description.',
  //         averageCostMin: '200',
  //         averageCostMax: '800',
  //         source: 'www.source.com',
  //         class: 'included'
  //       },
  //       {
  //         icon: 'brakes',
  //         component: 'Brake Pads',
  //         description: 'If you hear a squealing sound as you brake, it is likely that your brake pads are worn. If you experience grinding, your rotors could be damaged as well. In the case of worn brakes, Coverage includes all four brake pads. Please see the policy for a full description.',
  //         averageCostMin: '200',
  //         averageCostMax: '600',
  //         source: 'www.source.com',
  //         class: 'included'
  //       },
  //       {
  //         icon: 'cooling',
  //         component: 'Cooling System',
  //         description: 'Sometimes simple debris can find its way into your cooling system, causing blockage and failure. In the case of failure, coverage on your cooling system includes the radiator and all other internal components. Please see the policy for a full description.',
  //         averageCostMin: '300',
  //         averageCostMax: '1,200',
  //         source: 'www.source.com',
  //         class: 'included'
  //       },
  //       {
  //         icon: 'electrical',
  //         component: 'Electrical',
  //         description: 'Electrical failure is commonly the result of a bad starter or battery. If your electrical system fails, coverage includes all electrical system components. Please see the policy for a full description.',
  //         averageCostMin: '75',
  //         averageCostMax: '150',
  //         source: 'www.source.com',
  //         class: 'included'
  //       },
  //       {
  //         icon: 'suspension',
  //         component: 'Suspension',
  //         description:
  //           'If you feel unstable or unbalanced while driving, your suspension system may be worn or broken. This can be dangerous as you can lose control of your car. In case of suspension failure, coverage includes all components such as your shocks and struts. Please see the policy for a full description.',
  //         averageCostMin: '500',
  //         averageCostMax: '2,000',
  //         source: 'www.source.com',
  //         class: 'included'
  //       },
  //       {
  //         icon: 'fuel',
  //         component: 'Fuel',
  //         description:
  //           'Engine overheating or a bad fuel pump can lead to unusual noises, sputtering, backfire and the sense that you have run out of gas. In the even of a fuel system failure, coverage includes all fuel system components including your fuel pump. Please see the policy for a full description.',
  //         averageCostMin: '250',
  //         averageCostMax: '1,000',
  //         source: 'www.source.com',
  //         class: 'included'
  //       },
  //       {
  //         icon: 'gaskets',
  //         component: 'Gaskets',
  //         description: 'A damanged head gasket can cause other vehicle problems such as coolant leaking into your engine. In the case of gasket failure, your coverage includes head gasket repair or replacement. Please see the policy for a full description.',
  //         averageCostMin: '1,200',
  //         averageCostMax: '1,500',
  //         source: 'www.source.com',
  //         class: 'included'
  //       },
  //       {
  //         icon: 'steering',
  //         component: 'Steering',
  //         description:
  //           'If you notice a significant loss in steering power (extremely hard to move the steering wheel), you likely have damage to your hydrolic system. In the case of steering failure, your coverage includes repair or replacement of your steering components. Please see the policy for a full description.',
  //         averageCostMin: '250',
  //         averageCostMax: '850',
  //         source: 'www.source.com',
  //         class: 'included'
  //       },
  //       {
  //         icon: 'turbo',
  //         component: 'Turbo',
  //         description: 'Slow acceleration, burning oil, excessive exhaust smoke or a loud whining noise may indicate turbo failure. I the case of turbo failure, your coverage includes the repair or replacement of your turbocharge assembly. Please see the policy for a full description.',
  //         averageCostMin: '5,200',
  //         averageCostMax: '5,800',
  //         source: 'www.source.com',
  //         class: 'included'
  //       }
  //     ]
  //   }
  // ];



  openModal(data: string) {
    this.emitOpenModal.emit(data);
  }

  constructor(private prismicService: PrismicService) { }

  ngOnInit() {
  }

  getHtml(content, vehicleObject = null) {
    return this.prismicService.getHtml(content, vehicleObject);
  }

}
