import { Component, OnInit, Input } from '@angular/core';
import { PrismicService } from 'src/app/_services/prismic.service';

@Component({
  selector: 'app-slice-text',
  templateUrl: './text-slice.component.html',
  styleUrls: ['./text-slice.component.scss']
})
export class TextSliceComponent implements OnInit {
  @Input() slice: any;
  @Input() vehicle: any;

  constructor(private prismicService: PrismicService) { }

  ngOnInit() {
  }

  getHtml(content, vehicleObject) {
    return this.prismicService.getHtml(content, vehicleObject);
  }

}
