import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PrismicService } from 'src/app/_services/prismic.service';
import { environment } from '../../../environments/environment'
import brandInfo from "../../../environments/brand.json";
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Vehicle } from 'src/app/_models/vehicle';
import { Lead } from 'src/app/_models/lead';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  leadData$: Observable<Lead>;
  leadSub: Subscription;
  @Input() component: any;
  @Input() vehicle: any;
  environment: any;
  brand: any;
  imageLink = "";
  div_background;
  show_for_guest_flow = false;
  show_for_oid_flow = false;
  hideImage = true;
  div_height = 0;

  constructor(private prismicService: PrismicService,
    private store: Store<{
      lead: Lead
    }>) { }

  ngOnInit() {
    this.leadData$ = this.store.select((store) => store.lead);

    this.environment = environment;
    this.brand = brandInfo;

    const timezoneOffset = (new Date().getTimezoneOffset() / 60);

    this.imageLink = environment.apiUrl + '/v1/api/countdowntimer?brand=' + brandInfo.brand;

    this.imageLink += "&offsetHours=" + (timezoneOffset - 4).toString(); // Add time for CST, PST, etc

    this.imageLink += "&lang=" + (sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language)

    if (this.component.primary.background) {
      this.imageLink += "&background=" + encodeURIComponent(this.component.primary.background);
      this.div_background = this.component.primary.background;
    } else {
      this.imageLink += "&background=black";
      this.div_background = "black";
    }

    if (this.component.primary.color) {
      this.imageLink += "&color=" + encodeURIComponent(this.component.primary.color);
    } else {
      this.imageLink += "&color=white";
    }

    if (this.component.primary.start_showing) {
      this.imageLink += "&startshowing=" + this.component.primary.start_showing;
    }

    if (this.component.primary.headline) {
      this.imageLink += "&headline=" + encodeURIComponent(this.component.primary.headline);
    } else {
      this.imageLink += "&headline=!HEADLINE%20NOT%20SET!";
    }

    if (this.component.primary.expired_headline) {
      this.imageLink += "&headlineended=" + encodeURIComponent(this.component.primary.expired_headline);
    } else {
      this.imageLink += "&headlineended=!HEADLINE%20EXPIRED%20NOT%20SET!";
    }

    if (this.component.primary.timer_width) {
      this.imageLink += "&width=" + this.component.primary.timer_width;
    }

    if (this.component.primary.timer_height) {
      this.imageLink += "&height=" + this.component.primary.timer_height;
    }

    if (this.component.primary.hide_on_expire) {
      this.imageLink += "&hideonexpire=" + this.component.primary.hide_on_expire;
    }

    if (this.component.primary.add_days) {
      this.imageLink += "&addDays=" + this.component.primary.add_days;
    }

    if (this.component.primary.show_for_guest_flow && this.component.primary.guest_flow_expire_date) {
      let tempDate = this.component.primary.guest_flow_expire_date.split("-")
      tempDate = tempDate[1] + '-' + tempDate[2] + '-' + tempDate[0]; // Fix format for Dates form Prismic

      this.imageLink += "&expireDate=" + tempDate;
      this.show_for_guest_flow = true;
      this.show_for_oid_flow = false;
    }

    this.leadSub = this.leadData$.subscribe(leadData => {
      if (leadData && leadData.vehicle) {
        this.imageLink = this.imageLink.split("&expireDate=")[0]; // Overide expireDate if leadData exists
        this.imageLink += "&expireDate=" + leadData.vehicle[this.component.primary.expired_date_variable];
        this.show_for_guest_flow = false;
        if (this.component.primary.show_for_guest_flow) {
          this.show_for_oid_flow = false;
        } else {
          this.show_for_oid_flow = true;
        }
      }
    })
    let scrollTimeout: any; 

    window.onscroll = () => {
      this.fixNav();
    
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
      
      scrollTimeout = setTimeout(() => {
        this.fixNav();
      }, 500);
    };
    
    this.fixNav();
  }
  

  fixNav() {

    let doubleNav = document.querySelector(".double-nav") as HTMLElement;
    let countdownTimer = document.querySelector(".countdown-timer-div") as HTMLElement;

    // Use getComputedStyle or offsetHeight to get the rendered height
    let doubleNavHeight = getComputedStyle(doubleNav).height || `${doubleNav.offsetHeight}px`;
  
    console.log("doubleNav height:", doubleNavHeight);
  
    if (countdownTimer) {
      countdownTimer.style.top = doubleNavHeight;
    }
  }

  ngOnDestroy(): void {
    this.leadSub.unsubscribe();
  }

  getHtml(content, vehicleObject) {
    return this.prismicService.getHtml(content, vehicleObject);
  }

  checkSize(e) {
    let image = e.currentTarget as HTMLImageElement
    if (image.height > 1) { // If image if is bigger than  1x1 (pixel shim), then show it
      this.div_height = image.height;
      this.hideImage = false;
      let timerSlug = document.querySelector("#timerSlug") as HTMLElement;
      if (timerSlug) {
        timerSlug.style.height = this.div_height + "px";
      }
    }
  }

}
