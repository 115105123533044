import { Actions, createEffect, ofType, Effect } from '@ngrx/effects';
import { EMPTY, throwError } from 'rxjs';
import { map, switchMap, mapTo, catchError } from 'rxjs/operators';
import { AccountService } from '../_services/account.service';
import { accountLoaded, accountUpdated, updateContactStateOn, updateBillingStateOn, updatePaymentStateOn, loadPaylink, noAccountFound, updateBillingInformation } from '../_actions/account.actions'
import * as ActionConfigurationManager from '.././_actions/config.actions';
import { hideInlineLoader, hideInlineLoaderContact, hideInlineLoaderPayment, hideInlineLoaderBilling, hideInlineLoaderBillingInformation } from '../_actions/inline-loader.actions'
import { Store } from '@ngrx/store';
import { OrderService } from '../_services/order.service';
import { PaymentService } from '../_services/payment-and-tax-service.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AccountID } from '../_models/account';
import { OktaService } from '../_services/okta.service';
import { oktaPasswordChanged, oktaPasswordChangedFailed, oktaPasswordSet, oktaPasswordSetFailed, registerOktaUserSuccess, registrationTokenInvalid, registrationTokenUsed, registrationTokenValid, resetOktaUser, setOktaUser } from '../_actions/okta.actions';
import { OktaUser } from '../_models/okta';

@Injectable()

export class OktaEffects {


    public checkRegistrationToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.CHECK_REGISTRATION_TOKEN),
            switchMap((action: any) => this.oktaService.check_okta_registration_token(action)
                .pipe(
                    map(response => {
                        console.log('Response from okta', response)
                        if (response.data.hasSetPassword) {
                            return registrationTokenInvalid()
                        } else {
                            let object: OktaUser = {
                                id: response.data.oktaId,
                                login: response.data.login,
                                access_token: ''
                            }
                            this.store.dispatch(setOktaUser(object))
                            return registrationTokenValid()
                        }
                        
                    }),
                    catchError(() => EMPTY)
                )
            )
        )
    )

    public setPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.SET_OKTA_PASSWORD),
            switchMap((action: any) => this.oktaService.set_okta_password(action)
                .pipe(
                    map(response => {
                        if (response.success) {
                            return oktaPasswordSet()
                        } else {
                            return oktaPasswordSetFailed()
                        }
                        
                    }),
                    catchError(() => {
                        this.store.dispatch(oktaPasswordSetFailed())
                        return EMPTY
                    })
                )
            )
        )
    )

    public changePassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.CHANGE_OKTA_PASSWORD),
            switchMap((action: any) => this.oktaService.change_okta_password(action)
                .pipe(
                    map(response => {
                        return oktaPasswordChanged()
                    }),
                    catchError((error) => {
                        console.log(error)
                        this.store.dispatch(oktaPasswordChangedFailed())
                        return throwError(error)
                    })
                )
            )
        )
    )

    public signOut$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.OKTA_SIGN_OUT),
            switchMap((action: any) => this.oktaService.sign_out(action)
                .pipe(
                    map(response => {
                        return resetOktaUser()
                    }),
                    catchError(() => EMPTY)
                ))
        )
    )
    
    public registerUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionConfigurationManager.REGISTER_OKTA_USER),
            switchMap((action: any) => this.oktaService.register(action)
                .pipe(
                    map(response => {
                        return registerOktaUserSuccess()
                    }),
                    catchError(() => EMPTY)
                ))
        )
    )
    

    constructor(
        private actions$: Actions,
        private oktaService: OktaService,
        private store: Store<{}>,
    ) {}
}
