import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { success, ratesError, clear, invalidState, productExist, lamboNeverEligible, notWithinSixtyDays } from '../_actions/modal-component.actions';


const _modalComponentStateReducer = createReducer(initialState,
    on(success, (state, payload) => {
        sessionStorage.setItem('modalComponentState', 'success')
        return 'success';
    }),
    on(ratesError, (state, payload) => {
        sessionStorage.setItem('modalComponentState', 'error')
        return 'error';
    }),
    on(clear, (state, payload) => {
        sessionStorage.setItem('modalComponentState', null)
        return null;
    }),
    on(invalidState, (state, payload) => {
        sessionStorage.setItem('modalComponentState', 'invalidState')
        return 'invalidState'
    }),
    on(productExist, (state, payload) => {
        sessionStorage.setItem('modalComponentState', 'productExist')
        return 'productExist'
    }),
    on(lamboNeverEligible, (state, payload) => {
        sessionStorage.setItem('modalComponentState', 'lamboNeverEligible')
        return 'lamboNeverEligible'
    }),
    on(notWithinSixtyDays, (state, payload) => {
        sessionStorage.setItem('modalComponentState', 'notWithinSixtyDays')
        return 'notWithinSixtyDays'
    })
);


export function modalComponentStateReducer(state, action) {
    return _modalComponentStateReducer(state, action);
}
