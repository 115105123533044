<footer *ngIf="isFooterVisible">
    <div class="container text-center text-md-left">
      <div class="row justify-content-md-center">
        <div *ngIf="currentBrand !== 'NCESI'" class="col-md-6 footer-group-contact-us checkout">
          <ul class="list-unstyled">
            <li>
              <div class="give_us_a_call">
                Give Us A Call
              </div>
            </li>
            <li>
              <div tabindex="0">
                <mdb-icon fas icon="phone"></mdb-icon>{{ branding.cs_phone_number }}
              </div>
            </li>
          </ul>
        </div>
        <div *ngIf="currentBrand === 'NCESI'" class="col-md-6 footer-group-contact-us checkout ncesi">
          {{ 'FOOTER.LET_US_ANSWER' | translate }} - {{ branding.cs_phone_number }}
        </div>
      </div>
    </div>
  </footer>
