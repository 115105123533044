import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Observable, filter } from 'rxjs';
import { AppConfigService } from 'src/app/appconfig/appconfig.service';
import { ModalInterstitialComponent } from 'src/app/modal-interstitial-popup/modal-Interstitial-popup.component';
import { Lead } from 'src/app/_models/lead';
import { HomeService } from 'src/app/_services/home.service';
import { PrismicService } from 'src/app/_services/prismic.service';
import brandInfo from "src/environments/brand.json";
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-footer-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  public lead$: Observable<Lead>;
  modalRef: MDBModalRef;
  footer: any;
  branding: any;
  brandInfo: any;
  isFooterVisible = true;
  useLegalFolder = true;
  dealerName = null;
  isGMBrand = false;
  currentYear: any;
  thisLang = sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language;
  dealerSub: any;
  showPhoneNumber: boolean = false;
  constructor(
    private modalService: MDBModalService,
    private home: HomeService,
    private appConfig: AppConfigService,
    private prismicService: PrismicService,
    private store: Store<{
      lead: Lead,
      dealer: any
    }>,
    private router: Router,
  ) { }

  ngOnInit() {

    const year = new Date();
    this.currentYear = year.getFullYear();

    this.brandInfo = brandInfo;

    if (brandInfo.brand === 'NCESI') { // Nissan Canada different legal link structure
      this.useLegalFolder = false;
    }

    if(brandInfo.brand === 'GMC' || brandInfo.brand === 'Chevrolet' || brandInfo.brand === 'Cadillac' || brandInfo.brand === 'Buick') {
      this.isGMBrand = true;
    }

    if (brandInfo.showDealerName) {
      this.lead$ = this.store.select((store) => store.lead);
      this.lead$.subscribe((lead) => {
        if (lead && lead.dealerName && sessionStorage.getItem('appFlowState') !== 'serviceDrive') {
          this.dealerName = lead.dealerName;
        } else {
          this.dealerName = ""
        }
      })
    }

    this.dealerSub = this.store.select(store => store.dealer).subscribe(data => {
      if (data && sessionStorage.getItem('appFlowState').includes('serviceDrive')) {
        if(data && data.data) {
          this.dealerName = data.data.name
        } else {
          this.dealerName = ""
        }
      }
    })

    this.home.getFooter(brandInfo.brand, this.thisLang).subscribe({
      next: (result) => {
        this.footer = result;
      },
      error: (error) => {
        this.isFooterVisible = false;
      }
    });

    this.appConfig.getSettings().subscribe({
      next: (result) => {
        this.branding = result;
      }, error: (err) => {
        this.isFooterVisible = false;
      }
    });

    setTimeout(() => {
      if (brandInfo.siteSecuritySeal !== '' && this.isFooterVisible) { // Make sure the Footer is visible and then Load Security Seal if available
        this.loadVroomSecuritySealTag();
      }
    }, 250);
  }

  checkYear(term) {
       return term.replace("CURRENT_YEAR", this.currentYear)
  }

  loadVroomSecuritySealTag(): void {
    const securitySealScript = document.createElement('script');
    securitySealScript.setAttribute('async', 'true');
    securitySealScript.setAttribute('src', brandInfo.siteSecuritySeal);
    document.body.appendChild(securitySealScript);
    document.getElementById('siteseal').appendChild(securitySealScript);
    var addTag = setInterval(() => {
      console.log('lookingup godaddy')
      const sitesealimg = document.querySelector('#siteseal img');
      if (sitesealimg) {
        sitesealimg.setAttribute('tabindex','0');
        console.log('found godaddy')
        clearInterval(addTag)
      }
    }, 250);
    setTimeout(() => {
      console.log('timed out godaddy')
      clearInterval(addTag);
    }, 10000) // clear setInterval after 10 seconds in case godaddy seal fails to load.
  }

  getHtml(content, vehicleObject = null) {
    return this.prismicService.getHtml(content, vehicleObject);
  }

  showInterstitial() {
    const modalOptions = {
      class: "modal-md",
      ignoreBackdropClick: true,
      containerClass: "",
      animated: true,
      data: {
        timeout: 30000,
        title: "Notice",
        link:"https://www.gmfinancial.com/en-us/privacy-and-security/california-privacy-rights.html",
        content:
          "You are now being directed to GM Financial. GM Protection is a wholly owned subsidiary of GM Financial.<br><br>If you haven't been redirected in 30 seconds, please click continue.",
      },
    };
    this.modalRef = this.modalService.show(ModalInterstitialComponent, modalOptions);
  }
}
