export class Plan {
    planName: string;
    referenceNumber: string;
    productName: string;
    ratesProductName: string;
    productCode: string;
    planIndex: number;
    termIndex: number;
    yearsMileage: string;
}

export class CommercialPlan {
  active: boolean;
  componentCoverage: {};
  coverageOverview: {};
  header: string;
  name: string;
  plan: {};
  planTerms: [];
  plansOverview: {};
  previousPlan: {};
  product: {};
  samplePDF: {};
  term: any;
}
