import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { loadRates, ratesLoaded, rehydrateRates, ratesLoadedFailed, loadRatesHappy, resetRates } from '../_actions/rates.actions';

const _ratesReducer = createReducer(initialState,
    on(loadRates, (state) => console.log('Load Rates', state)),
    on(loadRatesHappy, (state) => console.log('Load Rates Happy', state)),
    on(ratesLoaded, (state, payload) => {
      console.log('Rates Loaded Success',state, payload);
      // To add a lead to state, use spread operator
      window.sessionStorage.setItem('rates', JSON.stringify(payload));
      return payload;
    }),
    on(rehydrateRates, (state, payload) => {
      // console.log('Rates Rehydrated', state, payload)
      sessionStorage.setItem('rates', JSON.stringify(payload))
      return payload;
    }),
    on(ratesLoadedFailed, (state, payload) => {
      console.log('Rates Loaded Failed', state, payload)
      sessionStorage.setItem('rates', JSON.stringify(payload))
      return payload;
    }),
    on(resetRates, (state, payload) => {
      window.sessionStorage.removeItem('rates')
      return null
    })
);

export function ratesReducer(state, action) {
    return _ratesReducer(state, action);
}
