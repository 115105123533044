import { createReducer, on } from '@ngrx/store';
import { loadRegistrationState, updateRegistration, rehydrateRegistration, clearRegistration } from '../_actions/registration.actions'

export const initialState = null;


const _registrationReducer = createReducer(initialState,
    on(loadRegistrationState, (state, payload) => {
      console.log('Registration State Loaded',state, payload);
      window.sessionStorage.setItem('registration', JSON.stringify(payload));
      return payload;
    }),
    on(updateRegistration, (state, payload) => {
      console.log('Registration Updated', state, payload);
      window.sessionStorage.setItem('registration', JSON.stringify(payload));
      return payload;
    }),
    on(rehydrateRegistration, (state, payload) => {
      console.log('Rehydrate Registration', state, payload);
      sessionStorage.setItem('registration', JSON.stringify(payload))
      return payload
    }),
    on(clearRegistration, (state, payload) => {
      sessionStorage.removeItem('registration')
      return null
    })
);

export function registrationReducer(state, action) {
    return _registrationReducer(state, action);
}
