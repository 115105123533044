export let buickvsc = [{
  productName: "BUICK VSC PROTECTION",
  productNumber: "01t55000005aiwvAAA",
  productCode: "BRVD",
  productFormNumber: null,
  productExternalId: "e7dfaf12-ec6f-45ab-9a7c-bcf7838b3996",
  productSfId: "01t55000005aiwvAAA",
  statusCode: 100,
  message: "Successfully retrieved product plans for this product",
  surcharge: {
    surchargeOption: [
      {
        surchargeAmount: "250.0",
        key: "41",
        type: "LIGHT DUTY COMMERCIAL USE COVERAGE",
        mandatory: false,
        label: {
          enLabel: "LIGHT DUTY COMMERCIAL USE COVERAGE",
          frLabel: null,
        },
      },
    ],
  },
  productPlanList: [
    {
      planName: "PLATINUM COVERAGE",
      planNumber: "a0555000008uw0BAAQ",
      referenceNumber1: "BUICK1",
      planCode: "BUICK1",
      planExternalId: "2ce1a05a-a3f0-4860-b58e-88717d068905",
      deductible: "100.0",
      planTermSKUList: [
        {
          planTermSKUName: "4 YEARS, 100,000 MILES",
          planTermSKURate: "1455.00",
          planTermSKUNumber: "a0655000005FOarAAG",
          productSkuSfId: "a0755000007x4qNAAQ",
          productPlanSkuId: "179587",
          contractDealerCost: "1455.0",
          productSkuTerm: 48.0,
          productSkuMileage: 100000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "5 YEARS, 60,000 MILES",
          planTermSKURate: "1339.00",
          planTermSKUNumber: "a0655000005FOasAAG",
          productSkuSfId: "a0755000007x4qOAAQ",
          productPlanSkuId: "179588",
          contractDealerCost: "1339.0",
          productSkuTerm: 60.0,
          productSkuMileage: 60000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "5 YEARS, 75,000 MILES",
          planTermSKURate: "1418.00",
          planTermSKUNumber: "a0655000005FOatAAG",
          productSkuSfId: "a0755000007x4qPAAQ",
          productPlanSkuId: "179589",
          contractDealerCost: "1418.0",
          productSkuTerm: 60.0,
          productSkuMileage: 75000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "5 YEARS, 100,000 MILES",
          planTermSKURate: "1526.00",
          planTermSKUNumber: "a0655000005FOauAAG",
          productSkuSfId: "a0755000007x4qQAAQ",
          productPlanSkuId: "179590",
          contractDealerCost: "1526.0",
          productSkuTerm: 60.0,
          productSkuMileage: 100000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "6 YEARS, 60,000 MILES",
          planTermSKURate: "1391.00",
          planTermSKUNumber: "a0655000005FOavAAG",
          productSkuSfId: "a0755000007x4qRAAQ",
          productPlanSkuId: "179591",
          contractDealerCost: "1391.0",
          productSkuTerm: 72.0,
          productSkuMileage: 60000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "6 YEARS, 72,000 MILES",
          planTermSKURate: "1490.00",
          planTermSKUNumber: "a0655000005FOawAAG",
          productSkuSfId: "a0755000007x4qSAAQ",
          productPlanSkuId: "179592",
          contractDealerCost: "1490.0",
          productSkuTerm: 72.0,
          productSkuMileage: 72000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "6 YEARS, 85,000 MILES",
          planTermSKURate: "1535.00",
          planTermSKUNumber: "a0655000005FOaxAAG",
          productSkuSfId: "a0755000007x4qTAAQ",
          productPlanSkuId: "179593",
          contractDealerCost: "1535.0",
          productSkuTerm: 72.0,
          productSkuMileage: 85000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "6 YEARS, 100,000 MILES",
          planTermSKURate: "1625.00",
          planTermSKUNumber: "a0655000005FOayAAG",
          productSkuSfId: "a0755000007x4qUAAQ",
          productPlanSkuId: "179594",
          contractDealerCost: "1625.0",
          productSkuTerm: 72.0,
          productSkuMileage: 100000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "6 YEARS, 120,000 MILES",
          planTermSKURate: "1715.00",
          planTermSKUNumber: "a0655000005FOazAAG",
          productSkuSfId: "a0755000007x4qVAAQ",
          productPlanSkuId: "179595",
          contractDealerCost: "1715.0",
          productSkuTerm: 72.0,
          productSkuMileage: 120000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "7 YEARS, 70,000 MILES",
          planTermSKURate: "1542.00",
          planTermSKUNumber: "a0655000005FOb0AAG",
          productSkuSfId: "a0755000007x4qWAAQ",
          productPlanSkuId: "179596",
          contractDealerCost: "1542.0",
          productSkuTerm: 84.0,
          productSkuMileage: 70000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "7 YEARS, 100,000 MILES",
          planTermSKURate: "1696.00",
          planTermSKUNumber: "a0655000005FOb1AAG",
          productSkuSfId: "a0755000007x4qXAAQ",
          productPlanSkuId: "179597",
          contractDealerCost: "1696.0",
          productSkuTerm: 84.0,
          productSkuMileage: 100000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "7 YEARS, 120,000 MILES",
          planTermSKURate: "1799.00",
          planTermSKUNumber: "a0655000005FOb2AAG",
          productSkuSfId: "a0755000007x4qYAAQ",
          productPlanSkuId: "179598",
          contractDealerCost: "1799.0",
          productSkuTerm: 84.0,
          productSkuMileage: 120000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "8 YEARS, 80,000 MILES",
          planTermSKURate: "1651.00",
          planTermSKUNumber: "a0655000005FOb3AAG",
          productSkuSfId: "a0755000007x4qZAAQ",
          productPlanSkuId: "179599",
          contractDealerCost: "1651.0",
          productSkuTerm: 96.0,
          productSkuMileage: 80000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "8 YEARS, 100,000 MILES",
          planTermSKURate: "1767.00",
          planTermSKUNumber: "a0655000005FOb4AAG",
          productSkuSfId: "a0755000007x4qaAAA",
          productPlanSkuId: "179600",
          contractDealerCost: "1767.0",
          productSkuTerm: 96.0,
          productSkuMileage: 100000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "8 YEARS, 120,000 MILES",
          planTermSKURate: "1882.00",
          planTermSKUNumber: "a0655000005FOb5AAG",
          productSkuSfId: "a0755000007x4qbAAA",
          productPlanSkuId: "179601",
          contractDealerCost: "1882.0",
          productSkuTerm: 96.0,
          productSkuMileage: 120000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "10 YEARS, 100,000 MILES",
          planTermSKURate: "1979.00",
          planTermSKUNumber: "a0655000005FOb6AAG",
          productSkuSfId: "a0755000007x4qcAAA",
          productPlanSkuId: "179602",
          contractDealerCost: "1979.0",
          productSkuTerm: 120.0,
          productSkuMileage: 100000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "10 YEARS, 120,000 MILES",
          planTermSKURate: "2133.00",
          planTermSKUNumber: "a0655000005FOb7AAG",
          productSkuSfId: "a0755000007x4qdAAA",
          productPlanSkuId: "179603",
          contractDealerCost: "2133.0",
          productSkuTerm: 120.0,
          productSkuMileage: 120000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
      ],
    },
    {
      planName: "POWERTRAIN PLUS COVERAGE",
      planNumber: "a0555000008uw0CAAQ",
      referenceNumber1: "BUICK2",
      planCode: "BUICKVSCPOWERPLUS",
      planExternalId: "bedda5a1-7011-4f17-b4e9-589f3b006252",
      deductible: "100.0",
      planTermSKUList: [
        {
          planTermSKUName: "4 YEARS, 48,000 MILES",
          planTermSKURate: "1335.00",
          planTermSKUNumber: "a0655000005FObBAAW",
          productSkuSfId: "a0755000007x4qhAAA",
          productPlanSkuId: "179607",
          contractDealerCost: "1335.0",
          productSkuTerm: 48.0,
          productSkuMileage: 48000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "4 YEARS, 60,000 MILES",
          planTermSKURate: "1351.00",
          planTermSKUNumber: "a0655000005FObCAAW",
          productSkuSfId: "a0755000007x4qiAAA",
          productPlanSkuId: "179608",
          contractDealerCost: "1351.0",
          productSkuTerm: 48.0,
          productSkuMileage: 60000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "4 YEARS, 100,000 MILES",
          planTermSKURate: "1548.00",
          planTermSKUNumber: "a0655000005FObDAAW",
          productSkuSfId: "a0755000007x4qjAAA",
          productPlanSkuId: "179609",
          contractDealerCost: "1548.0",
          productSkuTerm: 48.0,
          productSkuMileage: 100000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "5 YEARS, 60,000 MILES",
          planTermSKURate: "1399.00",
          planTermSKUNumber: "a0655000005FObEAAW",
          productSkuSfId: "a0755000007x4qkAAA",
          productPlanSkuId: "179610",
          contractDealerCost: "1399.0",
          productSkuTerm: 60.0,
          productSkuMileage: 60000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "5 YEARS, 75,000 MILES",
          planTermSKURate: "1500.00",
          planTermSKUNumber: "a0655000005FObFAAW",
          productSkuSfId: "a0755000007x4qlAAA",
          productPlanSkuId: "179611",
          contractDealerCost: "1500.0",
          productSkuTerm: 60.0,
          productSkuMileage: 75000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "5 YEARS, 100,000 MILES",
          planTermSKURate: "1639.00",
          planTermSKUNumber: "a0655000005FObGAAW",
          productSkuSfId: "a0755000007x4qmAAA",
          productPlanSkuId: "179612",
          contractDealerCost: "1639.0",
          productSkuTerm: 60.0,
          productSkuMileage: 100000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "6 YEARS, 60,000 MILES",
          planTermSKURate: "1466.00",
          planTermSKUNumber: "a0655000005FObHAAW",
          productSkuSfId: "a0755000007x4qnAAA",
          productPlanSkuId: "179613",
          contractDealerCost: "1466.0",
          productSkuTerm: 72.0,
          productSkuMileage: 60000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "6 YEARS, 72,000 MILES",
          planTermSKURate: "1593.00",
          planTermSKUNumber: "a0655000005FObIAAW",
          productSkuSfId: "a0755000007x4qoAAA",
          productPlanSkuId: "179614",
          contractDealerCost: "1593.0",
          productSkuTerm: 72.0,
          productSkuMileage: 72000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "6 YEARS, 85,000 MILES",
          planTermSKURate: "1651.00",
          planTermSKUNumber: "a0655000005FObJAAW",
          productSkuSfId: "a0755000007x4qpAAA",
          productPlanSkuId: "179615",
          contractDealerCost: "1651.0",
          productSkuTerm: 72.0,
          productSkuMileage: 85000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "6 YEARS, 100,000 MILES",
          planTermSKURate: "1767.00",
          planTermSKUNumber: "a0655000005FObKAAW",
          productSkuSfId: "a0755000007x4qqAAA",
          productPlanSkuId: "179616",
          contractDealerCost: "1767.0",
          productSkuTerm: 72.0,
          productSkuMileage: 100000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "6 YEARS, 120,000 MILES",
          planTermSKURate: "1882.00",
          planTermSKUNumber: "a0655000005FObLAAW",
          productSkuSfId: "a0755000007x4qrAAA",
          productPlanSkuId: "179617",
          contractDealerCost: "1882.0",
          productSkuTerm: 72.0,
          productSkuMileage: 120000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "7 YEARS, 70,000 MILES",
          planTermSKURate: "1659.00",
          planTermSKUNumber: "a0655000005FObMAAW",
          productSkuSfId: "a0755000007x4qsAAA",
          productPlanSkuId: "179618",
          contractDealerCost: "1659.0",
          productSkuTerm: 84.0,
          productSkuMileage: 70000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "7 YEARS, 100,000 MILES",
          planTermSKURate: "1858.00",
          planTermSKUNumber: "a0655000005FObNAAW",
          productSkuSfId: "a0755000007x4qtAAA",
          productPlanSkuId: "179619",
          contractDealerCost: "1858.0",
          productSkuTerm: 84.0,
          productSkuMileage: 100000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "7 YEARS, 120,000 MILES",
          planTermSKURate: "1990.00",
          planTermSKUNumber: "a0655000005FObOAAW",
          productSkuSfId: "a0755000007x4quAAA",
          productPlanSkuId: "179620",
          contractDealerCost: "1990.0",
          productSkuTerm: 84.0,
          productSkuMileage: 120000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "8 YEARS, 80,000 MILES",
          planTermSKURate: "1800.00",
          planTermSKUNumber: "a0655000005FObPAAW",
          productSkuSfId: "a0755000007x4qvAAA",
          productPlanSkuId: "179621",
          contractDealerCost: "1800.0",
          productSkuTerm: 96.0,
          productSkuMileage: 80000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "8 YEARS, 100,000 MILES",
          planTermSKURate: "1949.00",
          planTermSKUNumber: "a0655000005FObQAAW",
          productSkuSfId: "a0755000007x4qwAAA",
          productPlanSkuId: "179622",
          contractDealerCost: "1949.0",
          productSkuTerm: 96.0,
          productSkuMileage: 100000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "8 YEARS, 120,000 MILES",
          planTermSKURate: "2097.00",
          planTermSKUNumber: "a0655000005FObRAAW",
          productSkuSfId: "a0755000007x4qxAAA",
          productPlanSkuId: "179623",
          contractDealerCost: "2097.0",
          productSkuTerm: 96.0,
          productSkuMileage: 120000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "10 YEARS, 100,000 MILES",
          planTermSKURate: "2221.00",
          planTermSKUNumber: "a0655000005FObSAAW",
          productSkuSfId: "a0755000007x4qyAAA",
          productPlanSkuId: "179624",
          contractDealerCost: "2221.0",
          productSkuTerm: 120.0,
          productSkuMileage: 100000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "10 YEARS, 120,000 MILES",
          planTermSKURate: "2420.00",
          planTermSKUNumber: "a0655000005FObTAAW",
          productSkuSfId: "a0755000007x4qzAAA",
          productPlanSkuId: "179625",
          contractDealerCost: "2420.0",
          productSkuTerm: 120.0,
          productSkuMileage: 120000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
      ],
    },
    {
      planName: "POWERTRAIN COVERAGE",
      planNumber: "a0555000008uw0BAAQ",
      referenceNumber1: "BUICK3",
      planCode: "BUICK3",
      planExternalId: "2ce1a05a-a3f0-4860-b58e-88717d068905",
      deductible: "100.0",
      planTermSKUList: [
        {
          planTermSKUName: "4 YEARS, 100,000 MILES",
          planTermSKURate: "1455.00",
          planTermSKUNumber: "a0655000005FOarAAG",
          productSkuSfId: "a0755000007x4qNAAQ",
          productPlanSkuId: "179587",
          contractDealerCost: "1455.0",
          productSkuTerm: 48.0,
          productSkuMileage: 100000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "5 YEARS, 60,000 MILES",
          planTermSKURate: "1339.00",
          planTermSKUNumber: "a0655000005FOasAAG",
          productSkuSfId: "a0755000007x4qOAAQ",
          productPlanSkuId: "179588",
          contractDealerCost: "1339.0",
          productSkuTerm: 60.0,
          productSkuMileage: 60000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "5 YEARS, 75,000 MILES",
          planTermSKURate: "1418.00",
          planTermSKUNumber: "a0655000005FOatAAG",
          productSkuSfId: "a0755000007x4qPAAQ",
          productPlanSkuId: "179589",
          contractDealerCost: "1418.0",
          productSkuTerm: 60.0,
          productSkuMileage: 75000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "5 YEARS, 100,000 MILES",
          planTermSKURate: "1526.00",
          planTermSKUNumber: "a0655000005FOauAAG",
          productSkuSfId: "a0755000007x4qQAAQ",
          productPlanSkuId: "179590",
          contractDealerCost: "1526.0",
          productSkuTerm: 60.0,
          productSkuMileage: 100000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "6 YEARS, 60,000 MILES",
          planTermSKURate: "1391.00",
          planTermSKUNumber: "a0655000005FOavAAG",
          productSkuSfId: "a0755000007x4qRAAQ",
          productPlanSkuId: "179591",
          contractDealerCost: "1391.0",
          productSkuTerm: 72.0,
          productSkuMileage: 60000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "6 YEARS, 72,000 MILES",
          planTermSKURate: "1490.00",
          planTermSKUNumber: "a0655000005FOawAAG",
          productSkuSfId: "a0755000007x4qSAAQ",
          productPlanSkuId: "179592",
          contractDealerCost: "1490.0",
          productSkuTerm: 72.0,
          productSkuMileage: 72000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "6 YEARS, 85,000 MILES",
          planTermSKURate: "1535.00",
          planTermSKUNumber: "a0655000005FOaxAAG",
          productSkuSfId: "a0755000007x4qTAAQ",
          productPlanSkuId: "179593",
          contractDealerCost: "1535.0",
          productSkuTerm: 72.0,
          productSkuMileage: 85000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "6 YEARS, 100,000 MILES",
          planTermSKURate: "1625.00",
          planTermSKUNumber: "a0655000005FOayAAG",
          productSkuSfId: "a0755000007x4qUAAQ",
          productPlanSkuId: "179594",
          contractDealerCost: "1625.0",
          productSkuTerm: 72.0,
          productSkuMileage: 100000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "6 YEARS, 120,000 MILES",
          planTermSKURate: "1715.00",
          planTermSKUNumber: "a0655000005FOazAAG",
          productSkuSfId: "a0755000007x4qVAAQ",
          productPlanSkuId: "179595",
          contractDealerCost: "1715.0",
          productSkuTerm: 72.0,
          productSkuMileage: 120000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "7 YEARS, 70,000 MILES",
          planTermSKURate: "1542.00",
          planTermSKUNumber: "a0655000005FOb0AAG",
          productSkuSfId: "a0755000007x4qWAAQ",
          productPlanSkuId: "179596",
          contractDealerCost: "1542.0",
          productSkuTerm: 84.0,
          productSkuMileage: 70000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "7 YEARS, 100,000 MILES",
          planTermSKURate: "1696.00",
          planTermSKUNumber: "a0655000005FOb1AAG",
          productSkuSfId: "a0755000007x4qXAAQ",
          productPlanSkuId: "179597",
          contractDealerCost: "1696.0",
          productSkuTerm: 84.0,
          productSkuMileage: 100000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "7 YEARS, 120,000 MILES",
          planTermSKURate: "1799.00",
          planTermSKUNumber: "a0655000005FOb2AAG",
          productSkuSfId: "a0755000007x4qYAAQ",
          productPlanSkuId: "179598",
          contractDealerCost: "1799.0",
          productSkuTerm: 84.0,
          productSkuMileage: 120000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "8 YEARS, 80,000 MILES",
          planTermSKURate: "1651.00",
          planTermSKUNumber: "a0655000005FOb3AAG",
          productSkuSfId: "a0755000007x4qZAAQ",
          productPlanSkuId: "179599",
          contractDealerCost: "1651.0",
          productSkuTerm: 96.0,
          productSkuMileage: 80000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "8 YEARS, 100,000 MILES",
          planTermSKURate: "1767.00",
          planTermSKUNumber: "a0655000005FOb4AAG",
          productSkuSfId: "a0755000007x4qaAAA",
          productPlanSkuId: "179600",
          contractDealerCost: "1767.0",
          productSkuTerm: 96.0,
          productSkuMileage: 100000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "8 YEARS, 120,000 MILES",
          planTermSKURate: "1882.00",
          planTermSKUNumber: "a0655000005FOb5AAG",
          productSkuSfId: "a0755000007x4qbAAA",
          productPlanSkuId: "179601",
          contractDealerCost: "1882.0",
          productSkuTerm: 96.0,
          productSkuMileage: 120000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "10 YEARS, 100,000 MILES",
          planTermSKURate: "1979.00",
          planTermSKUNumber: "a0655000005FOb6AAG",
          productSkuSfId: "a0755000007x4qcAAA",
          productPlanSkuId: "179602",
          contractDealerCost: "1979.0",
          productSkuTerm: 120.0,
          productSkuMileage: 100000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
        {
          planTermSKUName: "10 YEARS, 120,000 MILES",
          planTermSKURate: "2133.00",
          planTermSKUNumber: "a0655000005FOb7AAG",
          productSkuSfId: "a0755000007x4qdAAA",
          productPlanSkuId: "179603",
          contractDealerCost: "2133.0",
          productSkuTerm: 120.0,
          productSkuMileage: 120000.0,
          paymentTerms: [
            "36 months",
            "30 months",
            "24 months",
            "18 months",
            "12 months",
            "6 months",
            "1 payment",
          ],
        },
      ],
    }
  ],
}];
