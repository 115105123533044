import { Component, OnInit, Input } from '@angular/core';
import { PrismicService } from 'src/app/_services/prismic.service';

@Component({
  selector: 'app-component-hero-body',
  templateUrl: './hero-body.component.html',
  styleUrls: ['./hero-body.component.scss']
})
export class HeroBodyComponent implements OnInit {


  @Input() public component: any;
  @Input() public text_color: any;
  @Input('vehicleObject') public vehicle: any;

  constructor(private prismicService: PrismicService) { }

  ngOnInit() {
  }

  getHtml(content, vehicleObject) {
    return this.prismicService.getHtml(content, vehicleObject);
  }
}
