import { createReducer, on } from '@ngrx/store';
import { cartTaxTokenRefresh, cartTaxTokenRefreshSuccess, checkoutAVSTokenRefresh, checkoutAVSTokenRefreshSuccess, purchaseContractTokenRefresh, purchaseContractTokenRefreshSuccess } from '../_actions/token-refresh.actions';

export const initialState = null;

const _tokenRefreshReducer = createReducer(initialState,
    on(cartTaxTokenRefresh, (state, payload) => {
        return state;
    }),
    on(cartTaxTokenRefreshSuccess, (state) => {
        return 'Token refreshed on cart page';
    }),
    on(checkoutAVSTokenRefresh, (state, payload) => {
        return state;
    }),
    on(checkoutAVSTokenRefreshSuccess, (state) => {
        return 'Token refreshed on checkout page - AVS';
    }),
    on(purchaseContractTokenRefresh, (state, payload) => {
        return state;
    }),
    on(purchaseContractTokenRefreshSuccess, (state, payload) => {
        return 'Token refreshed on checkout page - purchaseContract';
    })
);

export function tokenRefreshReducer(state, action) {
    return _tokenRefreshReducer(state, action);
}