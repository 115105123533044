<div class="row">
    <div class="col-12">
        <div class="container">
            <div class="pl-4 pr-4">
                <div class="d-flex {{text_align == 'center' ? 'justify-content-center':'justify-content-start'}}">
                    <div tabindex="0" class="disclaimer inline pb-4 pt-3" [ngStyle]="getStyle(component)" [innerHTML]="getHtml(component.primary.disclaimer, vehicle) | safeHtml">
                    </div>
                    <div *ngIf="component.primary.disclaimer_subscript" [attr.aria-label]="dislaimerText" role="tooltip" class="disclaimer_subscript" tabindex="0" (click)="showNotification(component.primary?.disclaimer_text)">
                    {{component.primary.disclaimer_subscript[0].text}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
