import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/appconfig/appconfig.service';
import { HomeService } from 'src/app/_services/home.service';
import brandInfo from "src/environments/brand.json";
@Component({
  selector: 'app-footer-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {
  footer: any;
  branding: any;
  isFooterVisible = true;
  constructor(private home: HomeService,private appConfig: AppConfigService,) { }

  ngOnInit() {

    this.home.getFooter(brandInfo.brand, sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language).subscribe({
      next: (result) => {
        this.footer = result;
      },
      error: (error) => {
        this.isFooterVisible = false;
      }
    });

    this.appConfig.getSettings().subscribe({
      next: (result) => {
        this.branding = result;
      }, error: (err) => {
        this.isFooterVisible = false;
      }
    });
  }

}
