import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, Observable, retry } from "rxjs";

import { GatewayService } from "./gateway.service";
import { LoggingService } from "./logging.service";
import { environment } from '../../environments/environment'
import brandInfo from "src/environments/brand.json";


@Injectable({
  providedIn: "root",
})



export class PaypalTransactionService {
  constructor(private httpClient: HttpClient, private gatewayService: GatewayService, private loggingService: LoggingService) {}

  voidPaypalTransaction(authorizationID): Observable<any> {
    const requestObj = {
        transactionId: authorizationID
    };
    return this.httpClient.post<any>(`${environment.apiUrl}/v1/api/paypal/void/`, requestObj)
  }


  paypalOauth2Token(): Observable<any> {
    const requestData = {
      brandName: brandInfo.brand,
    }
    return this.httpClient.post<any>(`${environment.apiUrl}/v1/api/paypal/paypalOauthToken`, requestData)
    .pipe(
      retry(1),
      catchError(this.loggingService.handleError)
  )}


  paypalSetupToken(requestObj): Observable<any> {
    const requestData = {
      accessToken: requestObj.accessToken,
      requestData: requestObj.requestData
    }
    return this.httpClient.post<any>(`${environment.apiUrl}/v1/api/paypal/paypalSetupToken`, requestData)
    .pipe(
      retry(1),
      catchError(this.loggingService.handleError)
  )}

  createPaypalPaymentToken(requestObj): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl}/v1/api/paypal/createPaypalPaymentToken/`, requestObj)
    .pipe(
      retry(1),
      catchError(this.loggingService.handleError)
  )}
}
