<div class="coverage-options-component">
  <form [formGroup]="coverageTermsForm">
    <div class="row">
      <div class="col-12 text-center pt-5 pb-3 pl-3 pr-3">
        <div [innerHTML]="getHtml(title, null)"></div>
        <div [innerHTML]="getHtml(subTitle, null)"></div>
      </div>
      <div class="w-100 select-coverage-deductible">
        <div class="p-2 pl-3 pr-3 text-center" *ngIf="isBrand !== 'Lambo'">
          <label>{{select_coverage_term}} <i aria-hidden="true" class="fa-info-circle fas"
              matTooltip="{{term_length_tooltip}}" [matTooltipPosition]="'above'"
              [matTooltipClass]="'tooltips'"></i></label>
          <div class="w-100">
            <select formControlName="terms" class="select-coverage-box" #selectCoverageBox>
              <option *ngFor="let term of planSelected?.planTerms" [value]="term.value">
                {{ checkTerm(term.label) }}
              </option>
            </select>
          </div>
        </div>
        <div *ngIf="multipleDeductibles && deductibles && (deductibles.length > 1  || deductibles.length == 1 && deductibles[0] !== 0)" class="p-2 pl-3 pr-3 text-center">
          <label>{{select_claim_deductible}}</label>
          <div class="w-100">
            <mat-button-toggle-group #coverageDeductiblesBox class="coverage-deductibles-box"
              formControlName="deductible" name="deductible" aria-label="Select a deductible">
              <mat-button-toggle *ngFor="let deductible of deductibles" [value]="deductible"
                (click)="chooseDeductible(deductible)">
                ${{ deductible }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <mat-accordion *ngIf="disappearingDeductible">
            <mat-expansion-panel [expanded]="disappearingDeductiblePanel">
              <div class="text-center disappearing_deductible mt-2 mb-2">
                {{ 'RATES_PAGE.PAY_LESS' | translate }} <i aria-hidden="true" class="fa-info-circle fas"
                  matTooltip="{{ 'RATES_PAGE.DISAPPEARING' | translate }}" [matTooltipPosition]="'above'"
                  [matTooltipClass]="'tooltips'"></i>
              </div>
              <div class="switch success-switch">
                <label>
                  {{ 'RATES_PAGE.NO' | translate }}
                  <input aria-labelledby="disappearingDeductible" id="disappearingDeductible"
                    [checked]="disappearingDeductibleValue" [value]="disappearingDeductibleValue" type="checkbox"
                    (click)="toggleDisappearing()">
                  <span class="lever"></span> {{ 'RATES_PAGE.YES' | translate }}
                </label>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
    <div class="text-center w-100 coverage-terms-container mt-3"
      *ngIf="this.isBrand !== 'Lambo' && termMiles !== '0'">
      <span class="estimated-coverage-text">{{ 'RATES_PAGE.ESTIMATED_COVERAGE' | translate }}: </span>
      <span class="pl-1 pr-1 estimated-coverage-terms"> {{expireDate | date: 'longDate' : preferredLanguage}}
        {{ 'RATES_PAGE.OR' | translate }} {{expireMileage | number }} {{('GENERIC.MILEAGE_PLURAL' |
        translate).toLowerCase()}}</span>
    </div>
    <div class="text-center w-100 coverage-terms-container mt-3" *ngIf="this.isBrand === 'Lambo'">
      <span class="estimated-coverage-text">{{ 'RATES_PAGE.ESTIMATED_COVERAGE' | translate }}: </span>
      <span class="pl-1 pr-1 estimated-coverage-terms">
        {{coverageDates.coverageStartDate | date: 'longDate' : preferredLanguage}}
        {{ 'RATES_PAGE.THROUGH' | translate }}
        {{coverageDates.coverageEndDate | date: 'longDate' : preferredLanguage}}</span>
    </div>
    <div class="text-center w-100 coverage-terms-container mt-3" *ngIf="this.isBrand === 'Porsche' && termMiles === '0'">
      <span class="estimated-coverage-text">{{ 'RATES_PAGE.ESTIMATED_COVERAGE' | translate }}: </span>
      <span class="pl-1 pr-1 estimated-coverage-terms">
        {{expireDate | date: 'longDate' : preferredLanguage}}</span>
    </div>
  </form>
</div>