export class VerifyAccountRequest {
    emailId: string;
    phoneNumber: string;
    contactType: string;
    lastName: string;
    brandName: string;
}

export class VerifyAccountResponse {
    status: string;
    data: VerifyAccountData;
    errorMessage: string;
}

export class VerifyAccountData {
    sfid: string;
    externalId: string;
    email: string;
    name: string;
    lastName: string;
    phone: string;
}

export class VerifyCodeRequest {
    contact: string;
    contactType: string;
    code: string;
    lastName: string;
}

export class VerifyCodeResponse {
    status: string;
    data: VerifyCode;
    errorMessage: string;
}

export class VerifyCode {
    sfid: string;
    externalId: string;
    email: string;
    name: string;
    lastname: string;
    phone: string;
    access_token?: string;
}

export class VerifyAccountOktaFlowRequest {
    orderNumber: string;
    lastName: string;
}

export class VerifyAccountOktaFlowResponse {
    id: string;
}
