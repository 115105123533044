<div class="modal-content">
    <div class="modal-header mb-n1">
        <app-close-button (callbackFunction)="modalRefCart.hide()"></app-close-button>
    </div>
    <div class="modal-body p-0" role="alert">
        <div class="row pl-3 pr-3">
            <div class="col-12 text-center">
                <h3>{{ 'CART_CHECKOUT.ARE_YOU_SURE' | translate }}</h3>                
                <p class="pt-3">{{ 'CART_CHECKOUT.START_OVER_SURE' | translate }}
            </div>
        </div>
        <div class="row">
            <div class="col-12 w-100 text-center pl-3 pr-3">
                <a (click)="modalRefCart.hide()" id="cart-remove-btn" tabindex="0" (click)="startOver()"
                    class="btn primary-btn waves-light" mdbBtn mdbWavesEffect>{{ 'CART_CHECKOUT.YES' | translate }}</a>
            </div>
            <div class="col-12 w-100 text-center pt-2 pb-3">
                <a (click)="modalRefCart.hide()" tabindex="0" class="click-text">{{ 'CART_CHECKOUT.NO_BACK' | translate }}</a>
            </div>
        </div>
    </div>
</div>