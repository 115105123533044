<div class="modal-content modal-timeout">
  <div class="modal-header mb-n1">
    <span aria-hidden="true" *ngIf="modalType == 'countdown'"><mdb-icon tabindex="0" class="modal-close-icon" aria-label="Close" (click)="keepSession()" fas icon="times"></mdb-icon></span>
  </div>
  <div class="modal-body p-0">
    <div class="row pl-3 pr-3 mt-4">
      <div *ngIf="modalType == 'countdown'" class="col-12 text-center" tabindex="0" role="alert" aria-live="assertive">
        <h2 class="mb-4">{{ 'MODAL.STILL_THERE' | translate }}</h2>
        <p class="mb-5">{{ 'MODAL.ABOUT_TO_EXPIRE' | translate }}</p>
        <div class="d-flex justify-content-center mb-5">
          <button role="button" mdbBtn class="btn cancel-btn" (click)="killSession()">{{ 'MODAL.NO_TIME' | translate }}</button>&nbsp;&nbsp;
          <button role="button" mdbBtn class="btn primary-btn" (click)="keepSession()">{{ 'MODAL.YES_HERE' | translate }}</button>
        </div>
      </div>
      <div *ngIf="modalType != 'countdown'" class="col-12 text-center" tabindex="0" role="alert" aria-live="assertive">
        <h2 class="mb-4">{{ 'MODAL.SESSION_EXPIRED' | translate }}</h2>
        <p class="mb-5">{{ 'MODAL.LOGGED_OUT' | translate }}</p>
        <div class="d-flex justify-content-center mb-5">
          <button role="button" mdbBtn class="btn primary-btn" (click)="renew()">{{ 'MODAL.RENEW' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
