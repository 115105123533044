import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { cookiesAccepted, cookiesDeclined } from '../_actions/cookie-acceptance.actions';


const _cookiesAcceptedReducer = createReducer(initialState,
    on(cookiesAccepted, (state, payload) => {
        sessionStorage.setItem('cookiesAccepted', 'true')
        return true;
    }),
    on(cookiesDeclined, (state, payload) => {
        sessionStorage.setItem('cookiesAccepted', 'false')
        return false;
    })
);

export function cookiesAcceptedReducer(state, action) {
    return _cookiesAcceptedReducer(state, action);
}