import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { PrismicService } from "src/app/_services/prismic.service";
import { Router } from "@angular/router";
import { PagesService } from "src/app/_services/pages.service";
import { AppConfigService } from "src/app/appconfig/appconfig.service";

declare var gtag;

@Component({
  selector: "app-component-cta",
  templateUrl: "./cta.component.html",
  styleUrls: ["./cta.component.scss"],
})
export class CtaComponent implements OnInit {
  @Input() public component: any;
  @Input() public text_color: any;
  @Input() public vehicleObject: any;
  @Input() public text_align: any;
  @Output() emitOpenModal = new EventEmitter<string>();
  branding: any;

  callToLiveChat: any;
  callToLiveCheck: boolean;

  constructor(private prismicService: PrismicService, private router: Router, private pageService: PagesService, private appConfig: AppConfigService) {}

  ngOnInit() {
    this.callToLiveChat = false;
    this.callToLiveCheck = false;
    setTimeout(() => {
      let ctas = document.querySelectorAll(".cta-button");
      ctas.forEach((cta, index) => {
        if (!cta.hasAttribute("id")) {
          cta.setAttribute("id", "cta-button-" + index);
        }
      });
    }); // Add Disclamier aria code to site when this component is used.
    this.appConfig.getSettings().subscribe((result) => {
      this.branding = result;
    });
  }

  openLink(link) {
    console.log("Test:", link);

    switch (link) {
      case "How it Works":
        this.router.navigate(["/how-vsp-works"]);
        break;
      case "Product Home":
        this.emitOpenModal.emit("cta component");
        break;
      case "PPM":
        this.pageService.redirectTo("/prepaid-maintenance");
        break;
      case "Plans":
        this.emitOpenModal.emit("cta component");
        break;
      case "Chat":
        this.openChat();
        break;
      case "Support":
        window.open("https://prismic-io.s3.amazonaws.com/sgidigital/5fdbb456-a079-43b8-9b8d-d92fbf6632fd_REVSC-D2C_191205_NO-BRACKET+-+Watermarked.pdf", "_blank");
        break;
      case "Custom":
        this.pageService.redirectTo(this.component.primary.custom_link);
        break;
      case "Call":
        this.call();
        break;
      default:
        this.emitOpenModal.emit("cta component");
        break;
    }

    return false;
  }

  getStyle(component) {
    const text_color = component.primary.text_color1 ? component.primary.text_color1 : this.text_color;
    const text_align = component.primary.text_align ? component.primary.text_align : this.text_align;

    return {
      color: text_color ? text_color : "inherit",
      "text-align": text_align ? text_align : "center",
    };
  }

  openModal(data: string, linkto = "") {
    if (linkto !== "Support") {
      this.emitOpenModal.emit(data);
    }
  }

  getHtml(content, vehicleObject) {
    console.log(this.prismicService.getHtml(content, vehicleObject));
    return this.prismicService.getHtml(content, vehicleObject);
  }

  gtagEvent(event) {
    console.log(event.target.innerText);
    // gtag('event', 'Clicked ' + event.target.innerText + ' Button', {
    //     event_label: 'User Interaction',
    //     event_category: 'Product Home Page',
    // });
  }
  openChat() {
    console.log("Open Live Person Chat");
    if (this.callToLiveCheck) {
      clearInterval(this.callToLiveChat);
    }
    this.callToLiveCheck = true;
    this.callToLiveChat = setInterval(() => {
      // Wait for button from Live Person to appear - it's delayed
      this.checkForMessageButton();
    }, 500);
  }

  call() {
    window.location.href = "tel://" + this.branding.cs_phone_number;
  }

  checkForMessageButton() {
    var LPButton = document.getElementsByClassName("LPMcontainer")[0] as HTMLElement;
    if (LPButton && this.callToLiveCheck) {
      // Button has appeared so now it can be clicked
      clearInterval(this.callToLiveChat);
      this.callToLiveCheck = false;
      LPButton.click();
    }
  }
}
