import * as ActionConfigurationManager from './config.actions';

export const checkRegistrationToken = ActionConfigurationManager.CHECK_REGISTRATION_TOKEN;
export const registrationTokenValid = ActionConfigurationManager.REGISTRATION_TOKEN_VALID;
export const registrationTokenInvalid = ActionConfigurationManager.REGISTRATION_TOKEN_INVALID;
export const registrationTokenUsed = ActionConfigurationManager.REGISTRATION_TOKEN_USED;
export const setOktaPassword = ActionConfigurationManager.SET_OKTA_PASSWORD
export const oktaPasswordSet = ActionConfigurationManager.OKTA_PASSWORD_SET
export const oktaPasswordSetFailed = ActionConfigurationManager.OKTA_PASSWORD_SET_FAILD
export const changeOktaPassword = ActionConfigurationManager.CHANGE_OKTA_PASSWORD
export const oktaPasswordChanged = ActionConfigurationManager.OKTA_PASSWORD_CHANGED
export const oktaPasswordChangedFailed = ActionConfigurationManager.OKTA_PASSWORD_CHANGED_FAILD
export const oktaPasswordReset = ActionConfigurationManager.OKTA_PASSWORD_RESET
export const oktaVerifyAccount = ActionConfigurationManager.OKTA_VERIFY_ACCOUNT
export const oktaVerifyAccountSuccess = ActionConfigurationManager.OKTA_VERIFY_ACCOUNT_SUCCESS
export const oktaVerifyAccountFailed = ActionConfigurationManager.OKTA_VERIFY_ACCOUNT_FAILED
export const oktaSignOut = ActionConfigurationManager.OKTA_SIGN_OUT
export const oktaSignIn = ActionConfigurationManager.OKTA_SIGN_IN
export const resetOktaUser = ActionConfigurationManager.RESET_OKTA_USER
export const registerOktaUser = ActionConfigurationManager.REGISTER_OKTA_USER
export const oktaUserAlreadyRegistered = ActionConfigurationManager.OKTA_USER_ALREADY_REGISTERED
export const registerOktaUserSuccess = ActionConfigurationManager.REGISTER_OKTA_USER_SUCCESS
export const registerOktaUserFailed = ActionConfigurationManager.REGISTER_OKTA_USER_FAILED
export const setOktaUser = ActionConfigurationManager.SET_OKTA_USER

