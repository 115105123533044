import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Order, OrderResponse } from '../_models/order';
import { TaxResponse, TaxRequest } from '../_models/tax';
import { PaymentResponse } from '../_models/payment';
import { environment } from '../../environments/environment';
import brandInfo from '../../environments/brand.json'
import { GatewayService } from './gateway.service';
import { LoggingService } from './logging.service';



@Injectable({
  providedIn: 'root'
})
export class s3ManagementService {


  constructor(
    private httpClient : HttpClient,
    private gatewayService: GatewayService,
    private loggingService: LoggingService) {}


  s3Upload(brandName: string, fileToUpload: any[], vinNumber: string): Observable<any> {
    const formData = new FormData();
    console.log('fileToUploadfileToUpload', fileToUpload)

    fileToUpload.forEach(file => {
      const blob = this.base64ToBlob(file.data, file.type);
      formData.append('File', blob, file.name);
    });

      // Append the brandName field to the formData
    return this.httpClient.post(`${environment.apiUrl}/v1/api/s3Upload/upload?vinNumber=${vinNumber}&brandName=${brandName}`, formData);
  }

  base64ToBlob(base64: string, contentType: string): Blob {
    // Decode the base64 string
    const binaryString = atob(base64.split('base64,')[1]);
    const len = binaryString.length;

    // Create a Uint8Array of the same length
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Convert the Uint8Array to a Blob
    return new Blob([bytes], { type: contentType });
  }
}



