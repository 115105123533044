import { FormControl, FormGroup } from '@angular/forms';
import { PrismicService } from 'src/app/_services/prismic.service';

import { AfterViewInit, Component, DoCheck, ElementRef, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, ViewChildren } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { CollapseComponent, MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { combineLatest, distinctUntilChanged, filter, map, Observable, Subject, Subscription, take, takeUntil, timeout } from "rxjs";
import { AppConfigService } from "src/app/appconfig/appconfig.service";
import { ModalInfoComponent } from "src/app/modal-info/modal-info.component";
import { RatesResponse } from "src/app/_models/rates";
import { Vehicle } from "src/app/_models/vehicle";
import { ProductTabsetService } from "src/app/_services/product-tabset.service";
import brandInfo from "src/environments/brand.json";
import moment from 'moment'
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import { SelectedPlanService } from 'src/app/_services/selectedPlan.service';
import * as SelectedPlanActions from "../../states/planSelect/selectedPlan.actions";
import { ContractService } from 'src/app/_services/contract.service';


@Component({
  selector: 'app-coverage-options',
  templateUrl: './coverage-options.component.html',
  styleUrls: ['./coverage-options.component.scss']
})
export class CoverageOptionsComponent implements OnInit {
  @Input() plans: any;
  @Input() chosenPlan: any;
  @Input() title: any;
  @Input() subTitle: any;
  @Input() _deductible: string;
  @Input() select_coverage_term: string;
  @Input() select_claim_deductible: string;
  @Input() estimated_coverage_through: string;

  @Input() changeTerms: boolean;
  @Input() index: number
  @Input() _commercial_use_toggle: boolean = false
  @Input() _snow_plow_toggle: boolean = false
  @Input() _grey_market_toggle: boolean = false
  @Input() _lift_kit_toggle: boolean = false
  @Output() chosenTerm = new EventEmitter<any>();
  @Output() emitChangeTerms = new EventEmitter<any>();
  @Output() emitAddToCart = new EventEmitter<string>();

  termMiles: any;
  termYears: any;
  termsAndRates: any = [];
  initialized: boolean;
  currentPlanIndex: number;
  check: any;
  modalRef: MDBModalRef;
  commercial_use_tooltip = "";
  term_length_tooltip = "";
  snow_plow_tooltip = "";
  lift_kit_tooltip = "";
  appConfigSubscription: Subscription;
  mileage: string;
  showLiftKit = false;
  showSnowPlow = false;
  grey_market_tooltip: string;
  expireMileage: number;
  expireDate: any;
  preferredLanguage: string;
  isBrand: string;

  //Select Claim deductible
  @ViewChild('selectCoverageBox') selectCoverageBox: ElementRef;
  @ViewChild('coverageDeductiblesBox') coverageDeductiblesBox: ElementRef;
  @Input() planSelected: any;
  @Input() allPlans: any;

  deductibles: any[];
  currentDeductible = 0 as number;
  multipleDeductibles = brandInfo.multiDeductible;
  disappearingDeductible = brandInfo.disappearingDeductible;
  disappearingDeductiblePanel = false;
  disappearingDeductibleValue = false;
  canSelect = true;
  planSelected$: Observable<any>;
  private destroy$ = new Subject<void>();
  allPlans$: Observable<any>;
  combinedPlans$: Observable<any>;
  selectedTermsVal = 0;
  selectedDeductible = 0;
  coverageTermsForm: FormGroup<{ terms: FormControl<number>; deductible: FormControl<number>; }>;
  coverageDates: { coverageStartDate: any; coverageEndDate: any; };
  deductiblesMap: any;

  constructor(
    public store: Store<{ selectedPlan: any }>,
    private prismicService: PrismicService,
    public router: Router, private modalService: MDBModalService,
    private appConfig: AppConfigService,
    private translate: TranslateService,
    private productTabSetService: ProductTabsetService,
    private selectedPlanService: SelectedPlanService,
    private contractService: ContractService,
  ) { }

  ngAfterViewInit() {
    console.log('coverage options: ' + this.coverageDeductiblesBox.nativeElement.offsetWidth);
    console.log('select options: ' + this.selectCoverageBox.nativeElement.offsetWidth);
    const coverageWidth = this.coverageDeductiblesBox.nativeElement.offsetWidth;
    const selectWidth = this.selectCoverageBox.nativeElement.offsetWidth;
    if (coverageWidth >= selectWidth) {
      this.selectCoverageBox.nativeElement.style.width = coverageWidth + 'px';
    } else {
      this.coverageDeductiblesBox.nativeElement.style.width = selectWidth + 'px';
    }
  }


  ngOnInit(): void {

    console.log('this.chosenPlan.planTerms', this.chosenPlan ? this.chosenPlan.planTerms : null)
    console.log("coverage-select ngOnInit")
    console.log('PLANNNNNNS plan', this.plans);
    this.isBrand = brandInfo.brand

    this.coverageDates = this.contractService.getCoverageStartDate();

    this.coverageTermsForm = new FormGroup({
      terms: new FormControl(0, [Validators.required]),
      deductible: new FormControl(0, [Validators.required]),
    });

    this.store.select((store) => store.selectedPlan).pipe(
      filter(val => val),
      takeUntil(this.destroy$),
    ).subscribe((val) => {
      console.log('coverage options selectedPlan sub', val)
      if (val.currentSelectedPlan) {
        let termsData = val.currentSelectedPlan.planTerms[val.currentSelectedPlan.term.termIndex].label;
        this.termMiles = termsData.split(' ')[2];
        this.termYears = termsData.split(' ')[0];
        this.setStateCoverageEstimate();
        this.updateDeductibles(val.currentSelectedPlan.name);
      }
    })

    this.store.select((store) => store.selectedPlan.defaultPlan).pipe(
      filter(val => val),
      takeUntil(this.destroy$),
    ).subscribe((val) => {
      const oldPlan = this.planSelected;
      const selectedCoverageValue = this.coverageTermsForm.value.terms;
      const selectedCoverageTermLabel = oldPlan?.planTerms.find(v => v.value === +selectedCoverageValue);
      this.store.dispatch(SelectedPlanActions.loadCurrentSelectedPlan({ currentSelectedPlan: val }));
      this.planSelected = val;

      if (selectedCoverageTermLabel) {
        const newSelectedValue = this.planSelected?.planTerms.find(v => v.label === selectedCoverageTermLabel.label);
        // setTimeout(() => this.coverageTermsForm.patchValue({terms: newSelectedValue.value}));
        setTimeout(() => {
          this.coverageTermsForm.patchValue({ terms: newSelectedValue.value });
        })
      }

      this.updatePlans();
    });

    this.coverageTermsForm.get('deductible').valueChanges.subscribe(value => {
      console.log('deductible value change', value)
      this.selectedDeductible = value;
      this.updatePlans();
    });
    this.coverageTermsForm.get('terms').valueChanges.subscribe(value => {
      this.selectedTermsVal = value;
      this.updatePlans();
    });
    if (brandInfo.multiDeductible) {
      this.setDeductible();
    }

    // this.uniquePlanTerms = this.selectedPlanService.extractUniquePlanTerms(this.plans);





    // Get Commercial Tooltip from Prismic
    this.appConfigSubscription = this.appConfig.getSettings().subscribe((result) => {
      this.commercial_use_tooltip = this.appConfig.getCommercialUseTooltip();
      this.term_length_tooltip = this.appConfig.getTermLengthTooltip();
      this.snow_plow_tooltip = this.appConfig.getSnowPlowTooltip();
      this.lift_kit_tooltip = this.appConfig.getLiftKitTooltip();
      this.grey_market_tooltip = this.appConfig.getGreyMarketTooltip()
    });

    // Set translation
    // this.translate.get("GENERIC.MILEAGE_PLURAL").subscribe((mileage) => {
    //   this.mileage = mileage.toLowerCase();
    // });

    // TODO set these to pull from API
    if (["Chevrolet", "GMC"].includes(brandInfo.brand)) {
      this.showLiftKit = true;
      this.showSnowPlow = true;
    }

    if (brandInfo.coverageBasedOnISD) {
      const rates: RatesResponse = JSON.parse(sessionStorage.getItem('rates'))

      if (rates.data.productDetailsList[0].productCode === 'NASP') {
        let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem('vehicle'))
        if (_vehicle && _vehicle?.inserviceDate !== null && _vehicle.inserviceDate !== '') {
          //this.setStateCoverageEstimate();
        }
      }

      if ((brandInfo.brand === 'BMW' || brandInfo.brand === 'MINI') && rates.data.productDetailsList[0].productPlanList[0].planName.toLocaleLowerCase().includes('in warranty')) {
        let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem('vehicle'))
        if (_vehicle && _vehicle?.inserviceDate !== null && _vehicle.inserviceDate !== '') {
          //this.setStateCoverageEstimate();
        }
      }
    }

    this.preferredLanguage = sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language;
    registerLocaleData(localeFr, 'fr');
    registerLocaleData(localeEn, 'en-ca');
    registerLocaleData(localeEn, 'en-us');

  }

  checkTerm(term) {
    return term.split(', 0 MILES')[0];
  }

  private updatePlans(): void {
    console.log('updatePlans this.planSelected', this.planSelected)
    const termIndex = this.planSelected?.planTerms.findIndex(v => {
      return +v.value === +this.selectedTermsVal;
    });
    console.log('updatePlans termIndex', termIndex)
    if (brandInfo.multiDeductible) {
      this.selectedPlanService.updatePlansWithMultiDeductible(this.plans, termIndex, this.planSelected, brandInfo, this.selectedDeductible, this.disappearingDeductibleValue);
      this.selectedPlanService.storingSelectedPlan(termIndex, this.planSelected, brandInfo, this.selectedDeductible, this.disappearingDeductibleValue)

    } else {
      const singleDeductible = this.planSelected.plan.deductible
      this.selectedPlanService.updatePlansWithSingleDeductible(this.plans, termIndex, this.planSelected, brandInfo, singleDeductible);
      this.selectedPlanService.storingSelectedPlan(termIndex, this.planSelected, brandInfo, singleDeductible, this.disappearingDeductibleValue)

    }

  }



  setStateCoverageEstimate() {
    let _vehicle: any = JSON.parse(sessionStorage.getItem("vehicle"));
    if (_vehicle.inserviceDate && (brandInfo.brand === 'NCESI' || (brandInfo.brand === 'BMW' || brandInfo.brand === 'MINI') && this.chosenPlan.plan.planNumber.includes('IW'))) {
      this.expireDate = new Date(_vehicle.inserviceDate.replace(/-/g, '/'));
      this.expireDate = this.expireDate.setFullYear(this.expireDate.getFullYear() + parseInt(this.termYears));
      this.expireDate = moment(this.expireDate).format('MMMM D, yyyy');
      this.expireMileage = Number(this.termMiles.toString().replace(/,/g, ''))
    } else if (brandInfo.brand === 'Lambo') {
      const contractDates = this.contractService.getCoverageStartDate();
      console.log('contractDatescontractDates', contractDates)

    }

    else {
      this.expireDate = new Date();
      this.expireDate = this.expireDate.setFullYear(this.expireDate.getFullYear() + parseInt(this.termYears));
      this.expireDate = moment(this.expireDate).format('MMMM D, yyyy');
      this.expireMileage = Number(_vehicle.mileage.toString().replace(/,/g, '')) + Number(this.termMiles.toString().replace(/,/g, ''));
    }

  }
  getHtml(content, vehicleObject) {
    return this.prismicService.getHtml(content, vehicleObject);
  }

  updateDeductibles(planName) {
    if (this.multipleDeductibles) {
      console.log('updateDeductibles planName', planName)
      console.log('updateDeductibles this.deductiblesMap', this.deductiblesMap)
      this.deductibles = this.deductiblesMap.find(plan => plan.planName === planName).deductibles;
      this.deductibles = this.deductibles.sort((a, b) => a - b);
      this.deductibles = [...new Set(this.deductibles)];

      console.log('updateDeductibles this.deductibles', this.deductibles)
      
      //check to see if the current deductible is in the list of available deductibles
      const index = this.deductibles.indexOf(this.currentDeductible);

      console.log('updateDeductibles index', index)

      //if it doesn't exist, set the form to the first deductible in the list
      if (index === -1) {
        this.coverageTermsForm.get('deductible').patchValue(this.deductibles[0]);
        this.currentDeductible = this.deductibles[0];
      }
      console.log('updateDeductibles this.currentDeductible', this.currentDeductible)
      console.log('updateDeductibles this.deductibles', this.deductibles)
    }
  }

  setDeductible() {
    this.deductibles = [];
    console.log('setDeductible this.plans', this.plans)
    for (let i = 0; i < this.plans.length; i++) {
      for (let j = 0; j < this.plans[i].planTerms[0].deductible.length; j++) {
        this.deductibles.push(this.plans[i].planTerms[0].deductible[j].amount);
      }
    }

    this.deductibles = this.deductibles.sort((a, b) => a - b);
    this.deductibles = [...new Set(this.deductibles)];

    //create a variable called deductiblesMap that holds the deductible value it its available plans
    this.deductiblesMap = this.plans.map(plan => {
      return {
        planName: plan.name,
        deductibles: plan.planTerms[0].deductible.map(deductible => deductible.amount).sort((a, b) => a - b)  //sort the deductibles in ascending order
      }
    });

    console.log('setDeductible deductiblesMap', this.deductiblesMap)

    for (let i = 0; i < this.plans.length; i++) {
      //chek for active plan
      if (this.plans[i].active === true) {
        //check if a deductible amount for the active plans exist in the list of all deductibles and get it's index
        
        const sorted = this.plans[i].planTerms[0].deductible.sort((a, b) => a.amount - b.amount);
        const index = this.deductibles.indexOf(sorted[0].amount);

        //if the index is found, set the current deductible to the active plan's deductible
        if (index > -1) {
          this.currentDeductible = sorted[0].amount;
        }

        //set form 
        this.coverageTermsForm.get('deductible').setValue(this.deductibles[index]);
      }
    }

    console.log('setDeductible this.currentDeductible', this.currentDeductible)
    console.log('setDeductible this.deductibles', this.deductibles)
  }

  toggleDisappearing() {
    this.disappearingDeductibleValue = !this.disappearingDeductibleValue
    // this.updatePlans()
  }


  chooseDeductible(deductible) {

    if (deductible == 100 && this.disappearingDeductible) {
      this.disappearingDeductiblePanel = true;
    } else {
      this.disappearingDeductiblePanel = false;
    }
    setTimeout(() => {
      this.disappearingDeductibleValue = false;
    })
  }


  ngOnDestroy() {
    if (this.appConfigSubscription) {
      this.appConfigSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
