import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function specialCharactersValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const specialCharsRegex = /[^a-zA-Z0-9]/; // Regex to match special characters

    if (control.value && specialCharsRegex.test(control.value)) {
      return { 'specialCharacters': true };
    }

    return null;
  };
}