import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { addToken, removeToken } from '../_actions/token.actions';

const _tokenReducer = createReducer(initialState,
    on(addToken, (state, payload) => {
      console.log('Token Added',state, payload);
      sessionStorage.setItem('token', JSON.stringify(payload));
      return payload;
    }),
    on(removeToken, (state) => {
      console.log('Token Removed');
      sessionStorage.removeItem('token');
      return null;
    })
);

export function tokenReducer(state, action) {
    return _tokenReducer(state, action);
}