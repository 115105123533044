import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
declare var Wistia;
declare var window;

@Component({
  selector: 'app-component-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  @Input() public component: any;
  @Input() public text_color: any;
  @Input() public text_align: any;
  @Input("vehicleObject") public vehicle: any;
  @Output() emitOpenModal = new EventEmitter<string>();
  wistiaVideoId: string;

  constructor() { }


  ngOnInit(): void {
    // this.wistiaVideoId = "bvxug1itja";
    this.wistiaVideoId = this.component.primary.video_id;

      window.theMediaEmbed = Wistia.embed(this.wistiaVideoId, {
        // accountKey: 'wistia-production_1102070',
        container: "wistia_video_embed_container",
        doNotTrack: false,
        uuid: "wistia_video_embed",
        dontFit: "false",
        videoFoam: "true",
      });
  }

}
