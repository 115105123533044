import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss']
})
export class SelectDropdownComponent {

 //mat-select
 @Input() options: Array<{
  value: string;
  display: string;
  disabled?: boolean;
}> = [];
@Input() label: string = "";
@Input() selected: string = "";
@Input() errorFlag: boolean = false;
@Input() errorMessage: string = " Please select a valid option";


constructor() {}

ngOnInit(): void {
  this.options = [
    { value: "volvo", display: "Volvo" },
    { value: "saab", display: "Saab", disabled: true },
    { value: "mercedes", display: "Mercedes" },
    { value: "audi", display: "Audi" },
  ];
}
}
