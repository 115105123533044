export class OktaRegistrationToken {
    token: string
}

export class OktaUser {
    id: string;
    login: string;
    access_token: string;
}

export class OktaSetPasswordRequest {
    password: string;
    id: string;
    token: string;
    email: string;
}

export class OktaChangePasswordRequest {
    current: string;
    new: string;
    id: string;
    access_token: string;
}

export class OktaVerifyAccountRequest {
    orderNumber: string;
    lastName: string;
}

export class OktaSignOutRequest {
    id: string;
}

export class OktaRegisterUserRequest {
    orderNumber: string;
    email: string;
    firstName: string;
    lastName: string;
}