
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PdfViewer {


  constructor() {}


  _base64ToArrayBuffer(base64) {
    if(!base64) return
    var binary_string = base64.replace(/\\n/g, '');
    binary_string =  window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array( len );
    for (var i = 0; i < len; i++)        {
        bytes[i] = binary_string.charCodeAt(i);
    }
    console.log('THE BUFFERIS', bytes, bytes.buffer)
    return bytes.buffer;
  }

  createPdfViewer(pdf) {
    return this._base64ToArrayBuffer(pdf.pdfRaw);
  }

}