<div class="modal-content">
  <div class="modal-header mb-n1">
    <span aria-hidden="true"><mdb-icon tabindex="0" class="modal-close-icon" aria-label="Close"
        (click)="cancel(); modalRef.hide()" fas icon="times"></mdb-icon></span>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center">
        <div class="mb-0"><strong>Switch Screen to Customer</strong></div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-center mb-4">
        The following sections must be completed by the customer to complete checkout.
      </div>
    </div>

    <!-- <div class="desktop">
      <div class="row">
        <div class="col-6 text-center mt-2 mb-3">
          <a class="btn secondary-btn waves-light uppercase" mdbBtn mdbWavesEffect (click)="cancel(); modalRef.hide();">Cancel</a>
        </div>
        <div class="col-6 text-center mt-2 mb-3">
          <button class="btn primary-btn waves-light uppercase" mdbBtn mdbWavesEffect (click)="proceed(); modalRef.hide();">Proceed</button>
        </div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-12 text-center mt-2 mb-3">
        <button class="btn primary-btn waves-light uppercase" mdbBtn mdbWavesEffect (click)="proceed(); modalRef.hide();">Proceed</button>
      </div>
      <div class="col-12 text-center mt-2 mb-3">
        <a class="uppercase click-text" mdbBtn mdbWavesEffect (click)="cancel(); modalRef.hide();">Cancel</a>
      </div>
    </div>
  </div>
</div>