<p *ngIf="_verifyType && _verifyType.toLowerCase() === 'phone'" tabindex="0" class="text-center">{{ 'MY_ACCOUNT_LOGIN.VERIFY_MESSAGE_PHONE' | translate }}</p>
<p *ngIf="_verifyType && _verifyType.toLowerCase() === 'email'" tabindex="0" class="text-center">{{ 'MY_ACCOUNT_LOGIN.VERIFY_MESSAGE_EMAIL' | translate }}</p>

<form class="col-12 pb-3 code" [formGroup]="codeValidationForm">
    <div class="alert alert-warning w-100 text-center" role="alert"
        *ngIf="(verifyCodeState$ | async) !== null && (verifyCodeState$ | async) !== 'valid'">
        <span *ngIf="(verifyCodeState$ | async) as code">{{code?.error?.errorMessage}}</span>
    </div>
    <div class="form-row verify-fields md-form">
        <input mdbInput type="text" class="form-control" maxlength="6" (keypress)="onlyNumberKey($event)"
            pattern="[0-9]*" inputmode="numeric" formControlName="code" id="code">
    </div>
    <div class="pt-5 w-100 text-center">
        <button role="button" mdbBtn class="btn primary-btn waves-light" mdbWavesEffect type="submit"
            [disabled]="!codeValidationForm.valid || (inlineLoader$ | async) === true"
            (click)="validateCode()">
            <span *ngIf="(inlineLoader$ | async) === null || (inlineLoader$ | async) === false">
                <mdb-icon fas icon="lock"></mdb-icon> {{ 'MY_ACCOUNT_LOGIN.VERIFIY_CONTINUE' | translate }}
            </span>
            <span *ngIf="(inlineLoader$ | async) === true">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{ 'MY_ACCOUNT_LOGIN.VERIFYING_ACCOUNT' | translate }}
            </span>
        </button>
    </div>
</form>

<div class="w-100 text-center pt-3">
    <span tabindex="0">
        <strong>{{ 'MY_ACCOUNT_LOGIN.NO_CODE' | translate }}</strong><br />
        {{ 'MY_ACCOUNT_LOGIN.GO_BACK' | translate }}<br /><br />
        <a href="javascript:void(0)" role="link" (click)="clearForms()"><i
                class="fas fa-arrow-alt-circle-left"></i> {{ 'MY_ACCOUNT_LOGIN.RETRY' | translate }}</a>
        <br /><br />
        {{ 'MY_ACCOUNT_LOGIN.CALL_NUMBER' | translate:{phone:branding.cs_phone_number} }}
    </span>
</div>