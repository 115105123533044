import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { checkRegistrationToken, registrationTokenInvalid, registrationTokenValid, registrationTokenUsed, registerOktaUser, registerOktaUserFailed, registerOktaUserSuccess, oktaUserAlreadyRegistered } from '../_actions/okta.actions';


const _oktaRegistrationReducer = createReducer(initialState,
    on(checkRegistrationToken, (state, payload) => {
        return state;
    }),
    on(registrationTokenInvalid, (state) => {
        return 'widget';
    }),
    on(registrationTokenValid, (state, payload) => {
        return 'password';
    }),
    on(registrationTokenUsed, (state, payload) => {
        return 'Used';
    }),
    on(registerOktaUser, (state, payload) => {
        return state;
    }),
    on(registerOktaUserFailed, (state) => {
        return 'Register Fail';
    }),
    on(registerOktaUserSuccess, (state, payload) => {
        return 'Registered';
    }),
    on(oktaUserAlreadyRegistered, (state, payload) => {
        return 'Already Registered';
    })

);

export function oktaRegistrationReducer(state, action) {
    return _oktaRegistrationReducer(state, action);
}