<!-- FACTS SECTION -->
<div class="facts">
    <div class="container">
      <div class="row" role="heading">
        <div class="col-12 text-center" tabindex="0">
          <h2 class="pb-3" [innerHTML]="getHtml(component.primary.carousel_section_title, vehicle)"></h2>
          <p [innerHTML]="getHtml(component.primary.carousel_section_description, vehicle)">
          </p>
        </div>
      </div>
    </div>
    <ngx-slick-carousel
      class="carousel"
      #slickModal="slick-carousel"
      [config]="slideConfig"
      (init)="slickInit($event)"
      (breakpoint)="breakpoint($event)"
      (afterChange)="afterChange($event)"
      (beforeChange)="beforeChange($event)">
        <div ngxSlickItem *ngFor="let slide of component.fields" class="slide">
              <div class="w-100 pr-2 pl-2 pb-4 pt-4" role="text">
                <mdb-card>
                  <!-- <mdb-card-img style="height:250px; overflow:hidden;" src="{{slide.image}}"></mdb-card-img> -->
                  <mdb-card-body>
                    <mdb-card-title class="text-center" *ngIf="slide.carousel_info_card_title.text">
                      <strong><p class="fact-title" [innerHTML]="getHtml(slide.carousel_info_card_title)"></p></strong>
                    </mdb-card-title>
                    <mdb-card-text class="text-center align-middle" [innerHTML]="getHtml(slide.carousel_info_card_body)">
                    </mdb-card-text>
                  </mdb-card-body>
                  <div class="w-100 float-left pb-3 pr-3">
                    <!-- <span tabindex="0" role="tooltip" aria-label="{{slide.source}}" mdbTooltip="{{slide.source}}" class="float-right" placement="top"><mdb-icon fas icon="info" class="info" ></mdb-icon></span> -->
                    <app-tooltip message="{{slide.source}}" icon="fa-info" class="float-right"></app-tooltip>
                  </div>
                </mdb-card>
            </div>
        </div>
    </ngx-slick-carousel>
  </div>
