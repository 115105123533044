import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { singleFlowProductChanged } from 'src/app/_actions/single-flow-product-changed.actions';

@Component({
  selector: 'app-product-tabset-same-product',
  templateUrl: './product-tabset-same-product.component.html',
  styleUrls: ['./product-tabset-same-product.component.scss']
})
export class ProductTabsetSameProductComponent implements OnInit {

  constructor(
    public modalRef: MDBModalRef,
    public store: Store<{}>
  ) { }

  ngOnInit(): void {
  }

  addNewItem() {
    this.store.dispatch(singleFlowProductChanged())
  }

}
