import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { preferenceCenterEmailFound, preferenceCenterNoEmailFound } from '../_actions/preference-center.actions';


const _preferenceCenterReducer = createReducer(initialState,
    on(preferenceCenterEmailFound, (state) => {
      return true;
    }),
    on(preferenceCenterNoEmailFound, (state) => {
      return false;
    }),
); 


export function preferenceCenterReducer(state, action) {
    return _preferenceCenterReducer(state, action);
}
