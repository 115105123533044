<!-- <section class="expansion-panel faq-overview">
  <div class="row">
    <div class="col-sm-12 col-md-8 offset-md-2">
      <div class="content-block faq" [ngStyle]="getStyles(slice)">
        <div class="main-expansion-panel">
          <div role="heading" tabindex="0" class="faq-title text-center"
            [innerHTML]="getHtml(slice.primary.section_title, vehicle)"></div>
          <mat-accordion multi="true" [ngStyle]="getStyles(slice)">
            <div class="col-3" *ngFor="let field of slice?.fields">
              <mat-expansion-panel class="" *ngFor="let qAndA of field.faq_group.body[0].fields">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span [innerHTML]="getHtml(qAndA.question)"></span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p class="answer" [innerHTML]="getHtml(qAndA.answer)"></p>
              </mat-expansion-panel>
            </div>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="faq-overview">
  <div class="row">
    <div class="col-sm-12">
      <div class="content-block faq" [ngStyle]="getStyles(slice)">
        <div class="main-expansion-panel">
          <div role="heading" tabindex="0" class="faq-title text-center"
            [innerHTML]="getHtml(slice.primary.section_title, vehicle)"></div>

          <div *ngFor="let field of slice?.fields" class="row">
            <div *ngFor="let qAndA of field.faq_group.body[0].fields" class="col-12 col-md-4">
              <div class="p-2">
                <mat-accordion multi="true" [ngStyle]="getStyles(slice)">
                  <mat-expansion-panel class="">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <span [innerHTML]="getHtml(qAndA.question)"></span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p class="answer" [innerHTML]="getHtml(qAndA.answer)"></p>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
