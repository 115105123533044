export class TrimResponse {
    status: string;
    data: Trim[];
    errorMessage: string;
}

export class Trim {
    trimId: string;
    modelId: string;
    code: string;
    description: string;
    name: string;
}

export class ModelMakeName {
    model: string;
    make: string;
}