<section>
  <div *ngIf="label && label !=''" id="termlength" class="active">
    <mat-label aria-label="{{ displayLabel | translate }}">{{ displayLabel | translate }}**</mat-label>
  </div>
  <mat-form-field appearance="fill">
    <select matNativeControl [(ngModel)]="selected" name="selectField" required #selectControl="ngModel">
      <ng-container *ngFor="let option of options">
        <option [value]="option.value" [disabled]="option.disabled" [selected]="option.value === selected">
          {{ option.display }}
        </option>
      </ng-container>
    </select>
    <mat-error *ngIf="errorFlag && selectControl.invalid">
  {{errorMessage}}
  </mat-error>
  </mat-form-field>
</section>
