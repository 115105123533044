import { Component, OnInit, Input, HostListener } from "@angular/core";
import { Store } from "@ngrx/store";
import { DeviceDetectorService } from "ngx-device-detector";
import { Observable } from "rxjs";
import { Vehicle } from "src/app/_models/vehicle";
import { PrismicService } from "src/app/_services/prismic.service";

@Component({
  selector: "app-component-vehicle",
  templateUrl: "./vehicle.component.html",
  styleUrls: ["./vehicle.component.scss"],
})
export class VehicleComponent implements OnInit {
  @Input() public component: any;
  @Input() public text_color: any;
  @Input() public text_align: any;
  @Input("vehicleObject") public vehicle: any;
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  vehicle$: Observable<any>;
  innerWidth: number;

  constructor(private prismicService: PrismicService, private store: Store<{ vehicle: Vehicle }>, private deviceService: DeviceDetectorService) {}

  ngOnInit() {
    console.log("VehicleComponent: ", this.component);
    this.vehicle$ = this.store.select((store) => store.vehicle);
    this.innerWidth = window.innerWidth;
  }

  getStyles(component) {
    return {
      "background-color": component.primary.background_color1 ? component.primary.background_color1 : "inherit",
      "padding-top": component.primary.padding_top1 ? component.primary.padding_top1 + "px" : "inherit",
      "padding-bottom": component.primary.padding_bottom1 ? component.primary.padding_bottom1 + "px" : "inherit",
      "padding-right": component.primary.padding_right1 ? component.primary.padding_right1 + "px" : "inherit",
      "padding-left": component.primary.padding_left1 ? component.primary.padding_left1 + "px" : "inherit",
      "max-width": this.innerWidth < 768 ? "100%" : "384px",
    };
  }
  getVehicleStyle(component) {
    const text_color = component.primary.text_color1 ? component.primary.text_color1 : this.text_color;
    const text_align = component.primary.text_align ? component.primary.text_align : this.text_align;

    return {
      color: text_color ? text_color : "inherit",
      "text-align": text_align ? text_align : "center"

    };
  }

  maskVin(vin) {
    let endVin = vin.substring(11, 17);
    return "***********" + endVin;
  }

  getHtml(content, vehicleObject) {
    return this.prismicService.getHtml(content, vehicleObject);
  }
}
