import { Component, OnInit, Input, HostListener } from '@angular/core';
import { PrismicService } from 'src/app/_services/prismic.service';

@Component({
  selector: 'app-component-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit {

  @Input() public component: any;
  @Input() public text_color: any;
  @Input() public vehicleObject: any;
  @Input() public text_align: any;
  innerWidth: number;
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }


  vehicle: any;

  constructor(private prismicService: PrismicService) {
    this.innerWidth = window.innerWidth;

  }

  ngOnInit() {
    // console.log("content-text", this.component.primary.text)
  }


  getMobileTextColor(component){

    if (component.primary.mobile_text_color){
      return component.primary.mobile_text_color
    } else {
      return this.getDesktopTextColor(component)
    }
  }

  getDesktopTextColor(component){

    const text_color = component.primary.text_color1 ? component.primary.text_color1 : this.text_color;

    if(text_color){
      return text_color
    }else {
      return "inherit"
    }
  }

  getMobileTextAlign(component){

    if (component.primary.mobile_text_align !== "inherit"){
      return component.primary.mobile_text_align
    } else {
      return this.getDesktopTextAlign(component)
    }
  }

  getDesktopTextAlign(component){

    const text_align = component.primary.text_align ? component.primary.text_align : this.text_align;

    if(text_align){
      return text_align
    } else {
      return "left"
    }
  }


  getTextStyle(component) {

    return {
      color: this.innerWidth > 767 ? this.getDesktopTextColor(component) :this.getMobileTextColor(component),
      "text-align": this.innerWidth > 767 ? this.getDesktopTextAlign(component) :this.getMobileTextAlign(component),
      "padding-top": component.primary.padding_top1 ? component.primary.padding_top1 + "px": "initial" ,
      "padding-bottom": component.primary.padding_bottom1 ? component.primary.padding_bottom1 + "px": "initial",
      "padding-left": component.primary.padding_left1? component.primary.padding_left1 + "px": "initial",
      "padding-right": component.primary.padding_right1 ? component.primary.padding_right1 + "px": "initial",
      "margin-top": component.primary.margin_top1 ? component.primary.margin_top1 + "px" : "initial",
      "margin-bottom": component.primary.margin_bottom1 ? component.primary.margin_bottom1 + "px" : "initial"
    };
  }

  getHtml(content, vehicleObject) {
    return this.prismicService.getHtml(content, vehicleObject);
  }

}
