import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { VehicleService } from '../_services/vehicle.service';
import { Injectable } from '@angular/core';
import * as ActionConfigurationManager from '../_actions/config.actions';
import { Vehicle, VinDecodeResponse } from '../_models/vehicle';
import { decodeVinGetCarInfo, decodeVinGetCarInfoFail, decodeVinGetCarInfoSuccess, vinDecodedSuccess, vinDecodeStateTrue } from '../_actions/vinDecode.actions';
import { Action, Store } from '@ngrx/store';
import { loadVehicle, updateOdometer, updateVIN } from '../_actions/vehicle.actions';
import { invalidState, productExist, ratesError, success } from '../_actions/modal-component.actions';
import { hideInlineLoader } from '../_actions/inline-loader.actions';
import { ratesLoaded, ratesLoadedFailed } from '../_actions/rates.actions';
import { VINDecodeCarInfoRequest, VINDecodeCarInfoResponse } from '../_models/vin-decode';

@Injectable()

export class VinDecodeEffects {

    public verifyVin: Observable<Action> = createEffect(() =>
        this.actions$
            .pipe(
                ofType(ActionConfigurationManager.DECODE_VIN_VERIFY_VIN),
                switchMap((action) => this.vehicleService.vin_decode(action.vin)
                    .pipe(
                        map((vinResponse) => {
                            console.log(vinResponse);
                            let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem('vehicle'))
                            _vehicle.vin = vinResponse.vin
                            this.store.dispatch(updateVIN(_vehicle))
                            this.store.dispatch(vinDecodeStateTrue())
                            return vinDecodedSuccess(vinResponse)
                        }),
                        catchError(() => EMPTY)
                    )
                )
            )
    )

    public getCarInfo: Observable<Action> = createEffect(() =>
        this.actions$
            .pipe(
                ofType(ActionConfigurationManager.DECODE_VIN_GET_CAR_INFO),
                switchMap((action) => this.vehicleService.getVinCarInfo(action.vin)
                    .pipe(
                        map((vinResponse: any) => {
                            console.log(vinResponse.data)
                            const vehicle: VINDecodeCarInfoResponse = {
                                vin: action.vin,
                                make: vinResponse.data.finalVinData.make,
                                model: vinResponse.data.finalVinData.model,
                                year: vinResponse.data.finalVinData.modelYear
                            }
                            const obj: Vehicle = {
                                vin: action.vin,
                                make: vinResponse.data.finalVinData.make,
                                model: vinResponse.data.finalVinData.model,
                                year: vinResponse.data.finalVinData.modelYear,
                                registeredState: JSON.parse(sessionStorage.getItem('dealer')).data.address.state,
                                isElectric: (vinResponse.data.finalVinData.fuelType && // Put here to try and figure out fuel type
                                             vinResponse.data.finalVinData.fuelType.toLowerCase().includes('g') || 
                                             vinResponse.data.finalVinData.fuelType.toLowerCase().includes('diesel')) ? false : true
                            }
                            console.log("obj ", obj)
                            this.store.dispatch(loadVehicle(obj))
                            return decodeVinGetCarInfoSuccess(vehicle)
                        }),
                        catchError(() => {
                            this.store.dispatch(decodeVinGetCarInfoFail());
                        return EMPTY;
                    })
                    )
                )
            ))

    public decodeVINGetRates: Observable<Action> = createEffect(() =>
        this.actions$
            .pipe(
                ofType(ActionConfigurationManager.DECODE_VIN_GET_RATES),
                switchMap((action) => this.vehicleService.decodeVinGetRates(action)
                    .pipe(
                        map((rates) => {
                            console.log("************");
                            console.log("1. loaded rates from api:", rates, action);
                            console.log(`2. Found ${rates.data.productDetailsList.length} products`);

                            // let odometer = action.odometer && typeof action.odometer === 'string' && action.odometer.includes(',') ? action.odometer.replace(',','') : action.odometer

                            for (let i = 0; i < rates.data.productDetailsList.length; i++) {
                                if (rates.data.productDetailsList[i].statusCode === 100) {
                                    // Set the registration State and mileage.
                                    let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem('vehicle'))
                                    if (_vehicle) {
                                        console.log('3. Update state and mileage on vehicle in store:', action)
                                        _vehicle.registeredState = action.customerState
                                        _vehicle.mileage = action.odometer
                                        _vehicle.model = rates.data.vehicleModel
                                        _vehicle.make = rates.data.vehicleMake
                                        _vehicle.year = rates.data.vehicleYear
                                        _vehicle.trim = rates.data.vehicleTrim
                                        _vehicle.vin = action.vin
                                        _vehicle.inserviceDate = rates.data.inServiceDate ? rates.data.inServiceDate : null
                                        _vehicle.isElectric = rates.data.productDetailsList[i].productName.includes('EVEHICLE') || rates.data.productDetailsList[i].productName.includes('EV') ? true : false
                                        this.store.dispatch(updateOdometer(_vehicle))
                                        console.log('4. Vehicle after action', _vehicle)
                                    } else {
                                        let vehicle: Vehicle = {
                                            registeredState: action.customerState,
                                            mileage: action.odometer,
                                            model: rates.data.vehicleModel,
                                            make: rates.data.vehicleMake,
                                            year: rates.data.vehicleYear,
                                            trim: rates.data.vehicleTrim,
                                            isElectric: rates.data.productDetailsList[i].productName.includes('EVEHICLE') || rates.data.productDetailsList[i].productName.includes('EV') ? true : false,
                                            inserviceDate: rates.data.inServiceDate ? rates.data.inServiceDate : null,
                                            makeExternalId: '',
                                            makeSfId: '',
                                            modelExternalId: '',
                                            modelSfId: '',
                                            modelNumber: '',
                                            purchaseDate: '',
                                            referenceNumber1: '',
                                            referenceNumber2: '',
                                            referenceNumber3: '',
                                            referenceNumber4: '',
                                            trimExternalId: '',
                                            trimSfId: '',
                                            vehicleSfId: '',
                                            vehicleCondition: '',
                                            vehicleExternalId: '',
                                            vehicleType: '',
                                            vin: action.vin,
                                            deliveryDate: '',
                                            exteriorColor: '',
                                            mfrWarrantyDate: '',
                                            image: ''
                                        }
                                        this.store.dispatch(updateOdometer(vehicle))
                                        console.log('4. Vehicle after action', vehicle)
                                    }
                                    this.store.dispatch(ratesLoaded(rates))
                                    // this.store.dispatch(loadVehicleImage(_vehicle));
                                    // this.router.navigate([action.getRatesPage])
                                    sessionStorage.setItem('page', 'ratesPage')
                                    return success()
                                } else if (rates.data.productDetailsList[i].statusCode === 101) {
                                    this.store.dispatch(ratesLoadedFailed(rates))
                                    this.store.dispatch(hideInlineLoader())
                                    return invalidState()
                                } else if (rates.data.productDetailsList[i].statusCode === 102) {
                                    this.store.dispatch(ratesLoadedFailed(rates))
                                    this.store.dispatch(hideInlineLoader())
                                    return productExist()
                                } else if (rates.data.productDetailsList[i].statusCode === 105) {
                                    this.store.dispatch(ratesLoadedFailed(rates))
                                    this.store.dispatch(hideInlineLoader())
                                    return ratesError()
                                }
                            }
                        }),
                        catchError((error) => {
                            console.log("Error Caught: ", error);

                            this.store.dispatch(hideInlineLoader());
                            this.store.dispatch(ratesError());

                            return EMPTY;

                        })
                    )
                )
            )
    )



    constructor(
        private actions$: Actions,
        private vehicleService: VehicleService,
        private store: Store<{}>
    ) { }
}
