<div class="row justify-content-md-center">
  <div class="col-md-8">
    <div id="media"  [ngStyle]="{'display': 'inline-block', 'margin-top':component.primary.margin_top ? component.primary.margin_top+'px': '0', 'margin-bottom':component.primary.margin_bottom? component.primary.margin_bottom+'px': '0', 'width':component.primary.width_percentage ? component.primary.width_percentage+'%' : '100%'}">
      <div
        class="video vsp-landing-page-video"
        id="embed"
        itemprop="video"
        itemscope
        itemtype="http://schema.org/VideoObject"
      >
        <div
          class="wistia_responsive_padding"
          style="padding: 56.25% 0 0 0; position: relative"
        >
          <div
            class="wistia_responsive_wrapper"
            style="
              height: 100%;
              left: 0;
              position: absolute;
              top: 0;
              width: 100%;
            "
          >
            <div
              id="wistia_video_embed_container"
              class="wistia_embed"
              style="width: 100%; height: 100%"
            >
              <div
                class="wistia_swatch"
                style="
                  height: 100%;
                  left: 0;
                  opacity: 0;
                  overflow: hidden;
                  position: absolute;
                  top: 0;
                  transition: opacity 200ms;
                  width: 100%;
                "
              >
                <img
                  src="https://fast.wistia.com/embed/medias/{{wistiaVideoId}}/swatch"
                  style="
                    filter: blur(5px);
                    height: 100%;
                    object-fit: contain;
                    width: 100%;
                  "
                  alt=""
                  aria-hidden="true"
                  onload="this.parentNode.style.opacity=1;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
