import { ModalBrowserWarningComponent } from "./modal-browser-warning/modal-browser-warning.component";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { Component, OnInit, AfterViewInit, isDevMode, OnDestroy, HostListener, Inject, ElementRef } from "@angular/core";
import { fadeOut, fadeAnimation } from "./animations/animations";
import { filter } from "rxjs/operators";
import { NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Event, Router, RouterOutlet, ActivatedRoute } from "@angular/router";
import { DOCUMENT } from "@angular/common";
declare var gtag;
declare var _etmc;
import { Lead } from "./_models/lead";
import { Store } from "@ngrx/store";
import { rehydrateLead } from "./_actions/lead.actions";
import { Vehicle, VerifyVehCustResponse, VerifyVehCustResponseData, VinDecodeData } from "./_models/vehicle";
import { rehydrateVehicle, verifyVehCustSuccessContactInformation } from "./_actions/vehicle.actions";
import { Cart } from "./_models/cart";
import { Registration } from "./_models/registration";
import { rehydrateRegistration } from "./_actions/registration.actions";
import { rehydrateCart } from "./_actions/cart.actions";
import { rehydrateVin } from "./_actions/vinDecode.actions";
import { rehydratePlan } from "./_actions/plan.actions";
import { AVSResponse } from "./_models/avs";
import { rehydrateAVS } from "./_actions/avs.actions";
import { TaxResponse } from "./_models/tax";
import { DeviceDetectorService } from "ngx-device-detector";

import { rehydrateTax, rehydrateTaxDP, rehydrateTaxMP } from "./_actions/tax.actions";
import { Plan } from "./_models/plan";
import { VerifyAccountResponse } from "./_models/verify-account";
import { rehydrateVerifiedAccount } from "./_actions/verify-account.actions";
import { RatesResponse } from "./_models/rates";
import { rehydrateRates } from "./_actions/rates.actions";
import { EmailInfo } from "./_models/emailInfo";
import { rehydrateEmail } from "./_actions/email.actions";
import { saveUrlParams } from "./_actions/urlParams.actions";
import { URLParameters } from "./_models/urlParams";
import { ConfirmationNumber } from "./_models/confirmation";
import { loadConfirmationNumber } from "./_actions/confirmation.actions";
import { rehydrateMyAccount, rehydratePaymentHistory, rehydratePaylink, resetMyAccount } from "./_actions/account.actions";
import { AccountResponse, PaymentHistory, PaymentHistoryObject, PaylinkResponse, Opportunity, Account } from "./_models/account";
import { account, guest, edit, editAccount, serviceDrive } from "./_actions/app-flow-state.actions";
import { ReferenceID } from "./_models/reference-id";
import { saveReferenceID } from "./_actions/reference-id.actions";
import { AppConfigService } from "./appconfig/appconfig.service";
import brandInfo from "src/environments/brand.json";
import { environment } from 'src/environments/environment';

import { storePrismicPDF, rehydratePrismicPDF } from "./_actions/pdf.actions";
import { PrismicPDF } from "./_models/pdf";
// import LogRocket from 'logrocket';
import { NgwWowService } from 'ngx-wow';


import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { ModalTimeoutComponent } from "./modal-timeout/modal-timeout.component";
import { Title } from "@angular/platform-browser";
import { hideInlineLoader, showInlineLoader } from "./_actions/inline-loader.actions";
import { GoogleAnalyticsService } from "./_services/google-analytics";
import { GoogleTagManagerService } from "./_services/google-tag-manager";
import { Product } from "./_models/product";
import { loadProductDisclaimers } from "./_actions/product-disclaimers.actions";
import { PrismicService } from "./_services/prismic.service";
import { loadOpportunity } from "./_actions/opportunity.actions";
import { LoggingService } from "./_services/logging.service";
import { sessionEnded } from "./_actions/session.actions";
import { cookiesAccepted, cookiesDeclined } from "./_actions/cookie-acceptance.actions";
import { CookieAcceptanceComponent } from "./cookie-acceptance/cookie-acceptance.component";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { PagesService } from "./_services/pages.service";
import { PaymentOptionMax } from "./_models/payment";
import { setPaymentOptionMax } from "./_actions/payment-options.actions";
import { OktaUser } from "./_models/okta";
import { oktaSignIn } from "./_actions/okta.actions";
import { CookieYesService } from "./_services/cookieyes.service";
import { GoogleMapsService } from "./_services/google-maps.service";
import { LivePersonService } from "./_services/livePerson.service";
import { rehydratePaypal } from "./_actions/paypal.action";
import { rehydrateDealer } from "./_actions/dealer-actions";
import { PaypalSettingsService } from "./_services/paypal-settings.service";
import { ModalMarketingEmailComponent } from "./modal-marketing-email/modal-marketing-email.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [fadeOut, fadeAnimation],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = "retailApp";
  isPageLoading: boolean = true;
  leadInformation: Lead;
  customerVehicle: Vehicle;
  customerCart: Cart;
  customerRegistration: Registration;
  custoemrVinDecode: VinDecodeData;
  customerRates: RatesResponse;
  plan: Plan;
  avs: AVSResponse;
  tax: TaxResponse;
  verifiedAccount: VerifyAccountResponse;
  email: EmailInfo;
  urlParams: URLParameters;
  confirmation: ConfirmationNumber;
  account: AccountResponse;
  paymentHistory: PaymentHistoryObject;
  paylink: PaylinkResponse;
  refID: ReferenceID;
  taxMP: TaxResponse;
  taxDP: TaxResponse;
  prismicPDF: PrismicPDF;
  deviceInfo = null;
  modalRef: MDBModalRef;
  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;
  isLogin = false;
  cookieAcceptanceSub: Subscription;
  languageDropdown = false;
  currentLangCode: any;
  supportedLangs = []
  usingOkta = false;
  livePersonWatch;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private googleAnalyticsService: GoogleAnalyticsService,
    private googleTagManagerService: GoogleTagManagerService,
    private cookieYesService: CookieYesService,
    private googleMapsService: GoogleMapsService,
    private livePersonService: LivePersonService,
    private paypalSettingsService: PaypalSettingsService,
    private router: Router,
    private store: Store<{
      account: Account;
      vehicle: Vehicle;
      cookiesAccepted: boolean;
    }>,
    private route: ActivatedRoute,
    private appConfig: AppConfigService,
    private deviceService: DeviceDetectorService,
    private modalService: MDBModalService,
    private idle: Idle,
    private keepalive: Keepalive,
    private titleService: Title,
    private prismicService: PrismicService,
    private loggingService: LoggingService,
    private translate: TranslateService,
    private pagesService: PagesService,
    private wowService: NgwWowService,
    private el: ElementRef
  ) {

    this.usingOkta = brandInfo.okta;

    // Load Brand from Prismic
    this.appConfig.loadSettings();

    // this.setLanguage();
    this.preloadFonts();

    this.googleMapsService.loadScriptTag();

    this.livePersonService.loadScriptTag();

    this.googleTagManagerService.loadScriptTag(brandInfo.gtmid);
    // cookieYesService.loadScriptTag(brandInfo.cookieYesLink)

    loggingService.init();

    // this.prismicService.storePrismicRef();

    const navEndEvent$ = router.events.pipe(filter((e) => e instanceof NavigationEnd));
    navEndEvent$.subscribe((e: NavigationEnd) => {
      setTimeout(() => {
        let superscripts = document.querySelectorAll(".superscript");
        superscripts.forEach((superscript) => {
          superscript.setAttribute("aria-label", "Disclaimer " + superscript.innerHTML); // Add Disclamier aria code to site when this component is used.
        });
        let aTags = document.querySelectorAll("a[href]");
        let anchors = [];
        let blanks = [];
        aTags.forEach(aTag => {
          let link = aTag.getAttribute('href');
          if (link.includes('#')) {
            let newLink = link.split('#')
            aTag.setAttribute('href', document.location.pathname + document.location.search + '#' + newLink[1]); // Fix anchor tags used in Prismic
            aTag.addEventListener('click', function (e) {
              e.preventDefault();
              window.location.hash = '#' + newLink[1];
            })
            anchors.push(aTag);
          }
        });
        aTags.forEach(aTag => {
          let link = aTag.getAttribute('target');
          if (link && link === '_blank' && !aTag.hasAttribute('aria-label')) {
            aTag.setAttribute('aria-label', aTag.textContent + ' (opens in a new tab)'); // Fix _blank aria label tags for Userway
            blanks.push(aTag);
          }
        })
        console.log('a tags: ', anchors)
        console.log('_blanks: ', blanks)
        this.checkLivePerson();
      }, 1500);
    });

    this.router.events.subscribe((event: Event) => {
      // console.log(event);
      this.loadingInterceptor(event);
    });

    // Load session timer
    this.setIdleTimer();
  }

  checkLivePerson() {
    let chatAvatar = null;
    let checkLP = setInterval(() => {
      chatAvatar = document.querySelector(".LPMimage");
      if (chatAvatar) { // Fix alt tags with LivePerson for Userway
        chatAvatar.setAttribute('alt', 'Live Person Chat Avatar');
        let helpImage = document.querySelector(".LPMslideOutPin");
        helpImage.setAttribute('alt', "We're here to help")
        clearInterval(checkLP);
      }
    }, 1000);
  }

  ngOnDestroy() {
    if (this.cookieAcceptanceSub) {
      this.cookieAcceptanceSub.unsubscribe();
    }
  }

  setCss() {
    let checkCss = this.appConfig.getCSS();
    if (checkCss) {
      const headEl = this.document.getElementsByTagName("head")[0];
      const css = this.document.createElement("style");
      css.innerHTML = checkCss
      headEl.appendChild(css);
    }
  }

  setLanguage() {
    let languageList = [];
    if (brandInfo.supportedLanguages.length > 0) {
      brandInfo.supportedLanguages.forEach(lang => {
        languageList.push(lang.code);
      });
    } else {
      languageList.push(brandInfo.language);
    }

    this.translate.addLangs(languageList);
    const lang = sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language;
    // Check to see if language exists in Prismic
    let prismicLanguageJson = this.appConfig.getLanguageJson(lang);

    let currentTranslations = this.translate.translations;
    console.log("languages1: ", currentTranslations)
    console.log("prismicLanguageJson: ", prismicLanguageJson);
    console.log("currentTranslations: ", currentTranslations);
    if (prismicLanguageJson) {
      //this.translate.setTranslation(sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language, prismicLanguageJson, true)
      //prismicLanguageJson

      for (var key in currentTranslations) {
        if (currentTranslations.hasOwnProperty(key) && key === lang) {
          var t =
            this.mergeJsonObjects(currentTranslations[key], prismicLanguageJson);
            console.log('languangs3', t);
            currentTranslations[key] = t;
        }
    }

      // this.translate.setTranslation(sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language, this.mergeJsonObjects(currentTranslations[sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language], prismicLanguageJson));

    }
    console.log("languages2: ", this.translate.translations)
    console.log("prismicLanguageJson: ", prismicLanguageJson)
    this.translate.setDefaultLang(lang);
  }

  setLanguageDropdown() {
    if (brandInfo.supportedLanguages && brandInfo.supportedLanguages.length > 0) {
      this.languageDropdown = true;
      const windowLang = window.navigator.language || window.navigator['userLanguage'];
      const assignedLanguge = sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language;
      brandInfo.supportedLanguages.forEach(language => {
        this.supportedLangs.push(language)
        if (language.code === assignedLanguge) {
          this.currentLangCode = language
        }
      });
      if (!this.currentLangCode) {
        // this.currentLangCode = {code: "en-us", label: "English - US"};
      }
    }
  }

   mergeJsonObjects(baseObj: any, preferredObj: any): any {
    // Create a deep clone of the base object to avoid mutating the original
    const mergedObj = JSON.parse(JSON.stringify(baseObj));

    // Helper function to perform the merge
    const merge = (base: any, preferred: any): any => {
      for (let key in preferred) {
        if (preferred.hasOwnProperty(key) && !isPrototypePollution(key)) {
          if (typeof preferred[key] === 'object' && preferred[key] !== null && !Array.isArray(preferred[key])) {
            // If the key is an object, perform a deep merge
            base[key] = merge(base[key] || {}, preferred[key]);
          } else {
            // Otherwise, use the preferred value
            base[key] = preferred[key];
          }
        }
      }
      return base;
    };

    // Helper function to check for prototype pollution
    const isPrototypePollution = (key: string): boolean => {
      return ['__proto__', 'constructor', 'prototype'].includes(key);
    };

    return merge(mergedObj, preferredObj);
  }

  changeLanguage(langCode) {
    sessionStorage.setItem('preferredLanguage', langCode)
    let path = window.location.href.split('?')[0]
    // location.reload();
    this.document.location.href = path;
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData["animation"];
  }

  resetUser() {

    this.isLogin = false;
    this.router.navigate([`${brandInfo.homePage}`]);

    setTimeout(() => {
      this.store.dispatch(sessionEnded());
      sessionStorage.clear();
    });

  }

  setIdleTimer() {

    // Check for a session timeout parameter - Need to do it in raw javascript to get the querystring in time for the constructor
    const urlParams = new URLSearchParams(window.location.search);
    const sessiontimeout = parseInt(urlParams.get('sessiontimeout'));

    if (sessiontimeout && sessiontimeout > 0 && sessiontimeout < 71980) {
      sessionStorage.setItem('sessionTimeout', sessiontimeout.toString())
      console.log("session timeout set to ", sessiontimeout);
      this.idle.setIdle(sessiontimeout); // session timeout set via a parameter to be used for testing
    } else {
      console.log("session timeout set to 19 hours and 40 seconds");
      this.idle.setIdle(71980); // 19 hours and 40 seconds - 71980
    }

    // sets a timeout period of 20 seconds. after 71980 seconds (19 hours and 40 seconds) of inactivity, the user will be considered timed out.
    this.idle.setTimeout(20);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = "No longer this.idle.";
      console.log(this.idleState);
      this.closeTimeoutModal();
      this.reset();
    });

    this.idle.onTimeout.subscribe(() => {
      this.showLogoutModal();
      // Run logout functionality
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      this.showTimeoutModal("countdown");
      this.idle.clearInterrupts();
      console.log(this.idleState);
      //  this.childModal.show();
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = "You will time out in " + countdown + " seconds!";
      console.log(this.idleState);
    });

    // sets the ping interval to 10 seconds
    this.keepalive.interval(5 * 2);

    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

  }

  reset() {
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;
  }

  private loadingInterceptor(event: Event) {
    // console.log('Ray')
    // console.log(event);
    if (event instanceof NavigationStart) {
      // console.log('-----');
      // console.log('NavigationStart');
      this.isPageLoading = true;
    }

    if (event instanceof (NavigationEnd || NavigationError || NavigationCancel)) {
      this.isPageLoading = false;
      let link = document.querySelector("#baseFocusLink") as HTMLElement;
      if (link) {
        link.focus();
      }
      // console.log('NavigationEnd||NavigationError||NavigationCancel');
      // console.log('-----');
    }

    if (event instanceof (NavigationEnd)) {
      this.wowService.init();
    }


  }

  loadTheme(cssFile: string) {
    /*
    <head>
      <link id="client-theme" rel="stylesheet" type="type/css" href="">
    </head>
    */

    const headEl = this.document.getElementsByTagName("head")[0];
    const existingLinkEl = this.document.getElementById("client-theme") as HTMLLinkElement;

    if (existingLinkEl) {
      existingLinkEl.href = cssFile;
    } else {
      const newLinkEl = this.document.createElement("link");
      newLinkEl.id = "client-theme";
      newLinkEl.rel = "stylesheet";
      newLinkEl.href = cssFile;
      headEl.appendChild(newLinkEl);
    }
  }

  preloadFonts() {
    const headEl = this.document.getElementsByTagName("head")[0];
    if (brandInfo['fonts'] && brandInfo['fonts'].length > 0) {
      const fonts = brandInfo['fonts'];
      fonts.forEach((font) => {
        const newLinkEl = this.document.createElement("link");
        newLinkEl.rel = "preload";
        newLinkEl.as = "font";
        newLinkEl.crossOrigin = "anonymous";
        newLinkEl.href = font;
        headEl.appendChild(newLinkEl);
      })
    }
  }

  showBrowserWarning() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    // console.log('deviceInfo', this.deviceInfo); // returns if the app is running on a Desktop browser.

    // const modalOptions = {
    //   class: 'modal-lg',
    //   containerClass: 'browser-warning-modal',
    //   animated: true,
    // };

    if (this.deviceInfo.browser === "IE") {
      this.router.navigate(["browser-warning"]);
      this.store.dispatch(hideInlineLoader());

      // this.modalRef = this.modalService.show(ModalBrowserWarningComponent, modalOptions);
    }
  }

  showTimeoutModal(modalType: String) {
    const modalOptions = {
      class: "modal-md",
      ignoreBackdropClick: true,
      containerClass: "browser-warning-modal",
      animated: true,
      data: { modalType },
    };

    this.modalRef = this.modalService.show(ModalTimeoutComponent, modalOptions);
    this.modalRef.content.action.subscribe((result: any) => {
      console.log(result);
      if (result === "keepalive") {
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.reset();
        this.closeTimeoutModal();
      } else if (result === "killsession") {
        this.idle.timeout();
      }
    });
  }

  showMarketingEmailModal() {
    const modalOptions = {
      class: "modal-md",
      ignoreBackdropClick: true,
      containerClass: "browser-warning-modal",
      animated: true,
    };

    this.modalRef = this.modalService.show(ModalMarketingEmailComponent, modalOptions);
  }

  showLogoutModal() {
    if (this.modalRef) {
      this.closeTimeoutModal();
    }
    this.showTimeoutModal("loggedout");
    this.idleState = "Timed out!";
    this.timedOut = true;
    console.log(this.idleState);
    this.resetUser();
  }

  closeTimeoutModal() {
    this.modalRef.hide();
  }



  rehydateStore() {
    let lead: Lead = JSON.parse(sessionStorage.getItem("lead"));
    if (lead) {
      this.leadInformation = lead;
      sessionStorage.removeItem("lead");
      this.store.dispatch(rehydrateLead(this.leadInformation));
    }

    let vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
    if (vehicle) {
      this.customerVehicle = vehicle;
      sessionStorage.removeItem("vehicle");
      this.store.dispatch(rehydrateVehicle(this.customerVehicle));
    }

    let _rates: RatesResponse = JSON.parse(sessionStorage.getItem("rates"));
    if (_rates) {
      this.customerRates = _rates;
      sessionStorage.removeItem("rates");
      this.store.dispatch(rehydrateRates(this.customerRates));
    }

    let cart: Cart = JSON.parse(sessionStorage.getItem("cart"));
    if (cart) {
      this.customerCart = cart;
      sessionStorage.removeItem("cart");
      this.store.dispatch(rehydrateCart(this.customerCart));
    }

    let registration: Registration = JSON.parse(sessionStorage.getItem("registration"));
    if (registration) {
      this.customerRegistration = registration;
      sessionStorage.removeItem("registration");
      this.store.dispatch(rehydrateRegistration(this.customerRegistration));
    }

    let vinDecode: VinDecodeData = JSON.parse(sessionStorage.getItem("vinDecode"));
    if (vinDecode) {
      this.custoemrVinDecode = vinDecode;
      sessionStorage.removeItem("vinDecode");
      this.store.dispatch(rehydrateVin(this.custoemrVinDecode));
    }

    let _plan: Plan = JSON.parse(sessionStorage.getItem("plan"));
    if (_plan) {
      this.plan = _plan;
      sessionStorage.removeItem("plan");
      this.store.dispatch(rehydratePlan(this.plan));
    }

    let _email: EmailInfo = JSON.parse(sessionStorage.getItem("email"));
    if (_email) {
      this.email = _email;
      sessionStorage.removeItem("email");
      this.store.dispatch(rehydrateEmail(this.email));
    }

    let _avs: AVSResponse = JSON.parse(sessionStorage.getItem("avs"));
    if (_avs) {
      this.avs = _avs;
      sessionStorage.removeItem("avs");
      this.store.dispatch(rehydrateAVS(this.avs));
    }

    let _tax: TaxResponse = JSON.parse(sessionStorage.getItem("tax"));
    if (_tax) {
      this.tax = _tax;
      sessionStorage.removeItem("tax");
      this.store.dispatch(rehydrateTax(this.tax));
    }

    let _taxDP: TaxResponse = JSON.parse(sessionStorage.getItem("taxDP"));
    if (_taxDP) {
      this.taxDP = _taxDP;
      sessionStorage.removeItem("taxDP");
      this.store.dispatch(rehydrateTaxDP(this.taxDP));
    }

    let _taxMP: TaxResponse = JSON.parse(sessionStorage.getItem("taxMP"));
    if (_taxMP) {
      this.taxMP = _taxMP;
      sessionStorage.removeItem("taxMP");
      this.store.dispatch(rehydrateTaxMP(this.taxMP));
    }

    let _verifiedAccount: VerifyAccountResponse = JSON.parse(sessionStorage.getItem("accountVerification"));
    if (_verifiedAccount) {
      this.verifiedAccount = _verifiedAccount;
      sessionStorage.removeItem("accountVerification");
      this.store.dispatch(rehydrateVerifiedAccount(this.verifiedAccount));
    }

    let _urlParams: URLParameters = JSON.parse(sessionStorage.getItem("urlParams"));
    if (_urlParams) {
      this.urlParams = _urlParams;
      sessionStorage.removeItem("urlParams");
      this.store.dispatch(saveUrlParams(this.urlParams));
    }

    let _confirmation: ConfirmationNumber = JSON.parse(sessionStorage.getItem("confirmationNumber"));
    if (_confirmation) {
      this.confirmation = _confirmation;
      sessionStorage.removeItem("confirmationNumber");
      this.store.dispatch(loadConfirmationNumber(this.confirmation));
    }

    let _account: AccountResponse = JSON.parse(sessionStorage.getItem("account"));
    if (_account) {
      this.account = _account;
      sessionStorage.removeItem("account");
      this.store.dispatch(rehydrateMyAccount(this.account));
    }

    let _paymentHistory: PaymentHistoryObject = JSON.parse(sessionStorage.getItem("paymentHistory"));
    if (_paymentHistory) {
      this.paymentHistory = _paymentHistory;
      sessionStorage.removeItem("paymentHistory");
      this.store.dispatch(rehydratePaymentHistory(this.paymentHistory));
    }

    let _paylink: PaylinkResponse = JSON.parse(sessionStorage.getItem("paylink"));
    if (_paylink) {
      this.paylink = _paylink;
      sessionStorage.removeItem("paylink");
      this.store.dispatch(rehydratePaylink(this.paylink));
    }

    let _refID: ReferenceID = JSON.parse(sessionStorage.getItem("refID"));
    if (_refID) {
      this.refID = _refID;
      sessionStorage.removeItem("refID");
      this.store.dispatch(saveReferenceID(this.refID));
    }

    let _appFlowState = sessionStorage.getItem("appFlowState");
    if (_appFlowState === "account") {
      sessionStorage.removeItem("appFlowState");
      this.store.dispatch(account());
    } else if (_appFlowState === "guest") {
      sessionStorage.removeItem("appFlowState");
      this.store.dispatch(guest());
    } else if (_appFlowState === "edit") {
      sessionStorage.removeItem("appFlowState");
      this.store.dispatch(edit());
    } else if (_appFlowState === "editAccount") {
      sessionStorage.removeItem("appFlowState");
      this.store.dispatch(editAccount());
    } else if (_appFlowState === "serviceDrive") {
      this.store.dispatch(serviceDrive())
    }

    let _primsicPDF: PrismicPDF = JSON.parse(sessionStorage.getItem("prismicPDF"));
    if (_primsicPDF) {
      this.prismicPDF = _primsicPDF;
      sessionStorage.removeItem("prismicPDF");
      this.store.dispatch(rehydratePrismicPDF(this.prismicPDF));
    }

    let _productDisclaimers: Product = JSON.parse(sessionStorage.getItem("productDisclaimers"));
    if (_productDisclaimers) {
      sessionStorage.removeItem("productDisclaimers");
      this.store.dispatch(loadProductDisclaimers(_productDisclaimers));
    }

    let _opp: Opportunity = JSON.parse(sessionStorage.getItem("opp"));
    if (_opp) {
      sessionStorage.removeItem("opp");
      this.store.dispatch(loadOpportunity(_opp));
    }

    let _vvc: VerifyVehCustResponse = JSON.parse(sessionStorage.getItem("vvc"));
    if (_vvc) {
      sessionStorage.removeItem("vvc");
      this.store.dispatch(verifyVehCustSuccessContactInformation(_vvc));
    }

    let _session = sessionStorage.getItem("sessionEnded");
    if (_session) {
      sessionStorage.removeItem("sessionEnded");
      this.store.dispatch(sessionEnded());
    }

    let _cookiesAccepted = sessionStorage.getItem("cookiesAccepted");
    if (_cookiesAccepted === "true") {
      sessionStorage.removeItem("cookiesAccepted");
      this.store.dispatch(cookiesAccepted());
    } else if (_cookiesAccepted === "false") {
      sessionStorage.removeItem("cookiesAccepted");
      this.store.dispatch(cookiesDeclined());
    }

    let _paymentOptionsMax: PaymentOptionMax = JSON.parse(sessionStorage.getItem('paymentOptionsMax'))
    if (_paymentOptionsMax) {
      sessionStorage.removeItem('paymentOptionsMax')
      this.store.dispatch(setPaymentOptionMax(_paymentOptionsMax))
    }

    let pages = JSON.parse(sessionStorage.getItem("pagesFlow"));
    if (pages) {
      this.pagesService.setRatingPage(pages.ratingPage)
    }

    let _oktaUser: OktaUser = JSON.parse(sessionStorage.getItem('oktaUser'))
    if (_oktaUser) {
      sessionStorage.removeItem('oktaUser')
      this.store.dispatch(oktaSignIn(_oktaUser))
    }

    const paypal = JSON.parse(sessionStorage.getItem('paypal'))
    if (paypal) {
      sessionStorage.removeItem('paypal')
      this.store.dispatch(rehydratePaypal(paypal))
    }

    const dealer = JSON.parse(sessionStorage.getItem('dealer'))
    if (dealer) {
      sessionStorage.removeItem('dealer')
      this.store.dispatch(rehydrateDealer(dealer))
    }
  }

  initializePage() {
    this.store
      .select((store) => store.account)
      .subscribe((account) => {
        if (account) {
          this.isLogin = true;
        }
      });

    this.store
      .select((store) => store.vehicle)
      .subscribe((vehicle) => {
      });

    this.titleService.setTitle(brandInfo.title);

    // FollowAnalytics.initialize({
    //     FAID: 'btkyy66SC_LlHw',
    //     bundleId: 'mango',
    //     defaultIcon: 'https://s3-eu-west-1.amazonaws.com/fa-sdks/sdk-web/web-sdk-assets/followanalytics192.png'
    // });

    this.router.events.subscribe((evt) => {

      if (!(evt instanceof NavigationEnd)) {
        return;
      } else {
        if (evt.urlAfterRedirects.includes('vehicle-service-protection') ||
          evt.urlAfterRedirects.includes('added-security-plan') ||
          evt.urlAfterRedirects.includes('prepaid-maintenance') ||
          evt.urlAfterRedirects.includes('pre-paid-maintenance')) {
          const plan = this.route.snapshot.queryParamMap.get('plan');
          const year = this.route.snapshot.queryParamMap.get('year');
          const mileage = this.route.snapshot.queryParamMap.get('mileage');
          const deductible = this.route.snapshot.queryParamMap.get('deductible');
          if (plan || year || mileage || deductible) {
            window.sessionStorage.setItem('presetProgram', JSON.stringify({ plan: plan, year: year, mileage: mileage, deductible: deductible }))
          }
        }


        console.log("evt: ", evt.urlAfterRedirects)
        const sessionTimeout = sessionStorage.getItem("sessionTimeout") ? parseInt(sessionStorage.getItem("sessionTimeout")) : 71980
        if (evt.urlAfterRedirects.includes('pricing') ||
          evt.urlAfterRedirects.includes('cart') ||
          evt.urlAfterRedirects.includes('checkout') ||
          evt.urlAfterRedirects.includes('confirmation')) {
          this.idle.setIdle(sessionTimeout)
          this.reset();
          setTimeout(() => {
            this.languageDropdown = false
          })
        }

        if (evt.urlAfterRedirects.includes('checkout') && !evt.urlAfterRedirects.includes('confirmation')) {
          clearInterval(this.livePersonWatch);
          this.livePersonWatch = setInterval(() => {
            var LPButton = document.getElementsByClassName("LPMcontainer")[0] as HTMLElement;
            console.log('lb button check')
            if (LPButton) {
              LPButton.classList.add('desktop');
              clearInterval(this.livePersonWatch);
            }
          }, 250);
          setTimeout(() => { // clear timeout in case LP is not activated for the site
            clearInterval(this.livePersonWatch);
            console.log("Final clear timeout")
          }, 8000);
        } else {
          clearInterval(this.livePersonWatch);
          this.livePersonWatch = setInterval(() => {
            console.log('lb button check')
            var LPButton = document.getElementsByClassName("LPMcontainer")[0] as HTMLElement;
            if (LPButton) {
              LPButton.classList.remove('desktop');
              clearInterval(this.livePersonWatch);
            }
          }, 250);
          setTimeout(() => { // clear timeout in case LP is not activated for the site
            clearInterval(this.livePersonWatch);
            console.log("Final clear timeout")
          }, 8000);
        }

        if (evt.urlAfterRedirects.includes('my-account')) { // My account (non-okta) gets a 30 minutes session
          // for testing set timeout to 5 minutes (280) - 4 minutes 40 seconds to allow for 20 second modal
          this.idle.setIdle(1780) // Set for a 29 minutes 40 seconds to allow for 20 second modal countdown (1780)
          this.reset();
          setTimeout(() => {
            this.languageDropdown = false
          })

        } else {
          if (brandInfo.supportedLanguages && brandInfo.supportedLanguages.length > 0) {
            this.languageDropdown = true;
          }
        }
        if (evt.urlAfterRedirects.includes('vehicle-service-contract') && brandInfo.brand === 'MINI') {
          // this.showMarketingEmailModal(); // Turned this off but left it as a placeholder for next campaign
        }
        if (evt.urlAfterRedirects.includes('vehicle-service-protection') && (brandInfo.brand === 'MINI' || brandInfo.brand === 'BMW')) {
          window.location.href = '/vehicle-service-contract'; // D2C-8273 - fix for legacy links
        }
      }
      window.scrollTo(0, 0);
    });
  }

  ngOnInit() {

    // Load Languages
    this.appConfig.getSettings().subscribe(settings => {
      console.log('checking settings', settings)
      if (settings.languages && settings.languages.length > 0) {
        this.setLanguage();
      }
    });

    setTimeout(() => {
      this.setLanguage();
      this.setCss();
    }, 750);

    // Show Browser Warning for IE
    this.showBrowserWarning();

    // Rehydrate NgRx Store
    this.rehydateStore();

    // Load FR, Favicon, title, login Boolean, router Nav End
    this.initializePage();

    if (brandInfo.paypal) {
      this.paypalSettingsService.loadScriptTag();
    }

    // Set Dropdown language
    setTimeout(() => {
      this.setLanguageDropdown();
    })
  }
  ngAfterViewInit() {
    // this.cookieAcceptanceSub = this.store
    //   .select((store) => store.cookiesAccepted)
    //   .subscribe((boolean) => {
    //     if (boolean === null) {
    //       const modalOptions = {
    //         class: "modal-bottom",
    //         ignoreBackdropClick: true,
    //         backdrop: false,
    //         animated: true,
    //       };

    //       this.modalRef = this.modalService.show(CookieAcceptanceComponent, modalOptions);
    //     }
    //   });
  }

  //   @HostListener('window:unload', [ '$event' ])
  //     /? "nloadHandler(event) {
  //       localStorage.clear()
  //     }
  @HostListener("window:beforeunload", ["$event"])
  beforeUnloadHandler(event) {
    localStorage.clear();

    let _cart: Cart = JSON.parse(sessionStorage.getItem("cart"));
    let _order = JSON.parse(sessionStorage.getItem("order"));

    if (_cart && !_order) {
      // gtag('event', 'User had a cart, but never completed an order', {
      //   event_label: 'Abandoned Cart',
      //   event_category: 'Abandoned Cart',
      // });
    }
  }
}
