import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { submitOrderAccountFlow, orderSubmittedAccountFlow, submitOrderChangeVehicleFlow, submitOrderChangeAccountFlow } from '../_actions/order.actions';


const _orderReducer = createReducer(initialState,
    on(submitOrderAccountFlow, (state, payload) => {
        console.log('Order Submitted', state, payload)
        window.sessionStorage.setItem('order', JSON.stringify(payload));
        return payload;
    }),
    on(orderSubmittedAccountFlow, (state) => {
        console.log('Order Submitted Successfully', state)
        return state;
    }),
    on(submitOrderChangeVehicleFlow, (state, payload) => {
        console.log('Order Submitted Successfully', state)
        window.sessionStorage.setItem('order', JSON.stringify(payload));
        return payload;
    }),
    on(submitOrderChangeAccountFlow, (state, payload) => {
        console.log('Order Submitted Successfully', state)
        window.sessionStorage.setItem('order', JSON.stringify(payload));
        return payload;
    })
);

export function orderReducer(state, action) {
    return _orderReducer(state, action);
}