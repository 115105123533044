import { createAction, props } from '@ngrx/store';

export const submitEmail = createAction(
  '[Email Form] Submit Email',
  props<{ email: string }>()
);

export const submitEmailSuccess = createAction(
  '[Email API] Submit Email Success'
);

export const submitEmailFailure = createAction(
  '[Email API] Submit Email Failure',
  props<{ error: any }>()
);
