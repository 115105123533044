import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';
import * as PrismicActions from './prismic2.actions';
import { prismicContent } from "src/app/_models/prismic";
import { HomeService } from 'src/app/_services/home.service';
import { hideInlineLoader } from "../../../app/_actions/inline-loader.actions";

@Injectable()
export class PrismicEffects2 {
  public loadPrismic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PrismicActions.loadPrismic2),
      switchMap((action) => {
        return this.homeService.getPage(action.prismicLandingPage).pipe(
          map((prismicResponse) => {
            let prismicContent = prismicResponse as prismicContent;
            console.log("3.NEwwwwwloadPrismic$ Got Page Content: ", prismicContent);

            //Remove loader
            this.store.dispatch(hideInlineLoader());

            // Load Prismic Data to Store
            return PrismicActions.loadPrismic2Successs({prismicContent});
          }),
          catchError((errorMessage: any) => {

            this.store.dispatch(hideInlineLoader());

            return of(PrismicActions.loadPrismic2Failure({errorMessage}));
          })
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<{}>,
    private homeService: HomeService,

  ) {}
}
