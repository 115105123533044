import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { showInlineLoaderBilling, hideInlineLoaderBilling } from '../_actions/inline-loader.actions';


const _inlineLoaderMyAccountBillingReducer = createReducer(initialState,
    on(showInlineLoaderBilling, (state) => {
      return true;
    }),
    on(hideInlineLoaderBilling, (state) => {
      return false;
    }),
); 


export function inlineLoaderMyAccountBillingReducer(state, action) {
    return _inlineLoaderMyAccountBillingReducer(state, action);
}
