import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { PrismicService } from "src/app/_services/prismic.service";

@Component({
  selector: "app-slice-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"],
})
export class FaqComponent implements OnInit {
  @Input() public slice: any;
  @Input("vehicleObject") public vehicle: any;
  @Output() emitOpenModal = new EventEmitter<string>();

  constructor(private prismicService: PrismicService) {}

  ngOnInit() {
    console.log("faq: ", this.slice);
    console.log("vehicle: ", this.vehicle);
    setTimeout(() => {
      console.log('tabs - ')
      const faq = document.querySelector('.md-accordion');
      console.log('cartPayment - ', faq)

      if(faq){
        let tabs = faq.querySelectorAll('a');
        tabs.forEach((tab, index) => {
          let h5 = tab.querySelector('.mb-0');
          if (h5) {
            let span = h5.querySelector('span');
            span.setAttribute("class", "mb-0");
            let i = h5.querySelector('i');
            tab.innerHTML = '';
            tab.appendChild(span)
            tab.appendChild(i)
          }
          if (!tab.hasAttribute('id')) {
            tab.setAttribute("id", 'faq-button-' + index);
          }
        });
      }
    }, 100)
  }

  getStyles(slice) {
    return {
      "background-color": slice.primary.background_color ? slice.primary.background_color : "inherit",
      "margin-top": slice.primary.margin_top  ? slice.primary.margin_top + "px": "initial",
      "margin-bottom": slice.primary.margin_bottom ? slice.primary.margin_bottom + "px": "initial",
      "padding-top": slice.primary.padding_top  ? slice.primary.padding_top + "px": "initial",
      "padding-bottom": slice.primary.padding_bottom ? slice.primary.padding_bottom + "px": "initial",
    };
  }

  openModal(data: string) {
    this.emitOpenModal.emit(data);
  }

  getHtml(content, vehicleObject) {
    return this.prismicService.getHtml(content, vehicleObject);
  }
}
