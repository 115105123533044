import { loadPrismic, loadPrismicSuccess, loadPrismicFailed } from "./../_actions/prismic.actions";
import { createReducer, on } from "@ngrx/store";
export const initialState = null;

const _prismicReducer = createReducer(
  initialState,
  on(loadPrismic, (state) => console.log("Load Prismic Reducer: ", state)),
  on(loadPrismicSuccess, (state, payload) => {
    console.log("Load Prismic Success Action called in _prismicReducer: ", payload);
    return payload;
  }),
  on(loadPrismicFailed, (state) => {
    console.log("Load Prismic Failed Action called in _prismicReducer:", state);
    return false;
  })
);


export function prismicReducer(state, action) {
  return _prismicReducer(state, action);
}
