import { createReducer, on } from '@ngrx/store';
import { vinDecodeStateFalse, vinDecodeStateTrue } from '../_actions/vinDecode.actions'

export const initialState = null;


const _vinDecodeStateReducer = createReducer(initialState,
    on(vinDecodeStateFalse, (state, payload) => {
      return false;
    }),
    on(vinDecodeStateTrue, (state, payload ) => {
        return true;
    })
);

export function vinDecodeStateReducer(state, action) {
    return _vinDecodeStateReducer(state, action);
}
