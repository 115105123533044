import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { loadAccount, accountLoaded, accountUpdated, noAccountFound, rehydrateMyAccount, resetMyAccount, getAccountSFID, getAccountSFIDSuccess, getAccountSFIDFailed } from '../_actions/account.actions';


const _accountReducer = createReducer(initialState,
    on(loadAccount, (state, payload) => {
        console.log('Load Account', state, payload)
        sessionStorage.setItem('accountSfId', JSON.stringify(payload))
        return state;
    }),
    on(accountLoaded, (state, payload) => {
        console.log('Account Loaded', state, payload)
        window.sessionStorage.setItem('account', JSON.stringify(payload));
        return payload;
    }),
    on(accountUpdated, (state, payload) => {
        console.log('Account Updated', state, payload)
        window.sessionStorage.setItem('account', JSON.stringify(payload));
        return payload;
    }),
    on(noAccountFound, (state) => {
        console.log('No Account Found', state)
        sessionStorage.setItem('account', null)
        return false
    }),
    on(rehydrateMyAccount, (state, payload) => {
        console.log('No Account Found', state)
        sessionStorage.setItem('account', JSON.stringify(payload))
        return payload
    }),
    on(resetMyAccount, (state, payload) => {
        sessionStorage.removeItem('account')
        return null
    }),
    on(getAccountSFID, (state) => {
        return state
    }),
    on(getAccountSFIDSuccess, (state, payload) => {
        return payload
    }),
    on(getAccountSFIDFailed, (state, payload) => {
        return false
    }),

);

export function accountReducer(state, action) {
    return _accountReducer(state, action);
}
