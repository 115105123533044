export let previewCart = {
  estimatedTax: "0.00",
  commercialUse: false,
  deductible: 100,
  grandTotal: "1335.0",
  monthlyPayment: "33.38",
  downPayment: 133.5,
  downPaymentWithTax: "133.50",
  numberOfMonthlyPayments: 36,
  plans: [{ planName: "PLATINUM COVERAGE", planPrice: "1335.00", planTerms: { yearsMileage: "4 YEARS, 48,000 MILES", deductible: 100 }, addons: [] }],
  shoppingCartId: "0065500000I6khFAAR",
  subtotal: "1335.00",
  shoppingCartData: {
    accountExternalId: "",
    accountSfId: "0015500001ILzd7AAD",
    brandExternalId: "",
    brandSfId: "a0355000005HlLbAAK",
    contactExternalId: "",
    contactSfId: "0035500001BsoG9AAJ",
    lineItems: [
      {
        currency: "USD",
        itemDescription: "VROOM VSC PROTECTION",
        itemInCartFrom: "2021-09-13 17:54:02",
        itemName: "PLATINUM COVERAGE",
        lineItemCostExcludingTax: "1335.00",
        lineItemCostIncludingTax: 1335,
        shoppingCartLineItemId: 0,
        shoppingCartLineItemReferenceNumber1: "",
        shoppingCartLineItemReferenceNumber2: "",
        shoppingCartLineItemReferenceNumber3: "",
        shoppingCartLineItemReferenceNumber4: "",
        status: "",
        salesTax: 0,
        product2ExternalId: "e7dfaf12-ec6f-45ab-9a7c-bcf7838b3996",
        product2Sfid: "01t55000005aiwvAAA",
        productSkuExternalId: "",
        productSkuSfId: "a0755000007x4qhAAA",
      },
    ],
    shoppingCartReferenceNumber1: "",
    shoppingCartReferenceNumber2: "",
    shoppingCartReferenceNumber3: "",
    shoppingCartReferenceNumber4: "",
    status: "",
    vehicleExternalId: "",
    vehicleSfId: "0015500001ILzgQAAT",
  },
  type: "[Shopping Cart Component] Cart Updated Successfully",
};
