import { ModalDisclaimerComponent } from './../../../modal-disclaimer/modal-disclaimer.component';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from "@angular/core";
import { PrismicService } from "src/app/_services/prismic.service";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";

@Component({
  selector: "app-disclaimer",
  templateUrl: "./disclaimer.component.html",
  styleUrls: ["./disclaimer.component.scss"],
})
export class DisclaimerComponent implements OnInit, OnChanges {
  @Input() public component: any;
  @Input() public text_color: any;
  @Input() public text_align: any;
  @Input("vehicleObject") public vehicle: any;
  modalRef: MDBModalRef;
  dislaimerText = '';

  constructor(private prismicService: PrismicService, private modalService: MDBModalService) {}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.component.currentValue.primary.disclaimer_text) {
      this.dislaimerText = "Disclaimer. " + changes.component.currentValue.primary.disclaimer_text[0].text
    }
  }

  getHtml(content, vehicleObject) {
    return this.prismicService.getHtml(content, vehicleObject);
  }

  getStyle(component) {

    const text_color = component.primary.text_color1 ? component.primary.text_color1 : this.text_color;
    const text_align = component.primary.text_align ? component.primary.text_align : this.text_align ;

    return {
      color: text_color ? text_color : "inherit",
      "text-align": text_align ? text_align : "center"
    };
  }

  showNotification(disclaimer_text) {
    let screen = document.getElementsByTagName('body')[0];

    const modalOptions = {
      backdrop: screen.clientWidth < 769 ? true: false,
      keyboard: true,
      focus: true,
      show: false,
      scroll: false,
      ignoreBackdropClick: false,
      class: "modal-fluid modal-full-height modal-bottom modal-dialog-full modal-dark",
      containerClass: screen.clientWidth < 769 ? "fade top": "fade bottom",
      animated: true,
      data: {
        disclaimer_text: disclaimer_text,
      },
    };

    this.modalRef = this.modalService.show(ModalDisclaimerComponent, modalOptions);
  }
}
