import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import brandInfo from "src/environments/brand.json";
@Injectable({
  providedIn: "root",
})
export class PagesService {
  pages = {
    landingPage: `${brandInfo.homePage}`,
    ratingPage: "my-vsp-pricing",
    cartPage: "cart",
    checkoutPage: "checkout",
  };

  ratingPages = ["/my-vsp-pricing-electric", 
                  "/my-vsp-pricing",
                  "/my-asp-pricing",
                  "/my-ppm-pricing",
                  "/my-pmp-pricing", 
                  "/plans-pricing",
                  "/electric-plans-pricing",
                  "/ppm-pricing",
                  "/ppm-ev-pricing",
                  "/my-lamborghini-rates"]

  constructor(private router: Router ) {

  }

  getRatingPages() {
    return this.ratingPages;
  }

  getLandingPage() {
    // This is a fix for an anomaly that redirects erroneously to 'help' instead of 'home' for nissan - D2C-2513
    if (this.pages.landingPage === 'help') {
      return '';
    } else {
      if (sessionStorage.getItem('appFlowState').includes('serviceDrive')) {
        return 'service-drive';
      } else {
        return brandInfo.homePage; 
      }
    } 
  }

  redirectTo(uri: string, queryParams = null) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";

    if (queryParams) {
      this.router.navigate([uri], { queryParams: { oid: queryParams } });
    } else {
      this.router.navigate([uri])
    }

  }

  setLandingPage(landingPage) {
    this.pages.landingPage = landingPage;
    return this.pages.landingPage;
  }

  getRatingPage() {
    return this.pages.ratingPage;
  }

  setRatingPage(ratingPage) {
    this.pages.ratingPage = ratingPage;

    window.sessionStorage.setItem("pagesFlow", JSON.stringify(this.pages));
    return this.pages.ratingPage;
  }

  isRatingPage(url) {
    return this.ratingPages.includes(url);
  }
}
