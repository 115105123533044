import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/appconfig/appconfig.service';
import brandInfo from 'src/environments/brand.json'

@Component({
  selector: 'app-component-icon-with-cta',
  templateUrl: './icon-with-cta.component.html',
  styleUrls: ['./icon-with-cta.component.scss']
})
export class IconWithCtaComponent implements OnInit {
  @Input() public component: any;
  @Input() public vehicleObject: any;
  @Output() emitOpenModal = new EventEmitter<string>();
  branding: any;

  constructor(private router: Router, private appConfig: AppConfigService) { }

  openLink(link) {
    console.log(link);

    switch (link) {
      case 'How it Works':
        this.router.navigate(['/how-vsp-works']);
        break;
      case 'Product Home':
        this.router.navigate([`/${brandInfo.homePage}`]);
        break;
      case 'PPM':
        this.router.navigate(['/prepaid-maintenance']);
        break;
      case 'Plans':
          this.emitOpenModal.emit("cta component");
      break;
      case "Call":
        this.call();
        break;
      default:
          this.emitOpenModal.emit("cta component");
        // this.router.navigate([link]);
        break;
    }

    return false;
  }

  ngOnInit() {
  }

  call() {
    window.location.href = "tel://" + this.branding.cs_phone_number;
  }

}
