import { Component, OnInit, Input } from '@angular/core';
import { PrismicService } from 'src/app/_services/prismic.service';

@Component({
  selector: 'app-component-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  @Input() public component: any;
  @Input() public text_color: any;
  @Input() public vehicleObject: any;
  @Input() public text_align: any;


  constructor(private prismicService: PrismicService) { }

  ngOnInit() {
    // console.log({component: this.component});
  }



  setContainerStyles(component){

    let flexPosition = 'center';

    if (component.primary.position === 'left'){
      flexPosition = 'flex-start'
    } else if (component.primary.position === 'right') {
      flexPosition = 'flex-end'
    }

    return {
      // 'display': 'flex',
      // 'justify-content': flexPosition,
      'padding-top': component.primary.padding_top1 ? component.primary.padding_top1+"px" : 'initial',
      'padding-bottom': component.primary.padding_bottom1 ? component.primary.padding_bottom1+"px" : 'initial',
      'padding-right': component.primary.padding_right1 ? component.primary.padding_right1+"px" : 'initial',
      'padding-left': component.primary.padding_left1 ? component.primary.padding_left1+"px" : 'initial',
      };
  }


  setStyles(component){
    return {
      'background-image': 'url('+component.primary.image.url+')',
      'height': component.primary.height1 ? component.primary.height1 : '100%',
      'width': component.primary.width ? component.primary.width : '100%',
      'background-size': 'cover',
      'background-position': component.primary.position ? component.primary.position : 'center'
      };
  }


  getHtml(content, vehicleObject) {
    return this.prismicService.getHtml(content, vehicleObject);
  }

}
