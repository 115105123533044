import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { submitTaxDP, rehydrateTaxDP, taxUpdatedDP, updateTaxDP, taxSubmittedDP } from '../_actions/tax.actions';


const _taxDownpaymentReducer = createReducer(initialState,
    on(taxSubmittedDP, (state, payload) => {
        console.log('Tax Submitted', state, payload)
        window.sessionStorage.setItem('taxDP', JSON.stringify(payload));
        return payload;
    }),
    on(updateTaxDP, (state, payload) => {
        console.log('Tax Updated', state, payload)
        return state;
    }),
    on(taxUpdatedDP, (state, payload) => {
        console.log('Tax Updated', state, payload)
        window.sessionStorage.setItem('taxDP', JSON.stringify(payload));
        return payload;
    }),
    on(rehydrateTaxDP, (state, payload) => {
        console.log('Rehydrate Tax', state, payload)
        sessionStorage.setItem('taxDP', JSON.stringify(payload));
        return payload;
    }),
    on(submitTaxDP, (state, payload) => {
        console.log('Submit Tax Initial Payments', state, payload)
        return state;
    })
);

export function taxDownpaymentReducer(state, action) {
    return _taxDownpaymentReducer(state, action);
}
