import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { multiProductPaymentTermsChanged, resetMultiProductPaymentTermsChanged } from '../_actions/multi-product-flow-payment-terms-changed.actions';


const _multiProductFlowPaymentTermsChangedReducer = createReducer(initialState,
    on(multiProductPaymentTermsChanged, (state, payload) => {
        return true;
    }),
    on(resetMultiProductPaymentTermsChanged, (state, payload) => {
        return null;
    })
);

export function multiProductFlowPaymentTermsChangedReducer(state, action) {
    return _multiProductFlowPaymentTermsChangedReducer(state, action);
}