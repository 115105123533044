import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { saveCart, cartSaved, cartSavedSuccess, updateCart, updateCartSuccess, clearCart, rehydrateCart, updateNISCart, getCart, getCartSuccess, prepCartForOrder } from '../_actions/cart.actions';


const _cartReducer = createReducer(initialState,
    on(saveCart, (state, payload) => {
        console.log('Cart Loaded', state, payload)
        sessionStorage.setItem('cart', JSON.stringify(payload));
        return payload;
    }),
    on(cartSaved, (state, payload) => {
        console.log('Cart Saved', state, payload)
        sessionStorage.setItem('cart', JSON.stringify(payload));
        return payload;
    }),
    on(cartSavedSuccess, (state, payload) => {
        console.log('Cart Saved Success', state, payload)
        sessionStorage.setItem('cart', JSON.stringify(payload));
        return payload;
    }),
    on(updateCart, (state, payload) => {
        console.log('Cart Updated', state, payload)
        sessionStorage.setItem('cart', JSON.stringify(payload));
        return payload;
    }),
    on(updateCartSuccess, (state, payload) => {
        console.log('Cart Updated Success', state, payload)
        sessionStorage.setItem('cart', JSON.stringify(payload));
        return payload;
    }),
    on(clearCart, (state) => {
        console.log('Cart Cleared', state)
        sessionStorage.removeItem('cart');
        sessionStorage.removeItem('numOfMonthlyPayments');
        return null;
    }),
    on(rehydrateCart, (state, payload) => {
        // console.log('Rehydrate Cart', state, payload);
        sessionStorage.setItem('cart', JSON.stringify(payload))
        return payload;
    }),
    on(updateNISCart, (state, payload) => {
        console.log('Update NIS Cart', state, payload)
        sessionStorage.setItem('cart', JSON.stringify(payload))
        return payload;
    }),
    on(getCart, (state, payload) => {
        console.log('Get Cart', state, payload)
        return state;
    }),
    on(getCartSuccess, (state, payload) => {
        console.log('Get Cart Success', state, payload)
        sessionStorage.setItem('cart', JSON.stringify(payload))
        return payload;
    }),
    on(prepCartForOrder, (state, payload) => {
        console.log('Get Cart Success', state, payload)
        return state;
    })
);

export function cartReducer(state, action) {
    return _cartReducer(state, action);
}
