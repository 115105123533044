import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '../appconfig/appconfig.service';
import brandInfo from '../../environments/brand.json'

@Component({
  selector: 'app-session-storage-data',
  templateUrl: './session-storage-data.component.html',
  styleUrls: ['./session-storage-data.component.scss']
})
export class SessionStorageDataComponent implements OnInit {
  isOfferTypeSet: boolean = false;
  branding: any;


  constructor(private router: Router) {}

  ngOnInit(): void {
    if (['GMC', 'Cadillac', 'Chevrolet', 'Buick'].includes(brandInfo.brand)) {
      sessionStorage.setItem('offerType', 'true');
    }

    this.router.navigate(['/vehicle-service-protection']);
  }

}
