import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, throwError } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import * as ActionConfigurationManager from '.././_actions/config.actions';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Action, Store } from '@ngrx/store';
import { PaypalTransactionService } from '../_services/paypal-transaction.service';
import { cancelAuthorizedPaymentFailed, cancelAuthorizedPaymentSuccess, createPaypalPaymentTokenFailure, createPaypalPaymentTokenSuccess, paypalOauth2TokenFailure, paypalOauth2TokenSuccess, paypalSetupTokenFailure, paypalSetupTokenSuccess } from '../_actions/paypal.action';


@Injectable()

export class PaypalTransactionEffects {

  //call to to cancel an authorized paypal transaction
  public voidPaypalTransaction$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionConfigurationManager.CANCEL_AUTHORIZED_PAYMENT),
      switchMap((action: any) => this.paypalTransactionService.voidPaypalTransaction(action)
        .pipe(
          map(value => {
            if (value) {
              return cancelAuthorizedPaymentSuccess({ value })
            }else {
              return cancelAuthorizedPaymentFailed(value)
            }
          }),
          catchError((error) => {
            return throwError(error)
          })

        )
      )

    )
  )
  // paypal authorization token request
  public paypalOauth2Token$: Observable<Action> = createEffect(() =>
  this.actions$.pipe(
    ofType(ActionConfigurationManager.PAYPAL_OAUTH2_TOKEN),
    switchMap(() => this.paypalTransactionService.paypalOauth2Token()
      .pipe(
        map(value => {
          if (value) {
            return paypalOauth2TokenSuccess(value)
          }else {
            return paypalOauth2TokenFailure(value)
          }
        }),
        catchError((error) => {
          return throwError(error)
        })

      )
    )

  )
)
   //call to to paypal setup token transaction
   public paypalSetupToken$: Observable<Action> = createEffect(() =>
   this.actions$.pipe(
     ofType(ActionConfigurationManager.PAYPAL_SETUP_TOKEN),
     switchMap((action: any) => this.paypalTransactionService.paypalSetupToken(action)
       .pipe(
         map(value => {
           if (value) {
             return paypalSetupTokenSuccess({ value })
           }else {
             return paypalSetupTokenFailure(value)
           }
         }),
         catchError((error) => {
           return throwError(error)
         })

       )
     )
   )
 )
   //call to to create paypal payment token transaction
   public createPaypalPaymentToken$: Observable<Action> = createEffect(() =>
   this.actions$.pipe(
     ofType(ActionConfigurationManager.CREATE_PAYPAL_PAYMENT_TOKEN),
     switchMap((action: any) => this.paypalTransactionService.createPaypalPaymentToken(action)
       .pipe(
         map(value => {
           if (value) {
             return createPaypalPaymentTokenSuccess({ value })
           }else {
             return createPaypalPaymentTokenFailure(value)
           }
         }),
         catchError((error) => {
           return throwError(error)
         })

       )
     )

   )
 )

  constructor(
    private actions$: Actions,
    private paypalTransactionService: PaypalTransactionService,
    private sanitizer: DomSanitizer
  ) { }

}


