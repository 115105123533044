import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, ActivatedRoute } from "@angular/router";
import { HomeService } from "./home.service";

@Injectable()
export class ProductHomeResolve implements Resolve<any> {
  constructor(private home: HomeService) {}

  resolve(route: ActivatedRouteSnapshot) {

    // Get query Params
    return this.home.resolvePage(route);

  }
}
