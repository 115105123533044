
<div class="loading" *ngIf="(inlineLoader$ | async)">
    <!--Big blue-->
    <div class="preloader-wrapper active indicator">
        <div class="spinner-layer spinner-client-color">
            <div class="circle-clipper left">
                <div class="circle"></div>
            </div>
            <div class="gap-patch">
                <div class="circle"></div>
            </div>
            <div class="circle-clipper right">
                <div class="circle"></div>
            </div>
        </div>
    </div>
</div>