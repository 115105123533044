import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: "app-tooltip",
    template: `
                <span tabindex="0" role="tooltip" id={{id}}
                (click)="toggleTooltip(tooltip, $event)"
                (focus)="focusTooltip(tooltip, $event)" 
                (mouseenter)="focusTooltip(tooltip, $event)" 
                (mouseleave)="blurTooltip(tooltip)"
                (blur)="blurTooltip(tooltip)">
                    <span class="click-text" *ngIf="clickText">{{clickText}}</span>
                    <i *ngIf="!clickText" aria-hidden="true" class="fas {{icon}}"></i>
                </span>
                <span aria-hidden="true" tabindex="-1" #tooltip="mdb-tooltip" mdbTooltip="{{message}}" placement="{{placement}}"></span>
                `
  })
  

export class AppTooltipComponent implements OnInit {
    @Input() message = '';
    @Input() icon = 'fa-info-circle'
    @Input() clickText = null;
    @Input() placement = 'top';
    public id: string;

    constructor(){ }

    ngOnInit() {
        this.id = "tooltip_" + Math.random();
    }

    blurTooltip(tooltip) {
        let thisTooltip = window.document.getElementById(this.id) as HTMLElement;
        thisTooltip.removeAttribute("aria-live"); 
        thisTooltip.setAttribute('aria-label', '');              
        tooltip.hide();
    }
    
    focusTooltip(tooltip, $event) { 
        let thisTooltip = window.document.getElementById(this.id) as HTMLElement;
        let message  = this.message;
        thisTooltip.removeAttribute("aria-live"); 
        thisTooltip.removeAttribute('aria-label'); 
        thisTooltip.removeAttribute('role'); 
        setTimeout(() => {
        thisTooltip.setAttribute('aria-label', message);
        thisTooltip.setAttribute('aria-live', 'polite');
        thisTooltip.setAttribute('role', 'tooltip');
        thisTooltip.focus();
        tooltip.show($event);
        }, 250)
    }
    
    toggleTooltip(tooltip, $event) {
       let thisTooltip = window.document.getElementById(this.id) as HTMLElement;
       let message  = this.message;
       thisTooltip.removeAttribute("aria-live"); 
       thisTooltip.removeAttribute('aria-label');  
       thisTooltip.removeAttribute('role');
       if (tooltip.isOpen) {  
          tooltip.hide();
       }
       if (!tooltip.isOpen) {
         setTimeout(() => {
          thisTooltip.setAttribute('aria-label', message);
          thisTooltip.setAttribute('aria-live', 'polite');
          thisTooltip.setAttribute('role', 'tooltip');
          thisTooltip.focus();
          tooltip.show($event);
         }, 175);
      }
    } 
    
}
