import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { submitAVS, avsSubmitted, rehydrateAVS } from '../_actions/avs.actions';


const _avsReducer = createReducer(initialState,
    on(submitAVS, (state, payload) => {
        console.log('Submit AVS', state, payload)
        return state;
    }),
    on(avsSubmitted, (state, payload) => {
        console.log('AVS Submitted', state, payload)
        window.sessionStorage.setItem('avs', JSON.stringify(payload));
        return payload;
    }),
    on(rehydrateAVS, (state, payload) => {
        console.log('Rehydate AVS', state, payload)
        sessionStorage.setItem('avs', JSON.stringify(payload));
        return payload;
    })
);

export function avsReducer(state, action) {
    return _avsReducer(state, action);
}