<div class="modal-content">
  <div class="modal-header mb-n1">
    <span aria-hidden="true"><mdb-icon tabindex="0" class="modal-close-icon" aria-label="Close" (click)="modalRef.hide()" fas icon="times"></mdb-icon></span>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center">
        <h3>{{ title }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <div class="pb-4">{{ subheader }}</div>
      </div>
    </div>
  </div>
</div>
