<div class="example-container">
  <div class="example-table-container">
    <table mat-table matSort [dataSource]="dataSource"> <ng-container *ngIf="selectable" matColumnDef="select">
      </ng-container>
      <ng-container *ngFor="let col of tableCols; let i = index" [matColumnDef]="col.key">
        <th class="table-header" mat-header-cell *matHeaderCellDef> {{ tableCols[i]['display'] }} </th>
        <td mat-cell [ngStyle]="col.style" *matCellDef="let element">
          <!-- Checking if it's a normal column or column with specific config -->
          <ng-container
            *ngIf="!col.config; else hasConfig"> {{ element[col.key] }} </ng-container> <ng-template #hasConfig>
            <ng-container *ngIf="col.config?.clickableCell"> <a (click)="handleClickAction(element)"
                class="clickable-cell"> {{ element[col.key] }} </a> </ng-container>
            <!-- if this attribute has an image to display -->
            <ng-container *ngIf="col.config?.hasImage"> <a
                (click)="handleClickAction(element)" class="clickable-cell"> <img class="table-img"
                  [src]="generateGravatarUrl(col.key)" />{{ element[col.key] }} </a> </ng-container>
            <!-- if this attribute is a date, we will format it depending on format value in config object -->
            <ng-container *ngIf="col.config?.isDate"> {{ element[col.key] * 1000 | date : col.config?.format }}
            </ng-container>
            <ng-container *ngIf="col.config?.isToggle"> <mat-slide-toggle [checked]="element[col.key]"></mat-slide-toggle>
            </ng-container> </ng-template> </td>
      </ng-container>
      <tr class="row-table-ihnor" mat-header-row *matHeaderRowDef="keys"></tr>
      <tr mat-row *matRowDef="let row; columns: keys"></tr>
      <tr *matNoDataRow>
        <td class="no-result-cell" colspan="10">
          <div class="no-result-div">
            <div class="no-result-title">Oops, No Records Found</div>
            <div class="no-result-description" *ngIf="searchTerm"> We are unable to find the record you are looking for.
              Try another search term. </div>
          </div>
        </td>
      </tr>
    </table>
  </div> <ng-container *ngIf="!preview"> <mat-paginator [pageSizeOptions]="paginationValues"
      aria-label="Select page of records" class="roboto"></mat-paginator> </ng-container>
</div>
