import { Injectable } from "@angular/core";
import LogRocket from "logrocket";
import brandInfo from "../../environments/brand.json";
import { last, Observable, take, throwError } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GatewayService } from "./gateway.service";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})


// Capture Console Errors
// Capture API Errors
export class LoggingService {
  constructor(private httpClient: HttpClient, private gatewayService: GatewayService) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  init() {
    // Initialize LogRocket with your app ID
    if(environment.production){
      LogRocket.init(`${environment.logrocket}`, {
        dom: {
          inputSanitizer: true,
        },
        network: {
          isEnabled: false,
        },
        console: {
          isEnabled: false,
        },
      });
    }

  }

  storeLogRocket(lead, payload) {
    const logRocketUser = {
      name: `${lead.customer.firstName} ${lead.customer.lastName}`,
      phoneNumber1: `${lead.customer.phoneNumber1}`,
      phoneNumber2: `${lead.customer.phoneNumber2}`,
      AgentId: payload.AgentId ? payload.AgentId : "",
      prid: payload.prid ? payload.prid : "",
    };

    console.log("Storing User to LogRocket: ", lead.customer.contactSfId, logRocketUser, payload.oid);
    if(environment.production){
      LogRocket.identify(payload.oid, logRocketUser);
    }
  }

  static captureLogRocketError(httpResponse, type = "Error", message = "Error") {
    let _vehicle = sessionStorage.getItem("vehicle");

    let _urlParams = JSON.parse(sessionStorage.getItem("urlParams"));

      console.log({
        brand: brandInfo.brand,
        oid: _urlParams && _urlParams.oid || "",
        prid: _urlParams && _urlParams.prid || "",
        error: JSON.stringify(httpResponse.error),
        httpResponse: JSON.stringify(httpResponse),
      });
      if(environment.production){
        LogRocket.captureMessage(message, {
          tags: {
            errorType: type,
          },
          extra: {
            brand: brandInfo.brand,
            oid: _urlParams && _urlParams.oid || "",
            prid: _urlParams && _urlParams.prid || "",
            error: JSON.stringify(httpResponse.error),
            httpResponse: JSON.stringify(httpResponse),
          },
        });
    }
  }

  captureD2CError(httpResponse, type = "Error", message = "Error", severity, service) {
    let _lead = JSON.parse(sessionStorage.getItem("lead"));
    let _urlParams = JSON.parse(sessionStorage.getItem("urlParams"));

    let url = "";
    if(service ==="payment"){
      url = httpResponse.url;
    }else {
      url = httpResponse.error.path
    }


    let error = {
      severity: severity,
      type: type,
      url: url,
      message: message,
      request: {
        url: url
      },
      response: httpResponse,
      notes: {
        brand: brandInfo.brand,
        oid: _urlParams.oid || "",
        prid: _urlParams.prid || "",
        vehicle: _lead ? (_lead.vehicle || {}): "",
        customer: _lead ? (_lead.customer || {}): "",
      },
    };
    return this.httpClient.post<any>(environment.apiUrl + "/v1/api/error", error);

  }

  captureException(error: Error, type: string) {

    if(environment.production){

      LogRocket.captureException(error, {
          tags: {
            errorType: type,
          },
          extra: {
            brand: brandInfo.brand,
            // pageName
            // errorData
            // apiLink
          },
        });
    }
  }


  handleErrorWithD2CCapture(httpResponse, severity, service) {
    let errorMessage = "";
    let correlationId = sessionStorage.getItem("correlationId");

    console.log("handleErrorWithD2CCapture this: ", httpResponse);
    LoggingService.captureLogRocketError(httpResponse, "API", httpResponse.message || "Error Performing Request:");

    let message_short = "";

    if(httpResponse.error){

      if(httpResponse.error.message){
        message_short = httpResponse.error.message.substring(0, 60)+" - "+correlationId+"..." || "Error Performing Request";
      } else if(httpResponse.error.errorMessage){
        message_short = `${service}: ${httpResponse.error.errorMessage.substring(0, 60)} - correlationId: ${correlationId}` || "Error Performing Request";
      }
      else if(httpResponse.error.data){
        message_short = httpResponse.error.data.substring(0, 60)+" - "+correlationId+"..." || "Error Performing Request";
      }else{
        message_short = "Error Performing Request";
      }
    }

    this.captureD2CError(httpResponse, "API", message_short, severity, service).pipe(take(1)).subscribe(value =>     console.error("captureD2CError Calling D2C Error API...", value));

    if (httpResponse.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = httpResponse.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${httpResponse.status}\nMessage: ${httpResponse.message}`;
    }
    console.log("handleErrorWithD2CCapture: ", errorMessage);
    return throwError(errorMessage);
  }
  // Error handling
  handleError(httpResponse) {
    let errorMessage = "";
    console.log("this: ", httpResponse);
    LoggingService.captureLogRocketError(httpResponse, "API", httpResponse.message || "Error Performing Request:");

    if (httpResponse.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = httpResponse.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${httpResponse.status}\nMessage: ${httpResponse.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  captureMessage(message = "Error ", type) {
    if(environment.production){
      LogRocket.captureMessage(message, {
        tags: {
          errorType: type,
        },
        extra: {
          brand: brandInfo.brand,
          // pageName
          // errorData
          // apiLink
        },
      });
    }
  }
}
