<!-- NO ORDERS FOUND MODAL BEGIN -->
<div style="overflow-y: auto" mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="myBasicModalLabel" aria-hidden="true">
    <div class="modal-dialog medium modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header mb-n1">
                <app-close-button (callbackFunction)="basicModal.hide()"></app-close-button>
            </div>
            <div class="modal-body mb-4">
                <div class="row justify-content-md-center">
                    <div class="col-md-10">
                        <h4 class="text-center mb-4">{{ 'MY_ACCOUNT_LOGIN.ACCOUNT_NOT_READY' | translate }}</h4>
                        <form class="text-left">
                            <div class="alert alert-warning text-center" role="alert">
                                {{ 'MY_ACCOUNT_LOGIN.ALLOW_TIME' | translate }}
                            </div>
                        </form>
                        <div class="pt-5 text-center">
                            <button mdbBtn class="btn primary-btn waves-light" mdbWavesEffect type="submit"
                                (click)="basicModal.hide()">{{ 'MY_ACCOUNT_LOGIN.GO_BACK' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- NO ORDERS FOUND MODAL END -->