import { Component, OnInit, Input } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ModalDisclaimerComponent } from 'src/app/modal-disclaimer/modal-disclaimer.component';
import { PrismicService } from 'src/app/_services/prismic.service';

@Component({
  selector: 'app-component-icon-group',
  templateUrl: './icon-group.component.html',
  styleUrls: ['./icon-group.component.scss']
})
export class IconGroupComponent implements OnInit {
  @Input() public component: any;
  @Input() public text_color: any;
  @Input() public text_align: any;

  @Input() public vehicleObject: any;
  @Input() ngStyle: { [klass: string]: any; }
  modalRef: MDBModalRef;


  slideConfig = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    centerMode: false,
    speed: 300,
    centerPadding: '30px',
    dots: true,
  };

  constructor(private prismicService: PrismicService, private modalService: MDBModalService) { }

  ngOnInit() {
  }

  showNotification(disclaimer_text) {
    const modalOptions = {
      backdrop: false,
      keyboard: true,
      focus: true,
      show: false,
      scroll: false,
      ignoreBackdropClick: false,
      class: "modal-fluid modal-full-height modal-bottom modal-dialog-full modal-dark",
      containerClass: "fade bottom",
      animated: true,
      data: {
        disclaimer_text: disclaimer_text,
      },
    };

    this.modalRef = this.modalService.show(ModalDisclaimerComponent, modalOptions);
  }

  getHtml(content, vehicleObject) {
    return this.prismicService.getHtml(content, vehicleObject);
  }


}
