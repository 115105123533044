import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-modal-browser-warning',
  templateUrl: './modal-browser-warning.component.html',
  styleUrls: ['./modal-browser-warning.component.scss']
})
export class ModalBrowserWarningComponent implements OnInit {

  constructor(public modalRef: MDBModalRef) { }

  ngOnInit(): void {
  }

}
