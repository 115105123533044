<!-- <div class="loading" *ngIf="isPageLoading" @fadeOutAnimation>
  <div class="spinner-grow indicator" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div> -->
<div class="loading" *ngIf="isPageLoading" @fadeOutAnimation>
  <!--Big blue-->
  <div class="preloader-wrapper active indicator">
    <div class="spinner-layer spinner-client-color">
      <div class="circle-clipper left">
        <div class="circle"></div>
      </div>
      <div class="gap-patch">
        <div class="circle"></div>
      </div>
      <div class="circle-clipper right">
        <div class="circle"></div>
      </div>
    </div>
  </div>
</div>

<app-navbar></app-navbar>

<main id="mainContent">
  <div class="container-fluid h-100">
    <router-outlet #outlet="outlet"></router-outlet>
    <app-footer></app-footer>
  </div>
</main>

<div id="languageDropdown" *ngIf="languageDropdown">
  <select (change)="changeLanguage($event.target.value)">
    <option *ngFor="let lang of supportedLangs" [value]="lang.code" [selected]="lang.code == currentLangCode.code">
      {{lang.label}}</option>
  </select>
</div>