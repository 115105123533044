import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { updateContactStateOn } from '../_actions/account.actions';


const _updateContactStateReducer = createReducer(initialState,
    on(updateContactStateOn, (state) => {
      // To add a lead to state, use spread operator
      return true;
    })
);

export function updateContactStateReducer(state, action) {
    return _updateContactStateReducer(state, action);
}
