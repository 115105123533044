import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { loadVehicle, updateOdometer, updateVehicleByVin, updateVehicleByYmmt, rehydrateVehicle, updateVIN } from '../_actions/vehicle.actions';
import { saveUrlParams } from '../_actions/urlParams.actions';


const _urlParamsReducer = createReducer(initialState,
    on(saveUrlParams, (state, payload) => {
         console.log('Rehydrate URL Params', payload);
        window.sessionStorage.setItem('urlParams', JSON.stringify(payload));
        return payload;
    })
);

export function urlParamsReducer(state, action) {
    return _urlParamsReducer(state, action);
}
