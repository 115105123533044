  <ngx-slick-carousel #slickModal="slick-carousel" class="carousel" (init)="slickInit($event)" [config]="slickConfig" (beforeChange)="beforeChange($event)">
    <div ngxSlickItem *ngFor="let field of component.fields" class="slide" style="position:relative"> 
      <div class="desktop desktop-image-carousel-container" style="background-image:url({{field.desktop_image.url}});">
        <div *ngIf="field.desktop_text" class="desktop-image-carousel" [innerHTML]="field.desktop_text[0].text | safeHtml"></div>  
      </div>
      <div class="mobile mobile-image-carousel-container" style="background-image:url({{field.mobile_image.url}});">
        <div *ngIf="field.mobile_text" class="mobile-image-carousel" [innerHTML]="field.mobile_text[0].text | safeHtml"></div>  
      </div>
    </div>
  </ngx-slick-carousel>
  
