import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/_services/home.service';
import { PrismicService } from 'src/app/_services/prismic.service';
import brandInfo from "src/environments/brand.json";
@Component({
  selector: 'app-footer-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {
  disclaimers: any;
  footer: any;
  constructor(private home: HomeService, private prismicService: PrismicService) { }

  ngOnInit(): void {

    this.home.getFooter(brandInfo.brand, sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language).subscribe({
      next: (result) => {
        this.footer = result;
        if (this.footer.body) {
          for (const footerContent of this.footer.body) {
            switch (footerContent.type) {
              case "disclaimers":
                this.disclaimers = this.prismicService.getHtml(
                  footerContent.primary.disclaimer_body
                );
                break;
              default:
                break;
            }
          }
        }
      },
      error: (error) => {}
    });

  }

}
