<section>
    <div class="view-contract pdf-iframe" *ngIf="pdf !== null">
        <!-- <pdf-viewer
            [src]="src"
            [render-text]="true"
            [original-size]="false"
            style="height: 100%">
        </pdf-viewer> -->
        <iframe [attr.src]="src" style="height: 100%; width:100%; border:0"></iframe>
    </div>

    <div class="text-center mt-2 mb-2" *ngIf="pdf !== null">
        <a role="link" tabindex="0" class="blue-link uppercase" mdbWavesEffect id="download-contract-button"
            (click)="downloadPDF()">{{
            'MY_ACCOUNT_LOGIN.DOWNLOAD_CONTRACT' | translate }}
        </a>
    </div>


    <div class="parent" *ngIf="pdf === null">
        <div class="centered-div text-center">
          <p>Sorry, the link to your Customer Agreement has expired.</p>
          <p>Please call {{branding.cs_phone_number}} for assistance.</p>
        </div>
    </div>
</section>
