import * as ActionConfigurationManager from './config.actions';

export const submitTax = ActionConfigurationManager.SUBMIT_FOR_TAX;
export const taxSubmitted = ActionConfigurationManager.TAX_SUBMITTED;
export const updateTax = ActionConfigurationManager.UPDATE_TAX;
export const taxUpdated = ActionConfigurationManager.TAX_UPDATED;
export const rehydrateTax = ActionConfigurationManager.REHYDRATE_TAX;
export const submitTaxMP = ActionConfigurationManager.SUBMIT_FOR_TAX_MP
export const submitTaxDP = ActionConfigurationManager.SUBMIT_FOR_TAX_DP
export const taxSubmittedDP = ActionConfigurationManager.TAX_SUBMITTED_DP;
export const updateTaxDP = ActionConfigurationManager.UPDATE_TAX_DP;
export const taxUpdatedDP = ActionConfigurationManager.TAX_UPDATED_DP;
export const rehydrateTaxDP = ActionConfigurationManager.REHYDRATE_TAX_DP;
export const taxSubmittedMP = ActionConfigurationManager.TAX_SUBMITTED_MP;
export const updateTaxMP = ActionConfigurationManager.UPDATE_TAX_MP;
export const taxUpdatedMP = ActionConfigurationManager.TAX_UPDATED_MP;
export const rehydrateTaxMP = ActionConfigurationManager.REHYDRATE_TAX_MP;
