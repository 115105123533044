<!-- PRICE MODAL -->
<div style="overflow-y: auto" mdbModal #priceModal="mdbModal" class="prices-modal modal fade" tabindex="-1" role="dialog" aria-labelledby="priceModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
        <div class="modal-header mb-n1">
            <app-close-button (callbackFunction)="priceModal.hide()"></app-close-button>
        </div>
        <div class="modal-body p-0">
            <div [ngSwitch]="view">
                <div class="row">
                    <div class="col-12 text-center">
                        <h2 class="mb-0">{{selectedPlan?.title}}</h2>
                    </div>
                </div>
                <div *ngSwitchDefault>
                    <p class="mt-0 text-center">Choose your coverage length and payment plan.</p>
                    <div class="customize-plan pt-3 pb-3 pr-4 pl-4">
                        <div class="row">
                            <div class="customize-term col-12">
                                <div class="col-12"><h5>I want to be covered for...</h5></div>
                                <div class="row d-flex mt-4">
                                    <div class="col-md-4 col-sm-12">
                                        <button id="3" (click)="getTermDataYears($event, 3)"  class="btn white-btn waves-light w-100" [ngClass]="{'active': planId === 3}" mdbBtn mdbWavesEffect>3 Years, 36,000 Miles</button>
                                    </div>
                                    <div class="col-md-4 col-sm-12">
                                        <button id="5" (click)="getTermDataYears($event, 5)" class="btn white-btn waves-light w-100" [ngClass]="{'active': planId === 5}" dbBtn mdbWavesEffect>5 Years, 60,000 Miles</button>
                                    </div>
                                    <div class="col-md-4 col-sm-12">
                                        <button id="7" (click)="getTermDataYears($event, 7)" class="btn white-btn waves-light w-100" [ngClass]="{'active': planId === 7}" mdbBtn mdbWavesEffect>7 Years, 84,000 Miles</button>
                                    </div>
                                </div>
                            </div>
                            <div class="customize-payments col-12 mt-4">
                                <div class="col-12"><h5>I want to pay off my plan in...</h5></div>
                                <div class="row mt-4">
                                    <div class="col-md-4 col-sm-12">
                                        <button id="12" (click)="getTermDataNumOfPayments($event, 12)" class="btn white-btn waves-light w-100 active" [ngClass]="{'active': planPayments === 12}" mdbBtn mdbWavesEffect>12 Payments</button>
                                    </div>
                                    <div class="col-md-4 col-sm-12">
                                        <button id="6" (click)="getTermDataNumOfPayments($event, 6)" class="btn white-btn waves-light w-100" [ngClass]="{'active': planPayments === 6}" mdbBtn mdbWavesEffect>6 Payments</button>
                                    </div>
                                    <div class="col-md-4 col-sm-12">
                                        <button id="1" (click)="getTermDataNumOfPayments($event, 1)" class="btn white-btn waves-light w-100" [ngClass]="{'active': planPayments === 1}" mdbBtn mdbWavesEffect>1 Payment</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 pt-3 text-center pb-3 pr-4 pl-4 mt-4" style="background:var(--grey-lite);">
                            <a class="btn primary-btn waves-light" mdbBtn mdbWavesEffect (click)="view='2'"> Next: Pick Addons <mdb-icon fas icon="arrow-right"></mdb-icon></a>
                        </div>
                    </div>
                </div>

                <div *ngSwitchCase="2">
                    <p class="mt-0 text-center">Next, select an amazing addon!</p>
                    <div class="row p-3 row-eq-height">
                        <div *ngFor="let addon of addons" class="p-2 col addon-wrap">
                            <mdb-card class="p-0 w-100 h-100">
                                <!-- <mdb-card-img width="100%" src="{{addon.image}}"></mdb-card-img> -->
                                <mdb-card-body>
                                    <mdb-card-title class="text-center">
                                        <!-- <mdb-icon far icon="{{addon.icon}}"></mdb-icon> -->
                                        <h3>{{addon.title}}</h3>
                                    </mdb-card-title>
                                    <mdb-card-text class="pt-2">
                                        <ul>
                                            <li *ngFor="let feature of addon.features">
                                                {{feature.benefit}}
                                            </li>
                                        </ul>
                                    </mdb-card-text>
                                </mdb-card-body>
                                <mdb-card-footer class="text-center addon-button">
                                    <p><strong>Add only ${{addon.price}} per month</strong></p>
                                    <button mtbBtn class="btn addon-btn addon-add w-100" [class.active]="isActive" (click)="toggleAddon(addon)"><mdb-icon fas icon="plus"></mdb-icon> Add</button>
                                </mdb-card-footer>
                            </mdb-card>
                        </div>
                    </div>
                    <div class="row desktop">
                        <div class="col-12 w-100 float-left pt-3 text-center pb-3 pr-4 pl-4 mt-4" style="background:var(--grey-lite);">
                            <a class="btn primary-btn waves-light float-left" mdbBtn mdbWavesEffect (click)="view=''"><mdb-icon fas icon="arrow-left"></mdb-icon> Go Back</a>
                            <a class="btn primary-btn waves-light float-right" mdbBtn mdbWavesEffect (click)="view='3'">Next: Verify VIN <mdb-icon fas icon="arrow-right"></mdb-icon></a>
                        </div>
                    </div>
                    <div class="row mobile">
                        <div class="col-12 w-100 float-left pt-3 text-center pb-3 pr-4 pl-4 mt-4" style="background:var(--grey-lite);">
                            <a class="btn primary-btn waves-light float-right" mdbBtn mdbWavesEffect (click)="view='3'">Next: Verify VIN <mdb-icon fas icon="arrow-right"></mdb-icon></a>
                            <a class="w-100 float-left pt-3" mdbWavesEffect (click)="view=''"><mdb-icon fas icon="arrow-left"></mdb-icon> Go Back</a>
                        </div>
                    </div>
                </div>

                <div *ngSwitchCase="3">
                    <div class="row pb-4 pl-3 pr-3">
                        <div class="col-12 text-center">
                            <p>Please carefully confirm your vehicle's VIN before checkout out.</p>
                        </div>
                    </div>
                    <div class="row pb-3 pr-4 pl-4">
                        <div class="col"></div>
                        <div class="col-xl-6 col-sm-12 center">
                            <form class="text-center">
                                <div class="pr-3 pl-3">
                                    <div class="md-form mt-3 mb-3">
                                        <label for="odometer">VIN</label>
                                        <input type="text" id="odometer" class="form-control" mdbInput required value="XXXXXXXXXXX384T75">
                                        <div class="invalid-feedback">
                                        Please enter your VIN.
                                        </div>
                                    </div>
                                    <span class="model-verify">
                                        <h5 style="color:#29af5e!important;">
                                            <mdb-icon fas icon="check"></mdb-icon> {{vehicleYear}} {{vehicleMake}} {{vehicleModel}} Confirmed!
                                        </h5>
                                    </span>
                                </div>
                            </form>
                        </div>
                        <div class="col"></div>
                    </div>
                    <div class="row desktop">
                        <div class="col-12 w-100 float-left pt-3 text-center pb-3 pr-4 pl-4 mt-4" style="background:var(--grey-lite);">
                            <a class="btn primary-btn waves-light float-left" mdbBtn mdbWavesEffect (click)="view='2'"><mdb-icon fas icon="arrow-left"></mdb-icon> Go Back</a>
                            <a routerLink="/cart" routerLinkActive="active" class="btn primary-btn waves-light float-right" mdbBtn mdbWavesEffect>Next: Shopping Cart <mdb-icon fas icon="arrow-right"></mdb-icon></a>
                        </div>
                    </div>
                    <div class="row mobile">
                        <div class="col-12 w-100 float-left pt-3 text-center pb-3 pr-4 pl-4 mt-4" style="background:var(--grey-lite);">
                            <a routerLink="/cart" routerLinkActive="active" class="btn primary-btn waves-light float-right" mdbBtn mdbWavesEffect>Next: Shopping Cart <mdb-icon fas icon="arrow-right"></mdb-icon></a>
                            <a class="w-100 float-left pt-3" mdbWavesEffect (click)="view='2'"><mdb-icon fas icon="arrow-left"></mdb-icon> Go Back</a>
                        </div>
                    </div>
                </div>

                <div class="prices row">
                    <div class="payment col-md-6 col-sm-12">
                        <div class="row">
                            <div class="col-md-2 col-sm-1"></div>
                            <div class="col-md-2 col-sm-3 pt-2 text-center desktop">
                                <mdb-icon class="icon" fas icon="dollar-sign"></mdb-icon>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <p class="mb-0">{{numOfPayments}} PAYMENTS OF</p>
                                <h4>{{monthlyPayments}}</h4>
                            </div>
                            <div class="col-md-2 col-sm-1"></div>
                        </div>
                    </div>
                    <div class="term col-md-6 col-sm-12">
                        <div class="row">
                            <div class="col-md-2 col-sm-1"></div>
                            <div class="col-md-2 col-sm-3 pt-2 text-center desktop">
                                <mdb-icon class="icon" fas icon="shield-alt"></mdb-icon>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <p class="mb-0">PROTECTED UNTIL</p>
                                <h4>{{currentMonth}} {{currentYear}}</h4>
                            </div>
                            <div class="col-md-2 col-sm-1"></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="modal-footer">
            <div class="w-100 text-center">
                <a>Need Help?</a>
            </div>
        </div>
        </div>
    </div>
</div>
<!-- PRICE MODAL -->
